var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { GalleryService } from '../gallery.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
export class AlbumPhotosComponent {
    constructor(router, activatedRoute, galleryService, snackBar, modalService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.galleryService = galleryService;
        this.snackBar = snackBar;
        this.modalService = modalService;
        this.albumDescTitleModel = {};
        this.activatedRoute.params.subscribe((parameter) => __awaiter(this, void 0, void 0, function* () {
            const { albumId, clientId } = parameter;
            if (localStorage.getItem('albumInfo')) {
                const albumData = JSON.parse(localStorage.getItem('albumInfo'));
                if (albumData.albumId === parseInt(albumId) && parseInt(clientId) === albumData.clientId) {
                    this.albumInfo = Object.assign({}, albumData);
                    this.loader = true;
                }
                else {
                    this.router.navigate([`client/${albumData.clientId}/gallery`]);
                }
            }
            else {
                this.router.navigate([`client/${clientId}/gallery`]);
            }
        }));
    }
    ngOnInit() {
        if (this.albumInfo) {
            this.galleryService.getAlbumById(this.albumInfo.albumId).subscribe((response) => {
                this.albumName = response.name;
                this.albums = response.albums;
                this.albums = this.albums.filter((album) => album.isDeleted === 0);
                if (this.albums && this.albums.length) {
                    for (let i = 0; i < this.albums.length; i++) {
                        const album = Object.assign({}, this.albums[i]);
                        this.albumDescTitleModel[album.id] = {
                            description: album.description,
                            title: album.title
                        };
                    }
                }
                this.loader = false;
            });
        }
    }
    cancel(row) {
        delete row['isEdit'];
        this.albumDescTitleModel[row.id] = {
            description: row.description,
            title: row.title
        };
    }
    set(row) {
        const data = {
            title: this.albumDescTitleModel[row.id].title,
            description: this.albumDescTitleModel[row.id].description
        };
        this.galleryService.updateAlbumPhoto(data, this.albumInfo.albumId, row.id).subscribe((response) => {
            delete row['isEdit'];
            Object.assign(row, data);
            const snackBarRef = this.snackBar.open('Photo Details Udated Successfully', 'Ok', {
                duration: 3000,
                panelClass: ['snackbar-success']
            });
        });
    }
    remove(row) {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = 'Delete Photo';
        modalRef.componentInstance.message = 'Do you want to delete Photo?';
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = 'Delete';
        modalRef.result.then((result) => {
            if (result === 'ok') {
                this.galleryService.deleteAlbumPhoto(this.albumInfo.albumId, row.id).subscribe((response) => {
                    this.albums = this.albums.filter((album) => album.id !== row.id);
                    delete this.albumDescTitleModel[row.id];
                    this.snackBar.open('Photo Deleted Successfully', '', {
                        duration: 2000,
                        panelClass: ['snackbar-success']
                    });
                });
            }
        }, () => { });
    }
}
