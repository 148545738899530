<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Bestellungen - GastroPay
        </h6>

        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Bezahlte Bestellungen">
            <div class="element-box">
              <div class="loading" *ngIf="!openOrders">
                <i class="fa fa-spinner fa-spin"></i>
                Bestellungen werden geladen
              </div>
              <ng-template [ngIf]="openOrders">
                <div *ngFor="let order of openOrders" class="open-order">
                  <div class="header-info">
                    <div>
                      <h4>Tisch {{ order.clientTableID }}</h4>
                      <small class="text-muted">Order #{{ order.id }}</small>
                    </div>
                    <div class="text-muted">{{ order.created | amTimeAgo }}</div>
                  </div>

                  <label>
                    Bestellte Artikel
                  </label>
                  <div>
                    <mat-checkbox
                      *ngFor="let item of order.items"
                      [class.text-warning]="item.deliveryStatus === 2"
                      [class.text-success]="item.deliveryStatus === 3"
                      [disabled]="item.deliveryStatus === 3"
                      [checked]="item.deliveryStatus === 3"
                      [(ngModel)]="item.checked"
                    >
                      {{ item.label }}
                    </mat-checkbox>
                  </div>

                  <div class="button-row">
                    <button class="btn btn-warning" (click)="markAsDone(order)">
                      <i class="fas fa-check fa-fw"></i>
                      Artikel fertig/abholbereit
                    </button>
                    <button class="btn btn-success" (click)="markAsFetched(order)">
                      <i class="fas fa-check-double fa-fw"></i>
                      Artikel abgeholt/geliefert
                    </button>
                  </div>
                </div>
              </ng-template>
            </div>
          </mat-tab>
          <mat-tab label="Unbezahlte Bestellungen">
            <div class="element-box">
              <div class="loading" *ngIf="!unpaidOrders">
                <i class="fa fa-spinner fa-spin"></i>
                Bestellungen werden geladen
              </div>
              <ng-template [ngIf]="unpaidOrders">
                <div *ngFor="let order of unpaidOrders" class="open-order">
                  <div class="header-info">
                    <div>
                      <h4>Tisch {{ order.clientTableID }}</h4>
                      <small class="text-muted">Order #{{ order.id }}</small>
                    </div>
                    <div class="text-muted">{{ order.created | amTimeAgo }}</div>
                  </div>

                  <label>
                    Bestellte Artikel
                  </label>
                  <div>
                    <mat-checkbox
                      *ngFor="let item of order.items"
                      [class.text-warning]="item.deliveryStatus === 2"
                      [class.text-success]="item.deliveryStatus === 3"
                      [disabled]="item.deliveryStatus === 3"
                      [checked]="item.deliveryStatus === 3"
                      [(ngModel)]="item.checked"
                    >
                      {{ item.label }}
                    </mat-checkbox>
                  </div>

                  <div class="button-row">
                    <button class="btn btn-warning" (click)="markAsDone(order)">
                      <i class="fas fa-check fa-fw"></i>
                      Artikel fertig/abholbereit
                    </button>
                    <button class="btn btn-success" (click)="markAsFetched(order)">
                      <i class="fas fa-check-double fa-fw"></i>
                      Artikel abgeholt/geliefert
                    </button>
                  </div>
                </div>
              </ng-template>
            </div>
          </mat-tab>
          <mat-tab label="Abgeschlossene Bestellungen">
            <div class="element-box">
              <div class="loading" *ngIf="!archive">
                <i class="fa fa-spinner fa-spin"></i>
                Bestellungen werden geladen
              </div>
              <ng-template [ngIf]="archive">
                <div *ngFor="let order of archive" class="open-order">
                  <div class="header-info">
                    <div>
                      <h4>Tisch {{ order.clientTableID }}</h4>
                      <small class="text-muted">Order #{{ order.id }}</small>
                    </div>
                    <div class="text-muted">{{ order.created | amTimeAgo }}</div>
                  </div>

                  <label>
                    Bestellte Artikel
                  </label>
                  <div>
                    <mat-checkbox
                      *ngFor="let item of order.items"
                      [class.text-warning]="item.deliveryStatus === 2"
                      [class.text-success]="item.deliveryStatus === 3"
                      [disabled]="item.deliveryStatus === 3"
                      [checked]="item.deliveryStatus === 3"
                      [(ngModel)]="item.checked"
                    >
                      {{ item.label }}
                    </mat-checkbox>
                  </div>
                </div>
              </ng-template>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
