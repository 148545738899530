<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Speisekarten verwalten
          <a
            class="btn btn-default btn-sm"
            ngbTooltip="Hilfe zu Speisekarten"
            href="https://blog.gastro.digital/article-categories/speisekarte/"
            target="_blank"
          >
            <mat-icon svgIcon="help"></mat-icon>
          </a>
        </h6>

        <div class="alert alert-warning" *ngIf="settings && settings.orderSystemVersion === 1">
          Bitte beachten Sie, dass die neue Speisekarte nur mit dem neuen Bestellsystem funktioniert. Bitte wenden Sie
          sich dazu an den Support.
        </div>

        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Aktive Speisekarten">
            <div class="element-box">
              <h5 class="form-header">Aktive Speisekarten</h5>
              <div class="form-desc">
                Hier finden Sie Ihre gültigen und aktiven Speise-, Getränke und Aktions-Karten.
                <button class="btn btn-primary" (click)="addMenu()" style="margin-top:15px">
                  <i class="fa fa-plus-circle fa-fw"></i> Neue Speisekarte anlegen
                </button>
              </div>

              <div class="alert alert-info" *ngIf="!menucards || !menucards.length" style="margin:0">
                Keine Speisekarten gefunden
              </div>
              <div class="table-responsive shifts" *ngIf="menucards && menucards.length">
                <table class="table table-padded">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Aktiv?</th>
                      <th>Name</th>
                      <th>Typ</th>
                      <th style="text-align:center">Foto</th>
                      <th>Gültig von</th>
                      <th>Gültig bis</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody cdkDropList (cdkDropListDropped)="dropMenu($event)">
                    <tr *ngFor="let menu of menucards" (cdkDropListDropped)="drop($event)" cdkDrag>
                      <td style="width:50px; padding: 0">
                        <button
                          class="btn btn-white btn-link"
                          cdkDragHandle
                          matTooltip="Klicken und verschieben um Reihenfolge zu ändern"
                        >
                          <i class="fas fa-sort"></i>
                        </button>
                      </td>
                      <td style="width:50px">
                        <mat-slide-toggle [(ngModel)]="menu.isActive" (change)="updateMenu(menu)"></mat-slide-toggle>
                      </td>
                      <td>{{ menu.name }}</td>
                      <td>
                        <div class="badge badge-default">{{ 'menucard.types.' + menu.type | translate }}</div>
                      </td>
                      <td style="text-align:center">
                        <i class="far fa-check-circle text-success" *ngIf="menu.photo"></i>
                        <i class="far fa-times-circle text-danger" *ngIf="!menu.photo"></i>
                      </td>
                      <td>{{ menu.validFrom | amDateFormat: 'DD.MM.YYYY' }}</td>
                      <td>{{ menu.validUntil | amDateFormat: 'DD.MM.YYYY' }}</td>
                      <td>
                        <button
                          class="btn btn-light"
                          (click)="manageMenu(menu.id)"
                          matTooltip="Verwalten Sie die Kategorien und Artikel der Speisekarte"
                        >
                          <i class="fas fa-grip-horizontal"></i>
                          Verwalten
                        </button>
                        <button class="btn btn-light" (click)="editMenu(menu)" matTooltip="Bearbeiten">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button class="btn btn-light" (click)="previewMenu(menu)" matTooltip="Vorschau">
                          <i class="fas fa-search"></i>
                        </button>
                        <button
                          class="btn btn-light"
                          (click)="openMenu(menu)"
                          matTooltip="Speisekarten Deeplink öffnen"
                        >
                          <i class="fas fa-link"></i>
                        </button>
                        <button class="btn btn-danger" (click)="deleteMenu(menu.id)" matTooltip="Löschen">
                          <i class="far fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Archiv">
            <div class="element-box">
              <h5 class="form-header">Archivierte Speisekarten</h5>
              <div class="form-desc">
                Hier finden Sie Ihre abgelaufenen Speise-, Getränke und Aktions-Karten. Sie können diese bearbeiten um
                sie wieder zu aktivieren.
              </div>

              <div class="alert alert-info" *ngIf="!menucardsArchive || !menucardsArchive.length" style="margin:0">
                Keine Speisekarten gefunden
              </div>
              <div class="table-responsive shifts" *ngIf="menucardsArchive && menucardsArchive.length">
                <table class="table table-padded">
                  <thead>
                    <tr>
                      <th>Aktiv?</th>
                      <th>Name</th>
                      <th>Typ</th>
                      <th style="text-align:center">Foto</th>
                      <th>Gültig von</th>
                      <th>Gültig bis</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let menu of menucardsArchive">
                      <td style="width:50px">
                        <mat-slide-toggle [(ngModel)]="menu.isActive" (change)="updateMenu(menu)"></mat-slide-toggle>
                      </td>
                      <td>{{ menu.name }}</td>
                      <td>
                        <div class="badge badge-default">{{ 'menucard.types.' + menu.type | translate }}</div>
                      </td>
                      <td style="text-align:center">
                        <i class="far fa-check-circle text-success" *ngIf="menu.photo"></i>
                        <i class="far fa-times-circle text-danger" *ngIf="!menu.photo"></i>
                      </td>
                      <td>{{ menu.validFrom | amDateFormat: 'DD.MM.YYYY' }}</td>
                      <td>{{ menu.validUntil | amDateFormat: 'DD.MM.YYYY' }}</td>
                      <td>
                        <button
                          class="btn btn-light"
                          (click)="manageMenu(menu.id)"
                          matTooltip="Verwalten Sie die Kategorien und Artikel der Speisekarte"
                        >
                          <i class="fas fa-grip-horizontal"></i>
                          Verwalten
                        </button>
                        <button class="btn btn-light" (click)="editMenu(menu)" matTooltip="Bearbeiten">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button class="btn btn-danger" (click)="deleteMenu(menu.id)" matTooltip="Löschen">
                          <i class="far fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
