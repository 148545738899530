/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lang-selector-backoffice.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/slide-toggle/typings/index.ngfactory";
import * as i3 from "@angular/material/slide-toggle";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/forms";
import * as i9 from "./lang-selector-backoffice.component";
import * as i10 from "@ngx-translate/core";
var styles_LangSelectorBackofficeComponent = [i0.styles];
var RenderType_LangSelectorBackofficeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LangSelectorBackofficeComponent, data: {} });
export { RenderType_LangSelectorBackofficeComponent as RenderType_LangSelectorBackofficeComponent };
export function View_LangSelectorBackofficeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "lang-switcher-backoffice"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  GER "])), (_l()(), i1.ɵeld(2, 0, null, null, 6, "mat-slide-toggle", [["class", "mat-slide-toggle"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checked", null], [2, "mat-disabled", null], [2, "mat-slide-toggle-label-before", null], [2, "_mat-animation-noopable", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.lang = $event) !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.changeLang($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatSlideToggle_0, i2.RenderType_MatSlideToggle)), i1.ɵdid(3, 1228800, null, 0, i3.MatSlideToggle, [i1.ElementRef, i4.Platform, i5.FocusMonitor, i1.ChangeDetectorRef, [8, null], i1.NgZone, i3.MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, [2, i6.ANIMATION_MODULE_TYPE], [2, i7.Directionality]], null, { change: "change" }), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatSlideToggle]), i1.ɵdid(5, 671744, null, 0, i8.NgModel, [[8, null], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i1.ɵdid(7, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), (_l()(), i1.ɵted(-1, 0, ["EN"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_13 = _co.lang; _ck(_v, 5, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = (i1.ɵnov(_v, 3).disabled ? null : (0 - 1)); var currVal_2 = i1.ɵnov(_v, 3).checked; var currVal_3 = i1.ɵnov(_v, 3).disabled; var currVal_4 = (i1.ɵnov(_v, 3).labelPosition == "before"); var currVal_5 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); var currVal_6 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 7).ngClassValid; var currVal_11 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }); }
export function View_LangSelectorBackofficeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lang-selector-backoffice", [], null, null, null, View_LangSelectorBackofficeComponent_0, RenderType_LangSelectorBackofficeComponent)), i1.ɵdid(1, 114688, null, 0, i9.LangSelectorBackofficeComponent, [i10.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LangSelectorBackofficeComponentNgFactory = i1.ɵccf("app-lang-selector-backoffice", i9.LangSelectorBackofficeComponent, View_LangSelectorBackofficeComponent_Host_0, {}, {}, []);
export { LangSelectorBackofficeComponentNgFactory as LangSelectorBackofficeComponentNgFactory };
