<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="col-sm-12">
    <div class="element-wrapper">
      <h6 class="element-header">
        {{ 'GastroAppStatistics.OwnAppStatistics' | translate }}
      </h6>
      <div *ngIf="!hasGastroAppData">
        <div class="element-box">
          {{ 'GastroAppStatistics.NoGastroAppData' | translate }}
        </div>
      </div>
      <div *ngIf="hasGastroAppData" class="element-box">
        <div class="row">
          <div class="col-lg-8">
            <div class="row">
              <div class="col-lg-3">
                <ng-select
                  appendTo="body"
                  [searchable]="false"
                  [clearable]="false"
                  (change)="getChartData($event.value)"
                  placeholder="{{ 'GastroAppStatistics.SelectPeriod' | translate }}"
                  [items]="selectedOption"
                  bindLabel="label"
                  bindValue="value"
                  [(ngModel)]="selectedTimePeriod"
                >
                </ng-select>
              </div>
            </div>
            <div class="chartjs-block">
              <canvas
                baseChart
                [datasets]="chartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [colors]="chartColors"
                [legend]="chartLegend"
                [chartType]="chartType"
                [plugins]="chartPlugins"
              >
              </canvas>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="row">
              <div class="col-lg-12">
                <h2 class="heading">{{ 'GastroAppStatistics.SubscriberTrends' | translate }}</h2>
              </div>
            </div>
            <div>
              <div class="subscriber-stat blue">
                <div class="visual"></div>
                <div class="details">
                  <div class="number">
                    <span>{{ totalUser }}</span>
                  </div>
                  <div class="desc">{{ 'GastroAppStatistics.TotalUsers' | translate }}</div>
                </div>
              </div>
            </div>
            <div>
              <div class="subscriber-stat hobi">
                <div class="visual"></div>
                <div class="details">
                  <div class="number">
                    <span>{{ subscribedUser }}</span>
                  </div>
                  <div class="desc">{{ 'GastroAppStatistics.SubscribedUsers' | translate }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <h2 class="heading">{{ 'GastroAppStatistics.AppStores' | translate }}</h2>
              </div>
            </div>
            <div class="app-store-links">
              <a [href]="gastroAppData.appleUrl" target="_blank"><img src="/assets/img/market-ios.png"/></a>
              <a [href]="gastroAppData.googleUrl" target="_blank"><img src="/assets/img/market-google-play.png"/></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
