<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Hinweise - Reservierungs-System
          <div class="element-actions">
            <button class="btn btn-primary hidden-xs-down" (click)="addAlert()">
              <i class="fas fa-plus-circle fa-fw"></i>
              Neuen Hinweis anlegen
            </button>
          </div>
        </h6>
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="{{ 'ReservationAlert.ActiveAlert' | translate }}">
            <div class="element-box">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Datum</th>
                      <th>Art</th>
                      <th>Hinweis</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let alert of alerts; let i = index">
                      <td class="nowrap">
                        <span>{{ alert.dateFrom | amDateFormat: 'ddd DD MMM YYYY' }}</span>
                        <span *ngIf="alert.dateTo && alert.dateFrom !== alert.dateTo">
                          - {{ alert.dateTo | amDateFormat: 'ddd DD MMM YYYY' }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="badge"
                          [class.badge-staff]="alert.type === 'staff'"
                          [class.badge-guest]="alert.type === 'guest'"
                          [class.badge-all]="alert.type === 'all'"
                          >{{ alert.type === 'staff' ? 'Mitarbeiter' : alert.type === 'all' ? 'Alle' : 'Gast' }}</span
                        >
                      </td>
                      <td>
                        <div *ngIf="alert.fileUrl !== null && alert.fileUrl !== 'null'">
                          <a href="https://cdn.gastroguide.de/{{ alert.fileUrl }}" class="pdf-files"
                            ><i class="fas fa-file-download file-url"></i>
                          </a>
                        </div>
                        {{ alert.info }}
                      </td>
                      <td style="width: 55px;">
                        <div class="shift-options" ngbDropdown>
                          <mat-icon svgIcon="settings" ngbDropdownToggle id="shiftDropdown"></mat-icon>
                          <div ngbDropdownMenu aria-labelledby="shiftDropdown">
                            <button ngbDropdownItem (click)="editAlert(alert, i)">Bearbeiten</button>
                            <button ngbDropdownItem (click)="deleteAlert(alert.id, 'active')">Löschen</button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="{{ 'ReservationAlert.ArchiveAltern' | translate }}">
            <div class="element-box">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Datum</th>
                      <th>Art</th>
                      <th>Hinweis</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let alert of archiveAlerts; let i = index">
                      <td class="nowrap">
                        <span>{{ alert.dateFrom | amDateFormat: 'ddd DD MMM YYYY' }}</span>
                        <span *ngIf="alert.dateTo && alert.dateFrom !== alert.dateTo">
                          - {{ alert.dateTo | amDateFormat: 'ddd DD MMM YYYY' }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="badge"
                          [class.badge-staff]="alert.type === 'staff'"
                          [class.badge-guest]="alert.type === 'guest'"
                          [class.badge-all]="alert.type === 'all'"
                          >{{ alert.type === 'staff' ? 'Mitarbeiter' : alert.type === 'all' ? 'Alle' : 'Gast' }}</span
                        >
                      </td>
                      <td>
                        <div *ngIf="alert.fileUrl !== null && alert.fileUrl !== 'null'">
                          <a href="https://cdn.gastroguide.de/{{ alert.fileUrl }}" class="pdf-files"
                            ><i class="fas fa-file-download file-url"></i>
                          </a>
                        </div>
                        {{ alert.info }}
                      </td>
                      <td style="width: 55px;">
                        <div class="shift-options" ngbDropdown>
                          <mat-icon svgIcon="settings" ngbDropdownToggle id="shiftDropdown"></mat-icon>
                          <div ngbDropdownMenu aria-labelledby="shiftDropdown">
                            <button ngbDropdownItem (click)="editAlert(alert, i)">Bearbeiten</button>
                            <button ngbDropdownItem (click)="deleteAlert(alert.id, 'archive')">Löschen</button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
