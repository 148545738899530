/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grid.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./grid.component";
var styles_GridComponent = [i0.styles];
var RenderType_GridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GridComponent, data: {} });
export { RenderType_GridComponent as RenderType_GridComponent };
export function View_GridComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { gridCanvas: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["canvasGrid", 1]], null, 0, "canvas", [["id", "canvasGrid"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_GridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-grid", [], null, null, null, View_GridComponent_0, RenderType_GridComponent)), i1.ɵdid(1, 4308992, null, 0, i2.GridComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GridComponentNgFactory = i1.ɵccf("app-grid", i2.GridComponent, View_GridComponent_Host_0, { room: "room" }, {}, []);
export { GridComponentNgFactory as GridComponentNgFactory };
