import { Component, OnInit, OnDestroy } from '@angular/core';
import { VouchersService } from '../vouchers.service';
import { Page } from '../vouchers.model';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import moment from 'moment';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vouchers-settings',
  templateUrl: './vouchers-settings.component.html',
  styleUrls: ['./vouchers-settings.component.scss']
})
export class VouchersSettingsComponent implements OnInit, OnDestroy {
  voucherValues: any = [0, 0, 0, 0, 0];
  voucherValuesRange: any = [0, 0, 0, 0, 0];
  comissionTakeover: number = 0;
  postDelivery: number = 0;
  deliveryPeriod: number = 0;
  sender: number = 1;
  shippingFee: any = '';
  notifyByEmailOption: boolean = false;
  notifyByEmail: string = '';
  emailTextError: string = '';
  headerBackground: string = '';
  imageURL: string | ArrayBuffer = 'https://cdn.gastroguide.de/';
  shopDescription: string = '';
  voucherShopLink: string;
  notifyByEmailType: boolean = false;

  deliveryPeriods: any[] = [2, 3, 4, 5, 6, 7, 8, 9, 10];
  voucherValueRange: any[] = [
    10,
    15,
    20,
    25,
    30,
    35,
    40,
    //44,
    45,
    50,
    55,
    60,
    65,
    70,
    75,
    80,
    85,
    90,
    95,
    100,
    110,
    120,
    130,
    140,
    150,
    200,
    250,
    300,
    350,
    400,
    450,
    500
  ];

  settings: any;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private voucherService: VouchersService,
    private snackBar: MatSnackBar,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.getVoucherSettings();
  }

  ngOnDestroy() {}

  saveSettings() {
    this.emailTextError = '';
    // Replace commas with dots
    const convertedValue = String(this.shippingFee).replace(/,/g, '.');
    if (this.notifyByEmailOption && (!this.notifyByEmail || this.notifyByEmail.trim() == '')) {
      this.emailTextError = 'Bitte einen Email für die Gutscheine eintragen.';
    }
    //  else if (this.notifyByEmailOption && this.notifyByEmail) {
    //   if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.notifyByEmail)) {
    //     this.emailTextError = 'Ungültige E-Mail Adresse';
    //   }
    // }
    if (this.emailTextError) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
      return false;
    }
    const data: any = {
      voucherValues: this.voucherValues,
      comissionTakeover: this.comissionTakeover,
      postDelivery: this.postDelivery,
      deliveryPeriod: this.deliveryPeriod,
      shippingFee: convertedValue,
      sender: this.sender == 1 ? 'gg' : 'customer',
      notifyByEmail: this.notifyByEmail,
      headerBackground: this.headerBackground,
      shopDescription: this.shopDescription,
      isActive: this.settings && this.settings.isActive ? this.settings.isActive : 0,
      notifyByEmailType: this.notifyByEmailType
    };
    if (this.sender == 1) {
      data.shippingFee = 3;
      data.deliveryPeriod = 3;
    }
    this.voucherService.saveVoucherSettings(data).subscribe(res => {
      this.snackBar.open(this.translate.instant('Vouchers.Settings.saveVoucher'), '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  changedSettings(isActivate: boolean) {
    this.settings.isActive = isActivate;
    this.saveSettings();
  }

  getVoucherSettings() {
    this.voucherService
      .getSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((res: any) => {
        this.settings = res;
        console.log('getVoucherSettings()', res);
        this.voucherShopLink =
          res && res.betrieb
            ? `https://gutschein.gastroguide.de/${res.betrieb.id}/${encodeURI(res.betrieb.name)}${
                res.betrieb.location ? '/' + res.betrieb.location : ''
              }`
            : '';
        const arr = res.customValues.split(',');
        let i = 0;
        arr.forEach((val: any) => {
          if (i <= 4) {
            this.voucherValues[i] = val;
            i++;
            if (!this.voucherValueRange.includes(parseInt(val))) {
              this.voucherValueRange.push(parseInt(val));
              this.voucherValueRange.sort((a, b) => a - b);
            }
          }
        });
        this.comissionTakeover = res.comissionTakeover || 0;
        this.postDelivery = res.postDelivery || 0;
        this.deliveryPeriod = res.deliveryPeriod || 0;
        this.shippingFee = res.shippingFee || 0;
        this.sender = res.sender == 'gg' ? 1 : 0;
        this.notifyByEmailOption = res.notifyByEmail ? true : false;
        this.notifyByEmail = res.notifyByEmail ? res.notifyByEmail : '';
        this.headerBackground = res.headerBackground ? res.headerBackground : '';
        this.imageURL = this.headerBackground
          ? this.headerBackground.includes('http')
            ? this.headerBackground
            : this.imageURL + this.headerBackground
          : '';
        this.shopDescription = res.shopDescription || '';
        this.notifyByEmailType = res.notifyByEmailType;
      });
  }

  setShippingData() {
    this.deliveryPeriod = 0;
    this.shippingFee = '';
  }

  changeNotifyEmailOption(value: any) {
    this.emailTextError = '';
    if (!value) {
      this.notifyByEmail = '';
    }
  }

  onFileChanged(event: any) {
    const uFile = event.target.files[0];
    console.log(uFile.name, uFile, uFile.type.split('/')[1]);
    if (
      uFile.type.split('/')[1] === 'jpeg' ||
      uFile.type.split('/')[1] === 'jpg' ||
      uFile.type.split('/')[1] === 'png'
    ) {
      const img = new Image();
      img.src = window.URL.createObjectURL(uFile);

      const reader = new FileReader();
      reader.readAsDataURL(uFile);

      img.onload = event => {
        this.imageURL = reader.result;
      };
      const uploadData = new FormData();
      uploadData.append('file', uFile, uFile.name);
      this.voucherService.uploadHeaderBackgroundVouchers(uploadData).subscribe(
        (res: any) => {
          this.headerBackground = res.headerBackground;
          this.snackBar.open('Die Datei wurde erfolgreich hochgeladen', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        (err: any) => {
          console.log(253, 'err', err);
          this.snackBar.open(err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
    } else {
      this.snackBar.open('Es können nur jpeg, jpg und png Dateien hochgeladen werden', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
    }
  }

  returnVal(val: any) {
    return val;
  }
}
