<div class="menu-item" [class.has-sub-menu]="item.children.length" *ngxPermissionsOnly="item.permission">
  <a
    [ngStyle]="{ 'padding-left': depth * 12 + 'px' }"
    (click)="onItemSelected(item)"
    [ngClass]="{
      active: item.route ? router.isActive(item.route, true) : false,
      expanded: expanded || item.route ? router.isActive(item.route, true) : false
    }"
    [class.disabled]="item.disabled"
  >
    <div class="menu-item-icon" *ngIf="item.iconName">
      <div [class]="item.iconName"></div>
    </div>
    <span>{{ item.displayName }}</span>
  </a>

  <div class="sub-menu-w" *ngIf="expanded">
    <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth + 1"> </app-menu-list-item>
  </div>
</div>
