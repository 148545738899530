import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class ThemeService {
    constructor() {
        this.activeTheme = new BehaviorSubject('light-theme');
        this.activeTheme$ = this.activeTheme.asObservable();
    }
    setActiveTheme(theme) {
        this.activeTheme.next(theme);
    }
    getActiveTheme() {
        return this.activeTheme.getValue();
    }
}
ThemeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(); }, token: ThemeService, providedIn: "root" });
