/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/material/checkbox";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./checkbox-renderer.component";
var styles_CheckboxRendererComponent = [];
var RenderType_CheckboxRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CheckboxRendererComponent, data: {} });
export { RenderType_CheckboxRendererComponent as RenderType_CheckboxRendererComponent };
export function View_CheckboxRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.checkedHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatCheckbox_0, i1.RenderType_MatCheckbox)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatCheckbox]), i0.ɵdid(3, 8568832, null, 0, i3.MatCheckbox, [i0.ElementRef, i0.ChangeDetectorRef, i4.FocusMonitor, i0.NgZone, [8, null], [2, i3.MAT_CHECKBOX_CLICK_ACTION], [2, i5.ANIMATION_MODULE_TYPE]], { checked: [0, "checked"] }, { change: "change" }), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.params.value; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).id; var currVal_1 = null; var currVal_2 = i0.ɵnov(_v, 3).indeterminate; var currVal_3 = i0.ɵnov(_v, 3).checked; var currVal_4 = i0.ɵnov(_v, 3).disabled; var currVal_5 = (i0.ɵnov(_v, 3).labelPosition == "before"); var currVal_6 = (i0.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_CheckboxRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "checkbox-renderer", [], null, null, null, View_CheckboxRendererComponent_0, RenderType_CheckboxRendererComponent)), i0.ɵdid(1, 180224, null, 0, i6.CheckboxRendererComponent, [], null, null)], null, null); }
var CheckboxRendererComponentNgFactory = i0.ɵccf("checkbox-renderer", i6.CheckboxRendererComponent, View_CheckboxRendererComponent_Host_0, {}, {}, []);
export { CheckboxRendererComponentNgFactory as CheckboxRendererComponentNgFactory };
