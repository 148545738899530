/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forbidden.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./forbidden.component";
import * as i3 from "@angular/router";
var styles_ForbiddenComponent = [i0.styles];
var RenderType_ForbiddenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForbiddenComponent, data: {} });
export { RenderType_ForbiddenComponent as RenderType_ForbiddenComponent };
export function View_ForbiddenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "content-box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "element-box not-found"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["403"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Keine Berechtigung"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sie haben keine Berechtigung f\u00FCr diese Seite"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Zum Login"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_ForbiddenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forbidden", [], null, null, null, View_ForbiddenComponent_0, RenderType_ForbiddenComponent)), i1.ɵdid(1, 114688, null, 0, i2.ForbiddenComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForbiddenComponentNgFactory = i1.ɵccf("app-forbidden", i2.ForbiddenComponent, View_ForbiddenComponent_Host_0, {}, {}, []);
export { ForbiddenComponentNgFactory as ForbiddenComponentNgFactory };
