<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Online - Bestellungen
          <a
            class="btn btn-default btn-sm"
            ngbTooltip="Hilfe zum Bestellsystem"
            href="https://blog.gastro.digital/article-categories/bestellsystem/"
            target="_blank"
          >
            <mat-icon svgIcon="help"></mat-icon>
          </a>
        </h6>

        <div class="element-box_ orders-scheduler">
          <div class="button-bar" #buttonBar fxLayout="row">
            <ng-select
              [items]="sortables"
              (change)="changeSorting()"
              [(ngModel)]="sortBy"
              bindLabel="name"
              bindValue="value"
              [clearable]="false"
              class="sort-select"
            >
              <ng-template ng-label-tmp let-item="item"> Sortieren nach: {{ item.name }} </ng-template>
            </ng-select>
            <div
              class="column date-selection"
              fxFlex
              fxFlexGrow="2"
              fxLayoutGap="10px"
              style="justify-content: center;"
            >
              <button class="btn btn-white" (click)="today()">
                <i class="fas fa-home"></i>
              </button>

              <div class="btn-group">
                <button class="btn btn-margin" (click)="prevDay()">
                  <i class="fas fa-chevron-left"></i>
                </button>
                <input
                  matInput
                  [matDatepicker]="picker"
                  type="text"
                  [(ngModel)]="currentDate"
                  (dateChange)="changeDate()"
                />
                <button class="btn date-btn" (click)="picker.open()">
                  <ng-template [ngIf]="isToday()">Bis Heute</ng-template>
                  <ng-template [ngIf]="!isToday() && layout >= LAYOUT.MD">{{
                    currentDate | dfnsFormat: 'dddd, DD. MMMM YYYY'
                  }}</ng-template>
                  <ng-template [ngIf]="!isToday() && layout < LAYOUT.MD">{{
                    currentDate | dfnsFormat: 'dd, DD. MMM YYYY'
                  }}</ng-template>
                </button>
                <mat-datepicker #picker></mat-datepicker>
                <button class="btn" (click)="nextDay()">
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div class="btn date-btn" style="background: #fff">
              <mat-slide-toggle (change)="showAllOpenOrders($event.checked)"
                >Alle offenen Bestellungen anzeigen</mat-slide-toggle
              >
            </div>
          </div>
        </div>

        <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)">
          <mat-tab label="Offene Bestellungen ({{ pendingOrders ? pendingOrders.length : 0 }})">
            <div class="element-box">
              <div class="loading" *ngIf="!pendingOrders">
                <i class="fa fa-spinner fa-spin"></i>
                Bestellungen werden geladen
              </div>
              <ng-template [ngIf]="pendingOrders">
                <div class="alert alert-info" *ngIf="!pendingOrders.length">
                  Sie haben für diesen Tag keine offenene Bestellungen
                </div>
                <div *ngFor="let order of pendingOrders">
                  <app-order
                    [data]="order"
                    (change)="orderChange($event)"
                    *ngIf="settings.orderSystemVersion === 1"
                  ></app-order>
                  <app-order-v2
                    [data]="order"
                    (change)="orderChange($event)"
                    *ngIf="settings.orderSystemVersion === 2"
                  ></app-order-v2>
                </div>
              </ng-template>
            </div>
          </mat-tab>
          <mat-tab label="In Arbeit ({{ inprogressOrders ? inprogressOrders.length : 0 }})">
            <div class="element-box">
              <div class="loading" *ngIf="!inprogressOrders">
                <i class="fa fa-spinner fa-spin"></i>
                Bestellungen werden geladen
              </div>
              <ng-template [ngIf]="inprogressOrders">
                <div class="alert alert-info" *ngIf="!inprogressOrders.length">
                  Sie haben für diesen Tag keine Bestellungen in Arbeit
                </div>
                <div *ngFor="let order of inprogressOrders" class="open-order">
                  <app-order
                    [data]="order"
                    (change)="orderChange($event)"
                    *ngIf="settings.orderSystemVersion === 1"
                  ></app-order>
                  <app-order-v2
                    [data]="order"
                    (change)="orderChange($event)"
                    *ngIf="settings.orderSystemVersion === 2"
                  ></app-order-v2>
                </div>
              </ng-template>
            </div>
          </mat-tab>
          <mat-tab label="Abgeschlossene Bestellungen ({{ finishedOrders ? finishedOrders.length : 0 }})">
            <div class="element-box">
              <div class="loading" *ngIf="!finishedOrders">
                <i class="fa fa-spinner fa-spin"></i>
                Bestellungen werden geladen
              </div>
              <ng-template [ngIf]="finishedOrders">
                <div class="alert alert-info" *ngIf="!finishedOrders.length">
                  Sie haben für diesen Tag keine fertigen Bestellungen
                </div>
                <div *ngFor="let order of finishedOrders" class="open-order">
                  <app-order
                    [data]="order"
                    [finished]="true"
                    (change)="orderChange($event)"
                    *ngIf="settings.orderSystemVersion === 1"
                  ></app-order>
                  <app-order-v2
                    [data]="order"
                    [finished]="true"
                    (change)="orderChange($event)"
                    *ngIf="settings.orderSystemVersion === 2"
                  ></app-order-v2>
                </div>
              </ng-template>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
