<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editReservationAddOnTemplate">{{
        'ResSettings.AddReservationAddOn.CreateAddOn' | translate
      }}</ng-template>
      <ng-template [ngIf]="editReservationAddOnTemplate">{{
        'ResSettings.AddReservationAddOn.EditAddOn' | translate
      }}</ng-template>
    </h4>
    <form [formGroup]="reservationAddOnTemplateFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">Name<br /> </label>
            <input
              class="form-control"
              placeholder=""
              name="title"
              formControlName="title"
              type="text"
              value=""
              required
              ngbAutofocus
            />
          </div>

          <!-- <label class="price-label" for="">
            Preis
          </label>
          <div class="input-group">
            <input class="form-control" placeholder="" value="0" formControlName="price" style="max-width:165px"
              mask="0*.00" [dropSpecialCharacters]="false" />
            <div class="input-group-append">
              <div class="input-group-text">EUR</div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group" formArrayName="allPrices">
            <div style="display: flex; margin-bottom: 5px">
              <div style="flex: 1;">
                <label for="">{{ 'ResSettings.AddReservationAddOn.FromPeople' | translate }}</label>
              </div>
              <div style="flex: 1;">
                <label for="">{{ 'ResSettings.AddReservationAddOn.ToPeople' | translate }}</label>
              </div>
              <div style="flex: 1;">
                <label for="">{{ 'ResSettings.AddReservationAddOn.Price' | translate }}</label>
              </div>
            </div>
            <ng-template ngFor let-unit [ngForOf]="formData.controls" let-i="index">
              <div style="display: flex; margin-bottom: 5px" [formGroupName]="i">
                <div style="flex: 1;margin-right: 1%;">
                  <input
                    type="number"
                    matInput
                    [min]="1"
                    [max]="20"
                    [step]="1"
                    formControlName="guestCountFrom"
                    class="form-control"
                    onkeydown="return false"
                  />
                </div>
                <div style="flex: 1;margin-right: 1%;">
                  <input
                    type="number"
                    matInput
                    [min]="1"
                    [max]="20"
                    [step]="1"
                    formControlName="guestCountTo"
                    class="form-control"
                    onkeydown="return false"
                  />
                </div>
                <div style="flex: 1">
                  <input
                    class="form-control"
                    placeholder=""
                    value="0"
                    formControlName="price"
                    style="max-width:165px"
                  />
                </div>
                <div>
                  <button class="btn btn-white" type="button" (click)="removeUnit(i)">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </ng-template>
            <button class="btn btn-dark btn-circle" type="button" style="margin-top: 5px" (click)="addNewUnit()">
              {{ 'ResSettings.AddReservationAddOn.NewUnit' | translate }}
            </button>
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'ResSettings.AddReservationAddOn.Picture' | translate }}</label>
            <div>
              <input
                class="file-input form-control"
                style="display:none"
                type="file"
                name="file"
                #fileInput
                (change)="onChange(fileInput.files[0])"
              />
              <div class="input-group">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-secondary browsebtn" (click)="fileInput.click()">
                    {{ 'ResSettings.AddReservationAddOn.ChoosePicture' | translate }}
                  </button>
                </div>
                <input
                  class="form-control"
                  placeholder="kein Bild ausgewählt"
                  [value]="fileName ? fileName : ''"
                  readonly
                />
                <div class="input-group-append" *ngIf="fileName">
                  <button type="button" class="btn btn-danger" (click)="removeFile()" matTooltip="Foto entfernen">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="preview-img" *ngIf="imageUrl">
            <div class="uploaded-list">
              <div class="uploaded-item">
                <img class="img-fluid" [src]="imageUrl" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>{{ 'ResSettings.AddReservationAddOn.Description' | translate }}</label>
        <textarea class="form-control" formControlName="description" rows="5"></textarea>
      </div>
      <div>
        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="add()"
          *ngIf="!editReservationAddOnTemplate"
          [promiseBtn]="addObservable"
        >
          {{ 'ResSettings.AddReservationAddOn.CreateAddOn' | translate }}
        </button>
        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="save()"
          *ngIf="editReservationAddOnTemplate"
          [promiseBtn]="editObservable"
        >
          {{ 'ResSettings.AddReservationAddOn.SaveAddOn' | translate }}
        </button>
        <!-- <button
          class="btn btn-link btn-sm delete-button"
          (click)="delete()"
          *ngIf="editReservationAddOnTemplate"
          [promiseBtn]="deleteObservable"
        >
          Reservation Addon löschen
        </button> -->
      </div>
    </form>
  </div>
</div>
