var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GalleryService } from '../gallery.service';
export class UploadPhotosComponent {
    constructor(activatedRoute, router, galleryService) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.galleryService = galleryService;
        this.files = [];
        this.loader = false;
        this.activatedRoute.params.subscribe((parameter) => __awaiter(this, void 0, void 0, function* () {
            const { albumId, clientId } = parameter;
            if (localStorage.getItem('albumInfo')) {
                const albumData = JSON.parse(localStorage.getItem('albumInfo'));
                if (albumData.albumId === parseInt(albumId) && parseInt(clientId) === albumData.clientId) {
                    this.albumInfo = Object.assign({}, albumData);
                }
                else {
                    this.router.navigate([`client/${albumData.clientId}/gallery`]);
                }
            }
            else {
                this.router.navigate([`client/${clientId}/gallery`]);
            }
        }));
    }
    ngOnInit() { }
    onFileChange(event) {
        this.photoFormMessage = '';
        const typeArray = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
        if (!event.addedFiles) {
            event['addedFiles'] = [...event.target.files];
        }
        for (let i = 0; i < event['addedFiles'].length; i++) {
            if (typeArray.indexOf(event['addedFiles'][i].type.toLowerCase()) === -1) {
                this.photoFormMessage = `${event['addedFiles'][i].name} has invalid file Extention`;
                return;
            }
        }
        this.files.push(...event.addedFiles);
        if (this.files && this.files.length) {
            this.files.forEach((file) => {
                file.sizeInKB = (file.size / 1024).toFixed(2);
            });
        }
        this.formData = new FormData();
        for (var i = 0; i < this.files.length; i++) {
            this.formData.append(`file[]`, this.files[i]);
        }
    }
    onRemove(event) {
        this.photoFormMessage = '';
        const typeArray = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
        this.files.splice(this.files.indexOf(event), 1);
        this.formData = new FormData();
        for (var i = 0; i < this.files.length; i++) {
            this.formData.append(`file[]`, this.files[i]);
        }
    }
    uploadPhotos() {
        this.loader = true;
        this.galleryService.uploadPhotos(this.formData, this.albumInfo.albumId).subscribe((response) => {
            this.loader = false;
            this.router.navigate([`client/${this.albumInfo.clientId}/gallery/album/${this.albumInfo.albumId}/album-photos`]);
        });
    }
}
