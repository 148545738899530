/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./set-reply.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./set-reply.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../reviews.service";
var styles_SetReplyComponent = [i0.styles];
var RenderType_SetReplyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SetReplyComponent, data: {} });
export { RenderType_SetReplyComponent as RenderType_SetReplyComponent };
function View_SetReplyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "margin-top:5px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      by "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [["style", "font-weight: bold;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.commentData.memberDataInfo.username; _ck(_v, 3, 0, currVal_0); }); }
export function View_SetReplyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 34, "div", [["class", "modal-content text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reply"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "onboarding-content"], ["style", "text-align: left;"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["\n    ", "\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SetReplyComponent_1)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(19, 0, null, null, 14, "div", [["class", "onboarding-content"], ["style", "text-align: left;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    Write a Reply\n    "])), (_l()(), i1.ɵeld(21, 0, null, null, 5, "textarea", [["class", "col-sm-12"], ["style", "outline: none; border:1px solid #dedede"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 22).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 22)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 22)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.reply = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(24, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(26, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(28, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitReply() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Reply"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "button", [["class", "btn btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Abort"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.commentData.comment; _ck(_v, 16, 0, currVal_1); var currVal_9 = _co.reply; _ck(_v, 24, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.commentData.comment; _ck(_v, 14, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 26).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 26).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 26).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 26).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 26).ngClassValid; var currVal_7 = i1.ɵnov(_v, 26).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 26).ngClassPending; _ck(_v, 21, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_SetReplyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-set-reply", [], null, null, null, View_SetReplyComponent_0, RenderType_SetReplyComponent)), i1.ɵdid(1, 114688, null, 0, i4.SetReplyComponent, [i5.NgbActiveModal, i6.ReviewsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SetReplyComponentNgFactory = i1.ɵccf("app-set-reply", i4.SetReplyComponent, View_SetReplyComponent_Host_0, { commentContent: "commentContent" }, { submitReplyResponse: "submitReplyResponse" }, []);
export { SetReplyComponentNgFactory as SetReplyComponentNgFactory };
