<div [ngStyle]="{ display: loader ? 'none' : 'block' }" class="company-box-w">
  <div class="logo-w">
    <a href="index.html"><img alt="" class="img-fluid" src="./assets/logo.png"/></a>
    <app-lang-selector-backoffice></app-lang-selector-backoffice>
  </div>
  <div class="auth-header d-flex justify-content-between">
    <h4>Neuen Betrieb eintragen - Einrichtungsassistent</h4>
    <button class="btn btn-primary" [routerLink]="['/select']">{{ 'NewCompany.GotoSelectClient' | translate }}</button>
  </div>
  <div>
    <mat-horizontal-stepper linear #stepper>
      <mat-step [stepControl]="masterDataForm">
        <form [formGroup]="masterDataForm">
          <ng-template matStepLabel>Stammdaten</ng-template>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label for="name">Name der Gastro*</label>
                <input class="form-control" formControlName="name" placeholder="Name der Gastro" type="text" />
                <small
                  [hidden]="masterDataForm.controls.name.valid || masterDataForm.controls.name.untouched"
                  class="text-danger"
                  translate
                  >Name der Gastro ist ungültig.</small
                >
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="Straße">Straße*</label>

                <input
                  class="form-control"
                  [ngClass]="{ 'ng-invalid': !isEmptyAddresss }"
                  name="street"
                  (keypress)="checkForTyping()"
                  (keyup)="fetchStreetData()"
                  formControlName="street"
                  type="text"
                  placeholder="Straße"
                  aria-label="address"
                />

                <!-- <input class="form-control" formControlName="street" placeholder="Straße" type="text" /> -->
                <small
                  [hidden]="masterDataForm.controls.street.valid || masterDataForm.controls.street.untouched"
                  class="text-danger"
                  translate
                >
                  Straße ist ungültig.
                </small>
                <small [hidden]="isEmptyAddresss" class="text-danger" translate>
                  Please Select Correct Address
                </small>
                <!-- <mat-autocomplete #streetAuto="matAutocomplete">
                  <mat-option *ngFor="let location of streetArray" (click)="selectStreet(location)" [value]="location">
                    {{ location.address }}
                  </mat-option>
                </mat-autocomplete> -->
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="PLZ">PLZ*</label>
                <input
                  class="form-control"
                  formControlName="zipCode"
                  placeholder="PLZ"
                  (keypress)="checkForTyping()"
                  (keyup)="fetchLocation()"
                  type="text"
                  aria-label="PLZ*"
                  [matAutocomplete]="auto"
                />
                <small
                  [hidden]="masterDataForm.controls.zipCode.valid || masterDataForm.controls.zipCode.untouched"
                  class="text-danger"
                  translate
                >
                  PLZ ist ungültig.
                </small>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option
                    *ngFor="let location of locationArray"
                    (click)="selectZipCode(location)"
                    [value]="location.name"
                  >
                    {{ location.zips }}&nbsp;{{ location.name }}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="Ort">Ort</label>
                <input class="form-control" formControlName="place" placeholder="Ort" type="text" />
                <small
                  [hidden]="masterDataForm.controls.place.valid || masterDataForm.controls.place.untouched"
                  class="text-danger"
                  translate
                >
                  Ort ist ungültig.
                </small>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="country">{{ 'Common.Country' | translate }}*</label>
                <ng-select
                  formControlName="countryId"
                  [items]="countries"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ 'Common.Country' | translate }}"
                  (change)="selectCountriesChange()"
                >
                </ng-select>
                <small
                  [hidden]="masterDataForm.controls.countryId.valid || masterDataForm.controls.countryId.untouched"
                  class="text-danger"
                  translate
                >
                  {{ 'Common.Country' | translate }} ist ungültig.
                </small>
              </div>
            </div>
            <!-- map-wrapper - start -->
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <div class="map-wrapper mb-30">
                  <!-- section-title - start -->
                  <div class="section-title mb-30">
                    <label for="">Karte Lage</label>
                  </div>
                  <!-- section-title - end -->
                  <div id="google-map">
                    <div id="googleMaps" class="google-map-container">
                      <agm-map
                        style="height: 300px;"
                        [zoom]="currZoom"
                        [latitude]="latitude"
                        [longitude]="longitude"
                        (mapClick)="mapClicked($event)"
                      >
                        <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                      </agm-map>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- map-wrapper - end -->
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="Stadtteil">Stadtteil</label>
                <select
                  class="form-control"
                  formControlName="district"
                  (change)="changeDistrict($event.target.value)"
                  *ngIf="districtArray && districtArray.length"
                >
                  <option value="">Select District</option>
                  <option *ngFor="let district of districtArray" value="{{ district.name }}">{{
                    district.name
                  }}</option>
                </select>
                <input
                  *ngIf="!districtArray.length"
                  class="form-control"
                  formControlName="district"
                  placeholder="Stadtteil"
                  type="text"
                  disabled
                />
                <small
                  [hidden]="masterDataForm.controls.district.valid || masterDataForm.controls.district.untouched"
                  class="text-danger"
                  translate
                >
                  Stadtteil ist ungültig.
                </small>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="Telefon">Telefon*</label>
                <input class="form-control" formControlName="telefon" placeholder="Telefon" type="text" />
                <small
                  [hidden]="masterDataForm.controls.telefon.valid || masterDataForm.controls.telefon.untouched"
                  class="text-danger"
                  translate
                  >Telefon ist ungültig.</small
                >
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="E-Mail Adresse">E-Mail Adresse</label>
                <input
                  class="form-control"
                  formControlName="email"
                  placeholder="Geben Sie Ihre E-Mail Adresse ein"
                  type="text"
                />
                <small
                  [hidden]="masterDataForm.controls.email.valid || masterDataForm.controls.email.untouched"
                  class="text-danger"
                  translate
                >
                  E-Mail Adresse ist ungültig.
                </small>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="Webseite">Webseite</label>
                <input class="form-control" formControlName="website" placeholder="https://sometext.com" type="text" />
                <small
                  [hidden]="masterDataForm.controls.website.valid || masterDataForm.controls.website.untouched"
                  class="text-danger"
                  translate
                >
                  Webseite ist ungültig.
                </small>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="Stadtteil">Eröffnungsdatum</label>
                <div class="input-group">
                  <div class="input-group-prepend" (click)="openingDatePicker.open()">
                    <div class="input-group-text">
                      <i class="fas fa-calendar-alt"></i>
                    </div>
                  </div>
                  <mat-datepicker #openingDatePicker (closed)="dateFromInput.blur()"></mat-datepicker>
                  <input
                    class="form-control"
                    #dateFromInput
                    formControlName="openingDate"
                    placeholder=""
                    type="text"
                    name="date"
                    [matDatepicker]="openingDatePicker"
                    (focus)="openingDatePicker.open()"
                  />
                </div>
                <small
                  [hidden]="masterDataForm.controls.openingDate.valid || masterDataForm.controls.openingDate.untouched"
                  class="text-danger"
                  translate
                >
                  Eröffnungsdatum ist ungültig.
                </small>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="logo">Logo</label>
                <div>
                  <input
                    class="form-control"
                    style="display:none"
                    type="file"
                    #logoFile
                    (change)="logoChange(logoFile.files[0])"
                  />
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button type="button" class="btn btn-secondary" (click)="logoFile.click()">Foto auswählen</button>
                    </div>
                    <input
                      class="form-control"
                      placeholder="Kein Foto ausgewählt"
                      [value]="fileName ? fileName : ''"
                      readonly
                    />
                    <div class="input-group-append" *ngIf="fileName">
                      <button type="button" class="btn btn-danger" (click)="removeLogo()" matTooltip="Foto entfernen">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group" *ngIf="imageUrl">
                <div class="preview-img">
                  <img class="col-sm-6" [src]="imageUrl" />
                </div>
              </div>
            </div>
          </div>
          <div class="text-danger" translate>{{ masterDataMessage }}</div>
          <div class="buttons-w">
            <button class="btn btn-primary" (click)="moveForward(stepper)">{{ 'NewCompany.Next' | translate }}</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="restaurantForm">
        <form [formGroup]="restaurantForm">
          <div class="alert alert-outline-primary" style="background-color: #e1f0f7; border: 1px solid #5BC0DE;">
            <p style="color: #525459;">
              Geben Sie bis zu 3 Gastronomietype und 5 Angebotstypen an, die zu Ihrem Betrieb passen. Eine detaillierte
              Auflistung über alle möglichen Typen finden Sie hier. Möchten Sie weniger als 3 Typen angeben, so lassen
              Sie die entsprechenden Felder frei.
            </p>
          </div>
          <ng-template matStepLabel>Gastronomietyp auswählen</ng-template>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="cateringType">Bitte Gastronomie-Typen wählen: {{ cateringTypeArray.length }}/3</label>
                <div class="row">
                  <ng-select
                    class="col-sm-12"
                    [items]="restaurantTypeArray"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [hideSelected]="true"
                    bindLabel="name"
                    placeholder="Restaurant"
                    [maxSelectedItems]="3"
                    (change)="selectedCatType()"
                    appendTo="body"
                    formControlName="cateringType"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="offerType">Bitte Angebots-Typen wählen: {{ offerArrayList.length }}/5</label>
                <div class="row">
                  <ng-select
                    class="col-sm-12"
                    [items]="offerTypeArray"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [hideSelected]="true"
                    bindLabel="name"
                    placeholder="Brunch"
                    [maxSelectedItems]="5"
                    (change)="selectedOfferType()"
                    appendTo="body"
                    formControlName="offerType"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div class="text-danger" translate>{{ restaurantFormMessage }}</div>
          <div class="buttons-w">
            <button class="btn btn-secondary" (click)="moveBackward(stepper)">
              {{ 'NewCompany.Back' | translate }}
            </button>
            <button class="btn btn-primary" (click)="moveForward(stepper)">{{ 'NewCompany.Next' | translate }}</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="dayTimeManagementForm">
        <form>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <div class="alert alert-outline-primary checkbox-box">
                  <p style="color:#525459;">
                    Ruhetage<br />
                    <input
                      type="checkbox"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="dayTimeArray[0].isDisable"
                      (change)="dayTimeArray[0].isDisable = $event.target.checked"
                    />&nbsp; Montag &nbsp;
                    <input
                      type="checkbox"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="dayTimeArray[1].isDisable"
                      (change)="dayTimeArray[1].isDisable = $event.target.checked"
                    />&nbsp; Dienstag &nbsp;
                    <input
                      type="checkbox"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="dayTimeArray[2].isDisable"
                      (change)="dayTimeArray[2].isDisable = $event.target.checked"
                    />&nbsp; Mittwoch &nbsp;
                    <input
                      type="checkbox"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="dayTimeArray[3].isDisable"
                      (change)="dayTimeArray[3].isDisable = $event.target.checked"
                    />&nbsp; Donnerstag &nbsp;
                    <input
                      type="checkbox"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="dayTimeArray[4].isDisable"
                      (change)="dayTimeArray[4].isDisable = $event.target.checked"
                    />&nbsp; Freitag &nbsp;
                    <input
                      type="checkbox"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="dayTimeArray[5].isDisable"
                      (change)="dayTimeArray[5].isDisable = $event.target.checked"
                    />&nbsp; Samstag &nbsp;
                    <input
                      type="checkbox"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="dayTimeArray[6].isDisable"
                      (change)="dayTimeArray[6].isDisable = $event.target.checked"
                    />&nbsp; Sonntag
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="alert alert-outline-primary"
            style="margin-top:10px; background-color: #e1f0f7; border: 1px solid #5BC0DE;"
          >
            <p style="color: #525459;">
              Info: Klicken Sie auf Kopieren um die Öffnungszeiten eines Tages über Einfügen auf einen anderen Tag zu
              übertragen.
            </p>
          </div>
          <ng-template matStepLabel>Öffnungszeiten</ng-template>
          <div class="form-group">
            <table class="table">
              <tbody>
                <tr *ngFor="let dayTime of dayTimeArray; index as i">
                  <th *ngIf="!dayTime.isDisable">{{ dayTime.day }}</th>
                  <th *ngIf="!dayTime.isDisable">
                    <input
                      type="time"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="dayTime.firstStartTime"
                    />&nbsp;
                    <input
                      type="time"
                      [disabled]="dayTime.firstStartTime ? false : true"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="dayTime.firstEndTime"
                      [ngStyle]="{
                        border:
                          dayTimeFormMessage.index === i && dayTimeFormMessage.position === 2
                            ? '1px solid #f70202'
                            : '1px solid #000000'
                      }"
                    />
                  </th>
                  <th *ngIf="!dayTime.isDisable" style="text-align: left;">&</th>
                  <th *ngIf="!dayTime.isDisable">
                    <input
                      type="time"
                      [disabled]="dayTime.firstStartTime && dayTime.firstEndTime ? false : true"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="dayTime.secondStartTime"
                      [ngStyle]="{
                        border:
                          dayTimeFormMessage.index === i && dayTimeFormMessage.position === 3
                            ? '1px solid #f70202'
                            : '1px solid #000000'
                      }"
                    />&nbsp;
                    <input
                      type="time"
                      [disabled]="
                        dayTime.firstStartTime && dayTime.firstEndTime && dayTime.secondStartTime ? false : true
                      "
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="dayTime.secondEndTime"
                      [ngStyle]="{
                        border:
                          dayTimeFormMessage.index === i && dayTimeFormMessage.position === 4
                            ? '1px solid #f70202'
                            : '1px solid #000000'
                      }"
                    />
                  </th>
                  <th *ngIf="!dayTime.isDisable">
                    <button class="btn btn-primary" (click)="copyData(i)">Kopieren</button>
                    <button class="btn btn-secondary" *ngIf="!dayTime.copied || !copiedValue" (click)="pasteTo(i)">
                      Einfügen
                    </button>
                    <button class="btn btn-primary" *ngIf="dayTime.copied && copiedValue" (click)="pasteTo('all')">
                      Insert To All
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div class="form-group">
            <label for="name">Hier tragen Sie bitte Abweichungen von den regulären Öffnungszeiten ein:</label>
            <textarea class="form-control" [ngModelOptions]="{ standalone: true }" [(ngModel)]="note"></textarea>
          </div>
          <div class="text-danger" translate>{{ dayTimeFormMessage.message }}</div>
          <div class="buttons-w">
            <button class="btn btn-secondary" (click)="moveBackward(stepper)">
              {{ 'NewCompany.Back' | translate }}
            </button>
            <button class="btn btn-primary" (click)="moveForward(stepper)">{{ 'NewCompany.Next' | translate }}</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="photoUploadForm">
        <form [ngStyle]="{ display: loader ? 'none' : 'block' }" [formGroup]="photoUploadForm">
          <ng-template matStepLabel>Fotos hochladen</ng-template>
          <div class="alert alert-outline-primary" style="background-color: #e1f0f7; border: 1px solid #5BC0DE;">
            <p style="color: #525459;">
              <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">
                  Laden Sie mit unserem Dateimanager ganz einfach die Fotos Ihrer Gastro hoch und präsentieren Sie Ihren
                  Betrieb von der besten Seite. Sie können auch mittels Drag & Drop die Fotos in die Dateibox ziehen.
                  Erlaubte Dateiendungen: jpg, gif, png, jpeg. Max. Größe: 10mb
                </font>
              </font>
            </p>
          </div>
          <div class="form-group">
            <ngx-dropzone style="height: 150px;" (change)="onFileChange($event)">
              <ngx-dropzone-label>{{ 'NewCompany.DropFilesHere' | translate }}</ngx-dropzone-label>
            </ngx-dropzone>
          </div>
          <div class="form-group">
            <button class="btn btn-primary" (click)="fileInput.click()">
              <span>{{ 'NewCompany.AddFiles' | translate }}</span>
              <input
                #fileInput
                formControlName="file"
                id="file"
                type="file"
                multiple
                accept="image/png, image/gif, image/jpeg, image/jpg"
                class="form-control"
                (change)="onFileChange($event)"
                style="display:none;"
              />
            </button>
          </div>
          <div class="form-group">
            <table class="table" *ngIf="files && files.length">
              <thead>
                <td>{{ 'NewCompany.FileName' | translate }}</td>
                <td style="text-align: right;">{{ 'NewCompany.Size' | translate }}</td>
                <td style="text-align: center;">{{ 'NewCompany.Action' | translate }}</td>
              </thead>
              <tbody>
                <tr *ngFor="let f of files">
                  <td>{{ f.name }}</td>
                  <td style="text-align: right;">{{ f.sizeInKB }} KB</td>
                  <td style="text-align: center;"><i class="fa fa-trash" (click)="onRemove(f)"></i></td>
                </tr>
                <tr>
                  <td style="text-align: right;">
                    <b>{{ 'NewCompany.TotalSize' | translate }}</b>
                  </td>
                  <td style="text-align: right;">{{ totalSizeInKB }} KB</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-danger" translate>{{ photoFormMessage }}</div>
          <div class="buttons-w">
            <button class="btn btn-secondary" (click)="moveBackward(stepper)">
              {{ 'NewCompany.Back' | translate }}
            </button>
            <button class="btn btn-primary" (click)="submitForm()">{{ 'NewCompany.Finish' | translate }}</button>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
<div [ngStyle]="{ display: loader ? 'block' : 'none' }" style="height: 100%;">
  <div style="position: relative; left: 25%; top: 48%; width: 50%;">
    <span *ngIf="isSubmit">Please Wait while are submitting your details</span>
    <span *ngIf="!isSubmit">Please Wait while form is loading</span>
    <mat-progress-bar mode="indeterminate" [value]="10"></mat-progress-bar>
  </div>
</div>
