<div class="modal-header">
  <h4 class="modal-title" *ngIf="!editUser">Neuen Benutzer anlegen</h4>
  <h4 class="modal-title" *ngIf="editUser">Benutzer ändern</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <form class="col-sm-5" [formGroup]="userFormGroup">
      <legend><span>Benutzer-Daten</span></legend>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              Vorname
            </label>
            <input
              class="form-control"
              name="firstName"
              formControlName="firstName"
              type="text"
              placeholder="(Optional)"
              ngbAutofocus
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              Nachname
            </label>
            <input
              class="form-control"
              name="lastName"
              formControlName="lastName"
              type="text"
              placeholder="(Optional)"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">
              E-Mail Adresse
            </label>
            <input
              class="form-control"
              name="email"
              formControlName="email"
              type="email"
              value=""
              required
              [customErrors]="{ email: 'invalidEmail' }"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Benutzerrolle</label>
            <ng-select
              (change)="changeRole($event)"
              formControlName="role"
              bindLabel="name"
              bindValue="id"
              [clearable]="false"
              placeholder="Rolle auswählen"
            >
              <ng-option [value]="role" *ngFor="let role of roles">{{ role.name }}</ng-option>
            </ng-select>
          </div>
        </div>
      </div>
    </form>

    <div class="col-sm-7">
      <legend><span>Berechtigungen</span></legend>
      <div [formGroup]="permissionsFormGroup">
        <mat-expansion-panel *ngFor="let permissionGroup of permissions" expanded class="permission-group">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ permissionGroup.name }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <ul class="checkbox-list">
              <li>
                <mat-checkbox
                  [formControlName]="permission.permission"
                  *ngFor="let permission of permissionGroup.permissions"
                >
                  {{ permission.name }}
                </mat-checkbox>
              </li>
            </ul>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    class="btn btn-primary btn-block btn-lg"
    [disabled]="disableBtn"
    (click)="save()"
    *ngIf="!editUser"
    [disabled]="!userFormGroup.valid"
  >
    Benutzer anlegen
  </button>
  <button
    class="btn btn-primary btn-block btn-lg"
    [disabled]="disableBtn"
    (click)="save()"
    *ngIf="editUser"
    [disabled]="!userFormGroup.valid"
  >
    Benutzer speichern
  </button>
</div>
