<div class="pipeline-item">
  <div class="pi-controls" *ngIf="showDeleteButton">
    <div class="pi-settings">
      <button
        class="btn btn-white btn-sm remove-button"
        matTooltip="Produkt entfernen"
        (click)="$event.stopPropagation(); delete()"
      >
        <mat-icon svgIcon="delete"></mat-icon>
      </button>
      <button
        *ngIf="showSizes || showChoices"
        class="btn btn-white btn-sm btn-expand"
        [matTooltip]="expanded ? 'Einklappen' : 'Ausklappen'"
        (click)="toggleExpand()"
      >
        <i class="fas fa-chevron-down" *ngIf="!expanded"></i>
        <i class="fas fa-chevron-up" *ngIf="expanded"></i>
      </button>
    </div>
  </div>
  <div class="pi-body" (click)="toggleExpand()">
    <div class="pi-info">
      <div class="h6 pi-name">
        <ng-template [ngIf]="data.productId">{{ data.productId }}.</ng-template>
        {{ data.name }}
        <button
          class="btn btn-sm btn-white"
          (click)="$event.stopPropagation(); openItem()"
          matTooltip="Artikelinfos aufrufen"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </div>
  </div>
  <div [hidden]="!expanded">
    <div
      class="item-choices"
      *ngIf="showSizes"
      cdkDropList
      [cdkDropListData]="data.sizes"
      (cdkDropListDropped)="dropSize($event)"
      id="cat-{{ categoryId }}-size-{{ data.id }}"
    >
      <legend style="margin-bottom: 0.8rem;"><span>Größen</span></legend>
      <ng-select
        placeholder="Wählen Sie eine Größe aus"
        [items]="availableSizes"
        bindLabel="internalName"
        bindValue="id"
        [clearable]="false"
        [(ngModel)]="selectedSize"
        (change)="addSize($event)"
      ></ng-select>

      <div *ngIf="data.sizes.length" style="margin-top: 15px;">
        <div *ngFor="let size of data.sizes" cdkDrag [cdkDragData]="choice" class="choice-item size">
          {{ size.internalName }}
          <button (click)="deleteItemSize(data.itemId, size.id)" class="btn btn-xs" matTooltip="Größe löschen">
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
          <div *cdkDragPlaceholder class="choice-placeholder"></div>
        </div>
      </div>
    </div>
    <div
      class="item-choices"
      *ngIf="showChoices"
      cdkDropList
      [cdkDropListData]="data.choices"
      (cdkDropListDropped)="dropChoice($event)"
      id="cat-{{ categoryId }}-item-{{ data.id }}"
    >
      <legend><span>Auswahlfenster</span></legend>
      <div class="drop-placeholder drop-placeholder-item-choice" *ngIf="!data.choices.length">
        <img src="/assets/img/droparea.png" />
        <span>Auswahlfenster hier reinschieben</span>
      </div>
      <div *ngIf="data.choices.length">
        <div *ngFor="let choice of data.choices" cdkDrag [cdkDragData]="choice" class="choice-item">
          {{ choice.internalName ? choice.internalName : choice.name }}
          <button
            (click)="deleteItemChoice(data.itemId, choice.id)"
            class="btn btn-xs"
            matTooltip="Auswahlfenster löschen"
          >
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
          <div *cdkDragPlaceholder class="choice-placeholder"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="pi-foot">
    <div class="tags">
      <span class="tag">{{ data.type }}</span>
      <span class="tag tag-danger" *ngIf="!data.isOrderable">Nicht bestellbar</span>
      <span class="tag" *ngIf="data.isVegetarian">Vegetarisch</span>
      <span class="tag" *ngIf="data.isVegan">Vegan</span>
      <span
        class="tag"
        *ngFor="let tag of data.tags"
        style="color: white;"
        [style.background-color]="tag.color"
        [style.border-color]="tag.color"
      >
        {{ tag.name }}
      </span>
    </div>
    <a class="extra-info" href="#">
      <span *ngIf="data.prices.length">
        <ng-template [ngIf]="data.prices.length > 1">ab</ng-template> {{ data.prices[0].price }} €
      </span>
      <span *ngIf="!data.prices.length">Kein Preis</span>
    </a>
  </div>
</div>
