<div class="auth-box-w">
  <div class="logo-w">
    <a href="index.html"><img alt="" src="./assets/logo.png"/></a>
  </div>
  <h4 class="auth-header">
    Neues Passwort festlegen
  </h4>
  <form (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm" novalidate>
    <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
      {{ error }}
    </div>
    <div class="form-group">
      <label for="">Neues Passwort</label
      ><input class="form-control" formControlName="password" placeholder="" type="password" />
      <small
        [hidden]="resetPasswordForm.controls.password.valid || resetPasswordForm.controls.password.untouched"
        class="text-danger"
        translate
      >
        Passwort ist ungültig.
      </small>
    </div>
    <div class="form-group">
      <label for="">Passwort wiederholen</label
      ><input class="form-control" formControlName="passwordRepeat" type="password" autocomplete="current-password" />
      <small
        [hidden]="
          resetPasswordForm.controls.passwordRepeat.valid || resetPasswordForm.controls.passwordRepeat.untouched
        "
        class="text-danger"
        translate
      >
        Passwörter stimmen nicht überein.
      </small>
    </div>
    <div class="buttons-w">
      <button class="btn btn-primary" type="submit" [disabled]="resetPasswordForm.invalid || isLoading">
        Passwort speichern
      </button>
    </div>
  </form>
</div>
