<breadcrumb></breadcrumb>

<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">Integration - Reservierungs-System</h6>
        <div class="element-box">
          <h5 class="form-header">Wählen Sie Ihre gewünschte Integration aus</h5>
          <div class="form-desc">
            Ob als Button auf Ihrer Facebook Seite oder als Widget auf Ihrer Homepage. Mit der gastro.digital
            Reservierungsintegration können Sie überall Reservierungen anbieten.
          </div>

          <mat-accordion class="integration-expandable">
            <mat-expansion-panel (afterExpand)="expandPanel('link')">
              <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
                <!-- <i class="icon icon-facebook"></i> -->
                <i class="icon icon-code"></i>
                <div>
                  <mat-panel-title>
                    Direkter Link
                  </mat-panel-title>
                  <mat-panel-description>
                    Setze einen Link zur Online-Reservierung auf der deiner Webseite, Facebook oder Instagram
                  </mat-panel-description>
                </div>
              </mat-expansion-panel-header>
              <div>
                <!-- Gehen Sie auf Ihre Facebook Seite und klicken Sie dort auf die blaue Schaltfläche "Button hinzufügen".
                Wählen Sie die Kategorie "Services buchen" und dann den Eintrag "Jetzt buchen". Fügen Sie dann in das
                Textfeld folgenden Link ein: -->
                <code>
                  https://reservierung.gastroguide.de/{{ activeClient?.name | specialCharacters: true | encodeURI }}/{{
                    activeClient?.id
                  }}

                  <a
                    class="btn btn-light"
                    style="padding: 0"
                    href="https://reservierung.gastroguide.de/{{
                      activeClient?.name | specialCharacters: true | encodeURI
                    }}/{{ activeClient?.id }}"
                    target="_blank"
                  >
                    <mat-icon style="font-size: 18px;line-height: 22px;">launch</mat-icon>
                  </a>
                </code>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel (afterExpand)="expandPanel('widget')">
              <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
                <i class="icon icon-code"></i>
                <div>
                  <mat-panel-title>
                    Reservierungs-Widget
                  </mat-panel-title>
                  <mat-panel-description>
                    Binden Sie das Reservierungssystem direkt auf Ihre eigene Webseite ein
                  </mat-panel-description>
                </div>
              </mat-expansion-panel-header>
              <div>
                1. Binden Sie zunächst folgende Scripte an eine beliebige Stelle in Ihre Webseite ein:
                <br /><b
                  >Wenn Sie mehrere Gastro.Digital Widgets auf Ihrer Webseite einbinden möchten, benötigen Sie diesen
                  Schritt nur 1x!</b
                >
                <code>
                  &#x3C;link rel=&#x22;stylesheet&#x22;
                  href=&#x22;https://kunden.gastro.digital/widgets/styles.css&#x22; /&#x3E;
                  <br />
                  &#x3C;script
                  src=&#x22;https://kunden.gastro.digital/widgets/elements.js&#x22;&#x3E;&#x3C;/script&#x3E;
                </code>
                <br />
                2. Fügen Sie an die Stelle, an der Sie das Reservierungs-Widget anzeigen möchten, folgendes HTML ein:
                <code>
                  &#x3C;reservation-widget client=&#x22;{{
                    activeClient?.id
                  }}&#x22;&#x3E;&#x3C;/reservation-widget&#x3E;
                </code>
              </div>
              <legend><span>Vorschau</span></legend>
              <div class="widget-preview" *ngIf="activeClient && expandedPanel === 'widget'">
                <app-reservation-widget
                  [attr.client]="activeClient ? activeClient.id : 0"
                  *axLazyElement="widgetUrl"
                ></app-reservation-widget>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel (afterExpand)="expandPanel('button')">
              <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
                <i class="icon icon-code"></i>
                <div>
                  <mat-panel-title>
                    Reservierungs-Button
                  </mat-panel-title>
                  <mat-panel-description>
                    Binden Sie das Reservierungssystem mit einem Button auf Ihre eigene Webseite ein
                  </mat-panel-description>
                </div>
              </mat-expansion-panel-header>
              <div>
                1. Binden Sie zunächst folgende Scripte an eine beliebige Stelle in Ihre Webseite ein:
                <br /><b
                  >Wenn Sie mehrere Gastro.Digital Widgets auf Ihrer Webseite einbinden möchten, benötigen Sie diesen
                  Schritt nur 1x!</b
                >
                <code>
                  &#x3C;link rel=&#x22;stylesheet&#x22;
                  href=&#x22;https://kunden.gastro.digital/widgets/styles.css&#x22; /&#x3E;
                  <br />
                  &#x3C;script
                  src=&#x22;https://kunden.gastro.digital/widgets/elements.js&#x22;&#x3E;&#x3C;/script&#x3E;
                </code>
                <br />
                2. Fügen Sie an die Stelle, an der Sie das Reservierungs-Widget anzeigen möchten, folgendes HTML ein:
                <code>
                  &#x3C;reservation-button client=&#x22;{{
                    activeClient?.id
                  }}&#x22;&#x3E;&#x3C;/reservation-button&#x3E;
                </code>
              </div>
              <legend><span>Vorschau</span></legend>
              <div class="widget-preview" *ngIf="activeClient && expandedPanel === 'button'">
                <reservation-button
                  [attr.client]="activeClient ? activeClient.id : 0"
                  *axLazyElement="widgetUrl"
                ></reservation-button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">Reihenfolge Online Reservierung</h6>
        <div class="element-box">
          <div class="row">
            <div class="col-sm-12 col-md-6" style="padding-left: 0px;">
              <div class="form-group toggle-div">
                Datum bevorzugt &nbsp;
                <mat-slide-toggle
                  style="vertical-align:bottom"
                  name="isActive"
                  [(ngModel)]="generalResSettings.showShiftFirstOnline"
                  (change)="saveResSettings()"
                >
                </mat-slide-toggle>
                &nbsp; Vorlage bevorzugt
              </div>
            </div>
            <div class="form-desc">
              Datum bevorzugt : Personen, Datum, Reservierungsvorlage
              <br />
              Vorlage bevorzugt : Personen, Reservierungsvorlage, Datum (nur verfügbare Tage können ausgewählt werden)
            </div>
          </div>
          <!-- <button class="btn btn-primary" (click)="saveResSettings()">
                      Einstellungen speichern
                    </button> -->
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">Widget-Layout</h6>
        <div class="element-box">
          <div class="row">
            <div class="col-sm-8">
              <form [formGroup]="settingsFormGroup">
                <h5 class="form-header">Wählen Sie eine Hintergrundfarbe</h5>

                <color-picker-wrapper
                  [(color)]="settingsFormGroup.value.backgroundColor"
                  (colorChange)="changeBgColor($event)"
                ></color-picker-wrapper>

                <div class="form-desc">
                  Die Hintergrundfarbe Ihres Widgets und der externen Gutscheinkauf Seite.
                </div>

                <!-- <p class="label-checkbox"><b>Transparenter Hintergrund</b></p> -->
                <mat-checkbox
                  class="example-margin"
                  formControlName="formBackgroundColorCheckBox"
                  (change)="checkedHandler($event)"
                >
                  <b>Transparenter Hintergrund</b>
                </mat-checkbox>

                <div class="form-desc"></div>

                <h5 class="form-header">Wählen Sie die Hauptfarbe aus</h5>

                <color-picker-wrapper
                  [(color)]="settingsFormGroup.value.mainColor"
                  (colorChange)="changeMainColor($event)"
                ></color-picker-wrapper>

                <div class="form-desc">
                  Die Hauptfarbe wird für Texte genutzt.
                </div>

                <h5 class="form-header">Wählen Sie die Akzentfarbe aus</h5>

                <color-picker-wrapper
                  [(color)]="settingsFormGroup.value.primaryColor"
                  (colorChange)="changePrimaryColor($event)"
                ></color-picker-wrapper>

                <div class="form-desc">
                  Die Akzentfarbe wird für Buttons und Akzente genutzt.
                </div>

                <div class="font-wrapper">
                  <h5 class="form-header">Wählen Sie eine Schriftart für Titel</h5>

                  <div
                    class="font"
                    [(fontPicker)]="font"
                    [fpWidth]="'320px'"
                    [fpDialogDisplay]="'popup'"
                    [fpSizeSelect]="true"
                    [fpSearchText]="'Schriftart suchen...'"
                    [fpLoadingText]="'Schriftarten werden geladen...'"
                    [fpPopularLabel]="'Beliebte Schriftarten'"
                    [fpResultsLabel]="'Suchergebnisse'"
                    [fpStyleSelect]="false"
                    [style.font-family]="settingsFormGroup.value.primaryFont"
                    (fontPickerChange)="changePrimaryFont($event)"
                  >
                    {{ settingsFormGroup.value.primaryFont }}
                  </div>

                  <div class="form-desc">
                    Die Schriftart wird für alle Titel und Buttons genutzt.
                  </div>
                </div>

                <div class="font-wrapper">
                  <h5 class="form-header">Wählen Sie eine Schriftart für Texte</h5>

                  <div
                    class="font"
                    [(fontPicker)]="secondaryFont"
                    [fpWidth]="'320px'"
                    [fpDialogDisplay]="'popup'"
                    [fpSizeSelect]="true"
                    [fpSearchText]="'Schriftart suchen...'"
                    [fpLoadingText]="'Schriftarten werden geladen...'"
                    [fpPopularLabel]="'Beliebte Schriftarten'"
                    [fpResultsLabel]="'Suchergebnisse'"
                    [fpStyleSelect]="false"
                    [style.font-family]="settingsFormGroup.value.secondaryFont"
                    (fontPickerChange)="changeSecondaryFont($event)"
                    #secondaryFontPicker
                  >
                    {{ settingsFormGroup.value.secondaryFont }}
                  </div>

                  <div class="form-desc">
                    Die Schriftart wird für alle Texte und Eingabefelder genutzt.
                  </div>
                </div>

                <button class="btn btn-primary" (click)="saveIntegrationStyles()">Einstellungen speichern</button>
              </form>
            </div>
            <div class="col-sm-4" style="display: flex;">
              <div class="preview-changes">
                <ng-scrollbar [trackX]="false" [shown]="'hover'" [compact]="true">
                  <app-reservation
                    [client]="activeClient ? activeClient.id : 0"
                    [settings]="settingsFormGroup.value"
                  ></app-reservation>
                </ng-scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
