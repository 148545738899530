<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          {{ 'Events&Tickets.AllDates.SchdeuleOverview' | translate }}
        </h6>
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="{{ 'Events&Tickets.AllDates.UpcomingDates' | translate }}">
            <div class="element-box" *ngIf="!isActiveEventDetails">
              <h5 class="form-header">{{ 'Events&Tickets.AllDates.UpcomingDates' | translate }}</h5>
              <form class="row" [formGroup]="activeDateForm">
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for=""> {{ 'Events&Tickets.Common.EventName' | translate }} </label
                    ><input class="form-control" type="text" formControlName="activeSearchName" />
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for=""> {{ 'Events&Tickets.AllDates.Beginning' | translate }} </label>
                    <div class="input-group">
                      <div class="input-group-prepend" (click)="activeStartDateFromPicker.open()">
                        <div class="input-group-text">
                          <i class="fas fa-calendar-alt"></i>
                        </div>
                      </div>
                      <mat-datepicker #activeStartDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                      <input
                        class="form-control"
                        #dateFromInput
                        formControlName="activeStartDate"
                        placeholder=""
                        type="text"
                        name="date"
                        [min]="startDate"
                        [matDatepicker]="activeStartDateFromPicker"
                        (focus)="activeStartDateFromPicker.open()"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for=""> {{ 'Events&Tickets.AllDates.End' | translate }} </label>
                    <div class="input-group">
                      <div class="input-group-prepend" (click)="activeEndDateFromPicker.open()">
                        <div class="input-group-text">
                          <i class="fas fa-calendar-alt"></i>
                        </div>
                      </div>
                      <mat-datepicker #activeEndDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                      <input
                        class="form-control"
                        #dateFromInput
                        formControlName="activeEndDate"
                        placeholder=""
                        type="text"
                        name="date"
                        [min]="startDate"
                        [matDatepicker]="activeEndDateFromPicker"
                        (focus)="activeEndDateFromPicker.open()"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2">
                  <div class="form-desc">
                    <label for="">&nbsp;</label>
                    <button class="btn btn-primary btn-block" (click)="activeFilter()">
                      {{ 'Events&Tickets.AllDates.Filter' | translate }}
                    </button>
                  </div>
                </div>
                <div class="spinner-wrapper" *ngIf="activeDateLoader">
                  <mat-spinner></mat-spinner>
                </div>
              </form>
              <!-- <div class="alert alert-info" *ngIf="!activeDates || !activeDates.length" style="margin:0">
                {{ 'Events&Tickets.AllDates.NoDatesFound' | translate }}
              </div> -->
              <div class="table-responsive shifts" *ngIf="!activeDateLoader">
                <div class="ngx-datatable-template">
                  <div class="desktop-only">
                    <ngx-datatable
                      class="material striped"
                      [rows]="activeDates"
                      [columnMode]="'force'"
                      headerHeight="60"
                      footerHeight="50"
                      rowHeight="auto"
                      [limit]="50"
                      [cssClasses]="{
                        pagerLeftArrow: 'fas fa-backward',
                        pagerRightArrow: 'fas fa-forward',
                        pagerPrevious: 'fas fa-step-backward',
                        pagerNext: 'fas fa-step-forward'
                      }"
                      [messages]="{ emptyMessage: 'Common.NoDataFound' | translate }"
                    >
                      <ngx-datatable-column
                        [sortable]="true"
                        name="{{ 'Events&Tickets.AllDates.start&time' | translate }}"
                        prop="startsAtDay"
                      >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span title="{{ row.startsAtDay | date: 'dd.MM.yyyy' }}" class="date-label">
                            {{ row.startsAtDay | date: 'dd.MM.yyyy' }} </span
                          >&nbsp;
                          <span title="{{ row.startsAtHour }}" class="date-label">
                            {{ row.startsAtHour }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column>
                      <!-- <ngx-datatable-column [sortable]="true" name="{{ 'Events&Tickets.Common.Time' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span title="{{ row.startsAtHour }}" class="date-label">
                            {{ row.startsAtHour }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column> -->
                      <!-- <ngx-datatable-column [sortable]="true" name="{{ 'Events&Tickets.AllDates.End' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span title="{{ row.endsAtDay }}" class="date-label">
                            {{ row.endsAtDay }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column [sortable]="true" name="{{ 'Events&Tickets.Common.Time' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span title="{{ row.endsAtHour }}" class="date-label">
                            {{ row.endsAtHour }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column> -->
                      <ngx-datatable-column
                        [sortable]="true"
                        prop="event.name"
                        name="{{ 'Events&Tickets.AllDates.Event' | translate }}"
                      >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span
                            title="{{ row.event.name }}"
                            class="date-label"
                            style="cursor: pointer;"
                            (click)="editEvent(row.event, 'active')"
                          >
                            {{ row.event.name }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column>
                        <ng-template let-column="column" ngx-datatable-header-template>
                          <div class="equal-spacing">
                            <span class="ticket-detail-name">
                              {{ 'Events&Tickets.AllDates.TicketName' | translate }}
                            </span>
                            <span class="date-label">
                              {{ 'Events&Tickets.AllDates.SoldRedeemed' | translate }}
                            </span>
                            <span class="date-label">
                              {{ 'Events&Tickets.AllDates.soldtickets/availabletickets' | translate }}
                            </span>
                          </div>
                        </ng-template>
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <div class="equal-spacing" *ngFor="let ticketsData of row.ticketDates">
                            <span class="ticket-detail-name">
                              {{ ticketsData.tickets.name }}
                            </span>
                            <!-- <span
                              class="date-label"
                              [matTooltip]="
                                ticketsData.sold + ' Tickets verkauft - ' + ticketsData.redeemed + ' davon eingelöst'
                              "
                            >
                              {{ ticketsData.sold }}/{{ ticketsData.redeemed }}
                            </span> -->
                            <span class="date-label">
                              {{ ticketsData.sold }}
                            </span>
                            <span class="date-label remaining-ticket-web">
                              {{ ticketsData.remainingTickets }}
                            </span>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column [sortable]="false" name="{{ 'Events&Tickets.Common.Actions' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <button
                            class="btn btn-white ml-10 download-csv"
                            (click)="downloadCSV(row)"
                            matTooltip="CSV Download"
                          >
                            <i class="fas fa-file-csv"></i>
                          </button>
                          <button class="btn btn-white ml-10" (click)="downloadPdf(row)" matTooltip="PDF Download">
                            <i class="fas fa-print fa-fw"></i>
                          </button>
                          <button class="btn edit-btn" (click)="updateDate(row, row.event)" matTooltip="Bearbeiten">
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            class="btn delete-btn"
                            (click)="removeEventDate(row.id, row.event.id, 'active')"
                            matTooltip="Löschen"
                          >
                            <i class="far fa-trash-alt"></i>
                          </button>
                          <button class="btn view-btn" (click)="preview(row.event.id, row.id)">
                            <i class="fas fa-eye"></i>
                          </button>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                  <div class="mobile-only">
                    <ngx-datatable
                      #myTable
                      class="material striped expandable"
                      [rows]="activeDates"
                      [columnMode]="'force'"
                      headerHeight="50"
                      footerHeight="50"
                      rowHeight="auto"
                      [limit]="50"
                      [cssClasses]="{
                        pagerLeftArrow: 'fas fa-backward',
                        pagerRightArrow: 'fas fa-forward',
                        pagerPrevious: 'fas fa-step-backward',
                        pagerNext: 'fas fa-step-forward'
                      }"
                    >
                      <ngx-datatable-row-detail [rowHeight]="auto" #myDetailRow (toggle)="onDetailToggle($event)">
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                          <ul class="table-list">
                            <li>
                              <label>{{ 'Events&Tickets.AllDates.Beginning' | translate }}</label>
                              <strong>{{ row.startsAtDay | date: 'dd.MM.yyyy' }}</strong>
                            </li>
                            <li>
                              <label>{{ 'Events&Tickets.Common.Time' | translate }}</label>
                              <strong>{{ row.startsAtHour }}</strong>
                            </li>
                            <label class="mobile-ticket-data">
                              {{ 'Events&Tickets.AllDates.TicketName' | translate }}</label
                            >
                            <label class="mobile-ticket-data">
                              {{ 'Events&Tickets.AllDates.SoldRedeemed' | translate }}</label
                            >
                            <label class="ticket-name-remaining-ticket mobile-ticket-data">
                              {{ 'Events&Tickets.AllDates.soldtickets/availabletickets' | translate }}</label
                            >
                            <br />
                            <li *ngFor="let ticketsData of row.ticketDates">
                              <label class="mobile-ticket-data">{{ ticketsData.tickets.name }}</label>
                              <!-- <strong
                                [matTooltip]="
                                  ticketsData.sold + ' Tickets verkauft - ' + ticketsData.redeemed + ' davon eingelöst'
                                "
                                class="ticket-name-remaining-ticket mobile-ticket-data"
                              >
                                {{ ticketsData.sold }}/{{ ticketsData.redeemed }}</strong
                              > -->
                              <strong>
                                {{ ticketsData.sold }}
                              </strong>

                              <strong class="ticket-name-remaining-ticket mobile-ticket-data">
                                {{ ticketsData.remainingTickets }}</strong
                              >
                            </li>
                            <!-- <li>
                              <label>{{ 'Events&Tickets.Common.Time' | translate }}</label>
                              <strong>{{ row.endsAtHour }}</strong>
                            </li> -->
                          </ul>
                        </ng-template>
                      </ngx-datatable-row-detail>
                      <ngx-datatable-column
                        [width]="30"
                        [resizeable]="false"
                        [sortable]="false"
                        [draggable]="false"
                        [canAutoResize]="false"
                      >
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                          <a
                            href="javascript:void(0)"
                            [class.datatable-icon-right]="!expanded"
                            [class.datatable-icon-down]="expanded"
                            title="Expand/Collapse Row"
                            (click)="toggleExpandRow(row)"
                          >
                            <i class="fas fa-chevron-right"></i>
                          </a>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column
                        [sortable]="true"
                        prop="event.name"
                        name="{{ 'Events&Tickets.Common.EventName' | translate }}"
                      >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span
                            title="{{ row.event.name }}"
                            class="date-label"
                            style="cursor: pointer;"
                            (click)="editEvent(row.event, 'active')"
                          >
                            {{ row.event.name }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column [sortable]="false" name="{{ 'Events&Tickets.Common.Actions' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <button
                            class="btn btn-white ml-10 download-csv"
                            (click)="downloadCSV(row)"
                            matTooltip="CSV Download"
                          >
                            <i class="fas fa-file-csv"></i>
                          </button>
                          <button class="btn btn-white ml-10" (click)="downloadPdf(row)" matTooltip="PDF Download">
                            <i class="fas fa-print fa-fw"></i>
                          </button>
                          <button class="btn edit-btn" (click)="updateDate(row, row.event)" matTooltip="Bearbeiten">
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            class="btn delete-btn"
                            (click)="removeEventDate(row.id, row.event.id, 'active')"
                            matTooltip="Löschen"
                          >
                            <i class="far fa-trash-alt"></i>
                          </button>
                          <button class="btn view-btn" (click)="preview(row.event.id, row.id)">
                            <i class="fas fa-eye"></i>
                          </button>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="{{ 'Events&Tickets.AllDates.ArchiveDates' | translate }}">
            <div class="element-box" *ngIf="!isArchiveEventDetails">
              <h5 class="form-header">{{ 'Events&Tickets.AllDates.ArchiveDates' | translate }}</h5>
              <form class="row" [formGroup]="archiveDateForm">
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for=""> {{ 'Events&Tickets.AllDates.Event' | translate }} </label
                    ><input class="form-control" type="text" formControlName="archiveSearchName" />
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for=""> {{ 'Events&Tickets.AllDates.Beginning' | translate }} </label>
                    <div class="input-group">
                      <div class="input-group-prepend" (click)="archiveStartDateFromPicker.open()">
                        <div class="input-group-text">
                          <i class="fas fa-calendar-alt"></i>
                        </div>
                      </div>
                      <mat-datepicker #archiveStartDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                      <input
                        class="form-control"
                        #dateFromInput
                        formControlName="archiveStartDate"
                        placeholder=""
                        type="text"
                        name="date"
                        [matDatepicker]="archiveStartDateFromPicker"
                        (focus)="archiveStartDateFromPicker.open()"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for=""> {{ 'Events&Tickets.AllDates.End' | translate }} </label>
                    <div class="input-group">
                      <div class="input-group-prepend" (click)="archiveEndDateFromPicker.open()">
                        <div class="input-group-text">
                          <i class="fas fa-calendar-alt"></i>
                        </div>
                      </div>
                      <mat-datepicker #archiveEndDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                      <input
                        class="form-control"
                        #dateFromInput
                        formControlName="archiveEndDate"
                        placeholder=""
                        type="text"
                        name="date"
                        [min]="startDate"
                        [matDatepicker]="archiveEndDateFromPicker"
                        (focus)="archiveEndDateFromPicker.open()"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2">
                  <div class="form-desc">
                    <label for="">&nbsp;</label>
                    <button class="btn btn-primary btn-block" (click)="archiveFilter()">
                      {{ 'Events&Tickets.AllDates.Filter' | translate }}
                    </button>
                  </div>
                </div>
                <div class="spinner-wrapper" *ngIf="archiveDateLoader">
                  <mat-spinner></mat-spinner>
                </div>
              </form>
              <!-- <div class="alert alert-info" *ngIf="!archiveDates || !archiveDates.length" style="margin:0">
                {{ 'Events&Tickets.AllDates.NoDatesFound' | translate }}
              </div> -->
              <div class="table-responsive shifts" *ngIf="!archiveDateLoader">
                <div class="ngx-datatable-template">
                  <div class="desktop-only">
                    <ngx-datatable
                      class="material striped"
                      [rows]="archiveDates"
                      [columnMode]="'force'"
                      headerHeight="60"
                      footerHeight="50"
                      rowHeight="auto"
                      [limit]="50"
                      [cssClasses]="{
                        pagerLeftArrow: 'fas fa-backward',
                        pagerRightArrow: 'fas fa-forward',
                        pagerPrevious: 'fas fa-step-backward',
                        pagerNext: 'fas fa-step-forward'
                      }"
                      [messages]="{ emptyMessage: 'Common.NoDataFound' | translate }"
                    >
                      <ngx-datatable-column
                        [sortable]="true"
                        prop="startsAtDay"
                        name="{{ 'Events&Tickets.AllDates.start&time' | translate }}"
                      >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span title="{{ row.startsAtDay | date: 'dd.MM.yyyy' }}" class="date-label">
                            {{ row.startsAtDay | date: 'dd.MM.yyyy' }} </span
                          >&nbsp;
                          <span title="{{ row.startsAtHour }}" class="date-label">
                            {{ row.startsAtHour }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column>
                      <!-- <ngx-datatable-column [sortable]="true" name="{{ 'Events&Tickets.Common.Time' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span title="{{ row.startsAtHour }}" class="date-label">
                            {{ row.startsAtHour }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column> -->
                      <!-- <ngx-datatable-column [sortable]="true" name="{{ 'Events&Tickets.AllDates.End' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span title="{{ row.endsAtDay }}" class="date-label">
                            {{ row.endsAtDay }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column [sortable]="true" name="{{ 'Events&Tickets.Common.Time' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span title="{{ row.endsAtHour }}" class="date-label">
                            {{ row.endsAtHour }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column> -->
                      <ngx-datatable-column
                        [sortable]="true"
                        prop="event.name"
                        name="{{ 'Events&Tickets.Common.EventName' | translate }}"
                      >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span
                            title="{{ row.event.name }}"
                            class="date-label"
                            style="cursor: pointer;"
                            (click)="editEvent(row.event, 'archive')"
                          >
                            {{ row.event.name }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column>
                        <ng-template let-column="column" ngx-datatable-header-template>
                          <div class="equal-spacing">
                            <span class="ticket-detail-name">
                              {{ 'Events&Tickets.AllDates.TicketName' | translate }}
                            </span>
                            <span class="date-label">
                              {{ 'Events&Tickets.AllDates.SoldRedeemed' | translate }}
                            </span>
                            <span class="date-label">
                              {{ 'Events&Tickets.AllDates.Redeemed' | translate }}
                            </span>
                          </div>
                        </ng-template>
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <div class="equal-spacing" *ngFor="let ticketsData of row.ticketDates">
                            <span class="ticket-detail-name">
                              {{ ticketsData.tickets.name }}
                            </span>
                            <span class="date-label">
                              {{ ticketsData.sold }}
                            </span>
                            <span class="date-label remaining-ticket-web">
                              {{ ticketsData.redeemed }}
                            </span>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column [sortable]="false" name="{{ 'Events&Tickets.Common.Actions' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <button
                            class="btn btn-white ml-10 download-csv"
                            (click)="downloadCSV(row)"
                            matTooltip="CSV Download"
                          >
                            <i class="fas fa-file-csv"></i>
                          </button>
                          <button class="btn btn-white ml-10" (click)="downloadPdf(row)" matTooltip="PDF Download">
                            <i class="fas fa-print fa-fw"></i>
                          </button>
                          <!-- <button class="btn edit-btn" (click)="updateDate(row, row.event)" matTooltip="Bearbeiten">
                            <i class="fas fa-edit"></i>
                          </button> -->
                          <button
                            class="btn delete-btn"
                            (click)="removeEventDate(row.id, row.event.id, 'archive')"
                            matTooltip="Löschen"
                          >
                            <i class="far fa-trash-alt"></i>
                          </button>
                          <button class="btn view-btn" (click)="preview(row.event.id, row.id)">
                            <i class="fas fa-eye"></i>
                          </button>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                  <div class="mobile-only">
                    <ngx-datatable
                      #myTable2
                      class="material striped expandable"
                      [rows]="archiveDates"
                      [columnMode]="'force'"
                      headerHeight="50"
                      footerHeight="50"
                      rowHeight="auto"
                      [limit]="50"
                      [cssClasses]="{
                        pagerLeftArrow: 'fas fa-backward',
                        pagerRightArrow: 'fas fa-forward',
                        pagerPrevious: 'fas fa-step-backward',
                        pagerNext: 'fas fa-step-forward'
                      }"
                    >
                      <ngx-datatable-row-detail [rowHeight]="auto" #myDetailRow (toggle)="onDetailToggle($event)">
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                          <ul class="table-list">
                            <li>
                              <label>{{ 'Events&Tickets.AllDates.Beginning' | translate }}</label>
                              <strong>{{ row.startsAtDay | date: 'dd.MM.yyyy' }}</strong>
                            </li>
                            <li>
                              <label>{{ 'Events&Tickets.Common.Time' | translate }}</label>
                              <strong>{{ row.startsAtHour }}</strong>
                            </li>
                            <label class="mobile-ticket-data">
                              {{ 'Events&Tickets.AllDates.TicketName' | translate }}</label
                            >
                            <label class="mobile-ticket-data">
                              {{ 'Events&Tickets.AllDates.SoldRedeemed' | translate }}</label
                            >
                            <label class="ticket-name-remaining-ticket mobile-ticket-data">
                              {{ 'Events&Tickets.AllDates.Redeemed' | translate }}</label
                            >
                            <br />
                            <li *ngFor="let ticketsData of row.ticketDates">
                              <label class="mobile-ticket-data">{{ ticketsData.tickets.name }}</label>
                              <strong class="ticket-name-remaining-ticket mobile-ticket-data">
                                {{ ticketsData.sold }}</strong
                              >
                              <strong class="ticket-name-remaining-ticket mobile-ticket-data">
                                {{ ticketsData.redeemed }}</strong
                              >
                            </li>
                            <!-- <li>
                              <label>{{ 'Events&Tickets.Common.Time' | translate }}</label>
                              <strong>{{ row.endsAtHour }}</strong>
                            </li> -->
                          </ul>
                        </ng-template>
                      </ngx-datatable-row-detail>
                      <ngx-datatable-column
                        [width]="30"
                        [resizeable]="false"
                        [sortable]="false"
                        [draggable]="false"
                        [canAutoResize]="false"
                      >
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                          <a
                            href="javascript:void(0)"
                            [class.datatable-icon-right]="!expanded"
                            [class.datatable-icon-down]="expanded"
                            title="Expand/Collapse Row"
                            (click)="toggleExpandRow2(row)"
                          >
                            <i class="fas fa-chevron-right"></i>
                          </a>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column
                        [sortable]="true"
                        prop="event.name"
                        name="{{ 'Events&Tickets.Common.EventName' | translate }}"
                      >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span
                            title="{{ row.event.name }}"
                            class="date-label"
                            style="cursor: pointer;"
                            (click)="editEvent(row.event, 'archive')"
                          >
                            {{ row.event.name }}
                          </span>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column [sortable]="false" name="{{ 'Events&Tickets.Common.Actions' | translate }}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <button
                            class="btn btn-white ml-10 download-csv"
                            (click)="downloadCSV(row)"
                            matTooltip="CSV Download"
                          >
                            <i class="fas fa-file-csv"></i>
                          </button>
                          <button class="btn btn-white ml-10" (click)="downloadPdf(row)" matTooltip="PDF Download">
                            <i class="fas fa-print fa-fw"></i>
                          </button>
                          <button
                            class="btn delete-btn"
                            (click)="removeEventDate(row.id, row.event.id, 'archive')"
                            matTooltip="Löschen"
                          >
                            <i class="far fa-trash-alt"></i>
                          </button>
                          <button class="btn view-btn" (click)="preview(row.event.id, row.id)">
                            <i class="fas fa-eye"></i>
                          </button>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
