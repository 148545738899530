<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Affiliate: Online - Bestellungen
          <small *ngIf="filterBy"><br />Filter/Betrieb: <a class="link" (click)="filterOrdersBy()">aufheben</a></small>
        </h6>

        <!--
        <div class="row" style="margin-bottom: 25px; display: none;">
          <div class="col-sm-6">
            <a class="element-box el-tablo" href="javascript:return;">
              <div class="label">Bestellungen insgesamt</div>
              <div class="value">{{ stats.orders.total }}</div>

              <div class="row" style="margin-top: 15px;">
                <div class="el-tablo smaller col-sm-4">
                  <div class="label">Gestern</div>
                  <div class="value">{{ stats.orders.yesterday }}</div>
                </div>
                <div class="el-tablo smaller col-sm-4">
                  <div class="label">Heute</div>
                  <div class="value">{{ stats.orders.today }}</div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-6">
            <a class="element-box el-tablo" href="javascript:return;">
              <div class="label">Umsatz insgesamt</div>
              <div class="value">
                {{ stats.sum.total | currency: 'EUR' }} <small>({{ stats.balance.total | currency: 'EUR' }})</small>
              </div>

              <div class="row" style="margin-top: 15px;">
                <div class="el-tablo smaller col-sm-4">
                  <div class="label">Gestern</div>
                  <div class="value">{{ stats.sum.yesterday | currency: 'EUR' }}</div>
                </div>
                <div class="el-tablo smaller col-sm-4">
                  <div class="label">Heute</div>
                  <div class="value">{{ stats.sum.today | currency: 'EUR' }}</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      -->

        <div class="row">
          <div class="col-md-8">
            <div class="element-box">
              <div class="row">
                <div class="col-md-8">
                  <legend><span>Filtern nach</span></legend>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <section>
                    <div class="form-group">
                      <label for="">Datum von</label>
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateFromPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                        <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #dateFromInput
                          placeholder=""
                          [(ngModel)]="dates.from"
                          type="text"
                          name="date"
                          [matDatepicker]="dateFromPicker"
                          (focus)="dateFromPicker.open()"
                          (dateChange)="filterDates('from', $event)"
                        />
                      </div>
                    </div>
                  </section>
                </div>
                <div class="col-md-4">
                  <section>
                    <div class="form-group">
                      <label for="">Datum bis</label>
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateFromPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                        <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #dateToInput
                          placeholder=""
                          [(ngModel)]="dates.to"
                          type="text"
                          name="date"
                          [matDatepicker]="dateToPicker"
                          (focus)="dateToPicker.open()"
                          (dateChange)="filterDates('to', $event)"
                        />
                      </div>
                    </div>
                  </section>
                </div>

                <div class="col-md-4">
                  <section>
                    <div class="form-group">
                      <label for="">Tabelle exportieren</label><br />
                      <angular2csv
                        class="btn btn-primary"
                        [data]="allOrders"
                        filename="export_{{ now | date: 'yyyy_MM_dd_H_mm' }}"
                        [options]="csvOptions"
                      ></angular2csv>
                    </div>
                  </section>
                </div>

                <div class="col-md-12">
                  <section>
                    <div class="form-group actions">
                      <label for="">Aktionen</label><br />
                      <button class="btn btn-light" (click)="setDates('today')">heute</button>
                      <button class="btn btn-light" (click)="setDates('yesterday')">gestern</button>
                      <button class="btn btn-light" (click)="setDates('lastSeven')">letzten 7 Tage</button>
                      <button class="btn btn-light" (click)="setDates('thisWeek')">akt. Woche</button>
                      <button class="btn btn-light" (click)="setDates('lastWeek')">letzte Woche</button>
                      <button class="btn btn-light" (click)="setDates('thisMonth')">akt. Monat</button>
                      <button class="btn btn-light" (click)="setDates('lastMonth')">letzter Monat</button>
                      <button class="btn btn-warning" *ngIf="filterBy" (click)="filterOrdersBy()">
                        <i class="fas fa-times"></i>&nbsp;Betrieb:&nbsp;{{ filterBy }}
                      </button>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4" *ngIf="allOrders.length">
            <a class="element-box el-tablo" style="padding: 1.65rem 1rem 1.65rem;" href="javascript:return;">
              <div class="row">
                <div class="col-sm-8">
                  <div class="label">
                    Umsatz (brutto)
                  </div>
                  <div class="value">
                    {{ filterStats?.orders.total | currency: 'EUR' }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="label">
                    Anz.
                  </div>
                  <div class="value text-right">
                    {{ filterStats.orders.count }}
                  </div>
                </div>
              </div>

              <div class="value">
                <div class="label" style="padding-top: 19px;">Zeitraum</div>
                <small>{{ dates.from | date: 'd.M.yy' }} - {{ dates.to | date: 'd.M.yy' }}</small>
              </div>

              <div class="row" style="margin-top: 15px;">
                <div class="el-tablo smaller col-sm-4">
                  <div class="label">Bar</div>
                  <div class="value">{{ filterStats?.orders.cash | currency: 'EUR' }}</div>
                </div>
                <div class="el-tablo smaller col-sm-4">
                  <div class="label">Online</div>
                  <div class="value">{{ filterStats?.orders.online | currency: 'EUR' }}</div>
                </div>
                <div class="el-tablo smaller col-sm-4">
                  <div class="label">Gebühren</div>
                  <div class="value">{{ filterStats?.orders.commission | currency: 'EUR' }}</div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <!-- filter box -->

        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Alle Bestellungen ({{ allOrders ? allOrders.length : 0 }})">
            <div class="element-box">
              <div class="loading" *ngIf="!pendingOrders">
                <i class="fa fa-spinner fa-spin"></i>
                Bestellungen werden geladen
              </div>
              <ng-template [ngIf]="pendingOrders">
                <div class="alert alert-info" *ngIf="!allOrders.length">
                  Sie haben keine Bestellungen im gewählten Zeitraum
                </div>

                <div class="datatable-orders">
                  <ngx-datatable
                    class="material"
                    [rows]="allOrders"
                    [columnMode]="'force'"
                    headerHeight="50"
                    footerHeight="50"
                    rowHeight="auto"
                    [limit]="tableLimit"
                    [sorts]="[{ prop: 'createdAt', dir: 'desc' }]"
                    [cssClasses]="{
                      pagerLeftArrow: 'fas fa-backward',
                      pagerRightArrow: 'fas fa-forward',
                      pagerPrevious: 'fas fa-step-backward',
                      pagerNext: 'fas fa-step-forward'
                    }"
                  >
                    <ngx-datatable-column [sortable]="true" name="ID" prop="id">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span>{{ row.id }}</span>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [sortable]="true" name="Datum" prop="createdAt">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="date-label">{{ row.createdAt | amDateFormat: 'DD.MM.YYYY' }}</span>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [sortable]="true" name="Uhrzeit" prop="createdAt">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="date-label">{{ row.createdAt | amDateFormat: 'HH:mm' }}</span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [sortable]="true" name="Betrieb" prop="client.name">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <a (click)="filterOrdersBy('client', row.client.id)" *ngIf="filterBy != 'client' && row.client">
                          <i class="fas fa-filter"></i>
                        </a>
                        <!-- <a href="/orders/list/client/{{ row.client.id }}" *ngIf="filterBy != 'client'"> -->

                        <!--
                        <a (click)="filterOrdersBy('client', row.client.id)" *ngIf="filterBy != 'client' && row.client">
                          <i class="fas fa-filter"></i>
                        </a>
                        <a
                          *ngIf="row.client"
                          href="https://kunden.gastro.digital/client/{{ row.client.id }}/orders/overview"
                          target="_blank"
                          >
                        --><ng-container *ngIf="row.client">{{ row.client.name }}</ng-container
                        ><!--</a
                        >
                        <a
                          *ngIf="!row.client"
                          href="https://kunden.gastro.digital/client/{{ row.clientId }}/orders/overview"
                          target="_blank"
                          ><em>--><ng-container *ngIf="!row.client">{{ row.clientId }}</ng-container
                        ><!--</em></a
                        > -->
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [sortable]="true" name="Betrag" prop="subtotal">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="price-label"
                          >{{ returnPrice(row.subtotal) + returnPrice(row.deliveryFee) | number: '1.2' }} €</span
                        >
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [sortable]="true" name="Gebühr" prop="commissionFee">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="price-label">{{ returnPrice(row.commissionFee) | number: '1.2' }} €</span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [sortable]="true" name="Zahlart" prop="paymentMethod">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.paymentMethod === 'cash'">Bar</span>
                        <span *ngIf="row.paymentMethod === 'gastropay'">Gastropay</span>
                        <span *ngIf="row.paymentMethod === 'online'">{{
                          row.paymentTransaction?.paymentMethod
                            .replace('paypal', 'PayPal')
                            .replace('creditcard', 'Kreditkarte')
                            .replace('klarna', 'Klarna')
                            .replace('applepay', 'Apple Pay')
                        }}</span>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [sortable]="true" name="Typ" prop="type">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.type === 'delivery'">Lieferung</span>
                        <span *ngIf="row.type === 'takeaway'">Abholung</span>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [sortable]="true" name="Status" prop="status">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="text-success" *ngIf="row.status === 'confirmed'">
                          Fertig
                        </span>
                        <span class="text-light" *ngIf="row.status === 'pending'">
                          Ausstehend
                        </span>
                        <span class="text-danger" *ngIf="row.status === 'canceled'">
                          Storniert
                        </span>
                      </ng-template>
                    </ngx-datatable-column>
                    <!--
                    <ngx-datatable-column [sortable]="false" name="Kontostand" prop="balance">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="price-label" *ngIf="row.balance"
                          >{{ returnPrice(row.balance) | number: '1.2' }} €</span
                        >
                      </ng-template>
                    </ngx-datatable-column>
                  -->
                  </ngx-datatable>
                </div>
              </ng-template>
            </div>
          </mat-tab>

          <!--
          <mat-tab label="Offene Bestellungen ({{ pendingOrders ? pendingOrders.length : 0 }})">
            <div class="element-box">
              <div class="loading" *ngIf="!pendingOrders">
                <i class="fa fa-spinner fa-spin"></i>
                Bestellungen werden geladen
              </div>
              <ng-template [ngIf]="pendingOrders">
                <div class="alert alert-info" *ngIf="!pendingOrders.length">
                  Sie haben derzeit keine offenene Bestellungen
                </div>
                <div *ngFor="let order of pendingOrders" class="open-order">
                  <div class="header-info">
                    <div>
                      <h4 *ngIf="order.type !== 'inhouse'">{{ order.firstName }} {{ order.lastName }}</h4>
                      <h4 *ngIf="order.type === 'inhouse'">Tisch {{ order.tableNr }}</h4>
                      <small class="text-muted">
                        <span class="badge badge-light">Order #{{ order.id }}</span>
                        <span class="badge badge-primary" *ngIf="order.type === 'takeaway'">Abholung</span>
                        <span class="badge badge-primary" *ngIf="order.type === 'delivery'">Lieferung</span>
                        <span class="badge badge-primary" *ngIf="order.type === 'inhouse'">Inhouse</span>
                        <span class="badge badge-danger" *ngIf="order.status === 'canceled'">Storniert</span>
                        <span class="badge badge-warning" *ngIf="!order.paymentId">Unbezahlt</span>
                        <span class="badge badge-dark" *ngIf="order.deliveryTime">
                          Lieferzeit: {{ order.deliveryTime }} min.
                        </span>
                      </small>
                    </div>
                    <div class="text-muted">{{ order.createdAt | amCalendar }}</div>
                  </div>

                  <div fxFlexLayout="row">
                    <div fxFlex>
                      <label>
                        Bestellte Artikel
                      </label>
                      <ul>
                        <li *ngFor="let item of order.orderItems">
                          {{ item.quantity }}x {{ item.name }}
                          <small *ngIf="item.option" style="font-style:italic">{{ item.option }}</small>
                        </li>
                      </ul>
                    </div>
                    <div fxFlex>
                      <label>
                        Bezahlung
                      </label>
                      <div>
                        <ul>
                          <li><b>Gesamtbetrag:</b> {{ order.subtotal + order.deliveryFee | number: '1.2' }} €</li>
                          <li>
                            <b>Zahlungsart:</b> {{ order.paymentId ? order.paymentTransaction?.paymentMethod : 'Bar' }}
                          </li>
                          <li>
                            <b>Status:</b>
                            <span
                              [class.text-success]="
                                order.paymentTransaction && order.paymentTransaction.status === 'finished'
                              "
                              [class.text-danger]="
                                !order.paymentId ||
                                !order.paymentTransaction ||
                                order.paymentTransaction.status === 'pending'
                              "
                            >
                              {{
                                order.paymentTransaction && order.paymentTransaction.status === 'finished'
                                  ? 'Vollständig bezahlt'
                                  : 'Noch nicht bezahlt'
                              }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div fxFlex *ngIf="order.type !== 'inhouse'">
                      <label>
                        Gewünschte <ng-template [ngIf]="order.type === 'delivery'">Lieferzeit</ng-template>
                        <ng-template [ngIf]="order.type === 'takeaway'">Abholung</ng-template>
                      </label>
                      <div>
                        <ng-template [ngIf]="order.desiredTime">
                          {{
                            order.desiredTime
                              | amCalendar: { sameDay: '[Heute um] HH:mm', nextDay: '[Morgen um] HH:mm' }
                          }}
                        </ng-template>
                        <ng-template [ngIf]="!order.desiredTime">Sofort</ng-template>
                      </div>
                    </div>
                  </div>
                  <div fxFlexLayout="row">
                    <div fxFlex *ngIf="order.type === 'delivery'">
                      <label>
                        Lieferadresse
                      </label>
                      <div>
                        {{ order.firstName }} {{ order.lastName }}<br />
                        {{ order.street }}<br />
                        Etage: {{ order.floor ? order.floor : '' }} <br />
                        {{ order.zipCode }} {{ order.city }}<br />
                        Tel.: {{ order.phone }}<br />
                        E-Mail: {{ order.email }}
                        <a
                          href="http://maps.google.de/maps?daddr={{ order.street }},{{ order.zipCode }}%20%{{
                            order.city
                          }}"
                          style="text-decoration: underline"
                          target="_blank"
                          >Google Maps Routenplaner</a
                        >
                      </div>
                    </div>

                    <div fxFlex *ngIf="order.type === 'takeaway'">
                      <label>
                        Gast Daten (Abholung)
                      </label>
                      <div>
                        Tel.: {{ order.phone }}<br />
                        E-Mail: {{ order.email }}
                      </div>
                    </div>

                    <div fxFlex>
                      <label>
                        Gast-Hinweise
                      </label>
                      <div>
                        {{ order.notes ? order.notes : '-' }}
                      </div>
                    </div>
                  </div>

                  <div class="button-row">
                    <button class="btn btn-dark" (click)="setDeliveryTime(order)" *ngIf="!order.deliveryTime">
                      <i class="fas fa-check fa-fw"></i>
                      Bestellung in Arbeit
                    </button>
                    <button class="btn btn-success" (click)="changeStatus(order.id, 'confirmed')">
                      <i class="fas fa-check-double fa-fw"></i>
                      Bestellung abschließen
                    </button>
                    <button class="btn btn-danger" (click)="changeStatus(order.id, 'canceled')">
                      <i class="fas fa-times fa-fw"></i>
                      Bestellung stornieren
                    </button>
                  </div>
                </div>
              </ng-template>
            </div>
          </mat-tab>
          <mat-tab label="In Arbeit ({{ inprogressOrders ? inprogressOrders.length : 0 }})">
            <div class="element-box">
              <div class="loading" *ngIf="!inprogressOrders">
                <i class="fa fa-spinner fa-spin"></i>
                Bestellungen werden geladen
              </div>
              <ng-template [ngIf]="inprogressOrders">
                <div class="alert alert-info" *ngIf="!inprogressOrders.length">
                  Sie haben derzeit keine Bestellungen in Arbeit
                </div>
                <div *ngFor="let order of inprogressOrders" class="open-order">
                  <div class="header-info">
                    <div>
                      <h4 *ngIf="order.type !== 'inhouse'">{{ order.firstName }} {{ order.lastName }}</h4>
                      <h4 *ngIf="order.type === 'inhouse'">Tisch {{ order.tableNr }}</h4>
                      <small class="text-muted">
                        <span class="badge badge-light">Order #{{ order.id }}</span>
                        <span class="badge badge-primary" *ngIf="order.type === 'takeaway'">Abholung</span>
                        <span class="badge badge-primary" *ngIf="order.type === 'delivery'">Lieferung</span>
                        <span class="badge badge-primary" *ngIf="order.type === 'inhouse'">Inhouse</span>
                        <span class="badge badge-danger" *ngIf="order.status === 'canceled'">Storniert</span>
                        <span class="badge badge-warning" *ngIf="!order.paymentId">Unbezahlt</span>
                        <span class="badge badge-dark" *ngIf="order.deliveryTime">
                          Lieferzeit: {{ order.deliveryTime }} min.
                        </span>
                      </small>
                    </div>
                    <div class="text-muted">{{ order.createdAt | amCalendar }}</div>
                  </div>

                  <div fxFlexLayout="row">
                    <div fxFlex>
                      <label>
                        Bestellte Artikel
                      </label>
                      <ul>
                        <li *ngFor="let item of order.orderItems">
                          {{ item.quantity }}x {{ item.name }}
                          <small *ngIf="item.option" style="font-style:italic">{{ item.option }}</small>
                        </li>
                      </ul>
                    </div>
                    <div fxFlex>
                      <label>
                        Bezahlung
                      </label>
                      <div>
                        <ul>
                          <li><b>Gesamtbetrag:</b> {{ order.subtotal + order.deliveryFee | number: '1.2' }} €</li>
                          <li>
                            <b>Zahlungsart:</b> {{ order.paymentId ? order.paymentTransaction?.paymentMethod : 'Bar' }}
                          </li>
                          <li>
                            <b>Status:</b>
                            <span
                              [class.text-success]="
                                order.paymentTransaction && order.paymentTransaction.status === 'finished'
                              "
                              [class.text-danger]="
                                !order.paymentId ||
                                !order.paymentTransaction ||
                                order.paymentTransaction.status === 'pending'
                              "
                            >
                              {{
                                order.paymentTransaction && order.paymentTransaction.status === 'finished'
                                  ? 'Vollständig bezahlt'
                                  : 'Noch nicht bezahlt'
                              }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div fxFlex *ngIf="order.type !== 'inhouse'">
                      <label>
                        Gewünschte <ng-template [ngIf]="order.type === 'delivery'">Lieferzeit</ng-template>
                        <ng-template [ngIf]="order.type === 'takeaway'">Abholung</ng-template>
                      </label>
                      <div>
                        {{ order.desiredTime ? order.desiredTime : 'Sofort' }}
                      </div>
                    </div>
                  </div>
                  <div fxFlexLayout="row">
                    <div fxFlex *ngIf="order.type === 'delivery'">
                      <label>
                        Lieferadresse
                      </label>
                      <div>
                        {{ order.firstName }} {{ order.lastName }}<br />
                        {{ order.street }}<br />
                        Etage: {{ order.floor ? order.floor : '' }} <br />
                        {{ order.zipCode }} {{ order.city }}<br />
                        Tel.: {{ order.phone }}<br />
                        E-Mail: {{ order.email }}
                        <a
                          href="http://maps.google.de/maps?daddr={{ order.street }},{{ order.zipCode }}%20%{{
                            order.city
                          }}"
                          style="text-decoration: underline"
                          target="_blank"
                          >Google Maps Routenplaner</a
                        >
                      </div>
                    </div>

                    <div fxFlex *ngIf="order.type === 'takeaway'">
                      <label>
                        Gast Daten (Abholung)
                      </label>
                      <div>
                        Tel.: {{ order.phone }}<br />
                        E-Mail: {{ order.email }}
                      </div>
                    </div>

                    <div fxFlex>
                      <label>
                        Gast-Hinweise
                      </label>
                      <div>
                        {{ order.notes ? order.notes : '-' }}
                      </div>
                    </div>
                  </div>

                  <div class="button-row">
                    <button class="btn btn-dark" (click)="setDeliveryTime(order)" *ngIf="!order.deliveryTime">
                      <i class="fas fa-check fa-fw"></i>
                      Bestellung in Arbeit
                    </button>
                    <button class="btn btn-success" (click)="changeStatus(order.id, 'confirmed')">
                      <i class="fas fa-check-double fa-fw"></i>
                      Bestellung abschließen
                    </button>
                    <button class="btn btn-danger" (click)="changeStatus(order.id, 'canceled')">
                      <i class="fas fa-times fa-fw"></i>
                      Bestellung stornieren
                    </button>
                  </div>
                </div>
              </ng-template>
            </div>
          </mat-tab>
          <mat-tab label="Abgeschlossene Bestellungen ({{ finishedOrders ? finishedOrders.length : 0 }})">
            <div class="element-box">
              <div class="loading" *ngIf="!finishedOrders">
                <i class="fa fa-spinner fa-spin"></i>
                Bestellungen werden geladen
              </div>
              <ng-template [ngIf]="finishedOrders">
                <div class="alert alert-info" *ngIf="!finishedOrders.length">
                  Sie haben derzeit keine fertigen Bestellungen
                </div>
                <div *ngFor="let order of finishedOrders" class="open-order">
                  <div class="header-info">
                    <div>
                      <h4 *ngIf="order.type !== 'inhouse'">{{ order.firstName }} {{ order.lastName }}</h4>
                      <h4 *ngIf="order.type === 'inhouse'">Tisch {{ order.tableNr }}</h4>
                      <small class="text-muted">
                        <span class="badge badge-light">Order #{{ order.id }}</span>
                        <span class="badge badge-primary" *ngIf="order.type === 'takeaway'">Abholung</span>
                        <span class="badge badge-primary" *ngIf="order.type === 'delivery'">Lieferung</span>
                        <span class="badge badge-primary" *ngIf="order.type === 'inhouse'">Inhouse</span>
                        <span class="badge badge-danger" *ngIf="order.status === 'canceled'">Storniert</span>
                      </small>
                    </div>
                    <div class="text-muted">{{ order.createdAt | amCalendar }}</div>
                  </div>

                  <div fxFlexLayout="row">
                    <div fxFlex>
                      <label>
                        Bestellte Artikel
                      </label>
                      <ul>
                        <li *ngFor="let item of order.orderItems">
                          {{ item.quantity }}x {{ item.name }}
                          <small *ngIf="item.option" style="font-style:italic">{{ item.option }}</small>
                        </li>
                      </ul>
                    </div>
                    <div fxFlex>
                      <label>
                        Bezahlung
                      </label>
                      <div>
                        <ul>
                          <li><b>Gesamtbetrag:</b> {{ order.subtotal + order.deliveryFee | number: '1.2' }} €</li>
                          <li>
                            <b>Zahlungsart:</b> {{ order.paymentId ? order.paymentTransaction?.paymentMethod : 'Bar' }}
                          </li>
                          <li>
                            <b>Status:</b>
                            <span
                              [class.text-success]="
                                (order.paymentTransaction && order.paymentTransaction.status === 'finished') ||
                                order.paymentMethod === 'cash'
                              "
                              [class.text-danger]="
                                (!order.paymentId ||
                                  !order.paymentTransaction ||
                                  order.paymentTransaction.status === 'pending') &&
                                order.paymentMethod !== 'cash'
                              "
                            >
                              {{
                                (order.paymentTransaction && order.paymentTransaction.status === 'finished') ||
                                order.paymentMethod === 'cash'
                                  ? 'Vollständig bezahlt'
                                  : 'Noch nicht bezahlt'
                              }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div fxFlexLayout="row">
                    <div fxFlex *ngIf="order.type === 'delivery'">
                      <label>
                        Lieferadresse
                      </label>
                      <div>
                        {{ order.firstName }} {{ order.lastName }}<br />
                        {{ order.street }}<br />
                        Etage: {{ order.floor ? order.floor : '' }} <br />
                        {{ order.zipCode }} {{ order.city }}<br />
                        E-Mail: {{ order.email }}
                        <a
                          href="http://maps.google.de/maps?daddr={{ order.street }},{{ order.zipCode }}%20%{{
                            order.city
                          }}"
                          style="text-decoration: underline"
                          target="_blank"
                          >Google Maps Routenplaner</a
                        >
                      </div>
                    </div>
                    <div fxFlex *ngIf="order.type === 'takeaway'">
                      <label>
                        Gast Daten (Abholung)
                      </label>
                      <div>
                        Tel.: {{ order.phone }}<br />
                        E-Mail: {{ order.email }}
                      </div>
                    </div>
                    <div fxFlex>
                      <label>
                        Gast-Hinweise
                      </label>
                      <div>
                        -
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </mat-tab>
          -->
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>

<!--

  <div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          <span *ngIf="!filterBy">Alle</span> Online - Bestellungen<small *ngIf="filterBy"
            ><br />Filter: <a class="link" (click)="filterOrdersBy()">aufheben</a></small
          >
        </h6>

        <div class="element-box datatable-orders">
          <ngx-datatable
            class="material"
            [rows]="orders"
            [columnMode]="'force'"
            headerHeight="50"
            footerHeight="50"
            rowHeight="auto"
            [limit]="tableLimit"
            [sorts]="[{ prop: 'id', dir: 'desc' }]"
            [cssClasses]="{
              pagerLeftArrow: 'fas fa-backward',
              pagerRightArrow: 'fas fa-forward',
              pagerPrevious: 'fas fa-step-backward',
              pagerNext: 'fas fa-step-forward'
            }"
          >
            <ngx-datatable-column [sortable]="true" name="ID" prop="id">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{ row.id }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="true" name="Datum" prop="createdAt">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="date-label">{{ row.createdAt | amDateFormat: 'DD.MM.YYYY' }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="true" name="Uhrzeit" prop="createdAt">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="date-label">{{ row.createdAt | amDateFormat: 'HH:mm' }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="true" name="Betrieb" prop="client.name">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  <a (click)="filterOrdersBy('client', row.client.id)" *ngIf="filterBy != 'client' && row.client">
                    <i class="fas fa-filter"></i>
                  </a>
                  <a
                    *ngIf="row.client"
                    href="https://kunden.gastro.digital/client/{{ row.client.id }}/orders/overview"
                    target="_blank"
                    >{{ row.client.name }}</a
                  >
                  <a
                    *ngIf="!row.client"
                    href="https://kunden.gastro.digital/client/{{ row.clientId }}/orders/overview"
                    target="_blank"
                    ><em>&lt;Client ID: {{ row.clientId }}&gt;</em></a
                  >
                </ng-template>
              </ngx-datatable-column>
  
              <ngx-datatable-column [sortable]="true" name="Besteller" prop="lastName">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.lastName + ', ' + row.firstName }}
                </ng-template>
              </ngx-datatable-column>
  
              <ngx-datatable-column [sortable]="true" name="Betrag" prop="subtotal">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span class="price-label">{{ row.subtotal + row.deliveryFee | number: '1.2' }} €</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="true" name="Zahlart" prop="paymentMethod">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.paymentMethod === 'cash'">Bar</span>
                  <span *ngIf="row.paymentMethod === 'online'">{{ row.paymentTransaction?.paymentMethod }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="true" name="Typ" prop="type">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.type === 'delivery'">Lieferung</span>
                  <span *ngIf="row.type === 'takeaway'">Abholung</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="true" name="Status" prop="status">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span class="text-success" *ngIf="row.status === 'confirmed'">
                    Fertig
                  </span>
                  <span class="text-light" *ngIf="row.status === 'pending'">
                    Ausstehend
                  </span>
                  <span class="text-danger" *ngIf="row.status === 'canceled'">
                    Storniert
                  </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="false" name="Aktionen">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button class="btn btn-light" (click)="cancelOrder(row.id)" style="margin-right: 5px">
                    Stornieren
                  </button>
                  <button class="btn btn-danger" (click)="deleteOrder(row.id)"><i class="fa fa-trash"></i></button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
  
-->
