<breadcrumb></breadcrumb>

<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">Schritt 1: Import .csv Reservierungsdatei</h6>

        <div class="element-box">
          <h5 class="form-header">Speisekarten / CSV-Import</h5>
          <div class="form-desc">
            Importieren Sie Ihre CSV-/XLSX-Datei aus anderen Quellen.<br />
            Laden Sie die Datei hoch und prüfen Sie die Zuordnung der Spalten.
          </div>

          <div>
            <div *ngIf="msg && msg != ''" [class]="msgClass" [innerHtml]="msg"></div>

            <button *ngIf="!csvData" class="btn btn-primary" (click)="fileImportInput.click(); resetImport()">
              <i class="fas fa-file-upload"></i> &nbsp; .csv/.xlsx Datei auswählen
            </button>
            <input
              class="d-none"
              type="file"
              #fileImportInput
              name="File Upload"
              id="txtFileUpload"
              (change)="fileChangeListener($event)"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />

            <button class="btn btn-light" *ngIf="csvData" (click)="resetImport()">
              <i class="fas fa-sync"></i> &nbsp; neuen .csv/.xlsx Import vorbereiten
            </button>
          </div>
        </div>

        <ng-container
          *ngIf="(linesToImport || linesImported) && linesImported < linesToImport && msg == '' && msgClass == ''"
        >
          <div class="alert alert-info">
            CSV-Import läuft...<br />
            Importierte Zeilen: {{ linesImported }} / {{ linesToImport }}
          </div>
        </ng-container>
        <ng-container
          *ngIf="(linesToImport || linesImported) && linesImported >= linesToImport && msg == '' && msgClass == ''"
        >
          <div class="alert alert-primary">
            CSV-Import abgeschlossen<br />
            Importierte Zeilen: {{ linesImported }} / {{ linesToImport }}
          </div>
        </ng-container>

        <ng-container *ngIf="csvData && linesToImport == 0 && linesImported == 0">
          <!-- <pre>{{ csvTemplate }}{{ mappingArray | json  }}</pre> -->
          <br />
          <h6 class="element-header">Schritt 2: Tabellen-Felder zuordnen</h6>
          <div class="element-box">
            <h5 class="form-header">Manuelle Zuordnung</h5>

            <div class="form-desc" style="border-bottom: 0;">
              Ordnen Sie die Spalten manuell zu.<br /><br />
              <!--
              <button
                class="btn"
                [ngClass]="{ 'btn-secondary': csvTemplate == 'Manuell', 'btn-light': csvTemplate != 'Manuell' }"
                (click)="setCsvTemplate('Manuell')"
              >
                Manuell
              </button>
              <button
                class="btn"
                [ngClass]="{ 'btn-secondary': csvTemplate == 'Resmio', 'btn-light': csvTemplate != 'Resmio' }"
                (click)="setCsvTemplate('Resmio')"
              >
                Resmio
              </button>
              <button
                class="btn"
                [ngClass]="{ 'btn-secondary': csvTemplate == 'Opentable', 'btn-light': csvTemplate != 'Opentable' }"
                (click)="setCsvTemplate('Opentable')"
              >
                Opentable
              </button>
              <button
                class="btn"
                [ngClass]="{ 'btn-secondary': csvTemplate == 'Quandoo', 'btn-light': csvTemplate != 'Quandoo' }"
                (click)="setCsvTemplate('Quandoo')"
              >
                Quandoo
              </button>
            -->
              <!--
              <button class="btn" [ngClass]="{ 'btn-secondary': csvTemplate == 'Bookatable', 'btn-light': csvTemplate != 'Bookatable' }" (click)="setCsvTemplate('Bookatable');">Bookatable</button> 
              -->
              &nbsp;
              <button
                class="btn btn-light"
                *ngIf="mappingArray[0] == '' || mappingArray[1] == '' || mappingArray[2] == ''"
                (click)="
                  msg = 'Bitte ordnen Sie mind. den ersten drei Feldern die dazugehörigen Spalten zu.';
                  msgClass = 'alert alert-info'
                "
              >
                <i class="fas fa-file-import"></i> &nbsp; Import starten
              </button>
              <button
                class="btn btn-primary"
                *ngIf="mappingArray[0] != '' && mappingArray[1] != '' && mappingArray[2] != ''"
                (click)="startCsvImport()"
              >
                <i class="fas fa-file-import"></i> &nbsp; Import starten
              </button>
            </div>
            <div>
              <table class="table table-striped" style="width: auto;">
                <thead>
                  <tr>
                    <th>
                      GastroDigital Feld
                    </th>
                    <th>
                      CSV-Kopfspalte
                    </th>
                    <th>
                      CSV-Beispielwert
                    </th>
                    <th>
                      Eigener Wert
                    </th>
                  </tr>
                </thead>
                <tr *ngFor="let row of textConstantsArray; let i = index">
                  <td [innerHtml]="textConstants[row]"></td>
                  <td>
                    <select
                      class="form-control csv-select"
                      [(ngModel)]="mappingArray[i]"
                      [ngClass]="{ 'text-grey': mappingArray[i] == '' }"
                      placeholder="CSV-Kopfspalte wählen..."
                      [disabled]="i == 8 && statusRewrite != ''"
                    >
                      <option value="">Bitte Spalte wählen...</option>
                      <option *ngFor="let field of csvHeader[0]; let j = index" [value]="j">{{
                        replaceArray(field, charFind, charReplace)
                      }}</option>
                    </select>
                  </td>
                  <td *ngIf="csvData[0].length > 1 || csvData[1].length > 1">
                    <select
                      class="form-control csv-select"
                      [(ngModel)]="mappingArray[i]"
                      [ngClass]="{ 'text-grey': mappingArray[i] == '' }"
                      placeholder="... oder Beispielspalte wählen"
                      [disabled]="i == 8 && statusRewrite != ''"
                    >
                      <option value="">... oder Wert wählen</option>

                      <ng-container *ngIf="csvData[0].length > 1">
                        <option *ngFor="let value of csvData[0]; let j = index" [value]="j">{{
                          replaceArray(value, charFind, charReplace)
                        }}</option>
                      </ng-container>
                      <ng-container *ngIf="csvData[0].length <= 1">
                        <option *ngFor="let value of csvData[1]; let j = index" [value]="j">{{
                          replaceArray(value, charFind, charReplace)
                        }}</option>
                      </ng-container>
                    </select>
                  </td>
                  <td>
                    <input
                      *ngIf="placeholderValues[i] != ''"
                      class="form-control csv-select"
                      [(ngModel)]="defaultValues[i]"
                      [placeholder]="placeholderValues[i]"
                    />
                    <select *ngIf="i == 4" class="form-control" name="placeholder-8" [(ngModel)]="statusRewrite">
                      <option *ngFor="let option of statusListName; let j = index" value="{{ statusListValue[j] }}">{{
                        option
                      }}</option>
                    </select>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
