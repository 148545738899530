<div class="modal-content text-center">
  <button aria-label="Close" class="close" style="outline:none;" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      Bestätige die E-Mail-Adresse
    </h4>
    <form [formGroup]="emailAddressFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">
              Email
            </label>
            <input
              class="form-control"
              placeholder=""
              type="email"
              autocomplete="email"
              formControlName="email"
              [customErrors]="{ email: 'invalidEmail' }"
              required
            />
          </div>
        </div>
        <button class="btn btn-primary btn-block btn-lg" (click)="sendTicket()">
          Ticket versenden
        </button>
      </div>
    </form>
  </div>
</div>
