<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss('Cross click')" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title" *ngIf="!editTableData && !isCombined">Neuen Tisch anlegen</h4>
    <h4 class="onboarding-title" *ngIf="!editTableData && isCombined">Neue Kombination anlegen</h4>
    <h4 class="onboarding-title" *ngIf="editTableData && !tableFormGroup.value.isCombined">Tisch bearbeiten</h4>
    <h4 class="onboarding-title" *ngIf="editTableData && tableFormGroup.value.isCombined">Kombination bearbeiten</h4>
    <form [formGroup]="tableFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="" *ngIf="!tableFormGroup.value.isCombined">Tisch-Nummer</label>
            <label for="" *ngIf="tableFormGroup.value.isCombined">Name der Kombination</label>
            <input
              class="form-control"
              name="name"
              formControlName="name"
              placeholder="{{
                tableFormGroup.value.isCombined
                  ? 'Geben Sie einen Namen für die Kombination ein...'
                  : 'Geben Sie die Tischnummer ein...'
              }}"
              type="text"
              value=""
              required
              ngbAutofocus
            />
          </div>
        </div>
        <div class="col-sm-12" *ngIf="tableFormGroup.value.isCombined">
          <div class="form-group">
            <label for="">Kombinierte Tische</label>
            <ng-select
              [items]="room.tables"
              bindLabel="name"
              [multiple]="true"
              placeholder="Tische auswählen"
              name="combinedTables"
              formControlName="combinedTables"
              [customErrors]="{ minlength: 'Mindestens 2 Tische auswählen' }"
            >
            </ng-select>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">Anzahl Plätze</label>
            <number-spinner
              name="seats"
              [min]="1"
              [max]="1000"
              [step]="1"
              [precision]="1"
              [defaultValue]="+tableFormGroup.value.seats"
              [inputDisabled]="false"
              (whenChange)="seatsAmountChanged($event)"
            ></number-spinner>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">Mindestbelegung</label>
            <number-spinner
              name="minOccupancy"
              [min]="1"
              [max]="1000"
              [step]="1"
              [precision]="1"
              [defaultValue]="+tableFormGroup.value.minOccupancy"
              [inputDisabled]="false"
              (whenChange)="minOccupancyChanged($event)"
            ></number-spinner>
          </div>
        </div>
        <div class="col-sm-12" style="margin-bottom: 20px">
          <mat-slide-toggle name="isActive" formControlName="isActive">
            <ng-template [ngIf]="!tableFormGroup.value.isCombined">Tisch ist reservierbar</ng-template>
            <ng-template [ngIf]="tableFormGroup.value.isCombined">Kombination ist reservierbar</ng-template>
          </mat-slide-toggle>
        </div>
        <div class="col-sm-12" style="margin-bottom: 20px" *ngIf="!tableFormGroup.value.isCombined">
          <div class="row">
            <div class="col-sm-4">
              <label for="tableType">Table type</label>
              <select id="tableType" class="form-control" (change)="selectTableType($event)">
                <option [value]="null" [selected]="!tableFormGroup.value.type">Auto</option>
                <option [value]="'Rectangle'" [selected]="tableFormGroup.value.type === 'Rectangle'">Rectangle</option>
                <option [value]="'Round'" [selected]="tableFormGroup.value.type === 'Round'">Round</option>
                <option [value]="'Square'" [selected]="tableFormGroup.value.type === 'Square'">Square</option>
              </select>
            </div>
            <div class="col-sm-8">
              <label for="tablePreview">Preview</label>
              <div id="tablePreview">
                <!-- Rectangle or Auto table type -->
                <ng-template [ngIf]="!tableFormGroup.value.type || tableFormGroup.value.type !== 'Round'">
                  <div class="table-wrapper">
                    <div
                      class="preview-table"
                      [ngStyle]="{
                        width: this.previewTableWidth + 'px',
                        height: previewTableHeight + 'px'
                      }"
                    >
                      <div
                        class="preview-chair"
                        *ngFor="let chair of this.chairPositions; let i = index"
                        [ngStyle]="{
                          top: chair.top + 'px',
                          left: chair.left + 'px'
                        }"
                      >
                        {{ chair.position }}
                      </div>
                    </div>
                  </div>
                </ng-template>
                <!-- End of Rectangle or Auto table -->
                <!-- Round table type -->
                <ng-template [ngIf]="tableFormGroup.value.type === 'Round'">
                  <div class="table-wrapper">
                    <div
                      class="preview-table round"
                      [ngStyle]="{
                        width: this.previewTableWidth + 'px',
                        height: previewTableHeight + 'px'
                      }"
                    >
                      <div
                        class="preview-chair"
                        *ngFor="let chair of this.chairPositions; let i = index"
                        [ngStyle]="{
                          top: chair.top + 'px',
                          left: chair.left + 'px'
                        }"
                      >
                        {{ chair.position }}
                      </div>
                    </div>
                  </div>
                </ng-template>
                <!-- End of Round table -->
              </div>
            </div>
          </div>
        </div>

        <!--
        <div class="col-sm-12" style="margin-bottom: 20px">
          <mat-slide-toggle name="isCombined" formControlName="isCombined" (change)="changeCombined($event)">
            Tisch ist eine Kombination
          </mat-slide-toggle>
        </div>
        -->

        <!-- GastroPay Admin Settings-->
        <div class="gastropay-settings" *ngIf="editTableData && credentialsService.credentials.role === 'admin'">
          <h5 (click)="toggleGpaySettings()">
            GastroPay Einstellungen
            <i class="fas" [class.fa-chevron-right]="!showGpaySettings" [class.fa-chevron-down]="showGpaySettings"></i>
          </h5>
          <ng-template [ngIf]="showGpaySettings">
            <div class="col-sm-12" style="margin-bottom: 10px">
              <div class="form-group">
                <label for="">Tisch-Nummer POS</label>
                <input
                  class="form-control"
                  name="POSName"
                  formControlName="POSName"
                  placeholder="POS-Name"
                  type="text"
                  value=""
                />
              </div>
            </div>
            <div class="col-sm-12" style="margin-bottom: 10px">
              <mat-slide-toggle name="selfOrdering" formControlName="selfOrdering">
                Selfordering aktiv
              </mat-slide-toggle>
            </div>
            <div class="col-sm-12" style="margin-bottom: 10px">
              <mat-slide-toggle name="instantPayment" formControlName="instantPayment">
                Bestellung muss sofort bezahlt werden
              </mat-slide-toggle>
            </div>
            <div class="col-sm-12" style="margin-bottom: 20px">
              <mat-slide-toggle name="bringService" formControlName="bringService">
                Bestellung wird gebracht
              </mat-slide-toggle>
            </div>
          </ng-template>
        </div>
        <!-- /GastroPay Admin Settings-->

        <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="addTable()"
          *ngIf="!editTableData"
          [promiseBtn]="addObservable"
        >
          <ng-template [ngIf]="!tableFormGroup.value.isCombined">Tisch anlegen</ng-template>
          <ng-template [ngIf]="tableFormGroup.value.isCombined">Kombination anlegen</ng-template>
        </button>
        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="saveTable()"
          *ngIf="editTableData"
          [promiseBtn]="editObservable"
        >
          <ng-template [ngIf]="!tableFormGroup.value.isCombined">Tisch speichern</ng-template>
          <ng-template [ngIf]="tableFormGroup.value.isCombined">Kombination speichern</ng-template>
        </button>
      </div>
    </form>
  </div>
</div>
