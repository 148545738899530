<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="hero">
        <div>
          <img class="gpay-logo" src="/assets/img/gastropay/logo_default_web.png" />
          <h1>
            Digital bezahlen<br />
            mit dem Smartphone
          </h1>

          <p class="lead">Wir bieten Ihnen das <mark>innovativste Zahlungsystem</mark> für die Gastronomie.</p>

          <ul>
            <li><i class="fas fa-arrow-right fa-fw"></i> Mehr Zeit für Gäste</li>
            <li><i class="fas fa-arrow-right fa-fw"></i> Mehr Bestellungen</li>
            <li><i class="fas fa-arrow-right fa-fw"></i> Mehr Umsatz</li>
          </ul>
        </div>
        <div>
          <img src="/assets/img/gastropay/app.png" />
        </div>
      </div>

      <div class="alert alert-info" role="alert">
        <h4 class="alert-heading">Erhalten Sie ein unverbindliches Angebot und mehr Informationen</h4>
        <p>
          Haben Sie Interesse daran Ihre Gastronomie zu digitalisieren und von den Vorteilen von GastroPay zu
          profitieren?<br />
          Fordern Sie unverbindlich weitere Informationen und ein Angebot an. Wir melden uns zeitnah bei Ihnen.
        </p>
        <button class="btn btn-secondary" (click)="requestMoreInfo()" [promiseBtn]="signupObservable">
          Ja, ich möchte mehr Informationen erhalten
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6 col-md-3 col-xxxl-3">
      <a class="element-box el-tablo" href="#"
        ><div class="label">Mehr Bestellungen</div>
        <p>
          Laufwege für Belege und EC-Geräte entfallen Abrechnungen werden zeitlich optimiert. Mehr Zeit für
          Bestellungen!
        </p>
      </a>
    </div>
    <div class="col-sm-6 col-md-3  col-xxxl-3">
      <a class="element-box el-tablo" href="#"
        ><div class="label">Mehr Umsatz</div>
        <p>Fokus auf Verkauf und Service Stoßzeiten und Unterbesetzungen immer im Griff.</p>
      </a>
    </div>
    <div class="col-sm-6 col-md-3  col-xxxl-3">
      <a class="element-box el-tablo" href="#"
        ><div class="label">Mehr Zeit für Gäste</div>
        <p>One Step Payment Direktes Bezahlen ohne Wartezeit. Einfach und sicher mit dem eigenen Smartphone.</p>
      </a>
    </div>
    <div class="col-sm-6 col-md-3  col-xxxl-3">
      <a class="element-box el-tablo" href="#"
        ><div class="label">Mehr Zeit zum Genießen</div>
        <p>
          Rechnungskontrolle Transparenz weckt Vertrauen. Rechnungskontrolle zu jeder Zeit!
        </p>
      </a>
    </div>
  </div>
</div>
