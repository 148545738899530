<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editTimeFilterData">{{
        'ResSettings.ResAddTimeFilter.NewTimeRange' | translate
      }}</ng-template>
      <ng-template [ngIf]="editTimeFilterData">
        {{ 'ResSettings.ResAddTimeFilter.EditTimeRange' | translate }}</ng-template
      >
    </h4>
    <form [formGroup]="resTimeFilterFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for=""> Name</label>
            <input class="form-control" placeholder="" type="text" formControlName="name" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for=""> {{ 'ResSettings.ResAddTimeFilter.StartTime' | translate }}</label>
            <ngb-timepicker formControlName="from" [meridian]="false"></ngb-timepicker>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'ResSettings.ResAddTimeFilter.EndTime' | translate }}</label>
            <ngb-timepicker formControlName="to" [meridian]="false"></ngb-timepicker>
          </div>
        </div>
      </div>
      <button class="btn btn-primary btn-block btn-lg" (click)="add()">
        {{ 'ResSettings.ResAddTimeFilter.Save' | translate }}
      </button>
    </form>
  </div>
</div>
