<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Übersicht - GastroPay

          <div class="element-actions form-group">
            <div class="date-input">
              <input
                class="form-control single-daterange"
                placeholder="Datum auswählen"
                (click)="picker.open()"
                [satDatepicker]="picker"
                [value]="date"
              />
            </div>
            <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
          </div>
        </h6>
        <div class="element-content">
          <div class="row">
            <div class="col-sm-4 col-xxxl-3">
              <a class="element-box el-tablo" href="#"
                ><div class="label">Erzeugte QR-Codes</div>
                <div class="value">{{ report.qrCodes.created }}</div>
                <div class="trending trending-up-basic"><span>12%</span><i class="os-icon os-icon-arrow-up2"></i></div
              ></a>
            </div>
            <div class="col-sm-4 col-xxxl-3">
              <a class="element-box el-tablo" href="#"
                ><div class="label">Gescannte QR-Codes</div>
                <div class="value">{{ report.qrCodes.scanned }}</div>
                <div class="trending trending-down-basic"><span>9%</span><i class="os-icon os-icon-arrow-down"></i></div
              ></a>
            </div>
            <div class="col-sm-4 col-xxxl-3">
              <a class="element-box el-tablo" href="#"
                ><div class="label">Registrierte Benutzer</div>
                <div class="value">{{ report.userAccounts.existingUsers }}</div>
                <div class="trending trending-down-basic">
                  <span>12%</span><i class="os-icon os-icon-arrow-down"></i></div
              ></a>
            </div>
            <div class="col-sm-4 col-xxxl-3">
              <a class="element-box el-tablo" href="#"
                ><div class="label">Neue Benutzer</div>
                <div class="value">{{ report.userAccounts.newUsers }}</div>
                <div class="trending trending-down-basic">
                  <span>12%</span><i class="os-icon os-icon-arrow-down"></i></div
              ></a>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 col-xxxl-3">
              <a class="element-box el-tablo" href="#"
                ><div class="label">Anzahl Transaktionen</div>
                <div class="value">{{ report.transactions.created }}</div>
              </a>
            </div>
            <div class="col-sm-4 col-xxxl-3">
              <a class="element-box el-tablo" href="#"
                ><div class="label">Umsatz</div>
                <div class="value">{{ report.transactions.turnover }} €</div>
                <div class="trending trending-up-basic">
                  <span>{{ report.transactions.turnoverCreatedRatio }}%</span
                  ><i class="os-icon os-icon-arrow-up2"></i></div
              ></a>
            </div>
            <div class="col-sm-4 col-xxxl-3">
              <a class="element-box el-tablo" href="#"
                ><div class="label">Trinkgeld</div>
                <div class="value">{{ report.transactions.serviceTip }} €</div>
                <div class="trending trending-down-basic">
                  <span>{{ report.transactions.serviceTipTurnoverRatio }}%</span
                  ><i class="os-icon os-icon-arrow-down"></i></div
              ></a>
            </div>
            <div class="col-sm-4 col-xxxl-3">
              <a class="element-box el-tablo" href="#"
                ><div class="label">Cashback</div>
                <div class="value">{{ report.transactions.cashback }} €</div>
                <div class="trending trending-down-basic">
                  <span>12%</span><i class="os-icon os-icon-arrow-down"></i></div
              ></a>
            </div>
          </div>

          <div class="element-box chart">
            <dx-chart palette="violet" [dataSource]="weekChartData" title="Monatsvergleich">
              <dxi-series valueField="turnover" name="Digitaler Umsatz"></dxi-series>
              <dxi-series valueField="reOrderTurnover" name="Nachbestellungsumsatz"></dxi-series>
              <dxo-common-series-settings #seriesSettings argumentField="month" type="spline">
              </dxo-common-series-settings>
              <dxo-margin [bottom]="20"></dxo-margin>
              <dxo-common-axis-settings>
                <dxo-grid [visible]="true"></dxo-grid>
              </dxo-common-axis-settings>
              <dxo-legend verticalAlignment="bottom" horizontalAlignment="center"> </dxo-legend>
              <dxo-argument-axis [allowDecimals]="false" [axisDivisionFactor]="60">
                <dxo-label>
                  <dxo-format type="decimal"></dxo-format>
                </dxo-label>
              </dxo-argument-axis>
              <dxo-export [enabled]="true"></dxo-export>
              <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeChartTooltip"></dxo-tooltip>
            </dx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
