import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import {
  MatIconModule,
  MatDatepickerModule,
  MatTabsModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatInputModule,
  MatPaginatorModule,
  MatTooltipModule
} from '@angular/material';
import { DateFnsModule } from 'ngx-date-fns';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'ngx-moment';
import { SatNativeDateModule, SatDatepickerModule } from 'saturn-datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { Angular2CsvModule } from 'angular2-csv';
import { EventsRoutingModule } from './events-routing.module';
import { Ng5SliderModule } from 'ng5-slider';
import { AllDatesComponent } from './all-dates/all-dates.component';
import { EditEventComponent } from './edit-event/edit-event.component';
import { EventDatesListComponent } from './event-dates-list/event-dates-list.component';
import { EventListComponent } from './event-list/event-list.component';
import { AddEventComponent } from './add-event/add-event.component';
import { AddEventDatesComponent } from './add-event-dates/add-event-dates.component';
import { AddEditTicketComponent } from './add-edit-ticket/add-edit-ticket.component';
import { AddEditSubcategoryComponent } from './add-edit-subcategory/add-edit-subcategory.component';
import { AddEditOptionComponent } from './add-edit-option/add-edit-option.component';
import { AssignTicketsDatesComponent } from './assign-tickets-dates/assign-tickets-dates.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OrdersComponent } from './orders/orders.component';
import { AgmCoreModule } from '@agm/core';
import { CKEditorModule } from 'ckeditor4-angular';
import { ChangeEmailAddressComponent } from './orders/change-email-address/change-email-address.component';
import { MoveTicketDatesComponent } from './move-ticket-dates/move-ticket-dates.component';
import { ChangeBuyerAddressComponent } from '@app/shared/change-buyer-address/change-buyer-address.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    MatIconModule,
    NgScrollbarModule,
    NgxDatatableModule,
    MomentModule,
    NgxDatatableModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
    MatTabsModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatSelectModule,
    MatInputModule,
    NgSelectModule,
    Ng5SliderModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    DateFnsModule.forRoot(),
    Angular2CsvModule,
    EventsRoutingModule,
    MatAutocompleteModule,
    CKEditorModule,
    MatPaginatorModule,
    MatTooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBvKqFb3-0ryGntQlxwSZWoO9miYqZWRkg'
    })
  ],
  declarations: [
    EventListComponent,
    AllDatesComponent,
    AddEventComponent,
    AddEventDatesComponent,
    EventDatesListComponent,
    AllDatesComponent,
    EditEventComponent,
    AddEditTicketComponent,
    AddEditSubcategoryComponent,
    AddEditOptionComponent,
    AssignTicketsDatesComponent,
    OrdersComponent,
    ChangeEmailAddressComponent,
    MoveTicketDatesComponent,
    ChangeBuyerAddressComponent
  ],
  entryComponents: [
    AddEventComponent,
    AddEventDatesComponent,
    AddEditSubcategoryComponent,
    AddEditOptionComponent,
    AssignTicketsDatesComponent,
    ChangeEmailAddressComponent,
    MoveTicketDatesComponent,
    ChangeBuyerAddressComponent
  ],
  providers: [NgbActiveModal]
})
export class EventModule {}
