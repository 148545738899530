/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i8 from "@angular/material/list";
import * as i9 from "@angular/material/core";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./upload-logo.component";
import * as i12 from "@angular/material/bottom-sheet";
var styles_UploadLogoComponent = [i0.styles];
var RenderType_UploadLogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadLogoComponent, data: {} });
export { RenderType_UploadLogoComponent as RenderType_UploadLogoComponent };
function View_UploadLogoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-progress-spinner", [["class", "mat-progress-spinner"], ["role", "progressbar"], ["style", "margin: 0 auto;"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressSpinner_0, i2.RenderType_MatProgressSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatProgressSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], mode: [1, "mode"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_7 = "primary"; var currVal_8 = "indeterminate"; _ck(_v, 1, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; var currVal_3 = ((i1.ɵnov(_v, 1).mode === "determinate") ? 0 : null); var currVal_4 = ((i1.ɵnov(_v, 1).mode === "determinate") ? 100 : null); var currVal_5 = i1.ɵnov(_v, 1).value; var currVal_6 = i1.ɵnov(_v, 1).mode; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_UploadLogoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(1, 0, null, null, 15, "a", [["class", "mat-list-item"], ["href", "#"], ["mat-list-item", ""]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        $event.preventDefault();
        var pd_0 = (i1.ɵnov(_v.parent, 6).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatListItem_0, i7.RenderType_MatListItem)), i1.ɵdid(2, 1228800, null, 3, i8.MatListItem, [i1.ElementRef, [2, i8.MatNavList], [2, i8.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵted(-1, 2, ["\n      "])), (_l()(), i1.ɵeld(7, 0, null, 1, 3, "span", [["class", "mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, [[1, 4]], 0, i9.MatLine, [], null, null), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, 2, ["\n      "])), (_l()(), i1.ɵeld(12, 0, null, 1, 3, "span", [["class", "mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(13, 16384, [[1, 4]], 0, i9.MatLine, [], null, null), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, 2, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(18, 0, null, null, 15, "a", [["class", "mat-list-item"], ["href", "https://docs.google.com/"], ["mat-list-item", ""]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.preventDefault();
        var pd_0 = (_co.uploadLogo($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatListItem_0, i7.RenderType_MatListItem)), i1.ɵdid(19, 1228800, null, 3, i8.MatListItem, [i1.ElementRef, [2, i8.MatNavList], [2, i8.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 4, { _lines: 1 }), i1.ɵqud(335544320, 5, { _avatar: 0 }), i1.ɵqud(335544320, 6, { _icon: 0 }), (_l()(), i1.ɵted(-1, 2, ["\n      "])), (_l()(), i1.ɵeld(24, 0, null, 1, 3, "span", [["class", "mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(25, 16384, [[4, 4]], 0, i9.MatLine, [], null, null), (_l()(), i1.ɵted(26, null, ["", ""])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, 2, ["\n      "])), (_l()(), i1.ɵeld(29, 0, null, 1, 3, "span", [["class", "mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(30, 16384, [[4, 4]], 0, i9.MatLine, [], null, null), (_l()(), i1.ɵted(31, null, ["", ""])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, 2, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2)._avatar || i1.ɵnov(_v, 2)._icon); var currVal_1 = (i1.ɵnov(_v, 2)._avatar || i1.ɵnov(_v, 2)._icon); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("RestaurantProfile.UploadLogo")); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("RestaurantProfile.UploadNewLogo")); _ck(_v, 14, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 19)._avatar || i1.ɵnov(_v, 19)._icon); var currVal_5 = (i1.ɵnov(_v, 19)._avatar || i1.ɵnov(_v, 19)._icon); _ck(_v, 18, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 26, 0, i1.ɵnov(_v, 27).transform("RestaurantProfile.DeleteLogo")); _ck(_v, 26, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 31, 0, i1.ɵnov(_v, 32).transform("RestaurantProfile.DeleteCurrentLogo")); _ck(_v, 31, 0, currVal_7); }); }
export function View_UploadLogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadLogoComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "mat-nav-list", [["class", "mat-nav-list mat-list-base"], ["role", "navigation"]], null, null, null, i7.View_MatNavList_0, i7.RenderType_MatNavList)), i1.ɵdid(4, 704512, null, 0, i8.MatNavList, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(6, 0, [["fileInput", 1]], 0, 0, "input", [["style", "display: none"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_UploadLogoComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUploading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isUploading; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_UploadLogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-logo", [], null, null, null, View_UploadLogoComponent_0, RenderType_UploadLogoComponent)), i1.ɵdid(1, 114688, null, 0, i11.UploadLogoComponent, [i12.MatBottomSheetRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadLogoComponentNgFactory = i1.ɵccf("app-upload-logo", i11.UploadLogoComponent, View_UploadLogoComponent_Host_0, {}, {}, []);
export { UploadLogoComponentNgFactory as UploadLogoComponentNgFactory };
