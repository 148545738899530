var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ReviewsService } from './reviews.service';
import { MatSnackBar } from '@angular/material';
import { ReportReviewComponent } from './report-review/report-review.component';
import { SetReplyComponent } from './set-reply/set-reply.component';
import { EditCommentComponent } from './edit-comment/edit-comment.component';
import moment from 'moment';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
// import { MatAccordion } from '@angular/material/expansion';
// import {nl2br} from 'nl2br';
export class ReviewsComponent {
    constructor(reviewService, modalService, snackBar) {
        this.reviewService = reviewService;
        this.modalService = modalService;
        this.snackBar = snackBar;
        // expanded: boolean;
        this.ngUnsubscribe = new Subject();
        this.loader = true;
        this.reviewData = [];
        this.comment = '';
        // this.expanded = false;
    }
    ngOnInit() {
        this.loadData();
    }
    getNestedComments(currentComment, parentComment, commentList, commentLikeData) {
        const straightComments = commentList.filter((comment) => comment.parentIds == currentComment.id);
        if (straightComments && straightComments.length) {
            for (let k = 0; k < straightComments.length; k++) {
                const commentData = Object.assign({}, straightComments[k]);
                const matchInfo = commentLikeData.filter((object) => commentData.id === object.thingId);
                if (matchInfo && matchInfo.length) {
                    straightComments[k]['commentLikeData'] = matchInfo[0];
                }
                parentComment['comments'].push(straightComments[k]);
                this.getNestedComments(straightComments[k], parentComment, commentList, commentLikeData);
            }
        }
    }
    buildCommentTree(commentList, commentLikeData, commentId, parentComment, step) {
        const nextLevel = {
            parent: 'child',
            child: 'grandChild',
            grandChild: 'grandChild'
        };
        let straightComments;
        if (!commentId) {
            straightComments = commentList.filter((comment) => !comment.parentIds);
        }
        else {
            straightComments = commentList.filter((comment) => comment.parentIds == commentId);
        }
        if (step === 'grandChild') {
            if (!parentComment['comments']) {
                parentComment['comments'] = [];
            }
            for (let k = 0; k < straightComments.length; k++) {
                const commentData = Object.assign({}, straightComments[k]);
                const matchInfo = commentLikeData.filter((object) => commentData.id === object.thingId);
                if (matchInfo && matchInfo.length) {
                    straightComments[k]['commentLikeData'] = matchInfo[0];
                }
                parentComment['comments'].push(straightComments[k]);
                this.getNestedComments(straightComments[k], parentComment, commentList, commentLikeData);
            }
        }
        else if (straightComments && straightComments.length) {
            for (let j = 0; j < straightComments.length; j++) {
                const commentData = Object.assign({}, straightComments[j]);
                const matchInfo = commentLikeData.filter((object) => commentData.id === object.thingId);
                if (matchInfo && matchInfo.length) {
                    straightComments[j]['commentLikeData'] = matchInfo[0];
                }
                straightComments[j]['comments'] = [
                    ...this.buildCommentTree(commentList, commentLikeData, commentData['id'], straightComments[j], nextLevel[step])
                ];
            }
        }
        return step === 'grandChild' ? parentComment['comments'] : straightComments;
    }
    reArrangeData(reviewInfo, likeData, wellWrittenData, commentLikeData, reviewComments) {
        const reviewData = [...reviewInfo];
        for (let i = 0; i < reviewData.length; i++) {
            const review = Object.assign({}, reviewData[i]);
            if (likeData && likeData.length) {
                const matchInfo = likeData.filter((object) => review.id === object.thingId);
                if (matchInfo && matchInfo.length) {
                    review['likeInfo'] = matchInfo[0];
                }
            }
            if (wellWrittenData && wellWrittenData.length) {
                const matchInfo = wellWrittenData.filter((object) => review.id === object.reviewId);
                if (matchInfo && matchInfo.length) {
                    review['well-written-info'] = matchInfo[0];
                }
            }
            if (reviewComments && reviewComments.length) {
                const matchInfo = reviewComments.filter((object) => review.id === object.itemId);
                if (matchInfo && matchInfo.length) {
                    const commentTree = this.buildCommentTree(matchInfo, commentLikeData, null, null, 'parent');
                    console.log('CommentTree => ', commentTree);
                    review['comments'] = [...commentTree];
                }
            }
            reviewData[i] = review;
        }
        this.reviewData = reviewData;
        this.loader = false;
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { id } = JSON.parse(localStorage.getItem('credentials'));
            const [reviewData, likeData, wellWrittenData, commentLikeData, memberInfo] = yield Promise.all([
                this.reviewService.getReviews().toPromise(),
                this.reviewService.getLikes(id, 'reviews').toPromise(),
                this.reviewService.getWellWrittenData(id).toPromise(),
                this.reviewService.getLikes(id, 'comment').toPromise(),
                this.reviewService.getMemberInfo(id).toPromise()
            ]);
            this.memberInfo = memberInfo;
            if (reviewData) {
                const reviewInfo = [...reviewData.reviewList];
                const reviewIds = [...reviewData.reviewIds];
                this.betriebInfo = Object.assign({}, reviewData.clientInfo);
                for (let i = 0; i < reviewInfo.length; i++) {
                    const postedDate = `${moment(reviewInfo[i].postedAt).format('DD.MM.YYYY')}`;
                    const regex = /\{Foto_(\d+)\}/g;
                    let { comment } = reviewInfo[i];
                    const photoStrings = comment.match(regex);
                    if (photoStrings && photoStrings.length) {
                        for (let i = 0; i < photoStrings.length; i++) {
                            let elem = photoStrings[i];
                            elem = elem.replace('{', '');
                            elem = elem.replace('}', '');
                            elem = elem.replace('Foto_', '');
                            photoStrings[i] = parseInt(elem);
                        }
                        const photos = yield this.reviewService
                            .getPhotos({ photoIds: JSON.stringify(photoStrings), memberId: id })
                            .toPromise();
                        if (photos && photos.length) {
                            for (let p = 0; p < photos.length; p++) {
                                const fotoHTML = `<a class="review-img" href="http://cdn.gastroguide.de/${photos[p].photoFile}" target="_blank" alt="${photos[p].description}">
                                  <img src="http://cdn.gastroguide.de/${photos[p].photoFile}"/>
                                  <i>${photos[p].description}</i>
                                </a>`;
                                const fotoId = `{Foto_${photos[p].id}}`;
                                comment = comment.replace(fotoId, `${fotoHTML}`);
                            }
                        }
                    }
                    comment = comment.replaceAll('[', '<');
                    comment = comment.replaceAll(']', '>');
                    // const parser = new DOMParser();
                    // const doc = parser.parseFromString(comment, 'text/html');
                    // comment = doc.body.innerHTML
                    reviewInfo[i].comment = comment;
                    reviewInfo[i].postedDate = postedDate;
                }
                let reviewComments;
                if (reviewIds && reviewIds.length) {
                    this.reviewService.getComments({ itemId: JSON.stringify(reviewIds) }).subscribe((response) => {
                        reviewComments = response;
                        this.reArrangeData(reviewInfo, likeData, wellWrittenData, commentLikeData, reviewComments);
                    });
                }
                else {
                    this.reArrangeData(reviewInfo, likeData, wellWrittenData, commentLikeData, null);
                }
            }
        });
    }
    toggleExpandRow(row) {
        this.table.rowDetail.toggleExpandRow(row);
    }
    likeUnlikeReview(action, review) {
        const { id } = JSON.parse(localStorage.getItem('credentials'));
        review['likeLoader'] = true;
        this.reviewService.likeUnlikeReview(id, review.id, action).subscribe((response) => {
            if (response) {
                switch (action) {
                    case 'like':
                        review['likeInfo'] = response;
                        delete review['likeLoader'];
                        review['likesCntr'] += 1;
                        break;
                    case 'unlike':
                        delete review['likeInfo'];
                        delete review['likeLoader'];
                        review['likesCntr'] -= 1;
                        break;
                }
            }
        });
    }
    likeUnlikeWellWritten(action, review) {
        const { id } = JSON.parse(localStorage.getItem('credentials'));
        review['wellWrittenLoader'] = true;
        this.reviewService.likeUnlikeWellWritten(id, review.id, action).subscribe((response) => {
            if (response) {
                switch (action) {
                    case 'well-wriiten-like':
                        review['well-written-info'] = response;
                        delete review['wellWrittenLoader'];
                        review['wellWrittenCntr'] += 1;
                        break;
                    case 'well-wriiten-unlike':
                        delete review['well-written-info'];
                        delete review['wellWrittenLoader'];
                        review['wellWrittenCntr'] -= 1;
                        break;
                }
            }
        });
    }
    reportReview(row) {
        const modalRef = this.modalService.open(ReportReviewComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.reviewContent = Object.assign({}, row);
        modalRef.componentInstance.submitReviewResponse.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            const snackBarRef = this.snackBar.open('Successfully Added Report', 'Ok', {
                duration: 3000,
                panelClass: ['snackbar-success']
            });
            modalRef.close();
        });
    }
    postComment(row) {
        const comment = this.comment;
        const { id } = JSON.parse(localStorage.getItem('credentials'));
        const reviewId = row.id;
        const data = {
            comment,
            reviewId,
            memberId: id
        };
        this.reviewService.postComment(data).subscribe((response) => {
            if (response) {
                const snackBarRef = this.snackBar.open('Successfully Added Comment', 'Ok', {
                    duration: 3000,
                    panelClass: ['snackbar-success']
                });
                this.comment = '';
                response['memberDataInfo'] = this.memberInfo;
                response['comments'] = [];
                if (row.comments) {
                    row.comments.push(response);
                }
                else {
                    row['comments'] = [response];
                }
            }
        });
    }
    likeUnlikeComment(action, review, comment) {
        const { id } = JSON.parse(localStorage.getItem('credentials'));
        comment['likeLoader'] = true;
        this.reviewService.likeUnlikeComment(id, review.id, comment.id, action).subscribe((response) => {
            if (response) {
                switch (action) {
                    case 'like':
                        comment['commentLikeData'] = response;
                        delete comment['likeLoader'];
                        comment['likes'] += 1;
                        break;
                    case 'unlike':
                        delete comment['commentLikeData'];
                        delete comment['likeLoader'];
                        comment['likes'] -= 1;
                        break;
                }
            }
        });
    }
    setReply(review, comment, mapComment) {
        const modalRef = this.modalService.open(SetReplyComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.commentContent = { review, comment };
        modalRef.componentInstance.submitReplyResponse.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            const snackBarRef = this.snackBar.open('Successfully Added Report', 'Ok', {
                duration: 3000,
                panelClass: ['snackbar-success']
            });
            receivedEntry['memberDataInfo'] = this.memberInfo;
            if (mapComment) {
                mapComment.comments.push(receivedEntry);
            }
            else if (comment.comments) {
                comment.comments.push(receivedEntry);
            }
            else {
                comment['comments'] = [...receivedEntry];
            }
            modalRef.close();
        });
    }
    removeComment(comment, parentData) {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = 'Delete Comment';
        modalRef.componentInstance.message = `Do you want to delete comment`;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = 'Delete';
        modalRef.result.then((result) => {
            if (result === 'ok') {
                this.reviewService.removeComment(comment.id, this.memberInfo.id).subscribe((event) => {
                    parentData['comments'] = parentData['comments'].filter((eachComment) => eachComment.id !== comment.id);
                    this.snackBar.open('Ereignis erfolgreich gelöscht', '', {
                        duration: 2000,
                        panelClass: ['snackbar-success']
                    });
                }, (err) => {
                    this.snackBar.open(err.error.msg, '', {
                        duration: 2000,
                        panelClass: ['snackbar-error']
                    });
                });
            }
        }, () => { });
    }
    editComment(comment, index, parentData) {
        const modalRef = this.modalService.open(EditCommentComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.commentContent = { comment };
        modalRef.componentInstance.commentResponse.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            const snackBarRef = this.snackBar.open('Comment Updated Successfully', 'Ok', {
                duration: 3000,
                panelClass: ['snackbar-success']
            });
            parentData['comments'][index]['comment'] = receivedEntry.comment;
            modalRef.close();
        });
    }
}
