<breadcrumb></breadcrumb>
<div class="content-box" [ngStyle]="{ display: loader ? 'none' : 'block' }">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">Album Name -: {{ albumName }}</h6>
        <div class="element-box">
          <ngx-datatable
            class="material striped"
            [rows]="albums"
            [columnMode]="'force'"
            headerHeight="50"
            footerHeight="50"
            rowHeight="150"
            [limit]="50"
            [cssClasses]="{
              pagerLeftArrow: 'fas fa-backward',
              pagerRightArrow: 'fas fa-forward',
              pagerPrevious: 'fas fa-step-backward',
              pagerNext: 'fas fa-step-forward'
            }"
          >
            <ngx-datatable-column [sortable]="true" name="Image">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>
                  <img
                    src="http://cdn.gastroguide.de/{{ row.photoFile }}"
                    width="120"
                    height="120"
                    style="border-radius: 120px; object-fit: cover;"
                  />
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="true" name="Title">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="!row.isEdit" title="{{ row.title }}" class="date-label">
                  {{ row.title }}
                </span>
                <input
                  *ngIf="row.isEdit"
                  type="text"
                  class="col-md-9"
                  [(ngModel)]="albumDescTitleModel[row.id].title"
                />
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="true" name="Description">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="!row.isEdit" title="{{ row.description }}" class="date-label">
                  {{ row.description }}
                </span>
                <input
                  *ngIf="row.isEdit"
                  type="text"
                  class="col-md-9"
                  [(ngModel)]="albumDescTitleModel[row.id].description"
                />
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="true" name="Likes">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span title="{{ row.likesCntr }}" class="date-label">
                  {{ row.likesCntr }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="true" name="Comments">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span title="{{ row.commentsCntr }}" class="date-label">
                  {{ row.commentsCntr }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="true" name="Action">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <button class="btn btn-primary" *ngIf="!row.isEdit" (click)="row.isEdit = true">
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-danger" *ngIf="!row.isEdit" (click)="remove(row)">
                  <i class="fa fa-trash"></i>
                </button>
                <button class="btn btn-primary" *ngIf="row.isEdit" (click)="set(row)">Set</button>
                <button class="btn btn-secondary" *ngIf="row.isEdit" (click)="cancel(row)">Cancel</button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngStyle]="{ display: loader ? 'block' : 'none' }" style="height: 100%;">
  <div style="position: relative; left: 25%; top: 45%; width: 50%;">
    <span>Please Wait...</span>
    <mat-progress-bar mode="indeterminate" [value]="10"></mat-progress-bar>
  </div>
</div>
