<div class="modal-content text-center">
  <div class="modal-header">
    <h4 class="modal-title">
      <ng-template [ngIf]="!editGuest">Neuen Gast anlegen</ng-template>
      <ng-template [ngIf]="editGuest">Gastdaten ändern</ng-template>
    </h4>
    <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
      <span class="fas fa-times"></span>
    </button>
  </div>
  <div class="onboarding-content">
    <form [formGroup]="guestFormGroup">
      <div class="row">
        <div class="col-md-3 form-group">
          <label for="form">Anrede</label>
          <ng-select
            [items]="arrayOfPrefixes"
            [clearable]="false"
            [searchable]="false"
            formControlName="form"
            bindLabel="form"
            bindValue="form"
          >
          </ng-select>
        </div>
        <div class="col-md-9 form-group">
          <label>Firmenname</label>
          <input class="form-control" formControlName="company" type="text" />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="name">
              Name
            </label>
            <input class="form-control" name="name" formControlName="name" type="text" value="" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label *ngIf="guestFormGroup.value.form !== 'Firma'">Vorname</label>
            <label *ngIf="guestFormGroup.value.form === 'Firma'">Firmenname</label>
            <input class="form-control" name="firstName" formControlName="firstName" type="text" value="" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="email">
              Email
            </label>
            <input class="form-control" name="email" formControlName="email" type="text" value="" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="phone">
              Telefonnummer
            </label>
            <input class="form-control" name="phone" formControlName="phone" type="text" value="" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="address">
              Straße
            </label>
            <input class="form-control" name="address" formControlName="address" type="text" value="" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="zip">
              PLZ
            </label>
            <input class="form-control" name="zip" formControlName="zip" type="text" value="" maxlength="5" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="place">
              Ort
            </label>
            <input class="form-control" name="place" formControlName="place" type="text" value="" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="birthdate">Geburtstag</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="birthdayDatePicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <mat-datepicker #birthdayDatePicker (closed)="dateFromInput1.blur()"></mat-datepicker>
              <input
                class="form-control"
                #dateFromInput1
                formControlName="birthdate"
                placeholder="Geburtsdatum auswählen"
                type="text"
                name="birthdate"
                [matDatepicker]="birthdayDatePicker"
                (focus)="birthdayDatePicker.open()"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="weddingDay">Hochzeitstag</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="weddingDatePicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <mat-datepicker #weddingDatePicker (closed)="dateFromInput2.blur()"></mat-datepicker>
              <input
                class="form-control"
                #dateFromInput2
                formControlName="weddingDay"
                placeholder="Hochzeitsdatum auswählen"
                type="text"
                name="weddingDay"
                [matDatepicker]="weddingDatePicker"
                (focus)="weddingDatePicker.open()"
              />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="editGuest" class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label for="lastVisit">
              Letzter Besuch
            </label>
            <div class="row" name="lastVisit">
              <div class="col-sm-12">{{ editGuest.lastVisit | date }}</div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label for="visits">
              Anzahl Besuche
            </label>
            <div class="row" name="visits">
              <div class="col-sm-12">{{ editGuest.visits }}</div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label for="no-shows">
              Anzahl no Shows
            </label>
            <div class="row" name="no-shows">
              <div class="col-sm-12">{{ editGuest.noShow }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="intolerance">
              Unverträglichkeiten
            </label>
            <ng-select
              multiple="true"
              [searchable]="false"
              [clearable]="false"
              formControlName="intolerance"
              placeholder="Wählen Sie Intoleranz"
            >
              <ng-option *ngFor="let intolerance of incompatibilities" value="{{ intolerance.id }}">
                {{ intolerance.longName }}
              </ng-option>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="notes">
              Zusätzliche Hinweise
            </label>
            <textarea class="form-control" name="notes" formControlName="notes" type="text" value=""></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <button class="btn btn-primary btn-block btn-lg" (click)="createGuest()" *ngIf="!editGuest">
          Gast anlegen
        </button>
        <button class="btn btn-primary btn-block btn-lg" (click)="updateGuest()" *ngIf="editGuest">
          Gast speichern
        </button>
        <button
          *ngIf="editGuest"
          type="button"
          class="btn btn-link mt-3"
          style="color: red;"
          (click)="deleteGuest(guestFormGroup.value)"
        >
          Gast löschen <i class="fas fa-trash-alt"></i>
        </button>
      </div>
    </form>
  </div>
</div>
