<div class="modal-content text-center">
  <button aria-label="Close" class="close" type="button" (click)="activeModal.dismiss()">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-media">
    <img alt="" src="./assets/img/under-construction.png" style="height:200px;width:200px" />
  </div>
  <div class="onboarding-content with-gradient">
    <h4 class="onboarding-title">Feature noch nicht verfügbar</h4>
    <div class="onboarding-text">
      Leider ist das von Ihnen gewählte Feature noch nicht im neuen Business-Portal verfügbar. Sie können aber weiterhin
      im alten Portal auf das Feature zugreifen.
    </div>

    <div class="modal-button-footer">
      <a class="btn btn-primary btn-block" [href]="link" ngbAutofocus>
        Zum alten Business-Portal wechseln
      </a>
      <button class="btn btn-light btn-block" (click)="activeModal.dismiss()">
        Schließen
      </button>
    </div>
  </div>
</div>
