var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RegisterService } from './register.service';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InitPermissionsService } from '@app/core/init-permissions.service';
import { NgxPermissionsService } from 'ngx-permissions';
export class RegisterComponent {
    constructor(formBuilder, registerService, snackBar, translate, authenticationService, router, route, permissionService, rs // private recaptchaV3Service: ReCaptchaV3Service
    ) {
        this.formBuilder = formBuilder;
        this.registerService = registerService;
        this.snackBar = snackBar;
        this.translate = translate;
        this.authenticationService = authenticationService;
        this.router = router;
        this.route = route;
        this.permissionService = permissionService;
        this.rs = rs;
        this.disableRegisterBtn = false;
        this.registerForm = this.formBuilder.group({
            email: ['', [Validators.email, Validators.required]],
            password: ['', Validators.required],
            zipCode: ['', Validators.required],
            companyName: ['', Validators.required],
            telefon: ['', Validators.required],
            country: ['', Validators.required],
            street: ['', Validators.required],
            name: ['', Validators.required],
            place: ['', Validators.required],
            // username: ['', Validators.required],
            termsAndCond: [false],
            cameFrom: [''],
            recaptcha: [null, Validators.required]
        });
    }
    ngOnInit() {
        this.getBannedIPs();
        this.message = false;
        this.locationArray = [];
        this.timer = this.timeoutVal = 500;
        this.registerService.getGeoCountries().subscribe(countries => {
            this.countries = countries;
            this.countries.push({ id: 4, name: 'anderes' });
        });
    }
    getBannedIPs() {
        this.registerService.getBannedIPs().subscribe(data => {
            if (data && data.length > 0) {
                const bannedIPs = data.map((item) => item.ip);
                this.registerService.getIPAddress().subscribe(IPdata => {
                    if (IPdata && IPdata.ip && bannedIPs.includes(IPdata.ip)) {
                        this.disableRegisterBtn = true;
                    }
                });
            }
        });
    }
    register() {
        // this.recaptchaV3Service.execute('submitForm').subscribe(
        //   (token: string) => {
        //     console.debug(`Token [${token}] generated`);
        //   },
        //   (error: any) => {
        //     console.error('An error occurred:', error);
        //     // You can add additional error handling logic here
        //   }
        // );
        let isValid = true;
        this.message = false;
        if (!this.registerForm.valid) {
            for (let key in this.registerForm.controls) {
                if (!this.registerForm.controls[key].valid) {
                    isValid = false;
                    this.registerForm.controls[key].markAsTouched({ onlySelf: true });
                }
            }
        }
        if (!this.registerForm.controls['termsAndCond'].value) {
            this.message = true;
            isValid = false;
        }
        if (isValid) {
            const data = Object.assign({}, this.registerForm.value);
            data['countryId'] = this.countryId ? this.countryId : data.country ? data.country : null;
            data['locationId'] = this.locationId ? this.locationId : null;
            data['stateId'] = this.stateId ? this.stateId : null;
            this.registerService.register(data).subscribe((response) => {
                this.snackBar.open('Erfolgreich registriert', '', {
                    duration: 2000,
                    panelClass: ['snackbar-success']
                });
                this.authenticationService
                    .login({ email: data.email, password: data.password, remember: true })
                    .subscribe(response => {
                    const snapshot = this.route.snapshot.queryParams.redirect;
                    let clientId = null;
                    if (snapshot) {
                        const match = snapshot.match(/^\/client\/(\d+)/);
                        if (match) {
                            clientId = match[1];
                        }
                    }
                    this.permissionService.load(clientId).then((permissions) => __awaiter(this, void 0, void 0, function* () {
                        yield this.rs.addPermission(permissions);
                        this.router.navigate([snapshot || '/select'], { replaceUrl: true });
                    }));
                });
            }, (err) => {
                this.snackBar.open(err.error.msg, '', {
                    duration: 2000,
                    panelClass: ['snackbar-error']
                });
            });
        }
    }
    checkForTyping() {
        window.clearTimeout(this.timer);
        // this.isList = false;
        // this.isGoogleResp = null;
    }
    fetchLocation() {
        window.clearTimeout(this.timer);
        const zips = this.registerForm.value.zipCode;
        console.log('Zips => ', zips);
        if (zips && this.registerForm.value.country === 1) {
            this.timer = window.setTimeout(() => {
                this.registerService.getGeoLocations({ zips }).subscribe(locations => {
                    if (locations && locations.length) {
                        // this.isList = true;
                        let locationArray = locations;
                        const newArray = [];
                        locationArray = locationArray.filter((each) => {
                            const locationArray = each.zips.split(',');
                            if (locationArray.length > 1) {
                                newArray.push(each);
                            }
                            else if (each.zips.startsWith(zips)) {
                                return each;
                            }
                        });
                        if (newArray && newArray.length) {
                            for (let j = 0; j < newArray.length; j++) {
                                const newZips = newArray[j].zips.split(',');
                                for (let z = 0; z < newZips.length; z++) {
                                    if (newZips[z].startsWith(zips)) {
                                        const element = Object.assign({}, newArray[j]);
                                        element['zips'] = newZips[z];
                                        locationArray.push(element);
                                    }
                                }
                            }
                        }
                        this.locationArray = locationArray;
                    }
                    else {
                        // this.isList = false;
                        this.locationArray = [];
                    }
                });
            }, this.timeoutVal);
        }
        else {
            // this.isList = false;
        }
    }
    selectZipCode(zipCode) {
        this.registerForm.get('zipCode').setValue(zipCode.zips);
        this.registerForm.get('place').setValue(zipCode.name);
        this.locationId = zipCode.id;
        this.countryId = zipCode.countryId;
        this.stateId = zipCode.stateId;
        // this.isList = false;
    }
    InputControl(event) {
        if (this.registerForm.value.country === 1) {
            setTimeout(() => {
                let isValueTrue = this.locationArray.filter((myAlias) => myAlias.zips === this.registerForm.value['zipCode']);
                if (isValueTrue.length === 0) {
                    this.registerForm.get('zipCode').setValue(null);
                    this.registerForm.get('place').setValue(null);
                }
            }, 300);
        }
    }
    selectCountriesChange() {
        this.registerForm.get('zipCode').setValue(null);
        this.registerForm.get('place').setValue(null);
    }
    resolved(captchaResponse) {
        console.log(`Resolved captcha with response: ${captchaResponse}`);
    }
}
