<mat-progress-spinner style="margin: 0 auto;" [color]="'primary'" [mode]="'indeterminate'" *ngIf="isUploading">
</mat-progress-spinner>
<mat-nav-list>
  <input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput />
  <ng-template [ngIf]="!isUploading">
    <a href="#" mat-list-item (click)="$event.preventDefault(); fileInput.click()">
      <span mat-line>{{ 'RestaurantProfile.UploadLogo' | translate }}</span>
      <span mat-line>{{ 'RestaurantProfile.UploadNewLogo' | translate }}</span>
    </a>

    <a href="https://docs.google.com/" mat-list-item (click)="$event.preventDefault(); uploadLogo($event)">
      <span mat-line>{{ 'RestaurantProfile.DeleteLogo' | translate }}</span>
      <span mat-line>{{ 'RestaurantProfile.DeleteCurrentLogo' | translate }}</span>
    </a>
  </ng-template>
</mat-nav-list>
