<div [style.background]="imageVisible && backgroundColor" #wrapper>
  <img
    #sourceImage
    class="source-image"
    *ngIf="safeImgDataUrl"
    [src]="safeImgDataUrl"
    [style.visibility]="imageVisible ? 'visible' : 'hidden'"
    [style.transform]="safeTransformStyle"
    (load)="imageLoadedInView()"
  />
  <div
    class="overlay"
    [style.width.px]="maxSize.width"
    [style.height.px]="maxSize.height"
    [style.margin-left]="alignImage === 'center' ? marginLeft : null"
  ></div>
  <div
    class="cropper"
    *ngIf="imageVisible"
    [class.rounded]="roundCropper"
    [style.top.px]="cropper.y1"
    [style.left.px]="cropper.x1"
    [style.width.px]="cropper.x2 - cropper.x1"
    [style.height.px]="cropper.y2 - cropper.y1"
    [style.margin-left]="alignImage === 'center' ? marginLeft : null"
    [style.visibility]="imageVisible ? 'visible' : 'hidden'"
    (keydown)="keyboardAccess($event)"
    tabindex="0"
  >
    <div
      (mousedown)="startMove($event, moveTypes.Move)"
      (touchstart)="startMove($event, moveTypes.Move)"
      class="move"
    ></div>
    <ng-container *ngIf="!hideResizeSquares">
      <span
        class="resize topleft"
        (mousedown)="startMove($event, moveTypes.Resize, 'topleft')"
        (touchstart)="startMove($event, moveTypes.Resize, 'topleft')"
      >
        <span class="square"></span>
      </span>
      <span class="resize top">
        <span class="square"></span>
      </span>
      <span
        class="resize topright"
        (mousedown)="startMove($event, moveTypes.Resize, 'topright')"
        (touchstart)="startMove($event, moveTypes.Resize, 'topright')"
      >
        <span class="square"></span>
      </span>
      <span class="resize right">
        <span class="square"></span>
      </span>
      <span
        class="resize bottomright"
        (mousedown)="startMove($event, moveTypes.Resize, 'bottomright')"
        (touchstart)="startMove($event, moveTypes.Resize, 'bottomright')"
      >
        <span class="square"></span>
      </span>
      <span class="resize bottom">
        <span class="square"></span>
      </span>
      <span
        class="resize bottomleft"
        (mousedown)="startMove($event, moveTypes.Resize, 'bottomleft')"
        (touchstart)="startMove($event, moveTypes.Resize, 'bottomleft')"
      >
        <span class="square"></span>
      </span>
      <span class="resize left">
        <span class="square"></span>
      </span>
      <span
        class="resize-bar top"
        (mousedown)="startMove($event, moveTypes.Resize, 'top')"
        (touchstart)="startMove($event, moveTypes.Resize, 'top')"
      >
      </span>
      <span
        class="resize-bar right"
        (mousedown)="startMove($event, moveTypes.Resize, 'right')"
        (touchstart)="startMove($event, moveTypes.Resize, 'right')"
      >
      </span>
      <span
        class="resize-bar bottom"
        (mousedown)="startMove($event, moveTypes.Resize, 'bottom')"
        (touchstart)="startMove($event, moveTypes.Resize, 'bottom')"
      >
      </span>
      <span
        class="resize-bar left"
        (mousedown)="startMove($event, moveTypes.Resize, 'left')"
        (touchstart)="startMove($event, moveTypes.Resize, 'left')"
      >
      </span>
    </ng-container>
  </div>
</div>
