/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./ranges-footer-new.component";
import * as i3 from "saturn-datepicker";
var styles_RangesFooterNewComponent = [];
var RenderType_RangesFooterNewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RangesFooterNewComponent, data: {} });
export { RenderType_RangesFooterNewComponent as RenderType_RangesFooterNewComponent };
function View_RangesFooterNewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setRange(_v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_RangesFooterNewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "mat-calendar-footer"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RangesFooterNewComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ranges; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_RangesFooterNewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_RangesFooterNewComponent_0, RenderType_RangesFooterNewComponent)), i0.ɵdid(1, 49152, null, 0, i2.RangesFooterNewComponent, [i3.SatCalendar, i3.SatDatepicker, i3.DateAdapter, i0.ChangeDetectorRef], null, null)], null, null); }
var RangesFooterNewComponentNgFactory = i0.ɵccf("ng-component", i2.RangesFooterNewComponent, View_RangesFooterNewComponent_Host_0, {}, {}, []);
export { RangesFooterNewComponentNgFactory as RangesFooterNewComponentNgFactory };
