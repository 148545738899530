<div class="modal-content text-center">
  <button aria-label="Close" class="close" style="outline:none;" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      {{ 'ResSettings.ReservationSetting.CreateNewShiftQuestionLayer' | translate }}
    </h4>
    <form [formGroup]="copyExistingShiftFormGroup">
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <button class="btn choose-btn" (click)="copyOrCreateShift('create')" [class.selected]="createShift">
              {{ 'Common.Yes' | translate }}
            </button>
          </div>
        </div>

        <div class="col-sm-8">
          <div class="form-group">
            <button class="btn choose-btn" (click)="copyOrCreateShift('copy')" [class.selected]="copyShift">
              {{ 'ResSettings.ReservationSetting.CopyExistingShift' | translate }}
            </button>
          </div>
        </div>

        <div class="col-sm-12" *ngIf="copyShift">
          <div class="form-group">
            <label>{{ 'ResSettings.ReservationSetting.SelectShift' | translate }} </label>
            <ng-select
              *ngIf="shifts && shifts.length > 0"
              [searchable]="false"
              [items]="shifts"
              [clearable]="true"
              formControlName="shiftId"
              bindLabel="name"
              bindValue="id"
            >
            </ng-select>
          </div>
        </div>

        <button class="btn btn-primary btn-block btn-lg" (click)="submit()">
          {{ 'Common.Submit' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
