import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class HandleStateForPipeService {
    constructor() {
        // Initialize the BehaviorSubject with an initial value (false in this case)
        this.stateSubject = new BehaviorSubject(false);
        this.apiCountSubject = new BehaviorSubject(0);
        // Expose the BehaviorSubject as an observable for components to subscribe to
        this.state$ = this.stateSubject.asObservable();
        this.apiCount$ = this.apiCountSubject.asObservable();
    }
    // Method to update the state
    updateState(newState) {
        this.stateSubject.next(newState);
    }
    getApiCount(apiCount) {
        this.apiCountSubject.next(apiCount);
    }
}
HandleStateForPipeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HandleStateForPipeService_Factory() { return new HandleStateForPipeService(); }, token: HandleStateForPipeService, providedIn: "root" });
