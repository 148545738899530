import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-buyer-address',
  templateUrl: './change-buyer-address.component.html',
  styleUrls: ['./change-buyer-address.component.scss']
})
export class ChangeBuyerAddressComponent implements OnInit {
  @Input() order: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  buyerAddressFormGroup: FormGroup;

  constructor(private formBuilder: FormBuilder, private snackBar: MatSnackBar, public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.buyerAddressFormGroup = this.formBuilder.group({
      email: ['', Validators.required],
      company: [''],
      name: ['', Validators.required],
      street: ['', Validators.required],
      zipCode: [''],
      city: ['', Validators.required]
    });
    if (this.order) {
      this.buyerAddressFormGroup.addControl('id', new FormControl(this.order.id, Validators.required));
      this.buyerAddressFormGroup.get('email').setValue(this.order.email);
      this.buyerAddressFormGroup.get('company').setValue(this.order.company);
      this.buyerAddressFormGroup.get('name').setValue(this.order.name);
      this.buyerAddressFormGroup.get('street').setValue(this.order.street);
      this.buyerAddressFormGroup.get('zipCode').setValue(this.order.zipCode);
      this.buyerAddressFormGroup.get('city').setValue(this.order.city);
    }
  }

  sendVoucher() {
    if (!this.buyerAddressFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.passEntry.emit(this.buyerAddressFormGroup.value);
    this.activeModal.dismiss('');
  }
}
