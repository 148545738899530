<div class="modal-header">
  <h4 class="modal-title">Reservation History</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeReservationHistory()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="padding: 0">
  <div class="content-box">
    <div class="element-wrapper" style="padding-bottom: 0;">
      <h6>
        <span>
          <ng-template [ngIf]="!guestData.company">
            {{ guestData.name ? guestData.name : 'Kein Name' }}
            {{ guestData.firstName ? guestData.firstName : '' }}
          </ng-template>
          <ng-template [ngIf]="guestData.company">
            {{ guestData.company }} ({{ guestData.name ? guestData.name : 'Kein Name' }})
          </ng-template>
          <span class="float:right">
            <ng-template [ngIf]="guestData.clientName"> &nbsp;({{ guestData.clientName }}) </ng-template>
          </span>
          <br />
          <ng-template [ngIf]="guestData.email">
            {{ guestData.email }}
          </ng-template>
          <br />
          <ng-template [ngIf]="guestData.notes">
            <i style="color: red;" class="fas fa-exclamation-triangle details-icon"></i> {{ guestData.notes }}
          </ng-template>
        </span>
      </h6>
    </div>
    <br />
    <div class="list-view">
      <!-- <ngx-datatable
        class="bootstrap reservations-table expandable"
        [columnMode]="'flex'"
        [headerHeight]="35"
        [footerHeight]="50"
        [loadingIndicator]="loadingReservationList"
        [rowHeight]="'auto'"
        [scrollbarV]="false"
        [scrollbarH]="false"
        [groupRowsBy]="'date'"
        [groupExpansionDefault]="true"
        [rows]="reservations"
        [limit]="10000"
        [sorts]="[{ prop: 'reservedFor', dir: 'asc' }]"
        (sort)="onSort($event)"
        [messages]="{ emptyMessage: 'There no reservations' }"
      > -->
      <ngx-datatable
        class="material"
        [columnMode]="'flex'"
        headerHeight="35"
        footerHeight="50"
        [loadingIndicator]="loadingReservationList"
        rowHeight="auto"
        [scrollbarV]="false"
        [scrollbarH]="false"
        [rows]="reservations"
        [limit]="tableLimit"
        [sorts]="[{ prop: 'reservedFor', dir: 'desc' }]"
        (sort)="onSort($event)"
        [messages]="{ emptyMessage: 'There no reservations' }"
        [cssClasses]="{
          pagerLeftArrow: 'fas fa-backward',
          pagerRightArrow: 'fas fa-forward',
          pagerPrevious: 'fas fa-step-backward',
          pagerNext: 'fas fa-step-forward'
        }"
      >
        <ngx-datatable-column
          [sortable]="true"
          [canAutoResize]="false"
          [resizeable]="false"
          prop="reservedFor"
          name="Datum"
          [width]="200"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div style="display: flex;align-items: center;">
              <div>
                <label style="width:100px;">{{ row.reservedFor | amDateFormat: 'DD.MM.YYYY' }}</label>
                &nbsp;
                <label>{{ row.reservedFor | amDateFormat: 'HH:mm' }}</label>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [sortable]="true" [resizeable]="false" prop="peopleCount" name="Gäste" [flexGrow]="0.5">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div [class.text-right]="layout < LAYOUT.XS">
              {{ row.peopleCount }} <span class="d-none d-xl-inline">Pers.</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [sortable]="true"
          [resizeable]="false"
          prop="tables"
          name="Tisch"
          [flexGrow]="1"
          [comparator]="sortTablesComparator"
          *ngIf="layout > LAYOUT.XXS"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="tables">
              <ng-template ngFor let-table [ngForOf]="row.tables">
                <span *ngIf="table" class="badge badge-default" placement="bottom" [ngbTooltip]="table?.room?.name">
                  {{ table.name }}
                </span>
              </ng-template>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [sortable]="false"
          [resizeable]="false"
          prop="tags"
          name="Tags"
          [flexGrow]="1"
          *ngIf="layout > LAYOUT.XXS"
        >
          <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            <mat-chip-list *ngIf="row.msg || row.notes">
              <mat-chip class="guest-note" *ngIf="row.msg" [matTooltip]="row.msg">Hinweis</mat-chip>
              <mat-chip class="admin-note" *ngIf="row.notes" [matTooltip]="row.notes">Notiz</mat-chip>
            </mat-chip-list>
            <div class="tags">
              <ng-template ngFor let-tag [ngForOf]="row.tags">
                <span class="badge" [style.background-color]="tag.color">
                  {{ tag.label }}
                </span>
              </ng-template>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [sortable]="true"
          [resizeable]="false"
          prop="status"
          name="Status"
          [flexGrow]="1"
          cellClass="text-center"
          headerClass="text-center"
          *ngIf="layout > LAYOUT.XXS"
        >
          <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            <span *ngIf="row.status === 'confirmed'" class="text-success">
              <i class="fas fa-check-circle"></i>
              Bestätigt
            </span>
            <span *ngIf="row.status === 'arrived'" class="text-arrived">
              <i class="fas fa-store-alt fa-fw"></i>
              Angekommen
            </span>
            <span *ngIf="row.status === 'placed'" class="text-info">
              <i class="fas fa-chair fa-fw"></i>
              Platziert
            </span>
            <span *ngIf="row.status === 'finished'" class="text-finished">
              <i class="fas fa-check-double fa-fw"></i>
              Fertig
            </span>
            <span *ngIf="row.status === 'pending'" class="text-warning">
              <i class="fas fa-minus-circle fa-fw"></i>
              Ausstehend
            </span>
            <span *ngIf="row.status === 'canceled'" class="text-danger">
              <i class="fas fa-ban fa-fw"></i>
              Storniert
            </span>
            <span *ngIf="row.status === 'noShow'" class="text-noshow">
              <i class="fas fa-eye-slash fa-fw"></i>
              No-Show
            </span>
            <span *ngIf="row.status === 'waiting'" class="text-muted">
              <i class="fas fa-hourglass-half fa-fw"></i>
              Warteliste
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
            let-isVisible="isVisible"
            *ngIf="reservations && reservations.length > 0"
          >
            <div class="page-count">
              <strong>{{ rowCount.toLocaleString() }}</strong> total
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'fas fa-backward'"
              [pagerRightArrowIcon]="'fas fa-forward'"
              [pagerPreviousIcon]="'fas fa-step-backward'"
              [pagerNextIcon]="'fas fa-step-forward'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="table.onFooterPage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</div>
