<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editCategoryData">Neue Kategorie anlegen</ng-template>
      <ng-template [ngIf]="editCategoryData">Kategorie ändern</ng-template>
    </h4>
    <form [formGroup]="categoryFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">
              Kategoriename<br />
              <small>Auch für den Gast sichtbar</small>
            </label>
            <input
              class="form-control"
              placeholder="Geben Sie den Namen ein..."
              name="name"
              formControlName="name"
              type="text"
              value=""
              ngbAutofocus
            />
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">
              Kategorie-Beschreibung<br />
              <small>Beschreibungstext der Kategorie</small>
            </label>
            <textarea
              class="form-control"
              placeholder="Optional"
              type="text"
              name="description"
              formControlName="description"
              rows="2"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">Foto</label>
            <div>
              <input
                class="file-input form-control"
                style="display:none"
                type="file"
                name="photo"
                #fileInput
                (change)="onChange(fileInput.files[0])"
              />
              <div class="input-group">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-secondary" (click)="fileInput.click()">Foto auswählen</button>
                </div>
                <input
                  class="form-control"
                  placeholder="Kein Foto ausgewählt"
                  [value]="fileName ? fileName : ''"
                  readonly
                />
                <div class="input-group-append" *ngIf="fileName">
                  <button type="button" class="btn btn-danger" (click)="removeFile()" matTooltip="Foto entfernen">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12" *ngIf="imageUrl">
          <div class="preview-img">
            <img [src]="imageUrl" />
          </div>
        </div>
      </div>

      <div class="row text-center" style="margin-top: 25px">
        <div class="col-sm-12">
          <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>
          <button
            class="btn btn-primary btn-block btn-lg"
            (click)="add()"
            *ngIf="!editCategoryData"
            [promiseBtn]="addObservable"
          >
            Kategorie anlegen
          </button>
          <button
            class="btn btn-primary btn-block btn-lg"
            (click)="save()"
            *ngIf="editCategoryData"
            [promiseBtn]="editObservable"
          >
            Kategorie speichern
          </button>
          <button
            class="btn btn-link btn-sm delete-button"
            (click)="delete()"
            *ngIf="editCategoryData"
            [promiseBtn]="deleteObservable"
          >
            Kategorie löschen
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
