<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss('Cross click')" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title" *ngIf="!editTableData && !isCombined">
      {{ 'ResSettings.ResAddTable.CreateNewTab' | translate }}
    </h4>
    <h4 class="onboarding-title" *ngIf="!editTableData && isCombined">
      {{ 'ResSettings.ResAddTable.CreateNewCombination' | translate }}
    </h4>
    <h4 class="onboarding-title" *ngIf="editTableData && !tableFormGroup.value.isCombined">
      {{ 'ResSettings.ResAddTable.EditTable' | translate }}
    </h4>
    <h4 class="onboarding-title" *ngIf="editTableData && tableFormGroup.value.isCombined">
      {{ 'ResSettings.ResAddTable.EditCombination' | translate }}
    </h4>
    <form [formGroup]="tableFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="" *ngIf="!tableFormGroup.value.isCombined">{{
              'ResSettings.ResAddTable.TableNumber' | translate
            }}</label>
            <label for="" *ngIf="tableFormGroup.value.isCombined">{{
              'ResSettings.ResAddTable.NameOfCombination' | translate
            }}</label>
            <input
              class="form-control"
              name="name"
              formControlName="name"
              placeholder="{{
                tableFormGroup.value.isCombined
                  ? 'Geben Sie einen Namen für die Kombination ein...'
                  : 'Geben Sie die Tischnummer ein...'
              }}"
              type="text"
              value=""
              required
              ngbAutofocus
            />
          </div>
        </div>
        <div class="col-sm-12" *ngIf="tableFormGroup.value.isCombined">
          <div class="form-group">
            <label for="">{{ 'ResSettings.ResAddTable.CombinedTable' | translate }}</label>
            <ng-select
              [items]="room.tables"
              bindLabel="name"
              [multiple]="true"
              placeholder="Tische auswählen"
              name="combinedTables"
              formControlName="combinedTables"
              [customErrors]="{ minlength: 'Mindestens 2 Tische auswählen' }"
            >
            </ng-select>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'ResSettings.ResAddTable.NumberOfPlaces' | translate }}</label>
            <number-spinner
              name="seats"
              [min]="1"
              [max]="1000"
              [step]="1"
              [precision]="1"
              [defaultValue]="+tableFormGroup.value.seats"
              [inputDisabled]="false"
              (whenChange)="seatsAmountChanged($event)"
            ></number-spinner>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'ResSettings.ResAddTable.MinOccupancy' | translate }}</label>
            <number-spinner
              name="minOccupancy"
              [min]="1"
              [max]="1000"
              [step]="1"
              [precision]="1"
              [defaultValue]="+tableFormGroup.value.minOccupancy"
              [inputDisabled]="false"
              (whenChange)="minOccupancyChanged($event)"
            ></number-spinner>
          </div>
        </div>
        <div class="col-sm-12" style="margin-bottom: 20px">
          <mat-slide-toggle name="isActive" formControlName="isActive">
            <ng-template [ngIf]="!tableFormGroup.value.isCombined">{{
              'ResSettings.ResAddTable.TableCanBeReserved' | translate
            }}</ng-template>
            <ng-template [ngIf]="tableFormGroup.value.isCombined">{{
              'ResSettings.ResAddTable.CombinationReserved' | translate
            }}</ng-template>
          </mat-slide-toggle>
        </div>
        <!--
        <div class="col-sm-12" style="margin-bottom: 20px">
          <mat-slide-toggle name="isCombined" formControlName="isCombined" (change)="changeCombined($event)">
            Tisch ist eine Kombination
          </mat-slide-toggle>
        </div>
        -->

        <!-- GastroPay Admin Settings-->
        <div class="gastropay-settings" *ngIf="editTableData && credentialsService.credentials.role === 'admin'">
          <h5 (click)="toggleGpaySettings()">
            {{ 'ResSettings.ResAddTable.GastroPaySetting' | translate }}
            <i class="fas" [class.fa-chevron-right]="!showGpaySettings" [class.fa-chevron-down]="showGpaySettings"></i>
          </h5>
          <ng-template [ngIf]="showGpaySettings">
            <div class="col-sm-12" style="margin-bottom: 10px">
              <mat-slide-toggle name="selfOrdering" formControlName="selfOrdering">
                {{ 'ResSettings.ResAddTable.SelfOrderingActive' | translate }}
              </mat-slide-toggle>
            </div>
            <div class="col-sm-12" style="margin-bottom: 10px">
              <mat-slide-toggle name="instantPayment" formControlName="instantPayment">
                {{ 'ResSettings.ResAddTable.OrderMustPaid' | translate }}
              </mat-slide-toggle>
            </div>
            <div class="col-sm-12" style="margin-bottom: 20px">
              <mat-slide-toggle name="bringService" formControlName="bringService">
                {{ 'ResSettings.ResAddTable.OrderIsBrought' | translate }}
              </mat-slide-toggle>
            </div>
          </ng-template>
        </div>
        <!-- /GastroPay Admin Settings-->

        <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="addTable()"
          *ngIf="!editTableData"
          [promiseBtn]="addObservable"
        >
          <ng-template [ngIf]="!tableFormGroup.value.isCombined">{{
            'ResSettings.ResAddTable.CreateTable' | translate
          }}</ng-template>
          <ng-template [ngIf]="tableFormGroup.value.isCombined">{{
            'ResSettings.ResAddTable.CreateCombination' | translate
          }}</ng-template>
        </button>
        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="saveTable()"
          *ngIf="editTableData"
          [promiseBtn]="editObservable"
        >
          <ng-template [ngIf]="!tableFormGroup.value.isCombined">{{
            'ResSettings.ResAddTable.SaveTable' | translate
          }}</ng-template>
          <ng-template [ngIf]="tableFormGroup.value.isCombined">{{
            'ResSettings.ResAddTable.SaveCombination' | translate
          }}</ng-template>
        </button>
      </div>
    </form>
  </div>
</div>
