<div class="auth-box-w wider centered">
  <h4 class="auth-header" style="padding-top:20px">
    <!-- <small style="font-size: 60%;"> -->
    <h5>Select Draft New Client from list</h5>
    <!-- </small> -->
  </h4>
  <div class="client-list">
    <div *ngIf="dataList && !dataList.length" style="padding-bottom:10px">
      No Drafts Available
    </div>
    <div class="item" *ngFor="let data of dataList">
      <div class="logged-user-name" style="cursor:pointer" (click)="loadForm(data)">
        <strong>{{ data.masterData.name }}</strong
        ><br />
        <span class="address"
          >{{ data.masterData.street }}, {{ data.masterData.zipCode }} {{ data.masterData.location }}
        </span>
      </div>
      <div class="auth-type">
        <i class="fa fa-trash" (click)="remove(data.id)"></i>
      </div>
    </div>
  </div>
  <button class="btn btn-primary" [routerLink]="['/select']">Go to Select Client</button>
  <button class="btn btn-primary" [routerLink]="['/new-client']">Neuen Betrieb hinzufügen</button>
</div>
