/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./color-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./color-picker.component";
var styles_ColorPickerComponent = [i0.styles];
var RenderType_ColorPickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColorPickerComponent, data: {} });
export { RenderType_ColorPickerComponent as RenderType_ColorPickerComponent };
function View_ColorPickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "circle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeColor(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { background: 0 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultStyleDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgStyleImpl, i5.DomSanitizer, [6, i2.NgStyle], [2, i4.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { background: 0 })], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, _v.context.$implicit); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ColorPickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "opened"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "colors"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPickerComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.defaultColors; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ColorPickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "color-picker"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["matTooltip", "Farbe ausw\u00E4hlen"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleColors() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "circle"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { background: 0 }), i1.ɵdid(8, 933888, null, 0, i3.DefaultStyleDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgStyleImpl, i5.DomSanitizer, [6, i2.NgStyle], [2, i4.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(9, { background: 0 }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColorPickerComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 7, 0, _co.color); _ck(_v, 6, 0, currVal_0); var currVal_1 = _ck(_v, 9, 0, _co.color); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.show; _ck(_v, 13, 0, currVal_2); }, null); }
export function View_ColorPickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-color-picker", [], null, null, null, View_ColorPickerComponent_0, RenderType_ColorPickerComponent)), i1.ɵdid(1, 49152, null, 0, i6.ColorPickerComponent, [], null, null)], null, null); }
var ColorPickerComponentNgFactory = i1.ɵccf("app-color-picker", i6.ColorPickerComponent, View_ColorPickerComponent_Host_0, { heading: "heading", color: "color" }, { event: "event" }, []);
export { ColorPickerComponentNgFactory as ColorPickerComponentNgFactory };
