<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editShortURL"> {{ 'ShortURLs.AddShortURL' | translate }}</ng-template>
      <ng-template [ngIf]="editShortURL"> {{ 'ShortURLs.EditShortURL' | translate }}</ng-template>
    </h4>
    <form [formGroup]="shortURLFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'ShortURLs.DocumentLink' | translate }}<br /> </label>
            <textarea class="form-control" formControlName="url" rows="2" required ngbAutofocus></textarea>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label> {{ 'ShortURLs.Description' | translate }}</label>
        <textarea class="form-control" formControlName="description" rows="3"></textarea>
      </div>

      <div>
        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="add()"
          *ngIf="!editShortURL"
          [promiseBtn]="addObservable"
        >
          {{ 'ShortURLs.Save' | translate }}
        </button>
        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="save()"
          *ngIf="editShortURL"
          [promiseBtn]="editObservable"
        >
          {{ 'ShortURLs.Save' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
