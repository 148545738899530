<div class="modal-header">
  <h4 class="modal-title">Gastinformation</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="guestFormGroup">
  <form [formGroup]="guestFormGroup">
    <div class="modal-body guestModal-List">
      <div class="row">
        <fieldset class="form-group col-sm-8" style="margin-top:0">
          <legend><span>Kontaktdaten</span></legend>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="">Name</label>
                <input class="form-control" formControlName="name" />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="">Telefon</label>
                <input class="form-control" formControlName="phone" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="">E-Mail Adresse</label>
                <input class="form-control" formControlName="email" />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for=""> Geburtstag</label>
                <div class="date-input">
                  <input
                    class="single-daterange form-control"
                    formControlName="birthdate"
                    [matDatepicker]="picker"
                    (focus)="picker.open()"
                  />
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label> Notizen</label><textarea class="form-control" rows="3" formControlName="notes"></textarea>
          </div>

          <div>
            <button
              class="btn btn-primary"
              type="submit"
              (click)="save()"
              [promiseBtn]="savingPromise"
              [disabled]="!guestFormGroup.dirty"
            >
              Daten speichern
            </button>
          </div>
        </fieldset>
        <div class="col-sm-4 guest-stats">
          <div>
            <div class="el-tablo centered padded-v">
              <div class="value">{{ guestData?.visits }}</div>
              <div class="label">Besuche insgesamt</div>
            </div>
          </div>
          <div>
            <div class="el-tablo centered padded-v">
              <div class="value">{{ guestData?.noShow }}</div>
              <div class="label">No-Shows</div>
            </div>
          </div>
          <div>
            <div class="el-tablo centered padded-v">
              <div class="value">{{ guestData?.lastVisit | amDateFormat: 'DD.MM.YY' }}</div>
              <div class="label">Letzter Besuch</div>
            </div>
          </div>
        </div>
      </div>
      <fieldset class="form-group">
        <legend style="margin-bottom: 0;"><span>Reservierungen</span></legend>
        <app-reservations-list
          [reservations]="reservations"
          [editing]="{}"
          [loading]="loadingReservationList"
          [showSummary]="false"
          [showFooter]="true"
          [limit]="3"
          [hideColumns]="['tags']"
          [emptyMessage]="'Keine Reservierungen gefunden'"
          class="row"
        ></app-reservations-list>
      </fieldset>
    </div>
  </form>
</div>
