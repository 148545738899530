<breadcrumb></breadcrumb>
<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="content-box">
  <div class="row">
    <div class="col-md-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          {{ 'PhoneAssistant.TelephoneAssistant' | translate }}
        </h6>

        <div class="element-box">
          <div *ngIf="showIfNotRegistry">
            <div class="system-setting-div" *ngIf="showErrorDiv && !showIsEnrolled">
              <span class="text-error">
                Please update your email/phone number.
                <a target="_blank" class="link-error" href="/client/{{ activeClient?.id }}/my-entry/kontaktdaten">
                  Click here.</a
                ></span
              >
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <img class="responsive-img" src="./assets/img/phone-assistant.png" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <h3>{{ 'PhoneAssistant.RestaurantAccessible' | translate }}</h3>
                <p>
                  {{ 'PhoneAssistant.TelephonePartner' | translate }}
                  <a href="https://www.assistent.ai/" target="_blank">Assistent.ai</a>
                  {{ 'PhoneAssistant.DonMissAnyMoreCalls' | translate }}
                </p>
                <p>{{ 'PhoneAssistant.ConjunctionGastro' | translate }}</p>
                <ul>
                  <li>{{ 'PhoneAssistant.AcceptCallsAutomatically' | translate }},</li>
                  <li>{{ 'PhoneAssistant.EnterReservationTelephone' | translate }},</li>
                  <li>{{ 'PhoneAssistant.RecordGuestRequests' | translate }}</li>
                  <li>{{ 'PhoneAssistant.AdditionalAnswerAsked' | translate }}</li>
                </ul>
                <p>{{ 'PhoneAssistant.YouCanActivatePhoneAssistant' | translate }}</p>

                <h4>{{ 'PhoneAssistant.GoLiveInSteps' | translate }}</h4>
                <ol>
                  <li>{{ 'PhoneAssistant.ReceiveIndependentTelephone' | translate }}</li>
                  <li>{{ 'PhoneAssistant.CustomizeGreetingAndFaq' | translate }}</li>
                  <li>{{ 'PhoneAssistant.ActivateCallForwarding' | translate }}</li>
                </ol>

                <p>
                  <strong>{{ 'PhoneAssistant.ActivatePhoneAssistantNow' | translate }}</strong>
                </p>
                <p>{{ 'PhoneAssistant.FirstCallFree' | translate }}</p>

                <p>
                  {{ 'PhoneAssistant.ByUsingTelephoneAssistant' | translate }}
                  <a href="https://www.assistent.ai/nutzungsbedingungen" target="_blank">{{
                    'PhoneAssistant.TermsOf' | translate
                  }}</a>
                  {{ 'PhoneAssistant.OurPartnerCompany' | translate }}
                  <a href="https://www.assistent.ai/" target="_blank">Assistent.ai</a>
                  {{ 'PhoneAssistant.To' | translate }}.
                </p>

                <p>
                  <strong>{{ 'PhoneAssistant.DataProtection' | translate }}</strong>
                </p>
                <p>
                  {{ 'PhoneAssistant.CollectedAndProcessed' | translate }}
                  <a href="https://www.assistent.ai/" target="_blank">Assistent.ai</a>
                  {{ 'PhoneAssistant.PersonalDataMeeting' | translate }}
                  {{ 'PhoneAssistant.DataController' | translate }}
                  <a href="https://www.assistent.ai/" target="_blank">Assistent.ai</a>
                  {{ 'PhoneAssistant.ToComplete' | translate }}. {{ 'PhoneAssistant.AVVForm' | translate }}
                  <a href="https://www.assistent.ai/" target="_blank">Assistent.ai</a>
                  {{ 'PhoneAssistant.PleaseSendSigned' | translate }}
                  <a href="mailto:support@assistent.ai">support@assistent.ai</a>.
                </p>
              </div>
            </div>

            <div class="responsive-img-div">
              <button class="btn btn-primary save-button" (click)="createPhoneAssistant()">
                {{ 'PhoneAssistant.TryNow' | translate }}
              </button>
            </div>
          </div>
          <div *ngIf="!showIfNotRegistry">
            <form [formGroup]="phoneAssistant">
              <ng-template [ngIf]="this.phoneAssistant.value.isEnrolled === 1">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <mat-slide-toggle class="mat-slide-toggle" formControlName="isActive" name="isActive">
                        Active Phone Assistant
                      </mat-slide-toggle>
                    </div>
                    <!-- <div class="form-group">
                          <label for=""> Link zu den eigenen AGBs für die Online-Reservierung</label>
                          <mat-slide-toggle
                              class="mat-slide-toggle"
                              formControlName="isActive"
                              name="isActive"
                              (change)="showForm()"
                            >
                              Active Phone Assistant
                            </mat-slide-toggle>
                        </div> -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <mat-slide-toggle name="roomChoice" formControlName="roomChoice">
                        Room Choice
                        <!-- <div class="info">Kann der Gast selbst online reservieren?</div> -->
                      </mat-slide-toggle>
                    </div>
                    <!-- <div class="form-group">
                          <label for=""> Link zu den eigenen AGBs für die Online-Reservierung</label>
                          <mat-slide-toggle name="roomChoice" formControlName="roomChoice"> Room Choice </mat-slide-toggle>
                        </div> -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label for="">Phone Assistant Number</label>
                      <input
                        class="form-control"
                        placeholder=""
                        type="text"
                        name="phoneNo"
                        formControlName="phoneNo"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <div
                            class="system-setting-div"
                          >
                            <mat-slide-toggle
                              class="mat-slide-toggle"
                              formControlName="isActive"
                              name="isActive"
                              (change)="showForm()"
                            >
                              Active Phone Assistant
                            </mat-slide-toggle>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <mat-form-field
                          appearance="outline"
                          class="w-100 mat-focused mat-form-field-should-placeholder mat-form-field-should-float mat-form-field-show-focus"
                        >
                          <mat-label floatLabel="never">Phone No</mat-label>
                          <input type="text" matInput formControlName="phoneNo" placeholder="Enter Phone number" />
                        </mat-form-field>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <mat-slide-toggle name="roomChoice" formControlName="roomChoice"> Room Choice </mat-slide-toggle>
                        </div>
                      </div>
                    </div> -->
              </ng-template>

              <div class="system-setting-div" *ngIf="showErrorDiv && !showIsEnrolled">
                <span class="text-error">
                  Please update your email/phone number.
                  <a
                    target="_blank"
                    class="link-error"
                    href="https://kunden.gastro.digital/client/{{ activeClient?.id }}/my-entry/kontaktdaten"
                  >
                    Click here.</a
                  ></span
                >
              </div>

              <div *ngIf="showFormDiv">
                <button class="btn btn-primary save-button" [disabled]="showErrorDiv" (click)="createPhoneAssistant()">
                  <span *ngIf="this.phoneAssistant.value.isEnrolled === 1; else other_content">
                    Save Changes
                  </span>
                  <ng-template #other_content>Enroll</ng-template>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
