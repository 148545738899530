<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <!-- <div class="d-flex justify-content-between mb-5">
          <div class="languageSwitcher">
            <select #langSelect class="form-control " (change)="translate.use(langSelect.value)">
              <option *ngFor="let lang of langArray" [value]="lang" [selected]="lang === translate.currentLang">{{
                lang
              }}</option>
            </select>
          </div>
          <div class="theme-options">
            <span>Light Theme</span>
            <mat-slide-toggle (change)="switchTheme($event)">Dark Theme</mat-slide-toggle>
          </div>
        </div> -->
        <h6 class="element-header">
          {{ 'GuestPage.PageTitle' | translate }}
          <div class="element-actions">
            <button class="btn btn-primary hidden-xs-down" (click)="createGuest()">
              <i class="fas fa-plus-circle fa-fw"></i>
              {{ 'GuestPage.AddNew' | translate }}
            </button>
          </div>
        </h6>
        <div class="row">
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for=""> Gäste suchen </label>
              <input
                class="search-guest form-control"
                type="text"
                #searchInput
                placeholder="nach Name, E-Mail oder Telefon"
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="">{{ 'GuestPage.FilterBy' | translate }} </label>
              <ng-select
                [(ngModel)]="searchForm"
                appendTo="body"
                [searchable]="false"
                [clearable]="true"
                (change)="filterGuestArray($event)"
                placeholder=" {{ 'GuestPage.SelectSalutation' | translate }}"
                [items]="['Alle Anrede', 'Herr', 'Frau', 'Diverse', 'Firma', 'Keine Anrede']"
                bindLabel="label"
                bindValue="value"
                groupBy="type"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <span [class]="item.icon"></span>
                  {{ item }}
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for=""> {{ 'GuestPage.AddressSearch' | translate }} </label>
              <input
                class="search-guest form-control"
                type="text"
                #searchAddress
                style="padding:8px;"
                placeholder="{{ 'GuestPage.CiyPlaceHolder' | translate }}"
              />
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for=""> {{ 'GuestPage.VisitCount' | translate }} </label>
              <div class="row">
                <div class="col-6">
                  <input
                    class="search-guest form-control"
                    type="number"
                    min="0"
                    #searchMinVisits
                    placeholder="{{ 'GuestPage.MinValue' | translate }}"
                    onKeyDown="return false"
                  />
                </div>
                <div class="col-6">
                  <input
                    class="search-guest form-control"
                    type="number"
                    min="0"
                    #searchMaxVisits
                    placeholder="{{ 'GuestPage.MaxValue' | translate }}"
                    onKeyDown="return false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for="">Guest</label>
              <ng-select
                [(ngModel)]="searchGuest"
                appendTo="body"
                [searchable]="false"
                [clearable]="true"
                (change)="filterGuestArray($event)"
                placeholder="Select"
                [items]="['Single', 'All']"
                bindLabel="label"
                bindValue="value"
                groupBy="type"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <span [class]="item.icon"></span>
                  {{ item }}
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-12">
            <div class="form-group">
              <label for=""> {{ 'GuestPage.GuestListCSV' | translate }} </label>
              <angular2csv
                #DownloadCSV
                class="btn btn-primary downloadCSV"
                [data]="filteredArray"
                filename="guests_{{ today | date: 'yyyy_MM_dd_H_mm' }}"
                [options]="csvOptions"
              >
                This is demo
              </angular2csv>
            </div>
          </div>
        </div>
        <div class="element-box">
          <div class="loading" *ngIf="loading">
            <i class="fa fa-spinner fa-spin"></i>
            {{ 'GuestPage.InvitedGuest' | translate }}
          </div>
          <ng-template [ngIf]="!loading">
            <div class="alert alert-info" *ngIf="!filteredGuests.length && !searchInput">
              {{ 'GuestPage.NoSaveGuest' | translate }}
            </div>
            <div class="datatable-res-transactions myGuests ngx-datatable-template guest-template-list">
              <ngx-datatable
                *ngIf="!mobileWindow"
                #myTable
                class="material striped expandable"
                [rows]="filteredGuests"
                [columnMode]="'force'"
                headerHeight="50"
                footerHeight="50"
                [sorts]="orderSort"
                [scrollbarH]="true"
                [rowHeight]="'100%'"
                [externalPaging]="true"
                [count]="page.totalElements"
                [offset]="page.pageNumber"
                [limit]="page.size"
                (page)="setPage($event)"
                (sort)="onSort($event)"
                [cssClasses]="{
                  pagerLeftArrow: 'fas fa-backward',
                  pagerRightArrow: 'fas fa-forward',
                  pagerPrevious: 'fas fa-step-backward',
                  pagerNext: 'fas fa-step-forward'
                }"
              >
                <ngx-datatable-row-detail [rowHeight]="auto" #myDetailRow (toggle)="onDetailToggle($event)">
                  <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                    <div>
                      <div>
                        <strong>{{ 'GuestPage.AddressLabel' | translate }}</strong>
                      </div>
                      <div>
                        {{ row.address || 'Straße unbekannt' }}, {{ row.place || 'Ort unbekannt' }},
                        {{ row.zip || 'PLZ unbekannt' }}
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-row-detail>
                <ngx-datatable-column
                  [width]="20"
                  [resizeable]="false"
                  [sortable]="false"
                  [draggable]="false"
                  [canAutoResize]="false"
                >
                  <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                    <a
                      href="javascript:void(0)"
                      [class.datatable-icon-right]="!expanded"
                      [class.datatable-icon-down]="expanded"
                      title="Expand/Collapse Row"
                      (click)="toggleExpandRow(row)"
                    >
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'GuestPage.GuestTable.SurName' | translate }}"
                  prop="name"
                  [width]="65"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="align-middle">{{ row.name }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'GuestPage.GuestTable.FirstName' | translate }}"
                  prop="firstName"
                  [width]="75"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span>{{ row.firstName }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'GuestPage.GuestTable.Salute' | translate }}"
                  prop="form"
                  [width]="70"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span>{{ row.form }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'GuestPage.GuestTable.Company' | translate }}"
                  prop="company"
                  [width]="115"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span>{{ row.company }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'GuestPage.GuestTable.EmailTel' | translate }}"
                  prop="email"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="row">
                      <div class="col-12">
                        {{ 'GuestPage.Email' | translate }}:
                        <span>{{ row.email || 'Unbekannt' }}, </span>
                      </div>
                      <div class="col-12">
                        {{ 'GuestPage.Telephone' | translate }}:
                        <span
                          [ngStyle]="{
                            color: 'gray'
                          }"
                          >{{ row.phone || 'Unbekannt' }}</span
                        >
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'GuestPage.GuestTable.birthDay' | translate }}"
                  prop="birthdate"
                  [width]="115"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span>{{ row.birthdate | date }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'GuestPage.GuestTable.WeddingDay' | translate }}"
                  prop="weddingDay"
                  [width]="115"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span>{{ row.weddingDay | date }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'GuestPage.GuestTable.LastVisit' | translate }}"
                  prop="lastVisit"
                  [width]="100"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span>{{ row.lastVisit | date }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'GuestPage.GuestTable.Visits' | translate }}"
                  prop="visits"
                  [width]="50"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span>{{ row.visits }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                  [sortable]="true"
                  name="{{ 'GuestPage.GuestTable.NoShows' | translate }}"
                  prop="noShow"
                  [width]="50"
                >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span>{{ row.noShow }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [sortable]="false" name="" [width]="30">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <button class="delete-btn" (click)="deleteGuest(row)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                    <button class="edit-btn" (click)="editGuest(row)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button class="edit-btn" (click)="getGuestReservationHistory(row)">
                      <i class="fas fa-file"></i>
                    </button>
                  </ng-template>
                </ngx-datatable-column>

                <!-- <div class="row">
                  <div class="col-12">
                    Addresse:
                    <div
                      [ngStyle]="{
                        color: 'gray'
                      }"
                      >{{ row.address || 'Straße unbekannt' }},
                    </div>
                    <div
                      [ngStyle]="{
                        color: 'gray'
                      }"
                      >{{ row.place || 'Ort unbekannt' }},
                    </div>
                    <div
                      [ngStyle]="{
                        color: 'gray'
                      }"
                      >{{ row.zip || 'PLZ unbekannt' }}</div>
                  </div>
                </div> -->
              </ngx-datatable>
              <div class="grid" *ngIf="mobileWindow">
                <mat-paginator
                  [length]="filteredArray.length"
                  pageSize="{{ pageSize }}"
                  (page)="getPaginatorData($event)"
                  [pageSizeOptions]="[10, 20, 50]"
                  showFirstLastButtons="false"
                >
                </mat-paginator>
                <div class="row">
                  <ng-container *ngFor="let row of filterGuestList; let i = index">
                    <div class="col-sm-6 col-md-6">
                      <div class="card" id="card-{{ row.id }}">
                        <div class="card-body">
                          <h5 class="card-title">{{ row.name }} {{ row.firstName }}</h5>
                          <div class="card-text" style="font-size: 15px;">
                            <p>{{ row.form }}</p>
                            <div class="row">
                              <div class="col-12">
                                {{ 'GuestPage.GuestTable.Company' | translate }}:
                                <span>{{ row.company || 'Unbekannt' }}</span>
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <div class="col-12">
                                {{ 'GuestPage.Email' | translate }}:
                                <span>{{ row.email || 'Unbekannt' }}</span>
                              </div>
                              <div class="col-12">
                                {{ 'GuestPage.Telephone' | translate }}:
                                <span
                                  [ngStyle]="{
                                    color: 'gray'
                                  }"
                                  >{{ row.phone || 'Unbekannt' }}</span
                                >
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <div class="col-12">
                                {{ 'GuestPage.GuestTable.birthDay' | translate }}:
                                <span>{{ row.birthdate | date }}</span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                {{ 'GuestPage.GuestTable.WeddingDay' | translate }}:
                                <span>{{ row.weddingDay | date }}</span>
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <div class="col-12">
                                {{ 'GuestPage.GuestTable.LastVisit' | translate }}:
                                <span>{{ row.lastVisit | date }}</span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                {{ 'GuestPage.GuestTable.Visits' | translate }}:
                                <span>{{ row.visits || 'Unbekannt' }}</span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                {{ 'GuestPage.GuestTable.NoShows' | translate }}:
                                <span>{{ row.noShow || 'Unbekannt' }}</span>
                              </div>
                            </div>
                            <br />
                            <div>
                              <ng-container>
                                <button class="edit-btn" (click)="editGuest(row)">
                                  <i class="fas fa-edit"></i>
                                </button>
                                <button class="delete-btn ml-2" (click)="deleteGuest(row)">
                                  <i class="fas fa-trash-alt"></i>
                                </button>
                                <button class="edit-btn" (click)="getGuestReservationHistory(row)">
                                  <i class="fas fa-file"></i>
                                </button>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
