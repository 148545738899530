<!--
<div class="spinner-wrapper" *ngIf="loading">
  <span>... wird geladen ...</span>
</div>
-->

<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Einstellungen: Impressum
          <div class="element-actions"></div>
        </h6>
        <div class="element-box">
          <form *ngIf="form.fields.length">
            <button (click)="save(imprint)" class="btn btn-primary float-right">
              <i class="far fa-save"></i> Speichern
            </button>
            <div style="clear: both;">&nbsp;</div>

            <div class="row">
              <ng-container *ngFor="let field of form.fields; let i = index">
                <div
                  class="
                  col-sm-{{ form.settings[field].sm ? form.settings[field].sm : '6' }} 
                  col-md-{{ form.settings[field].md ? form.settings[field].md : '6' }} 
                  col-lg-{{ form.settings[field].lg ? form.settings[field].lg : '4' }} 
                  col-xl-{{ form.settings[field].xl ? form.settings[field].xl : '3' }} "
                  [class.d-none]="form.settings[field].type == 'hidden'"
                >
                  <div [class]="form.settings[field].groupClass ? form.settings[field].groupClass : 'form-group'">
                    <label
                      [for]="'field' + field"
                      [class]="form.settings[field].labelClass ? form.settings[field].labelClass : 'label-default'"
                      [style.cursor]="form.settings[field].type == 'checkbox' ? 'pointer' : 'normal'"
                      [style.display]="form.settings[field].type == 'checkbox' ? 'block' : 'inline-block'"
                      [style.margin-left]="form.settings[field].type == 'checkbox' ? '-1.25rem' : '0'"
                    >
                      <span class="countChars" *ngIf="form.settings[field].maxlength">
                        <sup>
                          <ng-container *ngIf="this[form.targetObject][field]">
                            {{ this[form.targetObject][field].length }}
                          </ng-container>
                          <ng-container *ngIf="!this[form.targetObject][field]">
                            0
                          </ng-container>
                          / {{ form.settings[field].maxlength }}</sup
                        >
                      </span>
                      {{ form.settings[field].label }}:
                      <sup class="mandatory" *ngIf="form.settings[field].mandatory" style="color: var(--danger)"
                        >(pflicht)</sup
                      >
                    </label>

                    <select
                      *ngIf="form.settings[field].type == 'select'"
                      [id]="'field' + field"
                      [class]="
                        form.settings[field].inputClass
                          ? 'form-control ' + form.settings[field].inputClass
                          : 'form-control'
                      "
                      [(ngModel)]="this[form.targetObject][field]"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="onChange($event, field)"
                    >
                      <ng-container *ngFor="let option of form.settings[field].options; let i = index">
                        <option [value]="option.value">
                          {{ option.text }}
                        </option>
                      </ng-container>
                    </select>

                    <input
                      *ngIf="form.settings[field].type == 'checkbox'"
                      type="checkbox"
                      [id]="'field' + field"
                      [class]="
                        form.settings[field].inputClass
                          ? 'form-check-input ' + form.settings[field].inputClass
                          : 'form-check-input'
                      "
                      [value]="form.settings[field].checkboxValue ? form.settings[field].checkboxValue : 1"
                      [(ngModel)]="this[form.targetObject][field]"
                      [ngModelOptions]="{ standalone: true }"
                    />

                    <label
                      *ngIf="form.settings[field].type == 'checkbox'"
                      [for]="'field' + field"
                      class="form-check-label"
                      [style.cursor]="form.settings[field].type == 'checkbox' ? 'pointer' : 'normal'"
                    >
                      {{ form.settings[field].checkboxText }}
                    </label>

                    <input
                      *ngIf="
                        form.settings[field].type != 'textarea' &&
                        form.settings[field].type != 'select' &&
                        form.settings[field].type != 'checkbox'
                      "
                      [type]="form.settings[field].type"
                      [id]="'field' + field"
                      [class]="
                        form.settings[field].inputClass
                          ? 'form-control ' + form.settings[field].inputClass
                          : 'form-control'
                      "
                      [min]="form.settings[field].min ? form.settings[field].min : 0"
                      [max]="form.settings[field].max ? form.settings[field].max : 9999999"
                      [minlength]="form.settings[field].minlength ? form.settings[field].minlength : 0"
                      [maxlength]="form.settings[field].maxlength ? form.settings[field].maxlength : 9999999"
                      [placeholder]="form.settings[field].placeholder ? form.settings[field].placeholder : ''"
                      [(ngModel)]="this[form.targetObject][field]"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <textarea
                      *ngIf="form.settings[field].type == 'textarea'"
                      [id]="'field' + field"
                      [class]="
                        form.settings[field].inputClass
                          ? 'form-control ' + form.settings[field].inputClass
                          : 'form-control'
                      "
                      [rows]="form.settings[field].rows ? form.settings[field].rows : '3'"
                      [minlength]="form.settings[field].minlength ? form.settings[field].minlength : 0"
                      [maxlength]="form.settings[field].maxlength ? form.settings[field].maxlength : 9999999"
                      [(ngModel)]="this[form.targetObject][field]"
                      [ngModelOptions]="{ standalone: true }"
                    ></textarea>
                    <small [id]="'info' + field" class="form-text text-muted">{{ form.settings[field].info }}</small>
                    <br />
                  </div>
                </div>
              </ng-container>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
