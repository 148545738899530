<div class="content-box" style="margin: 5%;">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Gutschein-APIs testen
        </h6>

        <div class="row">
          <!-- Form Group -->
          <form [formGroup]="voucherApiFormGroup" class="col-md-12">
            <div class="form-group">
              <label for="code">Coupon Code<br /> </label>
              <input
                class="form-control"
                placeholder=""
                name="code"
                formControlName="code"
                type="text"
                value=""
                required
                ngbAutofocus
              />
            </div>

            <div class="form-group">
              <label for="value">Value<br /> </label>
              <input class="form-control" placeholder="" name="value" formControlName="value" type="text" value="" />
            </div>

            <div class="form-group">
              <label for="token">Token<br /> </label>
              <input class="form-control" placeholder="" name="token" formControlName="token" type="text" value="" />
            </div>

            <div class="form-group">
              <label for="secret">Staff Code<br /> </label>
              <input class="form-control" placeholder="" name="secret" formControlName="secret" type="text" value="" />
            </div>

            <div class="error-msg">
              {{ error }}
            </div>

            <button
              class="btn btn-primary btn-block btn-lg"
              (click)="getVoucherDetails()"
              [disabled]="isFormSubmitting"
            >
              Get Voucher Details
            </button>

            <button class="btn btn-primary btn-block btn-lg" (click)="redeemVoucher()" [disabled]="isFormSubmitting">
              Redeem Voucher
            </button>

            <button class="btn btn-primary btn-block btn-lg" (click)="rechargeVoucher()" [disabled]="isFormSubmitting">
              Recharge Voucher
            </button>

            <div class="form-group" *ngIf="showData">
              <label for="code">Result<br /> </label>
              {{ showData | json }}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
