/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./no-editor.component";
var styles_NoEditorComponent = [];
var RenderType_NoEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NoEditorComponent, data: {} });
export { RenderType_NoEditorComponent as RenderType_NoEditorComponent };
export function View_NoEditorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { container: 0 }), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 16777216, [[1, 3], ["container", 1]], null, 0, "div", [["tabindex", "0"]], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, null); }
export function View_NoEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "editor-cell", [], null, null, null, View_NoEditorComponent_0, RenderType_NoEditorComponent)), i0.ɵdid(1, 4243456, null, 0, i1.NoEditorComponent, [], null, null)], null, null); }
var NoEditorComponentNgFactory = i0.ɵccf("editor-cell", i1.NoEditorComponent, View_NoEditorComponent_Host_0, {}, {}, []);
export { NoEditorComponentNgFactory as NoEditorComponentNgFactory };
