<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title" style="padding:10px 0 4px 4px">
      <ng-template [ngIf]="!isEditCategory">Kategorie hinzufügen</ng-template>
      <ng-template [ngIf]="isEditCategory">Unterkategorie bearbeiten</ng-template>
    </h4>
    <form [formGroup]="subCategoryFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">Kategoriename</label>
            <input class="form-control" name="name" formControlName="name" type="text" value="" ngbAutofocus />
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">Beschreibung</label>
            <textarea
              class="form-control"
              name="description"
              formControlName="description"
              type="text"
              value=""
            ></textarea>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">Preis</label>
            <input class="form-control" name="price" formControlName="price" type="number" value="" ngbAutofocus />
          </div>
        </div>
      </div>
      <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

      <div class="clearfix" style="text-align: center;margin-top: 15px;">
        <button class="btn btn-block btn-primary btn-lg" (click)="add()" *ngIf="!isEditCategory">
          Kategorie hinzufügen
        </button>
        <button class="btn btn-block btn-primary btn-lg" (click)="save()" *ngIf="isEditCategory">
          Unterkategorie aktualisieren
        </button>
      </div>
    </form>
  </div>
</div>
