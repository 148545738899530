<div class="auth-box-w wider centered">
  <div class="logo-w">
    <a href="index.html"><img alt="" src="./assets/logo.png"/></a>
    <app-lang-selector-backoffice></app-lang-selector-backoffice>
  </div>
  <h5 class="auth-header">{{ 'SelectPage.ManageLocation' | translate }}</h5>
  <div class="client-list">
    <div class="item" *ngFor="let client of clients" (click)="openClientDashboard(client.id, client)">
      <div class="thumbnail">
        <img alt="" src="https://cdn.gastroguide.de/{{ client.logo }}" *ngIf="client.logo" />
        <img alt="" src="./assets/img/no-image.svg" *ngIf="!client.logo" />
      </div>
      <div class="logged-user-name">
        <strong>{{ client.name }}</strong
        ><br />
        <span class="address">{{ client.street }}, {{ client.zipCode }} {{ client.location }} </span>
      </div>
      <div class="auth-type">
        {{ client.isModerator ? 'Moderator' : 'Administrator' }}
      </div>
    </div>
  </div>
  <button class="btn btn-primary" (click)="addNewClient()">{{ 'SelectPage.AddNewCompany' | translate }}</button>
</div>
