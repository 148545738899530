import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ReservationService } from '../reservation.service';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { FontPickerComponent, FontPickerDirective } from 'ngx-font-picker';

@Component({
  selector: 'app-reservation-integration',
  templateUrl: './reservation-integration.component.html',
  styleUrls: ['./reservation-integration.component.scss']
})
export class ReservationIntegrationComponent implements OnInit, OnDestroy {
  activeClient: any = {
    id: null
  };
  widgetUrl: string;
  expandedPanel: string;
  settings: any;
  font: any;
  secondaryFont: any;
  settingsFormGroup: FormGroup;
  generalResSettings: any = {
    showShiftFirstOnline: false
  };
  @ViewChild('secondaryFontPicker', { static: false }) secondaryFontPicker: FontPickerDirective;

  private ngUnsubscribe: Subject<any> = new Subject();
  // formBackgroundColorCheckBox: boolean = false;

  constructor(
    public clientResolver: ClientResolverService,
    private formBuilder: FormBuilder,
    private reservationService: ReservationService,
    private snackBar: MatSnackBar
  ) {
    this.activeClient = this.clientResolver.client;
    this.widgetUrl = 'https://kunden.gastro.digital/widgets/elements.js';

    this.settingsFormGroup = this.formBuilder.group({
      mainColor: ['#000000', Validators.required],
      primaryColor: ['#009688', Validators.required],
      backgroundColor: ['#ffffff', Validators.required],
      primaryFont: ['Nunito Sans', Validators.required],
      secondaryFont: ['Nunito Sans', Validators.required],
      formBackgroundColor: [null, Validators.required],
      formBackgroundColorCheckBox: [false, '']
    });
  }

  ngOnInit() {
    this.getIntegrationStyles();
    this.getResSettings();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  expandPanel(panel: string) {
    this.expandedPanel = panel;
  }

  changeTheme(color: string) {
    document.documentElement.style.setProperty('--primary', color);
  }

  getIntegrationStyles() {
    this.reservationService
      .getWidgetStyle()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((settings: any) => {
        if (settings) {
          this.settingsFormGroup.get('mainColor').setValue(settings.mainColor);
          this.settingsFormGroup.get('primaryColor').setValue(settings.primaryColor);
          this.settingsFormGroup.get('backgroundColor').setValue(settings.backgroundColor);
          this.settingsFormGroup.get('primaryFont').setValue(settings.primaryFont);
          this.settingsFormGroup.get('secondaryFont').setValue(settings.secondaryFont);
          this.settingsFormGroup.get('formBackgroundColor').setValue(settings.formBackgroundColor);
          if (this.settingsFormGroup.get('formBackgroundColor').value === null) {
            this.settingsFormGroup.get('formBackgroundColorCheckBox').setValue(false);
          } else {
            this.settingsFormGroup.get('formBackgroundColorCheckBox').setValue(true);
          }
        }
      });
  }

  saveIntegrationStyles() {
    this.reservationService
      .saveWidgetStyle(this.settingsFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((settings: any) => {
        if (settings) {
          this.snackBar.open('Einstellungen wurden gespeichert', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        }
      });
  }

  checkedHandler(event: any) {
    const checked = event.checked;
    if (checked === true) {
      this.settingsFormGroup.get('formBackgroundColor').setValue('transparent');
    } else {
      this.settingsFormGroup.get('formBackgroundColor').setValue(null);
    }
  }

  getResSettings() {
    this.reservationService
      .getSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((settings: any) => {
        this.generalResSettings = settings;
      });
  }

  saveResSettings() {
    this.reservationService
      .saveSettings(this.generalResSettings)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resp: any) => {
        this.snackBar.open('Einstellungen erfolgreich aktualisiert', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
  }

  changeBgColor(e: any) {
    this.settingsFormGroup.controls['backgroundColor'].setValue(e);
  }

  changePrimaryColor(e: any) {
    this.settingsFormGroup.controls['primaryColor'].setValue(e);
  }

  changeMainColor(e: any) {
    this.settingsFormGroup.controls['mainColor'].setValue(e);
  }

  changePrimaryFont(e: any) {
    console.log(e);
    this.settingsFormGroup.controls['primaryFont'].setValue(e.family);
    this.secondaryFontPicker.closeDialog();
  }

  changeSecondaryFont(e: any) {
    this.settingsFormGroup.controls['secondaryFont'].setValue(e.family);
    this.secondaryFontPicker.closeDialog();
  }
}
