
    <mat-nav-list>
      <a href="#" mat-list-item (click)="$event.preventDefault(); addReservation()">
        <span mat-line>Neue Reservierung</span>
        <span mat-line>Erstellen Sie eine Reservierung <b>mit</b> Gastdaten</span>
      </a>
      <a href="#" mat-list-item (click)="$event.preventDefault(); addWalkin()">
        <span mat-line>Neuen Walk-In</span>
        <span mat-line>Erstellen Sie eine Reservierung <b>ohne</b> Gastdaten</span>
      </a>
    </mat-nav-list>
  