<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editQuestionData">Neue Abfrage anlegen</ng-template>
      <ng-template [ngIf]="editQuestionData">Abfrage ändern</ng-template>
    </h4>
    <form [formGroup]="questionFormGroup">
      <div>
        <div>
          <div class="form-group">
            <label for=""> Name der Zusatzabfrage</label>
            <small>Nur für Darstellung im Reservierungsbuch und in den Vorlagen</small>
            <input
              class="form-control"
              placeholder="Geben Sie einen Namen ein..."
              name="name"
              formControlName="name"
              type="text"
              value=""
              ngbAutofocus
            />
          </div>
        </div>
        <div>
          <div class="form-group">
            <label for="">Frage</label>
            <small>Die Frage an den Gast. Erscheint im Reservierungsformular.</small>
            <input
              class="form-control"
              placeholder="Geben Sie eine Frage ein..."
              name="question"
              formControlName="question"
              type="text"
              value=""
              ngbAutofocus
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for=""> {{ 'ResSettings.ResQuestions.QuestionType' | translate }}</label>
              <ng-select
                formControlName="questionType"
                [(ngModel)]="questionFormGroup.value.questionType"
                placeholder="-- Bitte wählen --"
                [searchable]="true"
                [clearable]="true"
                [items]="allQuestionTypes"
                bindLabel="name"
                bindValue="id"
                notFoundText="Kein Eintrag gefunden"
                (change)="questionAnswerDisplay(); changeQuestionType(); changeMultiSelect()"
              >
              </ng-select>
            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="isVisibleMultiSelect()">
          <div class="form-group">
            <mat-slide-toggle formControlName="multiSelect">
              Mehrfachauswahl
            </mat-slide-toggle>
          </div>
        </div>

        <!-- <div class="col-sm-4">
            <div class="form-group">
              <mat-slide-toggle formControlName="questionType" (change)="questionAnswerDisplay()">
                Freitext
              </mat-slide-toggle>
            </div>
          </div> -->
        <!-- <div class="col-sm-8" *ngIf="resMenuOrders && resMenuOrders.length">
            <div class="form-group">
              <mat-slide-toggle
                name="isResMenuOrder"
                (change)="clearValues()"
                [(ngModel)]="isResMenuOrder"
                [ngModelOptions]="{ standalone: true }"
              >
                Preorder with selected amount
              </mat-slide-toggle>
            </div>
          </div> -->

        <div class="row">
          <div class="col-md-12" *ngIf="questionFormGroup.value.questionType == 2">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResQuestions.ResMenuOrder' | translate }}</label>
              <ng-select
                formControlName="foreignId"
                [(ngModel)]="questionFormGroup.value.foreignId"
                placeholder="-- Bitte wählen --"
                [searchable]="true"
                [clearable]="true"
                [items]="resMenuOrders"
                bindLabel="name"
                bindValue="id"
                notFoundText="Kein Eintrag gefunden"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-md-12" *ngIf="questionFormGroup.value.questionType == 3">
            <div class="form-group">
              <label for="">{{ 'ResSettings.ResQuestions.FollowUpShift' | translate }}</label>
              <ng-select
                formControlName="foreignId"
                [(ngModel)]="questionFormGroup.value.foreignId"
                placeholder="-- Bitte wählen --"
                [searchable]="true"
                [clearable]="true"
                [items]="shifts"
                bindLabel="name"
                bindValue="id"
                notFoundText="Kein Eintrag gefunden"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-md-12" *ngIf="questionFormGroup.value.questionType == 4">
            <div class="form-group">
              <ng-select
                [items]="tags"
                bindLabel="label"
                [multiple]="true"
                placeholder="Select Tags"
                formControlName="tags"
              >
              </ng-select>
            </div>
          </div>
        </div>

        <div *ngIf="questionFormGroup.value.questionType !== 1">
          <div
            class="form-group"
            formArrayName="answers"
            style="margin-bottom: 35px;"
            *ngIf="
              questionFormGroup.value.questionType == 0 ||
              ((questionFormGroup.value.questionType == 2 || questionFormGroup.value.questionType == 3) &&
                questionFormGroup.value.foreignId)
            "
          >
            <label for=""> {{ 'ResSettings.ResQuestions.Answer' | translate }}</label>
            <small>{{ 'ResSettings.ResQuestions.GuestCanChosseTheAnswer' | translate }}</small>
            <ng-template ngFor let-answer [ngForOf]="formData.controls" let-i="index">
              <div fxFill fxLayout="row" class="answer-line">
                <div [formGroupName]="i" *ngIf="questionFormGroup.value.questionType == 2" fxFlex="92">
                  <div fxFlex="75">
                    <input
                      class="form-control"
                      placeholder="Geben Sie eine Antwortmöglichkeit ein..."
                      formControlName="answer"
                      type="text"
                      value=""
                    />
                  </div>
                  <div fxFlex="5"></div>
                  <div fxFlex="15">
                    <input class="form-control" placeholder="Qty." formControlName="foreignValue" />
                  </div>
                </div>

                <div [formGroupName]="i" *ngIf="questionFormGroup.value.questionType == 3" fxFlex="92">
                  <div fxFlex="60">
                    <input
                      class="form-control"
                      placeholder="Geben Sie eine Antwortmöglichkeit ein..."
                      formControlName="answer"
                      type="text"
                      value=""
                    />
                  </div>
                  <div fxFlex="5"></div>
                  <div fxFlex="30">
                    <ng-select
                      formControlName="foreignValue"
                      placeholder="-- Bitte wählen --"
                      [searchable]="true"
                      [clearable]="true"
                      [items]="foreignValuesFollowUpShift"
                      bindLabel="name"
                      bindValue="id"
                      notFoundText="Kein Eintrag gefunden"
                    ></ng-select>
                  </div>
                </div>

                <div [formGroupName]="i" *ngIf="questionFormGroup.value.questionType == 0" fxFlex="92">
                  <input
                    class="form-control"
                    placeholder="Geben Sie eine Antwortmöglichkeit ein..."
                    formControlName="answer"
                    type="text"
                    value=""
                  />
                </div>

                <div fxFlex="8" fxFlexAlign="center">
                  <button
                    class="btn btn-white"
                    type="button"
                    (click)="removeAnswer(i)"
                    [disabled]="i === 0"
                    [matTooltip]="
                      i === 0 ? 'Kann nicht entfernt werden. Mindestens 1 Antwort nötig.' : 'Antwort entfernen'
                    "
                    style="margin-left: 10px"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </ng-template>

            <div>
              <button class="btn btn-light" type="button" (click)="addNewAnswer()">
                <i class="fas fa-plus-circle fa-fw"></i>
                {{ 'ResSettings.ResQuestions.AddAnotherOptions' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="add()"
          *ngIf="!editQuestionData"
          type="submit"
          [promiseBtn]="addObservable"
        >
          {{ 'ResSettings.ResQuestions.CreateQuery' | translate }}
        </button>
        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="save()"
          *ngIf="editQuestionData"
          type="submit"
          [promiseBtn]="editObservable"
        >
          {{ 'ResSettings.ResQuestions.SaveQuery' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
