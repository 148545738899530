<div class="spinner-wrapper" *ngIf="loader">
  <mat-spinner></mat-spinner>
</div>
<breadcrumb [ngStyle]="{ display: loader ? 'none' : 'block' }"></breadcrumb>
<div class="content-box" [ngStyle]="{ visibility: loader ? 'hidden' : 'visible' }">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-box" *ngIf="!isTicketView">
        <div class="col-sm-12">
          <div class="onboarding-content">
            <div class="section-header">
              <h4 class="onboarding-title">
                <ng-template [ngIf]="editEventData">{{ 'Events&Tickets.EditEvent.Title' | translate }}</ng-template>
              </h4>
              <button class="btn btn-secondary" (click)="cancel()" *ngIf="editEventData">
                {{ 'Common.Abort' | translate }}
              </button>
            </div>
            <form [formGroup]="eventFormGroup">
              <div class="alert alert-danger borderless w-100" *ngIf="error">{{ error }}</div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="">{{ 'Events&Tickets.Common.EventName' | translate }}</label>
                    <input class="form-control" name="name" formControlName="name" type="text" value="" ngbAutofocus />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="">{{ 'Events&Tickets.Common.Address' | translate }}</label>
                    <input
                      matInput
                      class="form-control"
                      name="name"
                      (keypress)="checkForTyping()"
                      (keyup)="fetchLocation()"
                      formControlName="venue"
                      type="text"
                      aria-label="address"
                      [matAutocomplete]="auto"
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option
                        *ngFor="let location of locationArray"
                        (click)="selectLocation(location)"
                        [value]="option"
                      >
                        {{ location.address }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
              </div>
              <div class="col-sm-12" *ngIf="isMapShow">
                <div class="form-group">
                  <div class="map-wrapper mb-30">
                    <!-- section-title - start -->
                    <div class="section-title mb-30">
                      <label for=""><strong>Ort</strong></label>
                    </div>
                    <!-- section-title - end -->
                    <div id="google-map">
                      <div id="googleMaps" class="google-map-container">
                        <agm-map
                          style="height: 300px;"
                          [zoom]="currZoom"
                          [latitude]="latitude"
                          [longitude]="longitude"
                          (mapClick)="mapClicked($event)"
                        >
                          <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                        </agm-map>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="">{{ 'Events&Tickets.Common.Description' | translate }}</label>
                    <!-- <textarea
                      class="form-control"
                      name="description"
                      formControlName="description"
                      type="text"
                      value=""
                    ></textarea> -->
                    <ckeditor formControlName="description" [config]="{ versionCheck: false }"></ckeditor>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="">{{ 'Events&Tickets.Common.Tags' | translate }}</label>
                    <div class="tagInput-btn">
                      <input
                        class="form-control tag-input"
                        placeholder="{{ 'Events&Tickets.Common.AddTag' | translate }}"
                        type="text"
                        formControlName="tag"
                      />
                      <button class="btn btn-primary" (click)="addTag()" type="button">
                        <i class="fa fa-plus-circle fa-fw"></i> {{ 'Events&Tickets.Common.AddTag' | translate }}
                      </button>
                    </div>
                    <!-- <input class="form-control" name="endsAtHour" formControlName="endsAtHour" type="text" value="" ngbAutofocus /> -->
                  </div>
                  <div class="tags-list" [ngStyle]="{ 'margin-bottom': tags && tags.length ? '8px' : 0 }">
                    <div class="tag" *ngFor="let tag of tags; index as i">
                      {{ tag }}&nbsp;
                      <button class="btn btn-link" (click)="deleteTag(i)">
                        <i class="far fa-trash-alt cancel-icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="">{{ 'Events&Tickets.Common.Picture' | translate }}</label>
                    <div class="uploadImage">
                      <input
                        class="file-input form-control"
                        style="display:none"
                        type="file"
                        name="file"
                        #fileInput
                        (change)="onChange(fileInput.files[0])"
                      />
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <button type="button" class="btn btn-secondary browsebtn" (click)="fileInput.click()">
                            {{ 'Events&Tickets.Common.SelectPhoto' | translate }}
                          </button>
                        </div>
                        <input
                          class="form-control"
                          placeholder="{{ 'Events&Tickets.Common.NoPhoto' | translate }}"
                          [value]="fileName ? fileName : ''"
                          readonly
                        />
                        <div class="input-group-append" *ngIf="fileName">
                          <button
                            type="button"
                            class="btn btn-danger"
                            (click)="removeFile()"
                            matTooltip="Foto entfernen"
                          >
                            <i class="fas fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="preview-img" *ngIf="imageUrl">
                    <div class="uploaded-list">
                      <div class="uploaded-item">
                        <img class="img-fluid" [src]="imageUrl" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="action-block">
                <button
                  class="btn btn-primary"
                  [disabled]="!(eventFormGroup.valid && eventFormGroup.dirty)"
                  (click)="save()"
                  *ngIf="editEventData"
                >
                  {{ 'Common.Save' | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="element-box" *ngIf="!isTicketView">
        <div class="col-sm-12">
          <div class="section-header">
            <h4 class="onboarding-title">{{ 'Events&Tickets.EventDates.Title' | translate }}</h4>
            <button class="btn btn-primary" (click)="addEventDates(editEventData.event)">
              <i class="fa fa-plus-circle fa-fw"></i> {{ 'Events&Tickets.EventDates.AddAppointment' | translate }}
            </button>
          </div>
          <mat-tab-group #tabGroup animationDuration="0ms" class="custom-tabs">
            <mat-tab label="{{ 'Events&Tickets.EventDates.ViewActiveDate' | translate }}">
              <div class="element-box">
                <div class="onboarding-content">
                  <app-event-dates-list
                    [showDates]="showDates"
                    (loaderInfo)="loaderInfo($event)"
                  ></app-event-dates-list>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'Events&Tickets.EventDates.ViewArchiveDate' | translate }}">
              <div class="element-box">
                <div class="alert alert-info" *ngIf="!passedEventDates || !passedEventDates.length" style="margin:0">
                  {{ 'Events&Tickets.EventDates.NoAppointmentsFound' | translate }}
                </div>
                <div class="archive-dates ngx-datatable-template" *ngIf="passedEventDates && passedEventDates.length">
                  <div class="desktop-only">
                    <ngx-datatable
                      #PassDates
                      class="material striped expandable"
                      [rows]="passedEventDates"
                      [columnMode]="'force'"
                      [limit]="tableLimit"
                      [sorts]="[{ prop: 'lastVisit', dir: 'desc' }]"
                      [scrollbarH]="fasle"
                      [rowHeight]="'100%'"
                      [cssClasses]="{
                        pagerLeftArrow: 'fas fa-backward',
                        pagerRightArrow: 'fas fa-forward',
                        pagerPrevious: 'fas fa-step-backward',
                        pagerNext: 'fas fa-step-forward'
                      }"
                    >
                      <ngx-datatable-row-detail [rowHeight]="auto" #myDetailRow (toggle)="onDetailToggle($event)">
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                          <div class="ticket-list">
                            <ul *ngIf="row.ticketDates && row.ticketDates.length">
                              <li *ngFor="let ticket of row.ticketDates">
                                <div class="ticket-name">
                                  <label>{{ 'Events&Tickets.Common.TicketName' | translate }}</label>
                                  <span class="value">{{ ticket.tickets.name }}</span>
                                </div>
                                <div class="remaining-tickets">
                                  <label>{{ 'Events&Tickets.Tickets.RemainingTickets' | translate }}</label>
                                  <span class="value">{{ ticket.remainingTickets }}</span>
                                </div>
                                <div class="actions">
                                  <button
                                    class="btn btn-light pdf-btn"
                                    *ngIf="!ticket.loader"
                                    (click)="getPreview(ticket, row)"
                                  >
                                    <i class="fa fa-file-pdf"></i>
                                  </button>
                                  <button
                                    class="btn btn-light pdf-btn"
                                    style="pointer-events: none;"
                                    *ngIf="ticket.loader"
                                  >
                                    <i class="fa fa-spinner fa-spin"></i>
                                  </button>
                                  <!-- <div class="shift-options" ngbDropdown>
                                    <mat-icon
                                      svgIcon="settings"
                                      class="setting-btn"
                                      ngbDropdownToggle
                                      id="shiftDropdown"
                                    ></mat-icon>
                                    <div ngbDropdownMenu aria-labelledby="shiftDropdown">
                                      <button ngbDropdownItem (click)="removeDateTicket(ticket, row)">
                                        {{ 'EventList.clear' | translate }}
                                      </button>
                                    </div>
                                  </div> -->
                                </div>
                              </li>
                            </ul>
                            <ul *ngIf="row.ticketDates && !row.ticketDates.length">
                              <li>{{ 'Events&Tickets.Tickets.NoTicketsFound' | translate }}</li>
                            </ul>
                            <ul *ngIf="!row.ticketDates">
                              <li>{{ 'Events&Tickets.Tickets.NoTicketsFound' | translate }}</li>
                            </ul>
                          </div>
                        </ng-template>
                      </ngx-datatable-row-detail>

                      <ngx-datatable-column
                        [width]="30"
                        [resizeable]="false"
                        [sortable]="false"
                        [draggable]="false"
                        [canAutoResize]="false"
                      >
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                          <a
                            href="javascript:void(0)"
                            [class.datatable-icon-down]="expanded"
                            title="Expand/Collapse Row"
                            (click)="toggleExpandRow2(row)"
                          >
                            <i class="fas fa-chevron-right"></i>
                          </a>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="{{ 'Events&Tickets.Common.StartDate' | translate }}" [width]="200">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          {{ row.startsAtDay | date: 'dd.MM.yyyy' }}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="{{ 'Events&Tickets.Common.Time' | translate }}" [width]="150">
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                          {{ row.startsAtHour }}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="{{ 'Events&Tickets.Common.EndDate' | translate }}" [width]="200">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          {{ row.endsAtDay | date: 'dd.MM.yyyy' }}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="{{ 'Events&Tickets.Common.Time' | translate }}" [width]="150">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          {{ row.endsAtHour }}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="{{ 'Events&Tickets.Common.Actions' | translate }}" [width]="auto">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <button class="btn delete-btn" (click)="removeEventArchiveDate(row)" matTooltip="Löschen">
                            <i class="far fa-trash-alt"></i>
                          </button>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                  <div class="mobile-only">
                    <ngx-datatable
                      #PassDatesMobile
                      class="material striped expandable"
                      [rows]="passedEventDates"
                      [columnMode]="'force'"
                      [limit]="tableLimit"
                      [sorts]="[{ prop: 'lastVisit', dir: 'desc' }]"
                      [scrollbarH]="fasle"
                      [rowHeight]="'100%'"
                      [cssClasses]="{
                        pagerLeftArrow: 'fas fa-backward',
                        pagerRightArrow: 'fas fa-forward',
                        pagerPrevious: 'fas fa-step-backward',
                        pagerNext: 'fas fa-step-forward'
                      }"
                    >
                      <ngx-datatable-row-detail [rowHeight]="auto" #myDetailRow (toggle)="onDetailToggle($event)">
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                          <ul class="Moreinfo">
                            <li>
                              <label>{{ 'Events&Tickets.Common.Time' | translate }}</label>
                              <strong>{{ row.startsAtHour }}</strong>
                            </li>
                            <li>
                              <label>{{ 'Events&Tickets.Common.EndDate' | translate }}</label>
                              <strong class="value"> {{ row.endsAtDay | date: 'dd.MM.yyyy' }}</strong>
                            </li>
                            <li>
                              <label>{{ 'Events&Tickets.Common.Time' | translate }}</label>
                              <strong class="value"> {{ row.endsAtHour }}</strong>
                            </li>
                          </ul>
                          <div class="ticket-list">
                            <ul *ngIf="row.ticketDates && row.ticketDates.length">
                              <li *ngFor="let ticket of row.ticketDates">
                                <div class="ticket-name">
                                  <label>{{ 'Events&Tickets.Common.TicketName' | translate }}</label>
                                  <span class="value">{{ ticket.tickets.name }}</span>
                                </div>
                                <div class="remaining-tickets">
                                  <label>{{ 'Events&Tickets.Tickets.RemainingTickets' | translate }}</label>
                                  <span class="value">{{ ticket.remainingTickets }}</span>
                                </div>
                                <div class="actions">
                                  <button class="btn btn-light pdf-btn" (click)="getPreview(ticket, row)">
                                    <i class="fa fa-file-pdf"></i>
                                  </button>
                                  <!-- <div class="shift-options" ngbDropdown>
                                    <mat-icon
                                      svgIcon="settings"
                                      class="setting-btn"
                                      ngbDropdownToggle
                                      id="shiftDropdown"
                                    ></mat-icon>
                                    <div ngbDropdownMenu aria-labelledby="shiftDropdown">
                                      <button ngbDropdownItem (click)="removeDateTicket(ticket, row)">
                                        {{ 'EventList.clear' | translate }}
                                      </button>
                                    </div>
                                  </div> -->
                                </div>
                              </li>
                            </ul>
                            <ul *ngIf="row.ticketDates && !row.ticketDates.length">
                              <li>{{ 'Events&Tickets.Tickets.NoTicketsFound' | translate }}</li>
                            </ul>
                            <ul *ngIf="!row.ticketDates">
                              <li>{{ 'Events&Tickets.Tickets.NoTicketsFound' | translate }}</li>
                            </ul>
                          </div>
                        </ng-template>
                      </ngx-datatable-row-detail>

                      <ngx-datatable-column
                        [width]="30"
                        [resizeable]="false"
                        [sortable]="false"
                        [draggable]="false"
                        [canAutoResize]="false"
                      >
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                          <a
                            href="javascript:void(0)"
                            [class.datatable-icon-down]="expanded"
                            title="Expand/Collapse Row"
                            (click)="toggleExpandRow3(row)"
                          >
                            <i class="fas fa-chevron-right"></i>
                          </a>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="{{ 'Events&Tickets.Common.StartDate' | translate }}" [width]="200">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          {{ row.startsAtDay | date: 'dd.MM.yyyy' }}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="" [width]="auto">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <button class="btn delete-btn" (click)="removeEventArchiveDate(row)" matTooltip="Löschen">
                            <i class="far fa-trash-alt"></i>
                          </button>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

      <div class="element-box" *ngIf="!isTicketView">
        <div class="col-sm-12">
          <div class="onboarding-content">
            <div class="section-header">
              <h4 class="onboarding-title">{{ 'Events&Tickets.Tickets.Title' | translate }}</h4>
              <button class="btn btn-primary" (click)="addEventTicket(editEventData.event.id)">
                <i class="fa fa-plus-circle fa-fw"></i> {{ 'Events&Tickets.Tickets.AddTicket' | translate }}
              </button>
            </div>

            <div class="alert alert-info" *ngIf="!eventTickets || !eventTickets.length" style="margin:0">
              {{ 'Events&Tickets.Tickets.NoTicketsFound' | translate }}
            </div>
            <div class="ticket-list-table ngx-datatable-template " *ngIf="eventTickets && eventTickets.length">
              <div class="desktop-only">
                <ngx-datatable
                  #myTable
                  class="material striped"
                  [rows]="eventTickets"
                  [columnMode]="'force'"
                  [limit]="tableLimit"
                  [sorts]="[{ prop: 'lastVisit', dir: 'desc' }]"
                  [scrollbarH]="fasle"
                  [rowHeight]="'100%'"
                  [cssClasses]="{
                    pagerLeftArrow: 'fas fa-backward',
                    pagerRightArrow: 'fas fa-forward',
                    pagerPrevious: 'fas fa-step-backward',
                    pagerNext: 'fas fa-step-forward'
                  }"
                >
                  <ngx-datatable-column name="{{ 'Events&Tickets.Common.TicketName' | translate }}" [width]="50">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row.name }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="{{ 'Events&Tickets.Common.Description' | translate }}" [width]="200">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                      {{ row.description }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="{{ 'Events&Tickets.Tickets.AdditionalText' | translate }}" [width]="250">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row.additionalText }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="{{ 'Events&Tickets.Tickets.TicketCount' | translate }}" [width]="150">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row.amount }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="{{ 'Events&Tickets.Common.Actions' | translate }}" [width]="auto">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <button class="btn edit-btn" (click)="editTicket(row)" matTooltip="Bearbeiten">
                        <i class="fas fa-edit"></i>
                      </button>
                      <button class="btn delete-btn" (click)="removeEventTicket(row)" matTooltip="Löschen">
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
              <div class="mobile-only">
                <ngx-datatable
                  #myTableMobile
                  class="material striped expandable"
                  [rows]="eventTickets"
                  [columnMode]="'force'"
                  [limit]="tableLimit"
                  [sorts]="[{ prop: 'lastVisit', dir: 'desc' }]"
                  [scrollbarH]="fasle"
                  [rowHeight]="'100%'"
                  [cssClasses]="{
                    pagerLeftArrow: 'fas fa-backward',
                    pagerRightArrow: 'fas fa-forward',
                    pagerPrevious: 'fas fa-step-backward',
                    pagerNext: 'fas fa-step-forward'
                  }"
                >
                  <ngx-datatable-row-detail [rowHeight]="auto" #myDetailRow (toggle)="onDetailToggle($event)">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                      <ul class="Moreinfo">
                        <li>
                          <label>{{ 'Events&Tickets.Common.Description' | translate }}</label>
                          <strong>{{ row.description }}</strong>
                        </li>
                        <li>
                          <label>{{ 'Events&Tickets.Tickets.AdditionalText' | translate }}</label>
                          <strong class="value">{{ row.additionalText }}</strong>
                        </li>
                        <li>
                          <label>{{ 'Events&Tickets.Tickets.TicketCount' | translate }}</label>
                          <strong class="value">{{ row.amount }}</strong>
                        </li>
                      </ul>
                    </ng-template>
                  </ngx-datatable-row-detail>
                  <ngx-datatable-column
                    [width]="30"
                    [resizeable]="false"
                    [sortable]="false"
                    [draggable]="false"
                    [canAutoResize]="false"
                  >
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                      <a
                        href="javascript:void(0)"
                        [class.datatable-icon-down]="expanded"
                        title="Expand/Collapse Row"
                        (click)="toggleExpandRow(row)"
                      >
                        <i class="fas fa-chevron-right"></i>
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="{{ 'Events&Tickets.Common.TicketName' | translate }}" [width]="200">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row.name }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="{{ 'Events&Tickets.Common.Actions' | translate }}" [width]="auto">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <button class="btn edit-btn" (click)="editTicket(row)" matTooltip="Bearbeiten">
                        <i class="fas fa-edit"></i>
                      </button>
                      <button class="btn delete-btn" (click)="removeEventTicket(row)" matTooltip="Löschen">
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
          <!-- <div class="col-sm-12 form-group"> -->
          <button class="btn btn-danger mt-3" (click)="deleteEvent()" *ngIf="!isTicketView">
            {{ 'Events&Tickets.Common.DeleteEvent' | translate }}
          </button>
          <!-- </div> -->
        </div>
      </div>
      <div class="onboarding-content" *ngIf="isTicketView">
        <app-add-edit-ticket
          [ticketInfo]="ticketInfo"
          (addTicketResp)="addTicketResp($event)"
          (cancelAction)="cancelAction()"
          (updateTicketResp)="updateTicketResp($event)"
        ></app-add-edit-ticket>
      </div>
    </div>
  </div>
</div>
