<div class="modal-header">
  <h4 class="modal-title" *ngIf="!product">Artikel hinzufügen</h4>
  <h4 class="modal-title" *ngIf="product">Artikel bearbeiten</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="itemFormGroup">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <div class="section">
          <legend><span>Hauptdaten</span></legend>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="">Artikel-ID</label>
                <input class="form-control" formControlName="productId" type="text" autocomplete="nope" />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="">Kassen-ID</label>
                <input class="form-control" formControlName="cashregisterId" type="text" autocomplete="nope" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">Artikelname</label>
            <input class="form-control" formControlName="name" type="text" autocomplete="nope" />
          </div>
          <div class="form-group">
            <label for="">Beschreibung</label>
            <textarea class="form-control" rows="3" formControlName="description"></textarea>
          </div>
          <div class="form-group">
            <label for="">Typ</label>
            <ng-select [searchable]="false" [clearable]="false" formControlName="type">
              <ng-option value="Produkt">Produkt</ng-option>
              <ng-option value="Getränk">Getränk</ng-option>
              <ng-option value="Beilage">Beilage</ng-option>
              <ng-option value="Extra">Extra</ng-option>
              <ng-option value="Dip">Dip</ng-option>
              <ng-option value="Zutat">Zutat</ng-option>
              <ng-option value="Menü">Menü</ng-option>
            </ng-select>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>Einheits-Menge</label>
                <input class="form-control" formControlName="unitValue" type="text" autocomplete="nope" />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Einheit</label>
                <ng-select [searchable]="false" [clearable]="true" formControlName="unit">
                  <ng-option value="Stk">Stück (Stk)</ng-option>
                  <ng-option value="l">Liter (l)</ng-option>
                  <ng-option value="ml">Milliliter (ml)</ng-option>
                  <ng-option value="g">Gramm (g)</ng-option>
                  <ng-option value="kg">Kilogramm (kg)</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="">Foto</label>
                <div>
                  <input
                    class="file-input form-control"
                    style="display:none"
                    type="file"
                    name="photo"
                    #fileInput
                    (change)="onChange(fileInput.files[0])"
                  />
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button type="button" class="btn btn-secondary" (click)="fileInput.click()">
                        Foto auswählen
                      </button>
                    </div>
                    <input
                      class="form-control"
                      placeholder="Kein Foto ausgewählt"
                      [value]="fileName ? fileName : ''"
                      readonly
                    />
                    <div class="input-group-append" *ngIf="fileName">
                      <button type="button" class="btn btn-danger" (click)="removeFile()" matTooltip="Foto entfernen">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12" *ngIf="imageUrl">
              <div class="preview-img">
                <img [src]="imageUrl" />
              </div>
            </div>
          </div>

          <div class="section">
            <legend><span>Optionen</span></legend>
            <mat-slide-toggle formControlName="isVisible" class="detailed-toggle">
              Artikel ist sichtbar
              <div class="info">
                Soll der Artikel in der Speisekarte engezeigt werden?
              </div>
            </mat-slide-toggle>

            <mat-slide-toggle formControlName="isOrderable" class="detailed-toggle">
              Artikel ist Bestellbar
              <div class="info">
                Nicht bestellbare Artikel werden ausgegraut in der Speisekarte angezeigt und sind nicht auswählbar.
              </div>
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="section">
          <legend><span>Preise</span></legend>
          <div class="form-group">
            <label>Pfand</label>
            <div class="input-group">
              <input class="form-control" formControlName="deposit" type="text" autocomplete="nope" />
              <div class="input-group-append">
                <div class="input-group-text">€</div>
              </div>
            </div>
          </div>

          <div formArrayName="price" *ngFor="let price of itemFormGroup.get('price')['controls']; let i = index">
            <div class="form-group" [formGroupName]="i">
              <label>{{ priceCategories[i].name }}</label>
              <div class="input-group">
                <input class="form-control" formControlName="price" type="text" autocomplete="nope" />
                <div class="input-group-append">
                  <div class="input-group-text">€</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section" style="margin-bottom:20px">
          <legend><span>Sonstiges</span></legend>
          <div class="row">
            <div class="col-sm-6">
              <mat-checkbox formControlName="isVegetarian">
                Vegetarisch
                <mat-icon svgIcon="vegetarian" class="veg-icon"></mat-icon>
              </mat-checkbox>
            </div>
            <div class="col-sm-6">
              <mat-checkbox formControlName="isVegan">
                Vegan
                <mat-icon svgIcon="vegan" class="veg-icon"></mat-icon>
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="section" style="margin-bottom:20px">
          <legend><span>Tags</span></legend>
          <ng-select
            [items]="tags"
            [multiple]="true"
            bindLabel="name"
            placeholder="Nach Tags filtern..."
            [closeOnSelect]="false"
            formControlName="tags"
            appendTo="body"
            [clearable]="false"
            #select
          >
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value custom-select-tag" *ngFor="let item of items" [style.background-color]="item.color">
                <span class="ng-value-label">{{ item.name }}</span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="section">
          <legend><span>Allergene</span></legend>
          <div style="height:160px">
            <ng-scrollbar [trackX]="false" [compact]="true">
              <mat-checkbox
                [checked]="selectedAllergens.includes(allergen.identifier)"
                (change)="addAllergen(allergen.identifier, $event.checked)"
                *ngFor="let allergen of allAllergens"
                style="display: block"
              >
                <span class="badge badge-default">{{ allergen.identifier }}</span> {{ allergen.label }}
              </mat-checkbox>
            </ng-scrollbar>
          </div>
        </div>
        <div class="section" style="margin-top:20px">
          <legend><span>Zusatzstoffe</span></legend>
          <div style="height:160px">
            <ng-scrollbar [trackX]="false" [compact]="true">
              <mat-checkbox
                [checked]="selectedAdditives.includes(additive.identifier)"
                (change)="addAdditive(additive.identifier, $event.checked)"
                *ngFor="let additive of allAdditives"
                style="display: block"
              >
                <span class="badge badge-default">{{ additive.identifier }}</span> {{ additive.label }}
              </mat-checkbox>
            </ng-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-lg btn-block" (click)="save()" *ngIf="!product" [promiseBtn]="postObservable">
      Artikel hinzufügen
    </button>
    <button class="btn btn-primary btn-lg btn-block" (click)="save()" *ngIf="product" [promiseBtn]="postObservable">
      Artikel speichern
    </button>
  </div>
</form>
