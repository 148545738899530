<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      Update Guest Details
    </h4>
    <form [formGroup]="guestDataForm">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="email">
              Name
            </label>
            <input class="form-control" name="name" formControlName="name" type="text" value="" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="phone">
              First name
            </label>
            <input class="form-control" name="firstName" formControlName="firstName" type="text" value="" />
          </div>
        </div>
      </div>

      <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

      <div class="clearfix" style="text-align: center;margin-top: 15px;">
        <button class="btn btn-block btn-primary btn-lg" (click)="save()">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
