var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { VouchersService } from '../vouchers.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { TranslateService } from '@ngx-translate/core';
export class VouchersListComponent {
    constructor(voucherService, translate) {
        this.voucherService = voucherService;
        this.translate = translate;
        this.ngUnsubscribe = new Subject();
        this.transactions = [];
        this.vouchers = [];
        this.vouchersForExport = [];
        this.filter = {
            client: null,
            voucher: null
        };
        this.pageLimit = 50;
        this.csvOptions = {
            fieldSeparator: ';',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: false,
            headers: ['Gutschein', 'Wert', 'Restwert'],
            showTitle: false,
            title: '',
            useBom: false,
            removeNewLines: true,
            keys: ['code', 'value', 'residualValue']
        };
        this.now = new Date();
        this.message = {};
        this.voucherDetails = [];
        this.errorMsg = '';
        this.code = '';
        this.translate.onLangChange.subscribe((event) => {
            this.translate.setDefaultLang(event.lang);
            this.translator();
        });
    }
    ngOnInit() {
        // let code = window.location.href.split('/list/')[1] || null;
        // if (code) {
        //   this.filterVoucher({ code });
        // } else {
        //   this.getTransactions();
        // }
        // this.getVouchers();
        this.code = window.location.href.split('/list/')[1] || null;
        if (this.code) {
            this.searchTransactions(this.code);
        }
        this.translator();
    }
    ngAfterViewInit() {
        if (this.code) {
            this.voucherData.nativeElement.value = this.code;
        }
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    translator() {
        this.message = {
            emptyMessage: this.translate.instant('Vouchers.Coupons.NoTransactionWithThisCode')
        };
        this.csvOptions.headers = [
            this.translate.instant('Vouchers.Coupons.Voucher'),
            this.translate.instant('Vouchers.Coupons.Value'),
            this.translate.instant('Vouchers.Coupons.ResidualValue')
        ];
        const element = document.querySelector('angular2csv button');
        if (element) {
            element.textContent = this.translate.instant('Vouchers.Coupons.DownloadCSVFile');
        }
    }
    getVouchers() {
        this.voucherService.getVouchers().subscribe(res => {
            this.vouchersForExport = res || [];
            // this.vouchersForExport = res.map((item: any) => {
            //   if (item.status == 0) {
            //     item.status = 'aktiv';
            //   } else if (item.status == 1) {
            //     item.status = 'wartend';
            //   } else if (item.status == 2) {
            //     item.status = 'gesperrt';
            //   } else {
            //     item.status = '';
            //   }
            //   return item;
            // });
            console.log(41, this.vouchersForExport);
        });
    }
    // ngAfterViewInit(): void {
    //   fromEvent(this.voucherData.nativeElement, 'keyup')
    //     .pipe(
    //       filter(Boolean),
    //       debounceTime(500),
    //       distinctUntilChanged(),
    //       tap((event: any) => {
    //         if (this.voucherData.nativeElement.value && this.voucherData.nativeElement.value.trim().length >= 3) {
    //           this.getTransactions(null, this.voucherData.nativeElement.value);
    //         }
    //       })
    //     )
    //     .takeUntil(this.ngUnsubscribe)
    //     .subscribe();
    // }
    searchTransactions(code) {
        this.voucherDetails = [];
        this.errorMsg = '';
        if (code && code.trim()) {
            this.voucherService.getVoucherDetail(code).subscribe(res => {
                this.voucherDetails = res;
                if (this.voucherDetails.length > 0) {
                    this.errorMsg = this.errorMsg = this.translate.instant('Vouchers.Coupons.NoVoucherWithThisCode');
                }
            }, error => {
                this.errorMsg = this.errorMsg = this.translate.instant('Vouchers.Coupons.NoVoucherWithThisCode');
                console.log('err', error);
            });
            this.getTransactions(null, code);
        }
    }
    getTransactions(clientId = null, voucherCode = '') {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.voucherService
                .getTransactions(clientId, moment()
                .subtract(80, 'months')
                .format('YYYY-MM-DD'), moment()
                .add(1, 'months')
                .format('YYYY-MM-DD'), 0, '', voucherCode)
                .toPromise()
                .then((data) => {
                this.transactions = data;
                // console.log('data', this.transactions);
                // Reset voucher list
                this.vouchers = [];
                let voucherKeys = [];
                // Sum transaction values
                this.transactions.forEach((row) => {
                    if (row.voucher) {
                        if (!voucherKeys.includes(row.voucher.code)) {
                            voucherKeys.push(row.voucher.code);
                            this.vouchers.push({ code: row.voucher.code });
                        }
                    }
                    /*
                    if (row.transType === 'withdraw') {
                      if (row.transReferrer === 'gg') {
                      }
                      if (row.transReferrer === 'pos') {
                      }
                    }
          
                    if (row.transType === 'recharge' || row.transType === 'create') {
                      if (row.transReferrer === 'gg') {
                      }
                      if (row.transReferrer === 'pos') {
                      }
                    }
                    */
                });
                this.vouchers.sort(this.dynamicSort('code'));
                if (voucherCode && voucherCode != '') {
                    this.filter.voucher = voucherCode;
                    this.transactions = this.transactions.filter(function (el) {
                        if (el.voucher) {
                            return el.voucher.code.includes(voucherCode);
                        }
                        else {
                            return false;
                        }
                    });
                }
            });
        });
    }
    filterVoucher(voucher) {
        return __awaiter(this, void 0, void 0, function* () {
            this.transactions = [];
            if (voucher === undefined || !voucher) {
                voucher = { code: '' };
            }
            if (voucher.code) {
                this.filter.voucher = voucher.code;
                if (this.filter.client) {
                    this.getTransactions(this.filter.client.id, this.filter.voucher);
                }
                else {
                    this.getTransactions(null, this.filter.voucher);
                }
            }
            else {
                if (this.filter.client) {
                    this.getTransactions(this.filter.client.id);
                }
                else {
                    this.getTransactions();
                }
            }
        });
    }
    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
            return result * sortOrder;
        };
    }
    getArrayCopy(arr) {
        let vouchers = [];
        arr.forEach(row => {
            let tempRow = Object.assign({}, row);
            tempRow.value = `${tempRow.value}`.replace('.', ',');
            tempRow.residualValue = `${tempRow.residualValue}`.replace('.', ',');
            vouchers.push(tempRow);
        });
        return vouchers;
    }
    getStatusMessage(status) {
        if (status === 0) {
            return this.translate.instant('Vouchers.Coupons.Inactive');
        }
        else if (status === 1) {
            return 'OK';
        }
        else {
            return this.translate.instant('Vouchers.Coupons.Deactive');
        }
    }
}
