<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editSizeData">Neue Größe anlegen</ng-template>
      <ng-template [ngIf]="editSizeData">Größe ändern</ng-template>
    </h4>
    <form [formGroup]="sizeFormGroup">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              Interner Name<br />
              <small>Nicht für den Gast sichtbar</small>
            </label>
            <input
              class="form-control"
              placeholder=""
              name="internalName"
              formControlName="internalName"
              type="text"
              value=""
            />
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              Name<br />
              <small>Für den Gast sichtbar</small>
            </label>
            <input
              class="form-control"
              placeholder="z.B Groß"
              name="name"
              formControlName="name"
              type="text"
              value=""
              ngbAutofocus
            />
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <label for="">
              Preiskategorie<br />
              <small>Wählen Sie die Preiskategorie der Größe</small>
            </label>
            <ng-select
              appendTo="body"
              [searchable]="false"
              [clearable]="false"
              formControlName="priceCategory"
              placeholder="-- Preiskategorie auswählen --"
              [items]="priceCategories"
              bindLabel="name"
              bindValue="id"
            >
            </ng-select>
          </div>
        </div>

        <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="add()"
          *ngIf="!editSizeData"
          [promiseBtn]="addObservable"
        >
          Größe anlegen
        </button>
        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="save()"
          *ngIf="editSizeData"
          [promiseBtn]="editObservable"
        >
          Größe speichern
        </button>
        <button
          class="btn btn-link btn-sm delete-button"
          (click)="delete()"
          *ngIf="editSizeData"
          [promiseBtn]="deleteObservable"
        >
          Größe löschen
        </button>
      </div>
    </form>
  </div>
</div>
