<div class="content-box">
  <gridster [options]="options" class="dashboard-widgets">
    <gridster-item [item]="item" *ngFor="let item of dashboard">
      <ng-template [ngIf]="item.type === 'news'">
        <div class="news-articles">
          <h6 class="element-header">Wichtige Hinweis und neue Funktionen</h6>
          <ng-scrollbar>
            <div *ngFor="let news of newsArticles">
              <a href="{{ news.link }}" target="_blank">
                <h5>{{ news.title.rendered | striphtml }}</h5>
                <div class="date">{{ news.date | amDateFormat: 'ddd DD MMM YYYY' }}</div>

                <p>{{ news.content.rendered | striphtml | truncatetext: 150 }}</p>
              </a>
            </div>
          </ng-scrollbar>
        </div>
      </ng-template>
      <ng-template [ngIf]="item.type === 'button-reservation'">
        <div class="button-widget reservation">
          <button mat-button (click)="goTo('../reservation/book')">
            <div class="icon icon-reservation"></div>
            Reservierungsbuch
          </button>
        </div>
      </ng-template>
      <ng-template [ngIf]="item.type === 'button-orders'">
        <div class="button-widget ordering">
          <button mat-button (click)="goTo('../orders/overview')">
            <div class="icon icon-gpay"></div>
            Bestellungen
          </button>
        </div>
      </ng-template>

      <ng-template [ngIf]="item.type === 'button-feedbacks'">
        <div class="button-widget feedback" (click)="goTo('../feedback/overview')">
          <button mat-button>
            <div class="icon icon-feedback"></div>
            Feedback
          </button>
        </div>
      </ng-template>
    </gridster-item>
  </gridster>
</div>
