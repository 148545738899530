/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@iplab/ngx-color-picker/iplab-ngx-color-picker.ngfactory";
import * as i2 from "@iplab/ngx-color-picker";
import * as i3 from "@angular/common";
import * as i4 from "./color-picker-wrapper.component";
var styles_ColorPickerWrapperComponent = [];
var RenderType_ColorPickerWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ColorPickerWrapperComponent, data: {} });
export { RenderType_ColorPickerWrapperComponent as RenderType_ColorPickerWrapperComponent };
function View_ColorPickerWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "chrome-picker", [], null, null, null, i1.View_ChromePickerComponent_0, i1.RenderType_ChromePickerComponent)), i0.ɵdid(1, 770048, null, 0, i2.ChromePickerComponent, [i0.ChangeDetectorRef], { control: [0, "control"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colorControl; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ColorPickerWrapperComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "overlay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.overlayClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" "]))], null, null); }
export function View_ColorPickerWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorPickerWrapperComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorPickerWrapperComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isVisible; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ColorPickerWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "color-picker-wrapper", [], [[4, "background-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).showColorPicker($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ColorPickerWrapperComponent_0, RenderType_ColorPickerWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i4.ColorPickerWrapperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).background; _ck(_v, 0, 0, currVal_0); }); }
var ColorPickerWrapperComponentNgFactory = i0.ɵccf("color-picker-wrapper", i4.ColorPickerWrapperComponent, View_ColorPickerWrapperComponent_Host_0, { color: "color" }, { colorChange: "colorChange" }, []);
export { ColorPickerWrapperComponentNgFactory as ColorPickerWrapperComponentNgFactory };
