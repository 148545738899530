<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          {{ 'Vouchers.Transactions.TransactionsVouchers' | translate }}
        </h6>

        <div class="element-content">
          <div class="filter-box" fxLayout="row">
            <div class="form-group" fxFlex="50%" fxFlex.gt-sm="25%" style="padding-right: 10px;">
              <label for=""> {{ 'Common.DateRange' | translate }}</label>
              <div class="date-input">
                <input
                  class="form-control single-daterange"
                  placeholder="{{ 'Common.SelectDate' | translate }}"
                  (click)="picker.open()"
                  [satDatepicker]="picker"
                  [(ngModel)]="date"
                  (ngModelChange)="onDateRangesChange()"
                />
              </div>
              <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
            </div>
            <div class="form-group" fxFlex="50%" fxFlex.gt-sm="25%" style="padding-right: 10px;">
              <ng-template [ngIf]="settings && settings.poolSettings">
                <label for=""> {{ 'Vouchers.Transactions.FilterByOperation' | translate }}</label>
                <ng-select
                  [searchable]="true"
                  [clearable]="true"
                  [(ngModel)]="filter.client"
                  (change)="filterClient($event)"
                  placeholder="{{ 'Vouchers.VoucherPool.SelectCompany' | translate }}"
                  [class.filtered]="filter.client"
                  [items]="clients"
                  bindLabel="name"
                  notFoundText="{{ 'Vouchers.Transactions.NoEstablishmentFound' | translate }}"
                >
                </ng-select>
              </ng-template>
            </div>
            <div class="form-group" fxFlex="50%" fxFlex.gt-sm="25%" style="padding-left: 10px; padding-right: 10px;">
              <ng-template [ngIf]="settings && settings.poolSettings">
                <label for="">{{ 'Vouchers.VoucherPool.FilterByCouponCode' | translate }} </label>
                <ng-select
                  [searchable]="true"
                  [clearable]="true"
                  [(ngModel)]="filter.voucher"
                  (change)="filterVoucher($event)"
                  placeholder="{{ 'Vouchers.Common.CouponCode' | translate }}"
                  [class.filtered]="filter.voucher"
                  [items]="vouchers"
                  bindLabel="code"
                  notFoundText="{{ 'Vouchers.Common.NoVoucherFound' | translate }}"
                >
                </ng-select>
              </ng-template>
            </div>
            <div class="form-group" fxFlex="50%" fxFlex.gt-sm="25%" style="padding-right: 10px;">
              <label for="">{{ 'Common.Actions' | translate }}</label
              ><br />

              <button
                style="width: 100%; margin-bottom: 6px;"
                [disabled]="(!transactionsBalance.start && !transactionsBalance.end) || !transactions"
                class="btn btn-primary pdf"
                (click)="getVoucherTransactionsPdf()"
              >
                {{ 'Vouchers.Transactions.DownloadPDF' | translate }}
              </button>
              <br />
              <ng-container *ngIf="transactions">
                <angular2csv
                  *ngIf="transactions.length"
                  class="btn btn-primary"
                  [data]="csvTransactions"
                  filename="export_vouchers_{{ date.begin | date: 'yyyy_MM_dd' }}_to_{{
                    date.end | date: 'yyyy_MM_dd'
                  }}"
                  [options]="csvOptions"
                  style="width: 100%;"
                ></angular2csv>
              </ng-container>
            </div>
          </div>
          <div class="row" style="margin-bottom: 25px;">
            <div class="col-sm-6">
              <a class="element-box el-tablo" href="javascript:return;">
                <div class="label">{{ 'Vouchers.Transactions.TotalRedemptions' | translate }}</div>
                <div class="value">{{ sumRedeemed.total | currency: 'EUR' }}</div>

                <div class="row" style="margin-top: 15px;">
                  <div class="el-tablo smaller col-sm-4">
                    <div class="label">{{ 'Vouchers.Transactions.OfWhichPOS' | translate }} Gutscheine</div>
                    <div class="value">{{ sumRedeemed.pos | currency: 'EUR' }}</div>
                  </div>
                  <div class="el-tablo smaller col-sm-4">
                    <div class="label">{{ 'Vouchers.Transactions.OfWhichOnline' | translate }} Gutscheine</div>
                    <div class="value">{{ sumRedeemed.online | currency: 'EUR' }}</div>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-sm-6">
              <a class="element-box el-tablo" href="javascript:return;">
                <div class="label">{{ 'Vouchers.Transactions.TotalCharges' | translate }}</div>
                <div class="value">{{ sumRecharged.total | currency: 'EUR' }}</div>

                <div class="row" style="margin-top: 15px;">
                  <div class="el-tablo smaller col-sm-4">
                    <div class="label">{{ 'Vouchers.Transactions.OfWhichPOS' | translate }}</div>
                    <div class="value">{{ sumRecharged.pos | currency: 'EUR' }}</div>
                  </div>
                  <div class="el-tablo smaller col-sm-4">
                    <div class="label">{{ 'Vouchers.Transactions.OfWhichOnlineSold' | translate }}</div>
                    <div class="value">{{ sumRecharged.online | currency: 'EUR' }}</div>
                  </div>
                  <div class="el-tablo smaller col-sm-4">
                    <div class="label">{{ 'Vouchers.Transactions.CanceledVoucherTransactions' | translate }}</div>
                    <div class="value">{{ cancelVoucherAmount | currency: 'EUR' }}</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="element-box-tp">
          <div class="loading" *ngIf="!transactions">
            <i class="fa fa-spinner fa-spin"></i>
            {{ 'Vouchers.Transactions.TransactionsLoaded' | translate }}
          </div>

          <div class="table-responsive" *ngIf="allTransactions">
            <ngx-datatable
              #table
              class="bootstrap log-table expandable datatable-vouchers-log"
              [columnMode]="'flex'"
              [headerHeight]="35"
              [footerHeight]="50"
              [summaryRow]="false"
              [summaryHeight]="'auto'"
              [rowHeight]="'auto'"
              [scrollbarV]="false"
              [rows]="allTransactions"
              [limit]="pageLimit"
              [cssClasses]="{
                pagerLeftArrow: 'fas fa-backward',
                pagerRightArrow: 'fas fa-forward',
                pagerPrevious: 'fas fa-step-backward',
                pagerNext: 'fas fa-step-forward'
              }"
              [sorts]="[{ prop: 'createdAt', dir: 'desc' }]"
              [messages]="message"
            >
              <ngx-datatable-column [sortable]="true" [canAutoResize]="false" prop="createdAt" name="Datum">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.createdAt | dfnsFormat: 'DD.MM.YYYY HH:mm' }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [sortable]="true" [canAutoResize]="false" prop="code" name="Code">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{ 'Vouchers.Transactions.Code' | translate }}</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div style="display: flex;align-items: center;">
                    <code *ngIf="row.voucher">
                      <a routerLink="/client/{{ row.betriebId }}/vouchers/list/{{ row.voucher.code }}">{{
                        row.voucher.code
                      }}</a>
                    </code>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [sortable]="true" [canAutoResize]="false" prop="transType" name="Aktion">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{ 'Vouchers.Transactions.Action' | translate }}</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="action-text">
                    <div class="action-text-text">
                      <span *ngIf="row.transType === 'withdraw'">{{
                        'Vouchers.Transactions.VoucherRedeemed' | translate
                      }}</span>
                      <span *ngIf="row.transType === 'recharge'">{{
                        'Vouchers.Transactions.VoucherCharged' | translate
                      }}</span>
                      <span *ngIf="row.transType === 'create'">{{
                        'Vouchers.Transactions.VoucherCreated' | translate
                      }}</span>
                      <span *ngIf="row.transType === 'payout'">{{ 'Vouchers.Transactions.Payout' | translate }}</span>
                      <span *ngIf="row.transType === 'debit'"> {{ 'Vouchers.Transactions.Indent' | translate }}</span>

                      <span *ngIf="row.actionClient" title="row.actionClient.name">({{ row.actionClient.name }})</span>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="true"
                prop="client.name"
                name="Betrieb"
                *ngIf="settings && settings.poolSettings"
                [flexGrow]="1"
              >
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{ 'Vouchers.Transactions.Business' | translate }}</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <ng-template [ngIf]="row.client">
                    <div style="display:flex; align-items:center">
                      <a href="#" (click)="$event.preventDefault(); filterClient(row.client)">{{ row.client.name }}</a>
                    </div>
                  </ng-template>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [sortable]="true" prop="staffId" name="Staff">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.staff">
                    {{ row.staff.staffId }} <span *ngIf="row.staff.name"> ({{ row.staff.name }})</span></span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [sortable]="true"
                [flexGrow]="0"
                prop="transReferrer"
                name="Quelle"
                [canAutoResize]="false"
              >
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{ 'Vouchers.Transactions.Source' | translate }}</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <ng-template [ngIf]="row.transReferrer">
                    <div style="display:flex; align-items:center" [title]="row.transReferrer">
                      <ng-template [ngIf]="row.transReferrer === 'gg'">
                        Online
                      </ng-template>
                      <ng-template [ngIf]="row.transReferrer === 'business'">
                        BackOffice
                      </ng-template>
                      <ng-template [ngIf]="row.transReferrer === 'app'">
                        ChefApp
                      </ng-template>
                      <ng-template [ngIf]="row.transReferrer === 'admin'">
                        System
                      </ng-template>
                      <ng-template
                        [ngIf]="
                          row.transReferrer !== 'gg' &&
                          row.transReferrer !== 'business' &&
                          row.transReferrer !== 'app' &&
                          row.transReferrer !== 'admin'
                        "
                      >
                        POS
                      </ng-template>
                    </div>
                  </ng-template>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [sortable]="true" prop="ip" name="IP" [canAutoResize]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>IP</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.ip }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [sortable]="true" prop="transValue" name="Betrag" cellClass="justify-right">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <div style="text-align: right;">{{ 'Vouchers.Transactions.Amount' | translate }}</div>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div style="display: flex;align-items: center;">
                    <div>
                      <ng-template [ngIf]="row.transType === 'withdraw' || row.transType === 'debit'">-</ng-template>
                      {{ row.transValue | currency: 'EUR' }}
                      <div style="font-size:11px;color:red;" *ngIf="row.canceledAt">
                        {{ 'Common.Canceled' | translate }} {{ row.canceledAt | amDateFormat: 'DD.MM.YYYY HH:mm' }}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [sortable]="false" prop="action" name="action" [canAutoResize]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span></span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button
                    style="padding:5px;"
                    *ngIf="row.status != 2 && canCancelTransaction(row.createdAt)"
                    (click)="cancelVoucherTransaction(row.id)"
                    class="btn btn-danger"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </ng-template>
              </ngx-datatable-column>

              <!-- <ngx-datatable-column
                [sortable]="true"
                
                [canAutoResize]="false"
                prop="balance"
                name="Kundenguthaben"
                cellClass="justify-right"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div style="display: flex;align-items: center;">
                    <div [class.text-success]="balance > 0" [class.text-danger]="balance < 0">
                      {{ row.balance | currency: 'EUR' }}
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column> -->

              <!-- <ngx-datatable-column
                [sortable]="true"
                
                [canAutoResize]="false"
                prop="ledger"
                name="Kontostand"
                cellClass="justify-right"
              >
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{ 'Vouchers.Transactions.BankBalance' | translate }}</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div style="display: flex;align-items: center;">
                    <div [class.text-success]="ledger > 0" [class.text-danger]="ledger < 0">
                      <ng-container *ngIf="settings.payoutVersion === 2">
                        {{ row.balance | currency: 'EUR' }}
                      </ng-container>
                      <ng-container *ngIf="settings.payoutVersion !== 2">
                        {{ row.ledger | currency: 'EUR' }}
                      </ng-container>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column> -->

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count">
                    <span *ngIf="selectedMessage"> {{ selectedCount.toLocaleString() }} {{ selectedMessage }} / </span>
                    {{ 'Vouchers.Transactions.Show' | translate }}
                    <strong>{{ pageSize < rowCount.toLocaleString() ? pageSize : rowCount.toLocaleString() }}</strong>
                    {{ 'Vouchers.Transactions.Of' | translate }} <strong>{{ rowCount.toLocaleString() }}</strong>
                    {{ 'Vouchers.Transactions.Transactions' | translate }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-backward'"
                    [pagerRightArrowIcon]="'fas fa-forward'"
                    [pagerPreviousIcon]="'fas fa-step-backward'"
                    [pagerNextIcon]="'fas fa-step-forward'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="table.onFooterPage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
