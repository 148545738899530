<div [ngStyle]="{ display: loader ? 'block' : 'none' }" style="height: 100%;">
  <div style="position: relative; left: 25%; top: 48%; width: 50%;">
    Please Wait...
    <mat-progress-bar mode="indeterminate" [value]="10"></mat-progress-bar>
  </div>
</div>
<breadcrumb [ngStyle]="{ display: loader ? 'none' : 'block' }"></breadcrumb>
<div class="content-box" [ngStyle]="{ visibility: loader ? 'hidden' : 'visible' }">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          {{ 'Events&Tickets.AllEvents.EventListTitle' | translate }}
        </h6>

        <mat-tab-group animationDuration="0ms">
          <mat-tab label="{{ 'Events&Tickets.AllEvents.EventTab1' | translate }}">
            <div class="element-box" *ngIf="!isActiveEdit">
              <div class="section-header d-flex justify-content-between align-items-center flex-wrap">
                <h5 class="form-header mb-2 pt-0">{{ 'Events&Tickets.AllEvents.EventTab1' | translate }}</h5>
                <button class="btn btn-primary mb-0" (click)="addEvent()">
                  <i class="fa fa-plus-circle fa-fw"></i> {{ 'Events&Tickets.AllEvents.RegisterEvent' | translate }}
                </button>
              </div>

              <div class="alert alert-info" *ngIf="!activeEventList || !activeEventList.length" style="margin:0">
                {{ 'Events&Tickets.AllEvents.NoEvent' | translate }}
              </div>
              <div class="table-responsive shifts" *ngIf="activeEventList && activeEventList.length">
                <ul class="event-list">
                  <li class="event-item" *ngFor="let event of activeEventList; index as i">
                    <div class="event-image" *ngIf="event.photoFile">
                      <img src="http://cdn.gastroguide.de/{{ event.photoFile }}" height="100" width="100" />
                    </div>
                    <div class="event-image" *ngIf="!event.photoFile">
                      <img src="../../../assets/img/icons8-event-64.png" height="64" width="64" />
                    </div>
                    <div class="event-info">
                      <div class="info">
                        <h4 (click)="editEvent(event.id, 'active')">{{ event.name }}</h4>
                        <p class="event-venue">{{ event.venue }}</p>
                      </div>
                      <div class="event-actions">
                        <button
                          class="btn btn-light edit-btn"
                          (click)="editEvent(event.id, 'active')"
                          matTooltip="Bearbeiten"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                        <button class="btn btn-danger delete-btn" (click)="removeEvent(event.id)" matTooltip="Löschen">
                          <i class="far fa-trash-alt"></i>
                        </button>
                        <button
                          class="btn btn-light"
                          (click)="archiveEvent(event, 1)"
                          matTooltip="{{ 'Events&Tickets.AllEvents.Archive' | translate }}"
                        >
                          <i class="fas fa-archive"></i>
                        </button>
                        <br />
                        <mat-slide-toggle
                          class="visible-btn"
                          name="isActive"
                          (change)="changedSettings(event.id, 'isActive', event?.isActive)"
                          [(ngModel)]="event.isActive"
                        >
                          {{ 'Events&Tickets.AllEvents.Visible' | translate }}
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="{{ 'Events&Tickets.AllEvents.EventTab2' | translate }}">
            <div class="element-box" *ngIf="!isArchiveEdit">
              <div class="section-header">
                <h5 class="form-header">{{ 'Events&Tickets.AllEvents.EventTab2' | translate }}</h5>
              </div>
              <div class="alert alert-info" *ngIf="!archiveEventList || !archiveEventList.length" style="margin:0">
                {{ 'Events&Tickets.AllEvents.NoEvent' | translate }}
              </div>
              <div class="table-responsive shifts" *ngIf="archiveEventList && archiveEventList.length">
                <ul class="event-list">
                  <li class="event-item" *ngFor="let event of archiveEventList; index as i">
                    <div class="event-image" *ngIf="event.photoFile">
                      <img src="http://cdn.gastroguide.de/{{ event.photoFile }}" height="100" width="100" />
                    </div>
                    <div class="event-image" *ngIf="!event.photoFile">
                      <img src="../../../assets/img/icons8-event-64.png" height="64" width="64" />
                    </div>
                    <div class="event-info">
                      <div class="info">
                        <h4 (click)="editEvent(event.id, 'archive')">{{ event.name }}</h4>
                        <p class="event-venue">{{ event.venue }}</p>
                      </div>
                      <div class="event-actions">
                        <button
                          class="btn btn-light edit-btn"
                          (click)="editEvent(event.id, 'archive')"
                          matTooltip="Bearbeiten"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                        <button class="btn btn-danger delete-btn" (click)="removeEvent(event.id)" matTooltip="Löschen">
                          <i class="far fa-trash-alt"></i>
                        </button>
                        <button
                          class="btn btn-light"
                          (click)="archiveEvent(event, 0)"
                          matTooltip="{{ 'Events&Tickets.AllEvents.UnArchive' | translate }}"
                        >
                          <i class="fas fa-undo"></i>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
