<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <div class="pipelines-w">
          <div class="row">
            <div class="col-lg-4 col-xxl-4">
              <div class="pipeline white lined-warning">
                <div class="pipeline-header">
                  <h5 class="pipeline-name">Kategorien</h5>
                  <div class="pipeline-settings">
                    <button class="btn btn-white btn-sm" (click)="addCategory()" matTooltip="Kategorie hinzufügen">
                      <mat-icon svgIcon="plus"></mat-icon>
                    </button>
                  </div>
                </div>

                <ng-scrollbar
                  class="virtual-scrollbar-lists"
                  [trackX]="false"
                  [shown]="'hover'"
                  [compact]="true"
                  thumbClass="custom-scrollbar"
                >
                  <div class="pipeline-body" cdkDropList (cdkDropListDropped)="dropCategory($event)">
                    <div class="empty-list" *ngIf="!categories">
                      <mat-spinner [color]="light" class="loading-spinner" diameter="40"></mat-spinner>
                      <span>Kategorien werden geladen</span>
                    </div>
                    <div class="empty-list" *ngIf="categories && !categories.length">
                      <i class="fas fa-exclamation-triangle"></i>
                      <span>Keine Kategorien gefunden</span>
                      <a [routerLink]="" (click)="addCategory()">
                        Fügen Sie jetzt ihre erste Kategorie hinzu.
                      </a>
                    </div>

                    <mat-expansion-panel
                      class="category-panel"
                      [expanded]="false"
                      *ngFor="let category of categories; index as catIndex"
                      cdkDrag
                      [cdkDragStartDelay]="isTouchDevice ? 600 : 0"
                      (afterExpand)="expandCategory(category)"
                      (afterCollapse)="collapseCategory(category)"
                    >
                      <mat-expansion-panel-header
                        cdkDropList
                        (cdkDropListDropped)="itemDropped($event)"
                        id="cat-header-{{ category.id }}"
                      >
                        <mat-panel-title>
                          <div>
                            <button
                              class="btn btn-white btn-sm"
                              (click)="$event.stopPropagation()"
                              matTooltip="Klicken und verschieben um Kategorie zu sortieren"
                              cdkDragHandle
                            >
                              <mat-icon svgIcon="move"></mat-icon>
                            </button>
                            {{ category.name }} ({{ category.items.length }})
                          </div>
                          <div>
                            <button
                              *ngIf="category.isExpanded"
                              class="btn btn-white btn-sm expand-all-button"
                              (click)="$event.stopPropagation(); toggleExpandItems(category)"
                              [matTooltip]="category.expanded ? 'Alle einklappen' : 'Alle ausklappen'"
                            >
                              <i class="fas fa-expand-alt" *ngIf="!category.expanded"></i>
                              <i class="fas fa-compress-alt" *ngIf="category.expanded"></i>
                            </button>
                            <button
                              class="btn btn-white btn-sm edit-button"
                              (click)="$event.stopPropagation(); editCategory(category)"
                              matTooltip="Kategorie bearbeiten"
                            >
                              <mat-icon svgIcon="edit"></mat-icon>
                            </button>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <div
                        class="drop-area"
                        cdkDropList
                        (cdkDropListDropped)="itemDropped($event)"
                        id="cat-{{ category.id }}"
                        (cdkDropListEntered)="categoryDropEntered($event, category)"
                      >
                        <div class="drop-placeholder" *ngIf="!category.items.length">
                          <img src="/assets/img/droparea.png" />
                          <span>Artikel hier reinschieben</span>
                        </div>
                        <div *ngIf="category.items">
                          <div
                            *ngFor="let item of category.items; let fieldIndex = index; trackBy: trackByIndex"
                            class="pipeline-drag-item"
                            cdkDrag
                            [cdkDragStartDelay]="isTouchDevice ? 600 : 0"
                          >
                            <app-menucard-item
                              [data]="item"
                              [availableSizes]="sizes"
                              [categoryId]="category.id"
                              showDeleteButton="true"
                              showChoices="true"
                              showSizes="true"
                              [expanded]="category.expanded"
                              (deleteItem)="deleteCategoryItem($event)"
                            ></app-menucard-item>
                          </div>
                        </div>
                      </div>
                      <div *cdkDragPlaceholder class="category-placeholder"></div>
                    </mat-expansion-panel>
                  </div>
                </ng-scrollbar>
              </div>
            </div>

            <div class="col-lg-4 col-xxl-4">
              <div class="pipeline white lined-purple">
                <div class="pipeline-header">
                  <h5 class="pipeline-name">Auswahlfenster</h5>
                  <div class="pipeline-settings">
                    <button class="btn btn-white btn-sm" (click)="addChoice()" matTooltip="Auswahlfenster hinzufügen">
                      <mat-icon svgIcon="plus"></mat-icon>
                    </button>
                  </div>
                </div>
                <ng-scrollbar
                  class="virtual-scrollbar-lists"
                  [trackX]="false"
                  [shown]="'hover'"
                  [compact]="true"
                  thumbClass="custom-scrollbar"
                >
                  <div
                    class="pipeline-body"
                    cdkDropList
                    cdkDropListSortingDisabled
                    (cdkDropListDropped)="dropChoice($event)"
                    [cdkDropListConnectedTo]="getItemChoicesList()"
                    [cdkDropListData]="choices"
                  >
                    <div class="empty-list" *ngIf="!choices">
                      <mat-spinner [color]="light" class="loading-spinner" diameter="40"></mat-spinner>
                      <span>Auswahlfenster werden geladen</span>
                    </div>
                    <div class="empty-list" *ngIf="choices && !choices.length">
                      <i class="fas fa-exclamation-triangle"></i>
                      <span>Keine Auswahlfenster gefunden</span>
                      <a [routerLink]="" (click)="addChoice()">
                        Fügen Sie jetzt ihr erstes Auswahlfenster hinzu.
                      </a>
                    </div>
                    <mat-expansion-panel
                      class="category-panel"
                      [expanded]="false"
                      *ngFor="let choice of choices; index as i"
                      cdkDrag
                      [cdkDragStartDelay]="isTouchDevice ? 600 : 0"
                      [cdkDragData]="choice"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div>
                            <button
                              class="btn btn-white btn-sm"
                              (click)="$event.stopPropagation()"
                              matTooltip="Klicken und in einen Artikel ziehen"
                              cdkDragHandle
                            >
                              <mat-icon svgIcon="move"></mat-icon>
                            </button>
                            {{ choice.internalName ? choice.internalName : choice.name }} ({{ choice.items.length }})
                          </div>
                          <button
                            class="btn btn-white btn-sm edit-button"
                            (click)="$event.stopPropagation(); editChoice(choice)"
                            matTooltip="Auswahlfenster bearbeiten"
                          >
                            <mat-icon svgIcon="edit"></mat-icon>
                          </button>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div
                        class="drop-area"
                        cdkDropList
                        (cdkDropListDropped)="itemChoiceDropped($event)"
                        id="choice-{{ i }}"
                        [cdkDropListData]="choice.items"
                      >
                        <div class="drop-placeholder" *ngIf="!choice.items.length">
                          <img src="/assets/img/droparea.png" />
                          <span>Artikel hier reinschieben</span>
                        </div>
                        <div>
                          <div
                            *ngFor="let item of choice.items"
                            class="pipeline-drag-item"
                            cdkDrag
                            [cdkDragStartDelay]="isTouchDevice ? 600 : 0"
                            [cdkDragData]="item"
                          >
                            <app-menucard-item
                              [data]="item"
                              showDeleteButton="true"
                              (deleteItem)="deleteChoiceItem(choice, $event)"
                            ></app-menucard-item>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </div>
                </ng-scrollbar>
              </div>
            </div>

            <div class="col-lg-4 col-xxl-4">
              <div class="pipeline white lined-danger article-list">
                <div class="pipeline-header" style="margin-bottom: 10px;">
                  <h5 class="pipeline-name">Alle Artikel</h5>
                  <div class="pipeline-settings">
                    <button class="btn btn-white btn-sm" (click)="addArticle()" matTooltip="Artikel hinzufügen">
                      <mat-icon svgIcon="plus"></mat-icon>
                    </button>
                  </div>
                </div>
                <div class="article-filter">
                  <div class="row">
                    <div class="col-sm-6">
                      <input class="form-control" placeholder="Artikelname suchen..." (keyup)="filterInput($event)" />
                    </div>
                    <div class="col-sm-6">
                      <ng-select
                        placeholder="Sortieren nach..."
                        (change)="changeOrder($event)"
                        [(ngModel)]="articlesOrder"
                        [clearable]="false"
                        [searchable]="false"
                      >
                        <ng-option value="asc">
                          <i class="fas fa-sort-alpha-down fa-fw"></i>
                          A->Z
                        </ng-option>
                        <ng-option value="desc">
                          <i class="fas fa-sort-alpha-up fa-fw"></i>
                          Z->A
                        </ng-option>
                        <ng-option value="custom">
                          <i class="fas fa-bars fa-fw"></i>
                          Eigene Sortierung
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <ng-select placeholder="Nach Typ filtern" (change)="filterType($event)">
                        <ng-option value="Produkt">Produkt</ng-option>
                        <ng-option value="Getränk">Getränk</ng-option>
                        <ng-option value="Extra">Extra</ng-option>
                        <ng-option value="Beilage">Beilage</ng-option>
                        <ng-option value="Dip">Dip</ng-option>
                        <ng-option value="Zutat">Zutat</ng-option>
                        <ng-option value="Menü">Menü</ng-option>
                      </ng-select>
                    </div>
                    <div class="col-sm-6">
                      <ng-select
                        [items]="tags"
                        [addTag]="false"
                        [hideSelected]="true"
                        multiple="true"
                        bindLabel="name"
                        placeholder="Nach Tags filtern..."
                        (change)="filterTags($event)"
                      >
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label">
                            <i class="fas fa-circle" [style.color]="item.color"></i> {{ item.name }}
                          </span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>

                        <ng-template ng-option-tmp let-item="item">
                          <i class="fas fa-circle" [style.color]="item.color"></i> {{ item.name }}
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                </div>
                <ng-scrollbar
                  class="virtual-scrollbar-lists"
                  [trackX]="false"
                  [shown]="'hover'"
                  [compact]="true"
                  thumbClass="custom-scrollbar"
                >
                  <div class="pipeline-body">
                    <div class="empty-list" *ngIf="!menucardArticles">
                      <mat-spinner [color]="light" class="loading-spinner" diameter="40"></mat-spinner>
                      <span>Artikel werden geladen</span>
                    </div>
                    <div class="empty-list" *ngIf="menucardArticles && !menucardArticles.length">
                      <i class="fas fa-exclamation-triangle"></i>
                      <span>Keine Artikel gefunden</span>
                      <a [routerLink]="" (click)="addArticle()">
                        Fügen Sie einen neuen Artikel hinzu
                      </a>
                    </div>
                    <div
                      cdkDropList
                      cdkDropListSortingDisabled
                      [cdkDropListData]="menucardArticles"
                      [cdkDropListConnectedTo]="getConnectedList()"
                    >
                      <div
                        *ngFor="let item of menucardArticles"
                        class="pipeline-drag-item"
                        cdkDrag
                        [cdkDragStartDelay]="isTouchDevice ? 600 : 0"
                        [cdkDragData]="item"
                      >
                        <app-menucard-item [data]="item"></app-menucard-item>
                      </div>
                    </div>
                  </div>
                </ng-scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
