import { NgModule } from '@angular/core';
import { RecaptchaV3Component } from './recaptcha-v3.component';
import { RecaptchaV3Module as GoogleRecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [RecaptchaV3Component],
  imports: [CommonModule, GoogleRecaptchaV3Module], // Import the Google reCAPTCHA module
  exports: [RecaptchaV3Component], // Export component for usage in other modules
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcxfEEqAAAAADpYQbeuSja4usSAg5R5BsdNarFQ' } // Provide your Google reCAPTCHA site key here
  ]
})
export class RecaptchaV3CustomModule {}
