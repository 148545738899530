var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { I18nService, AuthenticationService, untilDestroyed } from '@app/core';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { NgxPermissionsService } from 'ngx-permissions';
import { InitPermissionsService } from '@app/core/init-permissions.service';
var CryptoJS = require('crypto-js');
export class LoginComponent {
    constructor(router, route, formBuilder, i18nService, authenticationService, permissionService, rs, translate) {
        this.router = router;
        this.route = route;
        this.formBuilder = formBuilder;
        this.i18nService = i18nService;
        this.authenticationService = authenticationService;
        this.permissionService = permissionService;
        this.rs = rs;
        this.translate = translate;
        this.isLoading = false;
        this.ngUnsubscribe = new Subject();
        this.createForm();
    }
    ngOnInit() { }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    login() {
        this.isLoading = true;
        const login$ = this.authenticationService.login(this.loginForm.value);
        login$
            .pipe(finalize(() => {
            this.loginForm.markAsPristine();
            this.isLoading = false;
        }), untilDestroyed(this))
            .takeUntil(this.ngUnsubscribe)
            .subscribe(credentials => {
            const snapshot = this.route.snapshot.queryParams.redirect;
            let clientId = null;
            if (snapshot) {
                const match = snapshot.match(/^\/client\/(\d+)/);
                if (match) {
                    clientId = match[1];
                }
            }
            let { email, id } = JSON.parse(localStorage.getItem('credentials'));
            // let key = 'qBkTSzSFwLTD-lmoh8DV4b1SwBeu_kUSbdvCD_sv'; // test
            let key = 'TZCUeUbiUDqdTTrdeVlqxtZ6ieSfVLxmPtKYzNH4'; // live
            // '1ca12d7c0629194a9f9d0dbbc957709dd3aed385925b077e726813f0b452de6a38256abd1116138d21754cfb33964b6b1aaa375b74d3580fcda916898f553c92';
            id = `${id}`;
            let signedString = CryptoJS.HmacSHA256(id, key);
            let resultSign = CryptoJS.enc.Hex.stringify(signedString);
            this.authenticationService.getUserData().subscribe((user) => {
                console.log(85, 'testing', user, credentials, email, id, resultSign);
                window.Intercom('boot', {
                    app_id: window.location.href.includes('kunden.gastro.digital') ? 'u57ft9ay' : 'u57ft9ay',
                    email: email,
                    user_id: id,
                    user_hash: resultSign,
                    name: user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : 'Besucher',
                    company: {
                        id: id + 10000000,
                        name: credentials.username || 'Firma'
                    }
                });
            }, error => {
                console.error(91, error);
            });
            this.permissionService.load(clientId).then((permissions) => __awaiter(this, void 0, void 0, function* () {
                yield this.rs.addPermission(permissions);
                this.router.navigate([snapshot || '/select'], { replaceUrl: true });
            }));
        }, error => {
            if (error && error.status != 0) {
                this.error = this.translate.instant('Login.Error');
            }
            else {
                this.error = 'Something went wrong! Please contact administrator.';
            }
        });
    }
    setLanguage(language) {
        this.i18nService.language = language;
    }
    resetPassword() {
        this.router.navigate(['/login/reset-password'], { replaceUrl: true });
    }
    get currentLanguage() {
        return this.i18nService.language;
    }
    get languages() {
        return this.i18nService.supportedLanguages;
    }
    createForm() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            remember: true
        });
    }
}
