<div class="row" *ngIf="reservationBookSettings?.showTablePlan">
  <div class="col-md-12">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="changeRoom($event)" #roomTabsGroup>
      <mat-tab *ngFor="let room of rooms">
        <ng-template mat-tab-label>
          <i class="fas fa-lock fa-fw" *ngIf="!room.isActive" style="padding-right: 15px;"></i> {{ room.name }}
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<div
  *ngIf="reservationBookSettings?.showTablePlan && activeRoom && activeRoom.tables.length > 0; else noTables"
  class="row"
>
  <div class="col-md-12">
    <div class="row" *ngIf="isEditing">
      <div class="col-md-2">
        <div class="text-center text-color"><small>Zoom</small></div>
        <div class="d-flex justify-content-between mx-2">
          <button class="btn btn-primary" (click)="zoomInOrOut('out')">-</button>
          <span>
            <div>
              <small class="text-center text-color">{{ zoomValue }}%</small>
            </div>
          </span>
          <button class="btn btn-primary" (click)="zoomInOrOut('in')">+</button>
        </div>
      </div>
      <div class="col-md-8">
        <div class="select-slider">
          <ng5-slider
            (userChange)="setSizeOfTables()"
            (userChangeEnd)="saveCurrentTableFactorSize()"
            class="slider"
            [(value)]="sizeValue"
            [options]="sizeOptions"
          >
          </ng5-slider>
        </div>
      </div>
      <!-- <div class="col-md-5">
        <div class="select-slider">
          <ng5-slider
            (userChange)="setZoomValueOfTheRoom()"
            (userChangeEnd)="saveCurrentZoomValue()"
            class="slider"
            [(value)]="zoomValue"
            [options]="zoomOptions"
          ></ng5-slider>
        </div>
      </div> -->
      <div class="col-md-2 mt-1">
        <div class="row">
          <div class="col-md-12 text-center">
            <button *ngIf="!interirorDrawerIsOpened" class="btn btn-primary" (click)="onToggleChange()">
              <i class="fa fa-arrow-left" aria-hidden="true"></i> {{ 'tablePlan.show' | translate }}
            </button>
            <button *ngIf="interirorDrawerIsOpened" class="btn btn-primary" (click)="onToggleChange()">
              {{ 'tablePlan.hide' | translate }} <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center text-color">{{ 'tablePlan.showHideInteriorElements' | translate }}</div>
        </div>
      </div>
    </div>
    <mat-drawer-container autosize>
      <mat-drawer #sidenav class="table-plan-nav-settings" mode="side" position="end">
        <mat-tab-group class="nav-tab-group">
          <mat-tab class="nav-mat-tab">
            <ng-template mat-tab-label>
              <i class="fas fa-table"></i>
            </ng-template>
            <div *ngIf="isEditing">
              <!--sidenav tables-->
              <h4 class="text-center green">{{ 'tablePlan.tables' | translate }}</h4>
              <div class="row" *ngIf="tablesThatAreNotInTheRoom.length > 0; else allTablesAreUsed">
                <div class="col-md-12">
                  <div cdkDropList class="room-list" (cdkDropListDropped)="drop($event)">
                    <div
                      class="list-item single-table"
                      *ngFor="let table of tablesThatAreNotInTheRoom"
                      (click)="insertTableInRoom(table)"
                      cdkDrag
                    >
                      <i class="fas fa-arrow-circle-left"></i> {{ table?.name }}
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #allTablesAreUsed>
                <div class="col-md-12">
                  <h6 class="gray">{{ 'tablePlan.allTablesAreUsed' | translate }}</h6>
                </div>
              </ng-template>
              <!--end of sidenav tables-->
              <hr />
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-cogs"></i>
            </ng-template>
            <h4 class="text-center green">{{ 'tablePlan.patterns' | translate }}</h4>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <!--Floor Patterns-->
                  <mat-form-field>
                    <mat-label>{{ 'tablePlan.floorPatterns' | translate }}</mat-label>
                    <mat-select>
                      <mat-option style="margin-top: 5px; margin-bottom: 5px;" (click)="onFloorPatternClick(null)">
                        <i class="fas fa-ban" style="color: red; font-size: 16px;"></i>
                        {{ 'tablePlan.deleteFloorPattern' | translate }}
                      </mat-option>
                      <mat-option
                        *ngFor="let pattern of patterns"
                        [value]="pattern.name"
                        style="margin-top: 5px; margin-bottom: 5px;"
                        (click)="onFloorPatternClick(pattern.id)"
                      >
                        <img src="{{ pattern.image }}" style="width: 100px; height: 100px;" /> {{ pattern.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!--End of floor patterns-->
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <!--Surroundings Patterns-->
                  <mat-form-field>
                    <mat-label>{{ 'tablePlan.surroundingsPattern' | translate }}</mat-label>
                    <mat-select>
                      <mat-option
                        style="margin-top: 5px; margin-bottom: 5px;"
                        (click)="onSurroundingsPatternClick(null)"
                      >
                        <i class="fas fa-ban" style="color: red; font-size: 16px;"></i>
                        {{ 'tablePlan.deleteSurroundingsPattern' | translate }}
                      </mat-option>
                      <mat-option
                        *ngFor="let pattern of patterns"
                        [value]="pattern.name"
                        style="margin-top: 5px; margin-bottom: 5px;"
                        (click)="onSurroundingsPatternClick(pattern.id)"
                      >
                        <img src="{{ pattern.image }}" style="width: 100px; height: 100px;" /> {{ pattern.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!--End of surrounding patterns-->
                </div>
              </div>
            </div>
            <hr />
            <h4 class="text-center green">{{ 'tablePlan.colors' | translate }}</h4>
            <div class="container">
              <div class="row mt-1 mb-1">
                <div class="col-md-12 input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    [(colorPicker)]="color1"
                    [cpPosition]="'top-left'"
                    [style.background]="roomFloorSettings?.roomFloorColor"
                    value="{{ roomFloorSettings?.roomFloorColor }}"
                    style="max-width: 150px;"
                    (colorPickerChange)="setFloorColor($event)"
                    (colorPickerClose)="onColorPickerClose(color1, 1)"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text input-select-color" style="width: 150px;">{{
                      'tablePlan.floorColor' | translate
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row mt-1 mb-1">
                <div class="col-md-12 input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    [(colorPicker)]="color2"
                    [cpPosition]="'top-left'"
                    [style.background]="roomFloorSettings?.surroundingsColor"
                    value="{{ roomFloorSettings?.surroundingsColor }}"
                    style="max-width: 150px;"
                    (colorPickerChange)="setSurroundingsColor($event)"
                    (colorPickerClose)="onColorPickerClose(color2, 2)"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text input-select-color" style="width: 150px;">
                      {{ 'tablePlan.surroundingsColor' | translate }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row mt-1 mb-1">
                <div class="col-md-12 input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    [(colorPicker)]="color3"
                    [cpPosition]="'top-left'"
                    [style.background]="roomFloorSettings?.wallColor"
                    value="{{ roomFloorSettings?.wallColor }}"
                    style="max-width: 150px;"
                    (colorPickerChange)="setWallColor($event)"
                    (colorPickerClose)="onColorPickerClose(color3, 3)"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text input-select-color" style="width: 150px;">{{
                      'tablePlan.wallColor' | translate
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-tree"></i>
            </ng-template>
            <div *ngIf="isEditing">
              <h4 class="text-center green">{{ 'tablePlan.plants' | translate }}</h4>
              <div class="row">
                <div class="col-md-12">
                  <div class="room-list" *ngFor="let decoration of allDecorations">
                    <div class="list-item single-table" *ngIf="decoration.type === 'plant'">
                      <div class="row" (click)="addDecorationToRoom(activeRoom.id, decoration)">
                        <div class="col-md-6"><i class="fas fa-arrow-circle-left"></i> {{ decoration.name }}</div>
                        <div class="col-md-6">
                          <img src="{{ decoration.image }}" alt="" style="width:100px" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <h4 class="text-center green">{{ 'tablePlan.decoration' | translate }}</h4>
              <div class="row">
                <div class="col-md-12">
                  <div class="room-list" *ngFor="let decoration of allDecorations">
                    <div class="list-item single-table" *ngIf="decoration.type === 'decoration'">
                      <div class="row" (click)="addDecorationToRoom(activeRoom.id, decoration)">
                        <div class="col-md-6"><i class="fas fa-arrow-circle-left"></i> {{ decoration.name }}</div>
                        <div class="col-md-6">
                          <img src="{{ decoration.image }}" alt="" style="width:100px" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-chair"></i> <i class="fas fa-tools" *ngIf="isSuperAdmin"></i>
            </ng-template>
            <div class="ml-2" *ngIf="isEditing">
              <!--furniture-->
              <div *ngIf="isEditing">
                <h4 class="text-center green">{{ 'tablePlan.furniture' | translate }}</h4>
                <div class="row">
                  <div class="col-md-12">
                    <div class="room-list">
                      <div class="list-item single-table" (click)="addInnerWallToRoom(activeRoom.id)">
                        <div class="row">
                          <div class="col-md-8">{{ 'tablePlan.innerWall' | translate }}</div>
                          <div class="col-md-1" [ngStyle]="{ height: '50px', 'background-color': 'saddlebrown' }"></div>
                        </div>
                      </div>
                    </div>
                    <div class="room-list" *ngFor="let decoration of allDecorations">
                      <div class="list-item single-table" *ngIf="decoration.type === 'furniture'">
                        <div class="row" (click)="addDecorationToRoom(activeRoom.id, decoration)">
                          <div class="col-md-8">{{ decoration.name }}</div>
                          <div class="col-md-4">
                            <img src="{{ decoration.image }}" alt="" style="width:80px" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end of furniture-->
              <ng-container *ngIf="isSuperAdmin">
                <!-- decorations start -->
                <div class="row mt-3">
                  <div class="col-md-8">
                    <h6 class="element-header">
                      {{ 'tablePlan.decorations' | translate }} {{ allDecorations.length }}
                    </h6>
                  </div>
                  <div class="col-md-4">
                    <button class="btn btn-primary float-right" (click)="createDecoration()">
                      <i class="fas fa-plus"></i> {{ 'tablePlan.addNew' | translate }}
                    </button>
                  </div>
                </div>
                <div class="row mb-1" *ngFor="let decoration of allDecorations">
                  <div class="col-md-4 pt-2">
                    {{ decoration.name }}
                  </div>
                  <div class="col-md-4">
                    <div
                      [style.background-image]="getSanitizedSafeUrl(decoration.image)"
                      [ngStyle]="{
                        'background-repeat': 'no-repeat',
                        'background-size': 'contain',
                        'height.px': 40
                      }"
                    ></div>
                  </div>
                  <div class="col-md-4 pt-1">
                    <button class="btn btn-danger float-right" (click)="deleteDecoration(decoration)">
                      <i class="fas fa-trash"></i>
                    </button>
                    <button class="btn btn-primary float-right mr-1" (click)="editDecoration(decoration)">
                      <i class="fas fa-edit"></i>
                    </button>
                  </div>
                </div>
              </ng-container>

              <!-- decoration ends -->
              <hr />
              <!-- patterns start -->
              <ng-container *ngIf="isSuperAdmin">
                <div class="row mt-3">
                  <div class="col-md-8">
                    <h6 class="element-header">{{ 'tablePlan.patterns' | translate }} {{ patterns.length }}</h6>
                  </div>
                  <div class="col-md-4">
                    <button class="btn btn-primary float-right" (click)="createPattern()">
                      <i class="fas fa-plus"></i> {{ 'tablePlan.addNew' | translate }}
                    </button>
                  </div>
                </div>
                <div class="row mb-1" *ngFor="let pattern of patterns">
                  <div class="col-md-4 pt-2">
                    {{ pattern.name }}
                  </div>
                  <div class="col-md-4">
                    <div
                      [style.background-image]="getSanitizedSafeUrl(pattern.image)"
                      [ngStyle]="{
                        'background-repeat': 'repeat',
                        'width.px': 80,
                        'height.px': 40
                      }"
                    ></div>
                  </div>
                  <div class="col-md-4 pt-1">
                    <button class="btn btn-danger float-right" (click)="deletePattern(pattern)">
                      <i class="fas fa-trash"></i>
                    </button>
                    <button class="btn btn-primary float-right mr-1" (click)="editPattern(pattern)">
                      <i class="fas fa-edit"></i>
                    </button>
                  </div>
                </div>
              </ng-container>
              <!-- patterns ends -->
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-drawer>
      <mat-drawer-content>
        <div class="row table-plan">
          <!--Reservaton list-->
          <div *ngIf="!isEditing" class="col-md-2">
            <div class="" style="margin-top: 12px;">
              <ng-container>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-primary" (click)="zoomInOrOut('out')"><i class="fas fa-minus"></i></button>
                  <div class="text-center" style="font-size: 14px">Zoom {{ zoomValue }}%</div>
                  <button class="btn btn-primary" (click)="zoomInOrOut('in')"><i class="fas fa-plus"></i></button>
                </div>
              </ng-container>
            </div>
            <div *ngIf="reservations.length > 0" class="row res-list">
              <div class="col-md-12">
                <div class="room-list">
                  <ng-container *ngFor="let reservation of reservations; let i = index">
                    <div
                      *ngIf="
                        (reservation.guestData && reservation.status != 'canceled') ||
                        (reservation.guestData && reservation.source != 'walkin')
                      "
                      class="reservation-card my-2 row"
                      [ngClass]="{ 'is-selected': reservation.active }"
                      [style.background]="reservation?.statusInfo?.color"
                      style="color: white"
                    >
                      <div
                        id="reservation-{{ reservation.id }}"
                        draggable="true"
                        (dragstart)="onDragStart($event, reservation)"
                        (dragend)="onDragEnd($event)"
                        (touchstart)="dragTouchstart($event, reservation)"
                        (touchmove)="dragTouchmove($event)"
                        (touchend)="dragTouchend($event)"
                        class="col-12"
                      >
                        <div
                          class="row reservation-card-item"
                          id="reservation-list-item{{ i }}"
                          (click)="onReservationClick(reservation)"
                        >
                          <div
                            class="col-2 d-flex flex-column justify-content-center"
                            *ngIf="allowDragging && !deviceService.isDesktop()"
                          >
                            <i class="fas fa-grip-vertical"></i>
                          </div>
                          <div [ngClass]="allowDragging && !deviceService.isDesktop() ? 'col-10' : 'col-12'">
                            <div class="d-flex justify-content-between align-items-end flex-wrap mr-2 mr-md-0">
                              <small class="time">
                                {{ reservation.startDate | date: 'H:mm' }} - {{ reservation.endDate | date: 'H:mm' }}
                              </small>
                              <div class="tables">
                                <div class="d-flex flex-nowrap" *ngIf="reservation.tables.length > 3; else tableName">
                                  <small
                                    >4+<i
                                      class="fas fa-puzzle-piece"
                                      matTooltip="Tischkombination {{ reservation.isTablePlan }}"
                                    ></i
                                  ></small>
                                </div>
                                <ng-template #tableName>
                                  <small style="white-space: nowrap">
                                    <ng-container *ngFor="let table of reservation.tables; let i = index">
                                      {{ table.name }}
                                      <ng-container *ngIf="i < reservation.tables.length - 1">,</ng-container>
                                    </ng-container>
                                  </small>
                                </ng-template>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-9 overflow-hidden">{{ reservation?.guestData?.name }}</div>
                              <div class="col-3 float-right px-1 mt-1 d-flex justify-content-between">
                                <i
                                  class="fa fa-info-circle guest-note-table-plan"
                                  *ngIf="reservation.msg"
                                  [matTooltip]="reservation?.msg"
                                ></i>
                                <i
                                  class="fa fa-info-circle admin-note-table-plan "
                                  *ngIf="reservation.notes"
                                  [matTooltip]="reservation?.notes"
                                ></i>
                                <div
                                  [ngStyle]="{
                                    fontSize: '12px',
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }"
                                >
                                  <div
                                    [ngStyle]="{
                                      backgroundColor: '#00000050',
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexWrap: 'nowrap'
                                    }"
                                  >
                                    {{ reservation.peopleCount }} <i class="fas fa-user mx-1 "></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="reservation.tags" class="col-12">
                            <div class="tags">
                              <ng-template ngFor let-tag [ngForOf]="reservation.tags">
                                <span class="badge" [style.background-color]="tag.color">
                                  {{ tag.label }}
                                </span>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 pr-0">
                        <div class="reservation-info text-white px-2" [ngClass]="{ expanded: reservation.active }">
                          <div class="d-flex justify-content-between">
                            <button
                              class="btn btn-white p-2 mt-2 uppercase w-100"
                              id="changeReservationStatusButton{{ i }}"
                            >
                              <!-- <ng-container *ngFor="let status of statusItems">
                                <div *ngIf="status.value === reservation.status" [ngStyle]="{ color: status.color }">
                                  {{ status.text }}
                                </div>
                              </ng-container> -->
                              <div [style.color]="reservation?.statusInfo?.color">
                                {{ reservation?.statusInfo?.label }}
                              </div>
                              <dx-context-menu
                                #reservationMenu
                                *ngIf="!isEditing"
                                [items]="statusItems"
                                target="#changeReservationStatusButton{{ i }}"
                                [width]="200"
                                (onItemClick)="statusItemClick($event, reservation)"
                                (onShowing)="refreshStatusOfMenuItems(reservation)"
                                showEvent="click"
                              >
                                <div
                                  *dxTemplate="let data of 'item'; let i = index"
                                  style="padding: 10px"
                                  [ngStyle]="data.isActive && { color: 'white', background: '#009688' }"
                                >
                                  <div *ngIf="data.color">
                                    <div class="item-badge" [ngStyle]="{ backgroundColor: data.color }"></div>
                                  </div>
                                  <div style="margin-left:5px">{{ data.text }}</div>
                                </div>
                              </dx-context-menu>
                            </button>
                          </div>
                          <div class="row">
                            <div class="col-6 overflow-hidden" style="white-space: nowrap">
                              <small>{{ 'tablePlan.guests' | translate }}</small>
                            </div>
                            <div class="col-6 overflow-hidden" style="white-space: nowrap">
                              <small>{{ reservation.peopleCount }} {{ 'tablePlan.persons' | translate }}</small>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6 overflow-hidden" style="white-space: nowrap">
                              <small>{{ 'tablePlan.stayTime' | translate }}</small>
                            </div>
                            <div class="col-6 overflow-hidden" style="white-space: nowrap">
                              <small>{{ reservation.stayTime }} {{ 'tablePlan.minutes' | translate }}</small>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6 overflow-hidden" style="white-space: nowrap">
                              <small>{{ 'tablePlan.start' | translate }}</small>
                            </div>
                            <div class="col-6 overflow-hidden" style="white-space: nowrap">
                              <small>{{ reservation.startDate | date: 'HH:mm' }}</small>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6 overflow-hidden" style="white-space: nowrap">
                              <small>{{ 'tablePlan.end' | translate }}</small>
                            </div>
                            <div class="col-6 overflow-hidden" style="white-space: nowrap">
                              <small>{{ reservation.endDate | date: 'HH:mm' }}</small>
                            </div>
                          </div>
                          <div
                            class="row mb-1 additional-notes"
                            [ngStyle]="{ backgroundColor: '#fff' }"
                            *ngIf="reservation.msg"
                          >
                            <div class="col-12">
                              {{ reservation.msg }}
                            </div>
                          </div>

                          <div
                            class="row additional-notes"
                            [ngStyle]="{ backgroundColor: '#e5e9f3' }"
                            *ngIf="reservation.notes"
                          >
                            <div class="col-12">
                              {{ reservation.notes }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!--                  <div-->
                  <!--                    id="ghostReservationCard"-->
                  <!--                    class="reservation-card"-->
                  <!--                    *ngIf="allowDragging"-->
                  <!--                    [ngClass]="{-->
                  <!--                      'is-selected': ghostReservation?.active,-->
                  <!--                      'status-confirmed': ghostReservation?.status === 'confirmed',-->
                  <!--                      'status-arrived': ghostReservation?.status === 'arrived',-->
                  <!--                      'status-placed': ghostReservation?.status === 'placed',-->
                  <!--                      'status-finished': ghostReservation?.status === 'finished',-->
                  <!--                      'status-noShow': ghostReservation?.status === 'noShow',-->
                  <!--                      'status-canceled': ghostReservation?.status === 'canceled'-->
                  <!--                    }"-->
                  <!--                    [ngStyle]="{-->
                  <!--                      opacity: 0.5,-->
                  <!--                      position: 'fixed',-->
                  <!--                      display: displayGhostReservation,-->
                  <!--                      'z-index': 1,-->
                  <!--                      left: ghostReservation?.left + 'px',-->
                  <!--                      top: ghostReservation?.top + 'px'-->
                  <!--                    }"-->
                  <!--                  >-->
                  <!--                    <div class="row reservation-card-item">-->
                  <!--                      <div class="col-md-12">-->
                  <!--                        <div class="row">-->
                  <!--                          <div class="col-md-10">-->
                  <!--                            {{ ghostReservation?.startDate | date: 'H:mm' }}-->
                  <!--                            - {{ ghostReservation?.endDate | date: 'H:mm' }}-->
                  <!--                          </div>-->
                  <!--                          <div class="col-md-2">-->
                  <!--                            <div class="float-md-right" *ngIf="ghostReservation?.tables.length > 1; else tableName">-->
                  <!--                              <i-->
                  <!--                                class="fas fa-puzzle-piece"-->
                  <!--                                matTooltip="Tischkombination {{ ghostReservation?.isTablePlan }}"-->
                  <!--                              ></i>-->
                  <!--                            </div>-->
                  <!--                            <ng-template #tableName>-->
                  <!--                              <div class="float-md-right">-->
                  <!--                                {{ (ghostReservation?.tables)[0]?.name }}-->
                  <!--                              </div>-->
                  <!--                            </ng-template>-->
                  <!--                          </div>-->
                  <!--                        </div>-->
                  <!--                        <div class="row">-->
                  <!--                          <div class="col-md-9">{{ ghostReservation?.guestData.name }}</div>-->
                  <!--                          <div class="col-md-3">-->
                  <!--                            <small class="float-md-right">-->
                  <!--                              {{ ghostReservation?.peopleCount }} <i class="fas fa-user"></i>-->
                  <!--                            </small>-->
                  <!--                          </div>-->
                  <!--                        </div>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
            <div *ngIf="reservations.length == 0" class="row">
              <div class="col-md-12 text-center">
                <h5 style="color:gray">{{ 'tablePlan.noReservations' | translate }}</h5>
              </div>
            </div>
          </div>
          <!--End of reservation list-->
          <div [ngClass]="isEditing ? 'col-md-12' : 'col-md-10'">
            <div class="row" *ngIf="timeOptions?.floor && !isEditing">
              <div class="col-1 my-auto">
                <button class="btn btn-primary" (click)="setTimeOnSliderToNow()"><i class="fas fa-clock"></i></button>
              </div>
              <div class="col-11">
                <div class="select-slider">
                  <ng5-slider [(value)]="timeValue" [options]="timeOptions" (userChangeEnd)="getValues(activeRoom?.id)">
                  </ng5-slider>
                </div>
              </div>
            </div>
            <div
              class="row surrounding-space"
              [style.background-image]="
                roomFloorSettings?.surroundingsPattern
                  ? getSanitizedSafeUrl(roomFloorSettings?.surroundingsPattern?.image)
                  : null
              "
              [ngStyle]="{
                'background-color': roomFloorSettings?.surroundingsColor ? roomFloorSettings?.surroundingsColor : null
              }"
            >
              <div class="col-12">
                <ng-scrollbar [trackX]="true" [trackY]="false" [shown]="'hover'">
                  <div class="row h-100 m-0">
                    <div class="col-md-12">
                      <div
                        class="room"
                        #room
                        [style.background-image]="
                          roomFloorSettings?.roomPattern
                            ? getSanitizedSafeUrl(roomFloorSettings?.roomPattern?.image)
                            : null
                        "
                        [ngStyle]="{
                          'width.px': roomWidth * zoomSizeFactor,
                          'height.px': roomLength * zoomSizeFactor,
                          'background-color': roomFloorSettings?.roomFloorColor ? roomFloorSettings?.roomFloorColor : ''
                        }"
                      >
                        <app-grid *ngIf="isEditing" [room]="activeRoom"></app-grid>
                        <!--Tables-->
                        <div
                          *ngFor="let table of dragableTables; let i = index"
                          class="table"
                          [ngClass]="{
                            'is-selected': table.isSelected,
                            resizable: isEditing,
                            'show-as-movable': table.showAsMovable
                          }"
                          [ngStyle]="{
                            'width.px': table.dimensions.width * tableSizeFactor * zoomSizeFactor,
                            'height.px': table.dimensions.height * tableSizeFactor * zoomSizeFactor,
                            'border-radius.px': table.dimensions.borderRadius * tableSizeFactor * zoomSizeFactor,
                            'max-width.px': roomWidth,
                            'max-height.px': roomLength,
                            background: getTableBackgroundColor(table.statusOfCurrentlySelectedReservation)
                          }"
                          cdkDragBoundary=".room"
                          cdkDrag
                          (cdkDragEnded)="onDragEnded($event, table)"
                          (cdkDragStarted)="onTableDragStart(table)"
                          [cdkDragFreeDragPosition]="{
                            x: table.position ? table.position?.x * zoomSizeFactor : null,
                            y: table.position ? table.position?.y * zoomSizeFactor : null
                          }"
                          id="roomTable{{ i }}"
                          (click)="onObjectClick(table)"
                          #tbl
                          (mouseup)="updateTableSize(table, tbl, $event)"
                          style="opacity: 1;"
                        >
                          <div *ngFor="let chair of table.chairPositions; let i = index">
                            <div
                              *ngIf="table.type === 'Round'"
                              [ngClass]="{ 'is-editing': isEditing }"
                              [ngStyle]="{
                                position: 'absolute',
                                left: 'calc(50% - ' + 10 * tableSizeFactor * zoomSizeFactor + 'px)',
                                top: 'calc(50% - ' + 10 * tableSizeFactor * zoomSizeFactor + 'px)',
                                'height.px': 20 * tableSizeFactor * zoomSizeFactor,
                                'width.px': 20 * tableSizeFactor * zoomSizeFactor,
                                'background-color': isEditing ? '#009688' : 'white',
                                border: 'solid 1px #ccc'
                              }"
                              [style.transform]="
                                getSanitizedSafeCssTransformed(
                                  chair.rotationsDegrees,
                                  i + 1,
                                  chair.translateX,
                                  table.position.angle
                                )
                              "
                            >
                              <!--Fill occupied chairs round table-->
                              <div *ngIf="table.statusOfCurrentlySelectedReservation">
                                <i
                                  *ngIf="
                                    table.currentlySelectedReservationInsideTable &&
                                    table.currentlySelectedReservationInsideTable.peopleCount > i
                                  "
                                  class="fas fa-user"
                                  [ngStyle]="{
                                    'font-size': 16 * tableSizeFactor * zoomSizeFactor + 'px',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '-webkit-fill-available',
                                    height: '-webkit-fill-available',
                                    'background-color':
                                      table.currentlySelectedReservationInsideTable?.checkins?.length > i
                                        ? 'green'
                                        : 'red',
                                    transform: 'rotate(-90deg)'
                                  }"
                                  matTooltip="{{
                                    (table.currentlySelectedReservationInsideTable?.checkins)[i]?.firstName
                                  }} {{ (table.currentlySelectedReservationInsideTable?.checkins)[i]?.lastName }}
                              "
                                ></i>
                              </div>
                              <!--End of fill occupied chairs-->
                            </div>
                            <div
                              *ngIf="table.type !== 'Round'"
                              [ngClass]="{ 'is-editing': isEditing }"
                              [ngStyle]="{
                                position: 'absolute',
                                'height.px': 20 * tableSizeFactor * zoomSizeFactor,
                                'width.px': 20 * tableSizeFactor * zoomSizeFactor,
                                'background-color': isEditing ? '#009688' : 'white',
                                border: 'solid 1px #ccc',
                                left: chair.translateX * tableSizeFactor * zoomSizeFactor + 'px',
                                top: chair.translateY * tableSizeFactor * zoomSizeFactor + 'px'
                              }"
                            >
                              <!--Fill occupied chairs square table-->
                              <div *ngIf="table.statusOfCurrentlySelectedReservation">
                                <i
                                  *ngIf="
                                    table.currentlySelectedReservationInsideTable &&
                                    table.currentlySelectedReservationInsideTable.peopleCount > i
                                  "
                                  class="fas fa-user"
                                  [ngStyle]="{
                                    'font-size': 16 * tableSizeFactor * zoomSizeFactor + 'px',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '-webkit-fill-available',
                                    height: '-webkit-fill-available',
                                    'background-color':
                                      table.currentlySelectedReservationInsideTable?.checkins?.length > i
                                        ? 'green'
                                        : 'red'
                                  }"
                                  matTooltip="{{
                                    (table.currentlySelectedReservationInsideTable?.checkins)[i]?.firstName
                                  }} {{ (table.currentlySelectedReservationInsideTable?.checkins)[i]?.lastName }}
                              "
                                ></i>
                              </div>
                              <!--End of fill occupied chairs-->
                            </div>
                          </div>
                          <div class="example-handle" cdkDragHandle>
                            <div id="dragableHandle" [ngClass]="{ 'is-editing': isEditing }"></div>
                          </div>
                          <span *ngIf="!isEditing" class="table-name">
                            {{ table?.name }}
                          </span>
                          <div
                            *ngIf="isEditing"
                            style="font-size: x-small;  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                  height: 100%;"
                          >
                            <div>
                              <div class="row">
                                <div class="col-12">{{ 'tablePlan.table' | translate }}: {{ table?.name }}</div>
                              </div>
                              <div class="row">
                                <div class="col-12">{{ table.dimensions.height }} X {{ table.dimensions.width }}</div>
                                <div class="col-12">Winkel: {{ table.position.angle || 0 }}°</div>
                              </div>
                            </div>
                          </div>
                          <app-incoming-reservations
                            *ngIf="!isEditing"
                            [reservations]="reservationsForRoom"
                            [table]="table"
                            [selectedTime]="timeValue"
                            [statusArray]="statusItems"
                            [sizeFactor]="tableSizeFactor"
                            [zoomSizeFactor]="zoomSizeFactor"
                            (onReservationSelected)="table.currentlySelectedReservationInsideTable = $event"
                            (onReservationDraged)="onReservationStartToDrag($event)"
                            (onReservationDragEnd)="onReservationDragEnd()"
                            (onReservationClick)="handleOnTableReservationClick($event)"
                            style="justify-content: center;
                                   align-items: center;
                                   height: 100%;"
                          >
                          </app-incoming-reservations>
                          <!-- Show number of checkin gusets -->
                          <ng-container *ngIf="generalSettings?.requestCheckin">
                            <div
                              class="number-of-chekins"
                              *ngIf="table.currentlySelectedReservationInsideTable?.checkins"
                            >
                              <small>
                                {{ table.currentlySelectedReservationInsideTable?.checkins?.length || 0 }}/{{
                                  table.currentlySelectedReservationInsideTable?.peopleCount || 0
                                }}
                              </small>
                            </div>
                          </ng-container>

                          <!-- Right click more actions - in settings -->
                          <dx-context-menu
                            *ngIf="isEditing"
                            [items]="contextMenuItems"
                            target="#roomTable{{ i }}"
                            [width]="200"
                            (onItemClick)="itemClick($event, table)"
                            (onShowing)="refreshStatusContextTableItems()"
                            showEvent="dxcontextmenu"
                          >
                          </dx-context-menu>
                          <!-- Right click add new reservation or walkin  -->
                          <dx-context-menu
                            *ngIf="!isEditing"
                            [items]="reservationMenuItems"
                            target="#roomTable{{ i }}"
                            [width]="200"
                            (onItemClick)="openAddNewReservation($event, table)"
                            showEvent="dxdblclick"
                          >
                          </dx-context-menu>

                          <!-- Drag and drop reservations -->
                          <div
                            *ngIf="
                              table.canMakeDropReservation &&
                              dragingReservation &&
                              dragingReservation.tableId !== table.id
                            "
                            [ngClass]="{
                              'dropable-reservation':
                                (dragingReservation && deviceService.isDesktop()) ||
                                (isDraggingReservationToRoom && !deviceService.isDesktop()),
                              'draging-over': dragingOverTable === table.id
                            }"
                            [ngStyle]="{
                              'width.px': table.dimensions.width * tableSizeFactor * zoomSizeFactor - 20,
                              'height.px': table.dimensions.height * tableSizeFactor * zoomSizeFactor - 20,
                              'border-radius.px': table.dimensions.borderRadius * tableSizeFactor * zoomSizeFactor
                            }"
                            (dragover)="onDragOver($event)"
                            (drop)="onDrop($event, table)"
                            (dragenter)="onDragEnter($event, table)"
                            (dragleave)="onDragLeave($event)"
                          ></div>
                        </div>
                        <!--End of tables-->
                        <!--Decoration-->
                        <ng-container *ngFor="let decoration of decorationsForRestaurant; let i = index">
                          <div
                            *ngIf="decoration.roomId == activeRoom.id"
                            #decorationHtmlElem
                            class="decoration"
                            cdkDragBoundary=".room"
                            cdkDrag
                            (cdkDragEnded)="onDecorationDragEnded($event, decoration)"
                            [cdkDragFreeDragPosition]="{
                              x: decoration.position.x * zoomSizeFactor,
                              y: decoration.position.y * zoomSizeFactor
                            }"
                            id="decoration{{ i }}"
                            [ngStyle]="{
                              'width.px': decoration.size.width * tableSizeFactor * zoomSizeFactor,
                              'height.px': decoration.size.height * tableSizeFactor * zoomSizeFactor
                            }"
                            [ngClass]="{
                              resizable: isEditing,
                              test: isEditing,
                              'show-as-movable': decoration.showAsMovable
                            }"
                            (mouseup)="updateDecorationSize(decoration, decorationHtmlElem, $event)"
                            (click)="onObjectClick(decoration)"
                          >
                            <div *ngIf="decoration.room_decoration !== null">
                              <img
                                class="decotarion-image"
                                src="{{ decoration.room_decoration.image }}"
                                [ngStyle]="{
                                  transform: 'rotate(' + decoration?.rotationAngle + 'deg)'
                                }"
                              />
                            </div>
                            <div cdkDragHandle>
                              <div id="dragableHandle" [ngClass]="{ 'is-editing': isEditing }"></div>
                            </div>
                            <div
                              *ngIf="decoration.room_decoration === null"
                              class="h-100 w-100"
                              [ngStyle]="{
                                'background-color': roomFloorSettings?.wallColor
                                  ? roomFloorSettings?.wallColor
                                  : '#0096888a'
                              }"
                            ></div>
                            <dx-context-menu
                              *ngIf="isEditing"
                              [items]="contextDecorationMenu"
                              target="#decoration{{ i }}"
                              [width]="200"
                              (onItemClick)="decorationClick($event, decoration)"
                              showEvent="dxcontextmenu"
                            >
                            </dx-context-menu>
                          </div>
                        </ng-container>
                        <!--End of decoration-->
                      </div>
                    </div>
                  </div>
                </ng-scrollbar>
              </div>
            </div>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
<ng-template #noTables>
  <div *ngIf="isEditing && reservationBookSettings?.showTablePlan" class="row h-100">
    <div class="col-md-12 no-tables-msg">
      {{ 'tablePlan.addTablesToUseTablePlanInfo' | translate }}
    </div>
  </div>
  <div *ngIf="isEditing && !reservationBookSettings?.showTablePlan">
    <div class="col-md-12 no-tables-msg">
      {{ 'tablePlan.enableSettingsToUseTablePlanInfo' | translate }}
    </div>
  </div>
</ng-template>
