<breadcrumb></breadcrumb>
<div class="content-box" #contentBoxEl>
  <div class="element-wrapper">
    <div class="alert alert-warning" *ngIf="false">
      <b>Hinweise und Informationen zum Eintragen der Artikel:</b><br />
      <a href="https://blog.gastro.digital/article-categories/speisekarte/" target="_blank">
        https://blog.gastro.digital/article-categories/speisekarte/
      </a>
    </div>
    <div style="height: 100%">
      <ag-grid-angular
        style="width: 100%; height: 100%;"
        class="ag-theme-alpine"
        [rowData]="items"
        [gridOptions]="gridOptions"
        [localeText]="gridLocale"
        [frameworkComponents]="frameworkComponents"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="true"
        [enableFillHandle]="true"
        [modules]="modules"
        [rowDragManaged]="true"
        [animateRows]="true"
        [allowContextMenuWithControlKey]="true"
        [getContextMenuItems]="getContextMenuItems"
        [sideBar]="sideBar"
        (gridReady)="onGridReady($event)"
        (cellValueChanged)="onCellValueChanged($event)"
        (rangeSelectionChanged)="onRangeSelectionChanged($event)"
        (rowDragEnd)="onRowDragEnd($event)"
        (pasteEnd)="onPasteEnd($event)"
      >
      </ag-grid-angular>
    </div>
  </div>
</div>
