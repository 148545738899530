<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Benutzer - Administration
          <div class="element-actions">
            <button class="btn btn-primary hidden-xs-down" (click)="addUser()">
              <i class="fas fa-plus-circle fa-fw"></i>
              Neuen Benutzer anlegen
            </button>
          </div>
        </h6>

        <div class="element-box">
          <div class="table-responsive">
            <table class="table table-padded">
              <thead>
                <tr>
                  <th>Benutzer</th>
                  <th>Benutzerrolle</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of users">
                  <td class="nowrap">
                    <b *ngIf="user.user.firstName || user.user.lastName"
                      >{{ user.user.firstName }} {{ user.user.lastName }}</b
                    >
                    <i *ngIf="!user.user.firstName && !user.user.lastName">Ohne Name</i>
                    <br />
                    {{ user.user.email }}
                  </td>

                  <td>
                    <span class="badge badge-default" *ngIf="user.role">{{ user.role?.name }}</span>
                  </td>

                  <td>
                    <div class="item-options" ngbDropdown>
                      <mat-icon svgIcon="settings" ngbDropdownToggle id="itemDropdown"></mat-icon>
                      <div ngbDropdownMenu aria-labelledby="itemDropdown">
                        <button ngbDropdownItem (click)="editUser(user)">Bearbeiten</button>
                        <button ngbDropdownItem (click)="deleteUser(user)">Löschen</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
