<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Gästefeedback Übersicht <ng-container *ngIf="feedbacks.length">({{ feedbacks.length }})</ng-container>
        </h6>

        <div class="element-box" *ngIf="!feedbacks.length">
          Es sind keine Gästefeedbacks vorhanden.
        </div>

        <div class="element-box" *ngIf="feedbacks.length">
          <div class="card-columns">
            <ng-container *ngFor="let feedback of feedbacks; let i = index">
              <ng-container *ngIf="i < showFeedbacks">
                <div class="card" (click)="selectedFeedback = i" [class.fullwidth]="i == selectedFeedback">
                  <div class="card-body">
                    <h5 class="card-title">
                      <span class="stars float-right">{{ feedback.generalRating }}<i class="fas fa-star"></i></span>
                      <div
                        title="Wird auf Homepage angezeigt"
                        class="eyes      float-right"
                        *ngIf="feedback.forGW == '1'"
                        (click)="feedback.forGW = '0'; saveFeedback(feedback)"
                      >
                        <i class="far fa-eye"></i>
                      </div>
                      <div
                        title="Wird nicht auf Homepage angezeigt"
                        class="eyes red  float-right"
                        *ngIf="feedback.forGW == '0'"
                        (click)="feedback.forGW = '1'; saveFeedback(feedback)"
                      >
                        <i class="far fa-eye-slash"></i>
                      </div>
                      <ng-container *ngIf="!feedback.personName">Anonym</ng-container>{{ feedback.personName }}<br />
                      <small>am {{ feedback.postedAt | date: 'dd.MM.yyy - HH:mm' }} Uhr</small><br />
                      <small *ngIf="feedback.reservation"
                        >Reserviert am {{ feedback.reservation.reservedFor | date: 'dd.MM.yyy - HH:mm' }} Uhr</small
                      >
                    </h5>
                    <p class="card-text" *ngIf="selectedFeedback != i">
                      {{ feedback.msg.substring(0, 60) }}
                      <ng-container *ngIf="feedback.msg.length > 60">...</ng-container>
                    </p>
                    <ng-container *ngIf="selectedFeedback == i">
                      <div
                        class="rating-box"
                        *ngIf="
                          feedback.serviceRating ||
                          feedback.sauberkeitRating ||
                          feedback.essenRating ||
                          feedback.ambienteRating ||
                          feedback.preisLeistungRating
                        "
                      >
                        <ul>
                          <li *ngIf="feedback.serviceRating">
                            Service
                            <span class="stars">
                              <span *ngIf="feedback.serviceRating == 1">
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.serviceRating == 2">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.serviceRating == 3">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.serviceRating == 4">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.serviceRating == 5">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                              </span>
                              <span class="color">{{ feedback.serviceRating }}</span>
                            </span>
                          </li>
                          <li *ngIf="feedback.sauberkeitRating">
                            Sauberkeit
                            <span class="stars">
                              <span *ngIf="feedback.sauberkeitRating == 1">
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.sauberkeitRating == 2">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.sauberkeitRating == 3">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.sauberkeitRating == 4">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.sauberkeitRating == 5">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                              </span>
                              <span class="color">{{ feedback.sauberkeitRating }}</span>
                            </span>
                          </li>
                          <li *ngIf="feedback.essenRating">
                            Essen
                            <span class="stars">
                              <span *ngIf="feedback.essenRating == 1">
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.essenRating == 2">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.essenRating == 3">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.essenRating == 4">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.essenRating == 5">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                              </span>
                              <span class="color">{{ feedback.essenRating }}</span>
                            </span>
                          </li>
                          <li *ngIf="feedback.ambienteRating">
                            Ambiente
                            <span class="stars">
                              <span *ngIf="feedback.ambienteRating == 1">
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.ambienteRating == 2">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.ambienteRating == 3">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.ambienteRating == 4">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.ambienteRating == 5">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                              </span>
                              <span class="color">{{ feedback.ambienteRating }}</span>
                            </span>
                          </li>
                          <li *ngIf="feedback.preisLeistungRating">
                            Preis / Leistung
                            <span class="stars">
                              <span *ngIf="feedback.preisLeistungRating == 1">
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.preisLeistungRating == 2">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.preisLeistungRating == 3">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.preisLeistungRating == 4">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                              </span>
                              <span *ngIf="feedback.preisLeistungRating == 5">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                              </span>
                              <span class="color">{{ feedback.preisLeistungRating }}</span>
                            </span>
                          </li>
                        </ul>
                      </div>

                      <p class="card-text" [class.minheight]="i == selectedFeedback">
                        {{ feedback.msg }}
                      </p>
                      <div class="form-group" style="margin-bottom: 8px;">
                        <button
                          class="btn btn-primary btn-sm float-right"
                          *ngIf="selectedFeedbackAnswer == i"
                          (click)="saveFeedback(feedback)"
                        >
                          Speichern
                        </button>
                        <label for="answer" style="font-size: 13px;">
                          <ng-container *ngIf="feedback.answeredAt == '0000-00-00 00:00:00'">Antwort</ng-container>
                          <ng-container *ngIf="feedback.answeredAt != '0000-00-00 00:00:00'"
                            ><i style="color: var(--primary);" class="far fa-comment-dots"></i> Geantwortet am:
                            {{ feedback.answeredAt | date: 'dd.MM.yyy - HH:mm' }} Uhr</ng-container
                          >
                        </label>
                        <textarea
                          *ngIf="feedback.answeredAt == '0000-00-00 00:00:00'"
                          class="form-control"
                          id="answer"
                          rows="3"
                          (click)="selectedFeedbackAnswer = i"
                          style="font-size: 13px;"
                          [(ngModel)]="feedback.answer"
                        ></textarea>
                        <div *ngIf="feedback.answeredAt != '0000-00-00 00:00:00'">
                          <blockquote>{{ feedback.answer }}</blockquote>
                        </div>
                      </div>

                      <!-- <button class="btn btn-danger btn-sm" (click)="deleteFeedback(feedback, i)">
                        Eintrag löschen
                      </button> -->

                      <div *ngIf="canDeleteFeedback; then thenTemplate1; else elseTemplate2"></div>

                      <ng-template #thenTemplate1>
                        <button class="btn btn-danger btn-sm" (click)="deleteFeedback(feedback, i)">
                          Eintrag löschen
                        </button>
                      </ng-template>

                      <ng-template #elseTemplate2>
                        <button
                          class="btn btn-danger btn-sm"
                          *ngxPermissionsOnly="['admin']"
                          (click)="deleteFeedback(feedback, i)"
                        >
                          Eintrag löschen
                        </button>
                      </ng-template>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <ng-container *ngIf="showFeedbacks < feedbacks.length">
            <div class="text-center">
              <button class="btn btn-primary btn-lg" (click)="showFeedbacks = showFeedbacks + showMoreFeedbacks">
                weitere laden
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
