<div class="modal-content text-center">
  <div class="modal-header">
    <h4 class="modal-title">{{ eventData.name }}</h4>
    <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
      <span class="fas fa-times"></span>
    </button>
  </div>
  <div class="onboarding-content">
    <form [formGroup]="eventDateFormGroup">
      <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.StartDate' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="startDateFromPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <mat-datepicker #startDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
              <input
                class="form-control"
                #dateFromInput
                formControlName="startsAtDay"
                placeholder=""
                type="text"
                name="date"
                [min]="startDate"
                [matDatepicker]="startDateFromPicker"
                (focus)="startDateFromPicker.open()"
                required
              />
            </div>
            <!-- <input class="form-control" name="startsAtDay" formControlName="startsAtDay" type="text" value="" ngbAutofocus /> -->
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.Time' | translate }}</label>
            <ng-select
              [searchable]="false"
              [items]="times"
              [clearable]="false"
              formControlName="startsAtHour"
              bindLabel="time"
              bindValue="time"
            >
            </ng-select>
            <!-- <input class="form-control" name="startsAtHour" formControlName="startsAtHour" type="text" value="" ngbAutofocus /> -->
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.EndDate' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="endDateFromPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <mat-datepicker #endDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
              <input
                class="form-control"
                #dateFromInput
                formControlName="endsAtDay"
                placeholder=""
                type="text"
                name="date"
                [min]="startDate"
                [matDatepicker]="endDateFromPicker"
                (focus)="endDateFromPicker.open()"
              />
            </div>
            <!-- <input class="form-control" name="endsAtDay" formControlName="endsAtDay" type="text" value="" ngbAutofocus /> -->
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.Time' | translate }}</label>
            <ng-select
              [searchable]="false"
              [items]="times"
              [clearable]="false"
              formControlName="endsAtHour"
              bindLabel="time"
              bindValue="time"
            >
            </ng-select>
            <!-- <input class="form-control" name="endsAtHour" formControlName="endsAtHour" type="text" value="" ngbAutofocus /> -->
          </div>
        </div>
        <div class="alert alert-danger" *ngIf="eventDateFormGroup.errors && eventDateFormGroup.errors.dates">
          {{ eventDateFormGroup.errors?.dates }}
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.EventDates.SalesStartDate' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="salesOpeningFromDateFromPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <mat-datepicker #salesOpeningFromDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
              <input
                class="form-control"
                #dateFromInput
                formControlName="salesOpeningFrom"
                placeholder=""
                type="text"
                name="salesOpeningFrom"
                [matDatepicker]="salesOpeningFromDateFromPicker"
                (focus)="salesOpeningFromDateFromPicker.open()"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.EventDates.SalesEndDate' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="salesEndsOnDateFromPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <mat-datepicker #salesEndsOnDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
              <input
                class="form-control"
                #dateFromInput
                formControlName="salesEndsOn"
                placeholder=""
                type="text"
                name="salesEndsOn"
                [matDatepicker]="salesEndsOnDateFromPicker"
                (focus)="salesEndsOnDateFromPicker.open()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="footer-btns">
        <button class="btn btn-primary" (click)="add()" *ngIf="!isEditDate">
          {{ 'Events&Tickets.EventDates.AddAppointment' | translate }}
        </button>
        <button class="btn btn-primary" (click)="save()" *ngIf="isEditDate">
          {{ 'Events&Tickets.EventDates.UpdateAppointment' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
