/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cancel-msg-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../shared/control-error/form-submit.directive";
import * as i4 from "@angular/forms";
import * as i5 from "../../../shared/control-error/control-errors.directive";
import * as i6 from "../../../shared/control-error/control-error-container.directive";
import * as i7 from "../../../shared/control-error/form-errors";
import * as i8 from "../../../core/i18n.service";
import * as i9 from "./cancel-msg-box.component";
import * as i10 from "@angular/material/snack-bar";
import * as i11 from "@ng-bootstrap/ng-bootstrap";
var styles_CancelMsgBoxComponent = [i0.styles];
var RenderType_CancelMsgBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CancelMsgBoxComponent, data: {} });
export { RenderType_CancelMsgBoxComponent as RenderType_CancelMsgBoxComponent };
export function View_CancelMsgBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 42, "div", [["class", "modal-content text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["aria-label", "Close"], ["class", "close"], ["style", "outline:none;"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, null, 34, "div", [["class", "onboarding-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "h4", [["class", "onboarding-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["\n      ", "\n    "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(13, 0, null, null, 27, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i3.FormSubmitDirective, [i1.ElementRef], null, null), i1.ɵdid(15, 16384, null, 0, i4.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(16, 540672, null, 0, i4.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i4.ControlContainer, null, [i4.FormGroupDirective]), i1.ɵdid(18, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(20, 0, null, null, 19, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(22, 0, null, null, 12, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(24, 0, null, null, 9, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(26, 16777216, null, null, 6, "textarea", [["class", "form-control"], ["formControlName", "msg"], ["name", "msg"], ["placeholder", ""], ["rows", "4"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 27)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 27).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 27)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 27)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(27, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(29, 671744, null, 0, i4.FormControlName, [[3, i4.ControlContainer], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR], [2, i4.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i4.NgControl, null, [i4.FormControlName]), i1.ɵdid(31, 212992, null, 0, i5.ControlErrorsDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver, [2, i6.ControlErrorContainerDirective], i7.FORM_ERRORS, [2, i3.FormSubmitDirective], i4.NgControl, i2.TranslateService, i8.I18nService], null, null), i1.ɵdid(32, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(36, 0, null, null, 2, "button", [["class", "btn btn-primary btn-block btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveCancelText() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(37, null, ["\n          ", "\n        "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.msgAddressFormGroup; _ck(_v, 16, 0, currVal_8); var currVal_16 = "msg"; _ck(_v, 29, 0, currVal_16); _ck(_v, 31, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("Vouchers.Transactions.CancelReasonText")); _ck(_v, 10, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 18).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 18).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 18).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 18).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 18).ngClassValid; var currVal_6 = i1.ɵnov(_v, 18).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 18).ngClassPending; _ck(_v, 13, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 32).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 32).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 32).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 32).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 32).ngClassValid; var currVal_14 = i1.ɵnov(_v, 32).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 32).ngClassPending; _ck(_v, 26, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_17 = i1.ɵunv(_v, 37, 0, i1.ɵnov(_v, 38).transform("Common.Cancel")); _ck(_v, 37, 0, currVal_17); }); }
export function View_CancelMsgBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cancel-msg-box", [], null, null, null, View_CancelMsgBoxComponent_0, RenderType_CancelMsgBoxComponent)), i1.ɵdid(1, 114688, null, 0, i9.CancelMsgBoxComponent, [i4.FormBuilder, i10.MatSnackBar, i11.NgbActiveModal, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CancelMsgBoxComponentNgFactory = i1.ɵccf("app-cancel-msg-box", i9.CancelMsgBoxComponent, View_CancelMsgBoxComponent_Host_0, {}, { passEntry: "passEntry" }, []);
export { CancelMsgBoxComponentNgFactory as CancelMsgBoxComponentNgFactory };
