export interface Reservation {
  id?: number;
  clientId?: number;
  reservedFor: string;
  guestData: any;
  stayTime: any;
  guestCount: Number;
  time: string;
  roomId?: number;
  msg?: string;
  status?: string;
  notes?: string;
  tags?: any;
  files?: any;
  staffId?: number;
  tableId?: number;
  isTablePlan?: string;
  locked?: boolean;
  startDate?: any;
  endDate?: any;
  gastId?: any;
  shiftId?: number;
  referrer?: string;
  peopleCount?: number;
  changeAllRecurringReservation?: boolean;
  addOns?: string;
  resMenuOrderItems?: any;
  selectedTicketData?: any;
  selectedTicketPrice?: any;
  resStatus?: any;
  serviceCharge?: any;
  finalPriceAfterVoucher?: any;
  voucher?: any;
}

export class Page {
  size = 0;
  totalElements = 0;
  totalPages = 0;
  pageNumber = 0;
}
