export const Constants = {
  BVClient: 282241,
  defaultStatus: {
    color: '#808080',
    icon: 'fas fa-adjust',
    isActive: 1,
    type: 'single'
  },

  fixedStatusMap: {
    confirmed: { class: 'text-success', icon: 'fas fa-check-circle', label: 'Bestätigt', color: '#24b314' },
    arrived: { class: 'text-arrived', icon: 'fas fa-store-alt fa-fw', label: 'Angekommen', color: '#ebb12b' },
    placed: { class: 'text-info', icon: 'fas fa-chair fa-fw', label: 'Platziert', color: '#1371fe' },
    finished: { class: 'text-finished', icon: 'fas fa-check-double fa-fw', label: 'Fertig', color: '#495057' },
    pending: { class: 'text-warning', icon: 'fas fa-minus-circle fa-fw', label: 'Ausstehend', color: '#9f9f9f' },
    canceled: { class: 'text-danger', icon: 'fas fa-ban fa-fw', label: 'Storniert', color: '#e65252' },
    noShow: { class: 'text-noshow', icon: 'fas fa-eye-slash fa-fw', label: 'No-Show', color: '#912f5e' },
    waiting: { class: 'text-muted', icon: 'fas fa-hourglass-half fa-fw', label: 'Warteliste', color: '#6c757d' },
    blocked: { class: 'text-muted', icon: 'fas fa-ban fa-fw', label: 'Blocked', color: '#6c757d' }
  } as { [key: string]: StatusDetails }
};

export interface StatusDetails {
  class: string;
  icon: string;
  label: string;
  color: string;
}
export const resStatus = [
  {
    label: 'Aktive Reservierungen',
    value: 'active',
    icon: '',
    color: '',
    type: 'group',
    description: 'Angekommen, Platziert & Bestätigt'
  },
  {
    label: 'Bestätigt',
    value: 'confirmed',
    icon: 'fas fa-check-circle',
    color: '#28a745',
    type: 'single'
  },
  {
    label: 'Storniert',
    value: 'canceled',
    icon: 'fas fa-ban fa-fw',
    color: '#dc3545',
    type: 'single'
  },
  {
    label: 'No-Show',
    value: 'noShow',
    icon: 'fas fa-eye-slash fa-fw',
    color: '#912f5e',
    type: 'single'
  },
  {
    label: 'Angekommen',
    value: 'arrived',
    icon: 'fas fa-store-alt fa-fw',
    color: '#ebb12b',
    type: 'single'
  },
  {
    label: 'Platziert',
    value: 'placed',
    icon: 'fas fa-chair fa-fw',
    color: '#17a2b8',
    type: 'single'
  },
  {
    label: 'Ausstehend',
    value: 'pending',
    icon: 'fas fa-minus-circle fa-fw',
    color: '#ffc107',
    type: 'single'
  },
  {
    label: 'Fertig',
    value: 'finished',
    icon: 'fas fa-check-double fa-fw',
    color: '#495057',
    type: 'single'
  },
  {
    label: 'Warteliste',
    value: 'waiting',
    icon: 'fas fa-hourglass-half fa-fw',
    color: '#6c757d',
    type: 'single'
  },
  {
    label: 'Blocked',
    value: 'blocked',
    icon: 'fas fa-ban fa-fw',
    color: '#6c757d',
    type: 'single'
  }
];
