<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editPricecategory">Neue Preiskategorie anlegen</ng-template>
      <ng-template [ngIf]="editPricecategory">Preiskategorie ändern</ng-template>
    </h4>
    <form [formGroup]="priceCategoryFormGroup">
      <div class="col-sm-12">
        <div class="form-group">
          <label for="">
            (Interner) Name der Preiskategorie<br />
            <small>Der Name erscheint nur intern beim erstellen von Größen und in der Artikelliste</small>
          </label>
          <input class="form-control" name="name" formControlName="name" type="text" value="" ngbAutofocus />
        </div>
      </div>
      <div class="col-sm-12">
        <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

        <div style="text-align:center">
          <button
            class="btn btn-primary btn-block btn-lg"
            (click)="add()"
            *ngIf="!editPricecategory"
            [promiseBtn]="addObservable"
          >
            Preiskategorie anlegen
          </button>
          <button
            class="btn btn-primary btn-block btn-lg"
            (click)="save()"
            *ngIf="editPricecategory"
            [promiseBtn]="editObservable"
          >
            Preiskategorie speichern
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
