/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report-review.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../nl2br.pipe";
import * as i6 from "@angular/common";
import * as i7 from "./report-review.component";
import * as i8 from "../reviews.service";
var styles_ReportReviewComponent = [i0.styles];
var RenderType_ReportReviewComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ReportReviewComponent, data: {} });
export { RenderType_ReportReviewComponent as RenderType_ReportReviewComponent };
function View_ReportReviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["style", "margin-top:5px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "ngb-rating", [["aria-valuemin", "0"], ["class", "d-inline-flex"], ["role", "slider"], ["tabindex", "0"]], [[1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "aria-valuetext", 0], [1, "aria-disabled", 0]], [[null, "rateChange"], [null, "blur"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).handleBlur() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).handleKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).reset() !== false);
        ad = (pd_2 && ad);
    } if (("rateChange" === en)) {
        var pd_3 = ((_co.review.rating = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_NgbRating_0, i2.RenderType_NgbRating)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.NgbRating]), i1.ɵdid(4, 638976, null, 1, i4.NgbRating, [i4.NgbRatingConfig, i1.ChangeDetectorRef], { max: [0, "max"], rate: [1, "rate"], readonly: [2, "readonly"] }, { rateChange: "rateChange" }), i1.ɵqud(335544320, 1, { starTemplateFromContent: 0 }), (_l()(), i1.ɵted(-1, null, [" by\n      "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "b", [["style", "font-weight: bold;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = 5; var currVal_5 = _co.review.rating; var currVal_6 = true; _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).max; var currVal_1 = i1.ɵnov(_v, 4).nextRate; var currVal_2 = i1.ɵnov(_v, 4).ariaValueText(); var currVal_3 = (i1.ɵnov(_v, 4).readonly ? true : null); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_7 = _co.review.memberInfo.username; _ck(_v, 8, 0, currVal_7); }); }
export function View_ReportReviewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.Nl2brPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 37, "div", [["class", "modal-content text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["Report Review -: ", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(14, 0, null, null, 7, "div", [["class", "onboarding-content"], ["style", "text-align: left;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "comment"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(17, 1), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportReviewComponent_1)), i1.ɵdid(20, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(23, 0, null, null, 14, "div", [["class", "onboarding-content"], ["style", "text-align: left;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    Why do you want to report the review?\n    "])), (_l()(), i1.ɵeld(25, 0, null, null, 5, "textarea", [["class", "col-sm-12"], ["style", "outline: none; border:1px solid #dedede"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 26)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 26).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 26)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 26)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.report = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(28, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(30, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(32, 0, null, null, 1, "button", [["class", "btn btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitReport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Report Review"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(35, 0, null, null, 1, "button", [["class", "btn btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Abort"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.review.comment; _ck(_v, 20, 0, currVal_2); var currVal_10 = _co.report; _ck(_v, 28, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.review.title; _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v, 0), _co.review.comment)); _ck(_v, 16, 0, currVal_1); var currVal_3 = i1.ɵnov(_v, 30).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 30).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 30).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 30).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 30).ngClassValid; var currVal_8 = i1.ɵnov(_v, 30).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 30).ngClassPending; _ck(_v, 25, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_ReportReviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-report-review", [], null, null, null, View_ReportReviewComponent_0, RenderType_ReportReviewComponent)), i1.ɵdid(1, 114688, null, 0, i7.ReportReviewComponent, [i4.NgbActiveModal, i8.ReviewsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportReviewComponentNgFactory = i1.ɵccf("app-report-review", i7.ReportReviewComponent, View_ReportReviewComponent_Host_0, { reviewContent: "reviewContent" }, { submitReviewResponse: "submitReviewResponse" }, []);
export { ReportReviewComponentNgFactory as ReportReviewComponentNgFactory };
