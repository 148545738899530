<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      {{ 'Events&Tickets.EventDates.LinkTicket' | translate }}
    </h4>
    <form [formGroup]="assignTicketDateFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.EventName' | translate }} : {{ event.name }}</label>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.StartDate' | translate }} : {{ dateInfo.startsAtDay }}</label>
          </div>
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.EditEvent.StartHrs' | translate }} : {{ dateInfo.startsAtHour }}</label>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.EndDate' | translate }} : {{ dateInfo.endsAtDay }}</label>
          </div>
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.EditEvent.EndHrs' | translate }} : {{ dateInfo.endsAtHour }}</label>
          </div>
        </div>
        <div class="col-sm-12" *ngIf="!ticketData">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Tickets.SelectTicket' | translate }}</label>
            <select class="form-control" formControlName="ticketId">
              <option *ngFor="let ticket of tickets" [ngValue]="ticket.id">
                {{ ticket.name }}
              </option>
            </select>
            <!-- <input class="form-control" name="price" formControlName="price" type="number" value="" ngbAutofocus /> -->
          </div>
        </div>
        <div class="col-sm-12" *ngIf="ticketData">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.TicketName' | translate }}: {{ ticketData.tickets.name }}</label>
          </div>
        </div>
        <div class="col-sm-12" *ngIf="ticketData">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Tickets.RemainingTickets' | translate }}</label>
            <number-spinner
              name="guestCount"
              [min]="0"
              [max]="1000"
              [step]="1"
              [precision]="1"
              [inputDisabled]="false"
              [defaultValue]="+assignTicketDateFormGroup.value.remainingTickets"
              (whenChange)="changeCount($event)"
              ngbAutofocus
              required
            ></number-spinner>
          </div>
        </div>
      </div>
      <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

      <div class="clearfix" style="text-align: center;margin-top: 15px;">
        <button class="btn btn-block btn-primary btn-lg" *ngIf="!ticketData" (click)="add()">
          {{ 'Events&Tickets.EventDates.LinkTicket' | translate }}
        </button>
        <button class="btn btn-block btn-primary btn-lg" *ngIf="ticketData" (click)="save()">
          {{ 'Events&Tickets.EventDates.UpdateRemainingSeats' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
