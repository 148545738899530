/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-list-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./menu-list-item.component";
import * as i3 from "@angular/cdk/layout";
import * as i4 from "../../nav.service";
import * as i5 from "@angular/router";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@angular/common";
import * as i8 from "ngx-permissions";
var styles_MenuListItemComponent = [i0.styles];
var RenderType_MenuListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuListItemComponent, data: { "animation": [{ type: 7, name: "indicatorRotate", definitions: [{ type: 0, name: "collapsed", styles: { type: 6, styles: { transform: "rotate(0deg)" }, offset: null }, options: undefined }, { type: 0, name: "expanded", styles: { type: 6, styles: { transform: "rotate(180deg)" }, offset: null }, options: undefined }, { type: 1, expr: "expanded <=> collapsed", animation: { type: 4, styles: null, timings: "225ms cubic-bezier(0.4,0.0,0.2,1)" }, options: null }], options: {} }] } });
export { RenderType_MenuListItemComponent as RenderType_MenuListItemComponent };
function View_MenuListItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "menu-item-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.iconName; _ck(_v, 2, 0, currVal_0); }); }
function View_MenuListItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-menu-list-item", [], [[1, "aria-expanded", 0]], [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_MenuListItemComponent_0, RenderType_MenuListItemComponent)), i1.ɵdid(1, 245760, null, 0, i2.MenuListItemComponent, [i1.ChangeDetectorRef, i3.MediaMatcher, i4.NavService, i5.Router, i6.NgbModal], { item: [0, "item"], depth: [1, "depth"] }, null), (_l()(), i1.ɵted(-1, null, [" "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = (_co.depth + 1); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).ariaExpanded; _ck(_v, 0, 0, currVal_0); }); }
function View_MenuListItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "sub-menu-w"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuListItemComponent_4)), i1.ɵdid(3, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.children; _ck(_v, 3, 0, currVal_0); }, null); }
function View_MenuListItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "menu-item"]], [[2, "has-sub-menu", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "a", [], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemSelected(_co.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { active: 0, expanded: 1 }), i1.ɵprd(512, null, i7.ɵNgStyleImpl, i7.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i7.NgStyle, [i7.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(8, { "padding-left": 0 }), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuListItemComponent_2)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuListItemComponent_3)), i1.ɵdid(18, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 5, 0, (_co.item.route ? _co.router.isActive(_co.item.route, true) : false), ((_co.expanded || _co.item.route) ? _co.router.isActive(_co.item.route, true) : false)); _ck(_v, 4, 0, currVal_2); var currVal_3 = _ck(_v, 8, 0, ((_co.depth * 12) + "px")); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.item.iconName; _ck(_v, 11, 0, currVal_4); var currVal_6 = _co.expanded; _ck(_v, 18, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.children.length; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.item.disabled; _ck(_v, 2, 0, currVal_1); var currVal_5 = _co.item.displayName; _ck(_v, 14, 0, currVal_5); }); }
export function View_MenuListItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuListItemComponent_1)), i1.ɵdid(1, 737280, null, 0, i8.NgxPermissionsDirective, [i8.NgxPermissionsService, i8.NgxPermissionsConfigurationService, i8.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.permission; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MenuListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-list-item", [], [[1, "aria-expanded", 0]], [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_MenuListItemComponent_0, RenderType_MenuListItemComponent)), i1.ɵdid(1, 245760, null, 0, i2.MenuListItemComponent, [i1.ChangeDetectorRef, i3.MediaMatcher, i4.NavService, i5.Router, i6.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).ariaExpanded; _ck(_v, 0, 0, currVal_0); }); }
var MenuListItemComponentNgFactory = i1.ɵccf("app-menu-list-item", i2.MenuListItemComponent, View_MenuListItemComponent_Host_0, { item: "item", depth: "depth" }, {}, []);
export { MenuListItemComponentNgFactory as MenuListItemComponentNgFactory };
