/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../control-error/control-errors.directive";
import * as i3 from "../control-error/control-error-container.directive";
import * as i4 from "../control-error/form-errors";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../core/i18n.service";
import * as i7 from "./number-spinner.component";
var styles_NumberSpinnerComponent = ["input[type='number'][_ngcontent-%COMP%]::-webkit-inner-spin-button, input[type='number'][_ngcontent-%COMP%]::-webkit-outer-spin-button {\n        -webkit-appearance: none;\n        margin: 0;\n      }\n      input[type='number'][_ngcontent-%COMP%] {\n        text-align: center;\n      }\n      .btn[_ngcontent-%COMP%] {\n        display: flex;\n        align-items: center;\n        padding: 0.375rem 0.75rem;\n        margin-bottom: 0;\n        font-size: 0.9rem;\n        font-weight: 400;\n        line-height: 1.5;\n        color: #495057;\n        text-align: center;\n        white-space: nowrap;\n        background-color: #e9ecef;\n        border: 2px solid #dde2ec;\n        border-radius: 4px;\n      }\n      .btn.decrease[_ngcontent-%COMP%] {\n        border-top-right-radius: 0;\n        border-bottom-right-radius: 0;\n        border-right: 0;\n      }\n      .btn.increase[_ngcontent-%COMP%] {\n        border-top-left-radius: 0;\n        border-bottom-left-radius: 0;\n        border-left: 0;\n      }"];
var RenderType_NumberSpinnerComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_NumberSpinnerComponent, data: {} });
export { RenderType_NumberSpinnerComponent as RenderType_NumberSpinnerComponent };
export function View_NumberSpinnerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 26, "span", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "input-group-btn"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "button", [["class", "btn btn-primary decrease"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decreaseValue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "i", [["class", "fas fa-minus fa-fw"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(11, 16777216, null, null, 7, "input", [["class", "form-control"], ["type", "number"]], [[8, "min", 0], [8, "max", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "blur"], [null, "input"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 13).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i0.ɵnov(_v, 13).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 13).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("blur" === en)) {
        var pd_7 = (_co.changeValue() !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(13, 16384, null, 0, i1.NumberValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i1.DefaultValueAccessor, i1.NumberValueAccessor]), i0.ɵdid(15, 540672, null, 0, i1.FormControlDirective, [[8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlDirective]), i0.ɵdid(17, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), i0.ɵdid(18, 212992, null, 0, i2.ControlErrorsDirective, [i0.ViewContainerRef, i0.ComponentFactoryResolver, [2, i3.ControlErrorContainerDirective], i4.FORM_ERRORS, [8, null], i1.NgControl, i5.TranslateService, i6.I18nService], null, null), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(20, 0, null, null, 6, "div", [["class", "input-group-btn"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(22, 0, null, null, 3, "button", [["class", "btn btn-primary increase"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.increaseValue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵeld(24, 0, null, null, 0, "i", [["class", "fas fa-plus fa-fw"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.numberPicker; _ck(_v, 15, 0, currVal_9); _ck(_v, 18, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.min, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.max, ""); var currVal_2 = i0.ɵnov(_v, 17).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 17).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 17).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 17).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 17).ngClassValid; var currVal_7 = i0.ɵnov(_v, 17).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 17).ngClassPending; _ck(_v, 11, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_NumberSpinnerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "number-spinner", [], null, null, null, View_NumberSpinnerComponent_0, RenderType_NumberSpinnerComponent)), i0.ɵdid(1, 638976, null, 0, i7.NumberSpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NumberSpinnerComponentNgFactory = i0.ɵccf("number-spinner", i7.NumberSpinnerComponent, View_NumberSpinnerComponent_Host_0, { min: "min", max: "max", step: "step", precision: "precision", defaultValue: "defaultValue", inputDisabled: "inputDisabled", readonly: "readonly" }, { whenChange: "whenChange" }, []);
export { NumberSpinnerComponentNgFactory as NumberSpinnerComponentNgFactory };
