<ag-grid-angular
  style="width: 100%; height: 400px;"
  class="ag-theme-alpine"
  [rowData]="deliveryTimes"
  [gridOptions]="gridOptions"
  [localeText]="gridLocale"
  [frameworkComponents]="frameworkComponents"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [enableRangeSelection]="true"
  [enableFillHandle]="true"
  [rowDragManaged]="true"
  [animateRows]="true"
  [allowContextMenuWithControlKey]="true"
  [getContextMenuItems]="getContextMenuItems"
  [sideBar]="sideBar"
  (gridReady)="onGridReady($event)"
  (cellValueChanged)="onCellValueChanged($event)"
  (rangeSelectionChanged)="onRangeSelectionChanged($event)"
  (pasteEnd)="onPasteEnd($event)"
>
</ag-grid-angular>
