/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/ngx-scrollbar/ngx-scrollbar.ngfactory";
import * as i3 from "ngx-scrollbar";
import * as i4 from "@angular/cdk/layout";
import * as i5 from "@angular/common";
import * as i6 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "../../../node_modules/angular-gridster2/angular-gridster2.ngfactory";
import * as i12 from "angular-gridster2";
import * as i13 from "../striphtml.pipe";
import * as i14 from "ngx-moment";
import * as i15 from "../shared/truncate/truncate.pipe";
import * as i16 from "./home.component";
import * as i17 from "../core/util.service";
import * as i18 from "@angular/router";
import * as i19 from "../core/client.service";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵeld(2, 0, null, null, 14, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵted(-1, null, ["\n\n                "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵppd(14, 1), i1.ɵppd(15, 2), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵted(-1, null, ["\n            "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.link, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.title.rendered)); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent.parent, 1), _v.context.$implicit.date, "ddd DD MMM YYYY")); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 13, 0, _ck(_v, 15, 0, i1.ɵnov(_v.parent.parent.parent, 2), i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.content.rendered)), 150)); _ck(_v, 13, 0, currVal_3); }); }
function View_HomeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "news-articles"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h6", [["class", "element-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Wichtige Hinweis und neue Funktionen"])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "ng-scrollbar", [], [[1, "customView", 0], [1, "trackX", 0], [1, "trackY", 0], [1, "compact", 0], [1, "autoHide", 0]], null, null, i2.View_NgScrollbar_0, i2.RenderType_NgScrollbar)), i1.ɵdid(7, 4374528, null, 1, i3.NgScrollbar, [i1.ChangeDetectorRef, i4.BreakpointObserver, i1.PLATFORM_ID], null, null), i1.ɵqud(335544320, 1, { customViewPort: 0 }), (_l()(), i1.ɵted(-1, 0, ["\n            "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HomeComponent_3)), i1.ɵdid(11, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.newsArticles; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = !!i1.ɵnov(_v, 7).customViewPort; var currVal_1 = i1.ɵnov(_v, 7).trackX; var currVal_2 = i1.ɵnov(_v, 7).trackY; var currVal_3 = i1.ɵnov(_v, 7).compact; var currVal_4 = (i1.ɵnov(_v, 7).shown === "hover"); _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_HomeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "button-widget reservation"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo("../reservation/book") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["\n            "])), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "div", [["class", "icon icon-reservation"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n            Reservierungsbuch\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_HomeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "button-widget ordering"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo("../orders/overview") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["\n            "])), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "div", [["class", "icon icon-gpay"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n            Bestellungen\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_HomeComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "button-widget feedback"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo("../feedback/overview") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["\n            "])), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "div", [["class", "icon icon-feedback"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n            Feedback\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "gridster-item", [], null, null, null, i11.View_GridsterItemComponent_0, i11.RenderType_GridsterItemComponent)), i1.ɵdid(1, 245760, null, 0, i12.GridsterItemComponent, [i1.ElementRef, i12.GridsterComponent, i1.Renderer2, i1.NgZone], { item: [0, "item"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HomeComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HomeComponent_4)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HomeComponent_5)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\n      "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HomeComponent_6)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type === "news"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type === "button-reservation"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_v.context.$implicit.type === "button-orders"); _ck(_v, 10, 0, currVal_3); var currVal_4 = (_v.context.$implicit.type === "button-feedbacks"); _ck(_v, 13, 0, currVal_4); }, null); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.StripHtmlPipe, []), i1.ɵpid(0, i14.DateFormatPipe, []), i1.ɵpid(0, i15.TruncateTextPipe, []), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "content-box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "gridster", [["class", "dashboard-widgets"]], null, null, null, i11.View_GridsterComponent_0, i11.RenderType_GridsterComponent)), i1.ɵdid(6, 770048, null, 0, i12.GridsterComponent, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone], { options: [0, "options"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HomeComponent_1)), i1.ɵdid(9, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.dashboard; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i16.HomeComponent, [i17.UtilService, i18.Router, i18.ActivatedRoute, i19.ClientService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i16.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
