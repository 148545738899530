<div class="modal-wrapper">
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="decoration">{{ 'tablePlan.editDecoration' | translate }}</h4>
    <h4 class="modal-title" *ngIf="!decoration">{{ 'tablePlan.createNewDecoration' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="">{{ 'tablePlan.name' | translate }}</label>
          <input class="form-control" placeholder="" type="text" name="name" [(ngModel)]="name" required />
          <div *ngIf="isSubmited && (!name || name === '')" class="text-danger">
            {{ 'tablePlan.nameIsRequired' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">{{ 'tablePlan.type' | translate }}</label>
          <mat-select [(ngModel)]="type">
            <mat-option *ngFor="let type of decorationTypes" [value]="type.value">{{ type.name }}</mat-option>
          </mat-select>
          <div *ngIf="isSubmited && (!type || type === '')" class="text-danger">
            {{ 'tablePlan.typeIsRequired' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">{{ 'tablePlan.selectImage' | translate }}</label>
          <input
            type="file"
            (change)="onChange($event)"
            name="image"
            accept="image/x-png,image/gif,image/jpeg"
            required
          />
          <div *ngIf="isSubmited && (!image || image === '')" class="text-danger">
            {{ 'tablePlan.imageIsRequired' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">{{ 'tablePlan.preview' | translate }}</label>
          <img *ngIf="!image" class="form-control" style="height: 100px;" src="assets/img/no-image.svg" />
          <div
            *ngIf="image"
            [style.background-image]="getSanitizedSafeUrl(image)"
            [ngStyle]="{
              'width.px': 220,
              'height.px': 100,
              'background-size': 'contain',
              'background-repeat': 'no-repeat'
            }"
          ></div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4"></div>
      <div class="col-md-2" *ngIf="!decoration">
        <button class="btn btn-primary btn-lg" (click)="createDecoration()">
          {{ 'tablePlan.create' | translate }}
        </button>
      </div>
      <div class="col-md-2" *ngIf="decoration">
        <button class="btn btn-primary btn-lg" (click)="editDecoration()">{{ 'tablePlan.save' | translate }}</button>
      </div>
      <div class="col-md-2">
        <button class="btn btn-danger btn-lg" (click)="activeModal.dismiss('')">
          {{ 'tablePlan.cancel' | translate }}
        </button>
      </div>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>
