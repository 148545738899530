<breadcrumb></breadcrumb>
<div [ngStyle]="{ display: loader ? 'none' : 'block' }" class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Review
        </h6>
        <div class="element-box">
          Total Reviews -: <b>{{ reviewData.length }}</b>
        </div>
        <div class="shifts element-box">
          <div class="alert alert-info" *ngIf="!reviewData || !reviewData.length" style="margin:0">
            No Reviews Found
          </div>
          <ngx-datatable
            *ngIf="reviewData && reviewData.length"
            #myTable
            class="material"
            [rows]="reviewData"
            [columnMode]="'force'"
            [limit]="tableLimit"
            [sorts]="[{ prop: 'lastVisit', dir: 'desc' }]"
            [scrollbarH]="false"
            [rowHeight]="'100%'"
            [cssClasses]="{
              pagerLeftArrow: 'fas fa-backward',
              pagerRightArrow: 'fas fa-forward',
              pagerPrevious: 'fas fa-step-backward',
              pagerNext: 'fas fa-step-forward'
            }"
          >
            <ngx-datatable-row-detail [rowHeight]="'100%'" #myDetailRow>
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                <div class="ticket-list">
                  <ul *ngIf="row.comment">
                    <li>
                      <div class="ticket-name">
                        <div class="value">
                          <div class="comment" [innerHtml]="row.comment | nl2br"></div>
                          <div
                            style="margin-top:10px; padding: 10px; background-color: #f5f5f5; border: 1px solid #dedede"
                          >
                            <div style="padding: 10px;">DETAILBEWERTUNG</div>
                            <div class="row" style="padding: 0 10px;">
                              <div class="col-sm-2">
                                Service
                                <div>
                                  <ng-template #serviceRating let-fill="fill">
                                    <span class="star" [class.full]="fill === 100">
                                      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                                    </span>
                                  </ng-template>
                                  <ngb-rating
                                    [(rate)]="row.serviceRating"
                                    [starTemplate]="serviceRating"
                                    [readonly]="true"
                                    [max]="5"
                                  ></ngb-rating>
                                  &nbsp; {{ row.serviceRating }}
                                </div>
                              </div>
                              <div class="col-sm-2">
                                Sauberkeit
                                <div>
                                  <ng-template #sauberkeitRating let-fill="fill">
                                    <span class="star" [class.full]="fill === 100">
                                      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                                    </span>
                                  </ng-template>
                                  <ngb-rating
                                    [(rate)]="row.sauberkeitRating"
                                    [starTemplate]="sauberkeitRating"
                                    [readonly]="true"
                                    [max]="5"
                                  ></ngb-rating>
                                  &nbsp; {{ row.sauberkeitRating }}
                                </div>
                              </div>
                              <div class="col-sm-2">
                                Essen
                                <div>
                                  <ng-template #essenRating let-fill="fill">
                                    <span class="star" [class.full]="fill === 100">
                                      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                                    </span>
                                  </ng-template>
                                  <ngb-rating
                                    [(rate)]="row.essenRating"
                                    [starTemplate]="essenRating"
                                    [readonly]="true"
                                    [max]="5"
                                  ></ngb-rating>
                                  &nbsp; {{ row.essenRating }}
                                </div>
                              </div>
                              <div class="col-sm-2">
                                Ambiente
                                <div>
                                  <ng-template #ambienteRating let-fill="fill">
                                    <span class="star" [class.full]="fill === 100">
                                      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                                    </span>
                                  </ng-template>
                                  <ngb-rating
                                    [(rate)]="row.ambienteRating"
                                    [starTemplate]="ambienteRating"
                                    [readonly]="true"
                                    [max]="5"
                                  ></ngb-rating>
                                  &nbsp; {{ row.ambienteRating }}
                                </div>
                              </div>
                              <div class="col-sm-2">
                                Preis/Leistung
                                <div>
                                  <ng-template #preisRating let-fill="fill">
                                    <span class="star" [class.full]="fill === 100">
                                      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                                    </span>
                                  </ng-template>
                                  <ngb-rating
                                    [(rate)]="row.preisRating"
                                    [starTemplate]="preisRating"
                                    [readonly]="true"
                                    [max]="5"
                                  ></ngb-rating>
                                  &nbsp; {{ row.preisRating }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style="margin-top:5px">
                            <button
                              class="btn btn-light"
                              *ngIf="!row.likeInfo && !row.likeLoader"
                              (click)="likeUnlikeReview('like', row)"
                            >
                              <i class="fas fa-thumbs-up"></i>&nbsp;
                              {{ row.likesCntr }}
                            </button>
                            <button class="btn btn-light pdf-btn" style="pointer-events: none;" *ngIf="row.likeLoader">
                              <i class="fa fa-spinner fa-spin"></i>
                            </button>
                            <button
                              class="btn btn-primary"
                              *ngIf="row.likeInfo && !row.likeLoader"
                              (click)="likeUnlikeReview('unlike', row)"
                            >
                              <i class="fas fa-thumbs-up"></i>&nbsp;
                              {{ row.likesCntr }}
                            </button>
                            <button
                              class="btn btn-light"
                              *ngIf="!row['well-written-info'] && !row.wellWrittenLoader"
                              (click)="likeUnlikeWellWritten('well-wriiten-like', row)"
                            >
                              <i class="far fa-heart"></i>&nbsp;
                              {{ row.wellWrittenCntr }}
                            </button>
                            <button
                              class="btn btn-light pdf-btn"
                              style="pointer-events: none;"
                              *ngIf="row.wellWrittenLoader"
                            >
                              <i class="fa fa-spinner fa-spin"></i>
                            </button>
                            <button
                              class="btn btn-primary"
                              *ngIf="row['well-written-info'] && !row.wellWrittenLoader"
                              (click)="likeUnlikeWellWritten('well-wriiten-unlike', row)"
                            >
                              <i class="far fa-heart"></i>&nbsp;
                              {{ row.wellWrittenCntr }}
                            </button>
                            <button class="btn btn-info" style="float: right;" (click)="reportReview(row)">
                              <i class="fas fa-clipboard" title="Report Review"></i>
                            </button>
                          </div>
                          <div style="margin-top:5px">
                            <div>
                              <textarea
                                style="outline: none; border: 1px solid #dedede"
                                class="col-sm-12"
                                [(ngModel)]="comment"
                              ></textarea>
                            </div>
                            <button class="btn btn-primary" (click)="postComment(row)">Comment</button>
                          </div>

                          <div *ngIf="row.comments && row.comments.length" class="parent">
                            <div class="header">{{ row.comments.length }} Comments</div>
                            <div>
                              <ul>
                                <li *ngFor="let comment of row.comments; index as i">
                                  <div style="padding: 5px">
                                    <table class="table">
                                      <tr>
                                        <td class="icon">
                                          <img
                                            class="imageIcon"
                                            *ngIf="comment.memberDataInfo.photoFile"
                                            src="http://cdn.gastroguide.de/{{ comment.memberDataInfo.photoFile }}"
                                            width="25"
                                            height="25"
                                          />
                                          <img
                                            class="imageIcon"
                                            *ngIf="!comment.memberDataInfo.photoFile"
                                            src="http://cdn.gastroguide.de/{{ betriebInfo.logo }}"
                                            width="25"
                                            height="25"
                                          />
                                        </td>
                                        <td>
                                          {{ comment.comment }}
                                          <div class="actionControls">
                                            <span style="cursor: pointer;" (click)="setReply(row, comment, null)"
                                              ><i class="fa fa-reply"></i>&nbsp;Reply</span
                                            >&nbsp;&nbsp;

                                            <span style="cursor: pointer;">
                                              <i
                                                class="far fa-thumbs-up"
                                                *ngIf="!comment['commentLikeData'] && !comment.likeLoader"
                                                (click)="likeUnlikeComment('like', row, comment)"
                                              ></i>
                                              <i
                                                class="fa fa-spinner fa-spin"
                                                style="pointer-events: none;"
                                                *ngIf="comment.likeLoader"
                                              ></i>
                                              <i
                                                class="fas fa-thumbs-up"
                                                *ngIf="comment['commentLikeData'] && !comment.likeLoader"
                                                (click)="likeUnlikeComment('unlike', row, comment)"
                                              ></i>
                                              &nbsp;{{ comment.likes }} </span
                                            >&nbsp;&nbsp;

                                            <span
                                              style="cursor: pointer;"
                                              *ngIf="comment.memberDataInfo.id === memberInfo.id"
                                              (click)="editComment(comment, i, row)"
                                            >
                                              <i class="fa fa-edit"></i>&nbsp;Edit </span
                                            >&nbsp;&nbsp;
                                            <span
                                              style="cursor: pointer;"
                                              *ngIf="comment.memberDataInfo.id === memberInfo.id"
                                              (click)="removeComment(comment, row)"
                                            >
                                              <i class="fa fa-trash"></i>&nbsp;Remove </span
                                            >&nbsp;&nbsp;
                                          </div>

                                          <div *ngIf="comment.comments && comment.comments.length" class="parent">
                                            <div class="header">{{ comment.comments.length }} Comments</div>
                                            <div>
                                              <ul>
                                                <li *ngFor="let childComment of comment.comments; index as i">
                                                  <div style="padding:5px">
                                                    <table class="table">
                                                      <tr>
                                                        <td class="icon">
                                                          <img
                                                            class="imageIcon"
                                                            *ngIf="childComment.memberDataInfo.photoFile"
                                                            src="http://cdn.gastroguide.de/{{
                                                              childComment.memberDataInfo.photoFile
                                                            }}"
                                                            width="25"
                                                            height="25"
                                                          />
                                                          <img
                                                            class="imageIcon"
                                                            *ngIf="!childComment.memberDataInfo.photoFile"
                                                            src="http://cdn.gastroguide.de/{{ betriebInfo.logo }}"
                                                            width="25"
                                                            height="25"
                                                          />
                                                        </td>
                                                        <td>
                                                          {{ childComment.comment }}
                                                          <div class="actionControls">
                                                            <span
                                                              style="cursor: pointer;"
                                                              (click)="setReply(row, childComment, null)"
                                                              ><i class="fa fa-reply"></i>&nbsp;Reply</span
                                                            >&nbsp;&nbsp;
                                                            <span style="cursor: pointer;">
                                                              <i
                                                                class="far fa-thumbs-up"
                                                                *ngIf="
                                                                  !childComment['commentLikeData'] &&
                                                                  !childComment.likeLoader
                                                                "
                                                                (click)="likeUnlikeComment('like', row, childComment)"
                                                              ></i>
                                                              <i
                                                                class="fa fa-spinner fa-spin"
                                                                style="pointer-events: none;"
                                                                *ngIf="childComment.likeLoader"
                                                              ></i>
                                                              <i
                                                                class="fas fa-thumbs-up"
                                                                *ngIf="
                                                                  childComment['commentLikeData'] &&
                                                                  !childComment.likeLoader
                                                                "
                                                                (click)="likeUnlikeComment('unlike', row, childComment)"
                                                              ></i>
                                                              &nbsp;{{ childComment.likes }} </span
                                                            >&nbsp;&nbsp;
                                                            <span
                                                              style="cursor: pointer;"
                                                              *ngIf="childComment.memberDataInfo.id === memberInfo.id"
                                                              (click)="editComment(childComment, p, comment)"
                                                            >
                                                              <i class="fa fa-edit"></i>&nbsp;Edit </span
                                                            >&nbsp;&nbsp;
                                                            <span
                                                              style="cursor: pointer;"
                                                              *ngIf="childComment.memberDataInfo.id === memberInfo.id"
                                                              (click)="removeComment(childComment, comment)"
                                                            >
                                                              <i class="fa fa-trash"></i>&nbsp;Remove </span
                                                            >&nbsp;&nbsp;
                                                          </div>

                                                          <div
                                                            *ngIf="
                                                              childComment.comments && childComment.comments.length
                                                            "
                                                            class="parent"
                                                          >
                                                            <div class="header">
                                                              {{ childComment.comments.length }} Comments
                                                            </div>
                                                            <div>
                                                              <ul>
                                                                <li
                                                                  *ngFor="
                                                                    let grandChildComment of childComment.comments;
                                                                    index as i
                                                                  "
                                                                >
                                                                  <div style="padding:5px">
                                                                    <table class="table">
                                                                      <tr>
                                                                        <td class="icon">
                                                                          <img
                                                                            class="imageIcon"
                                                                            *ngIf="
                                                                              grandChildComment.memberDataInfo.photoFile
                                                                            "
                                                                            src="http://cdn.gastroguide.de/{{
                                                                              grandChildComment.memberDataInfo.photoFile
                                                                            }}"
                                                                            width="25"
                                                                            height="25"
                                                                          />
                                                                          <img
                                                                            class="imageIcon"
                                                                            *ngIf="
                                                                              !grandChildComment.memberDataInfo
                                                                                .photoFile
                                                                            "
                                                                            src="http://cdn.gastroguide.de/{{
                                                                              betriebInfo.logo
                                                                            }}"
                                                                            width="25"
                                                                            height="25"
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          {{ grandChildComment.comment }}
                                                                          <div class="actionControls">
                                                                            <span
                                                                              style="cursor: pointer;"
                                                                              (click)="
                                                                                setReply(
                                                                                  row,
                                                                                  grandChildComment,
                                                                                  childComment
                                                                                )
                                                                              "
                                                                              ><i class="fa fa-reply"></i
                                                                              >&nbsp;Reply</span
                                                                            >&nbsp;&nbsp;
                                                                            <span style="cursor: pointer;">
                                                                              <i
                                                                                class="far fa-thumbs-up"
                                                                                *ngIf="
                                                                                  !grandChildComment[
                                                                                    'commentLikeData'
                                                                                  ] && !grandChildComment.likeLoader
                                                                                "
                                                                                (click)="
                                                                                  likeUnlikeComment(
                                                                                    'like',
                                                                                    row,
                                                                                    grandChildComment
                                                                                  )
                                                                                "
                                                                              ></i>
                                                                              <i
                                                                                class="fa fa-spinner fa-spin"
                                                                                style="pointer-events: none;"
                                                                                *ngIf="grandChildComment.likeLoader"
                                                                              ></i>
                                                                              <i
                                                                                class="fas fa-thumbs-up"
                                                                                *ngIf="
                                                                                  grandChildComment[
                                                                                    'commentLikeData'
                                                                                  ] && !grandChildComment.likeLoader
                                                                                "
                                                                                (click)="
                                                                                  likeUnlikeComment(
                                                                                    'unlike',
                                                                                    row,
                                                                                    grandChildComment
                                                                                  )
                                                                                "
                                                                              ></i>
                                                                              &nbsp;{{ grandChildComment.likes }} </span
                                                                            >&nbsp;&nbsp;
                                                                            <span
                                                                              style="cursor: pointer;"
                                                                              *ngIf="
                                                                                grandChildComment.memberDataInfo.id ===
                                                                                memberInfo.id
                                                                              "
                                                                              (click)="
                                                                                editComment(
                                                                                  grandChildComment,
                                                                                  p,
                                                                                  childComment
                                                                                )
                                                                              "
                                                                            >
                                                                              <i class="fa fa-edit"></i
                                                                              >&nbsp;Edit </span
                                                                            >&nbsp;&nbsp;
                                                                            <span
                                                                              style="cursor: pointer;"
                                                                              *ngIf="
                                                                                grandChildComment.memberDataInfo.id ===
                                                                                memberInfo.id
                                                                              "
                                                                              (click)="
                                                                                removeComment(
                                                                                  grandChildComment,
                                                                                  childComment
                                                                                )
                                                                              "
                                                                            >
                                                                              <i class="fa fa-trash"></i
                                                                              >&nbsp;Remove </span
                                                                            >&nbsp;&nbsp;
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    </table>
                                                                  </div>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <!-- <mat-accordion class="integration-expandable" #commentTemplateRef>

                            <mat-expansion-panel class="mat-expanded" *ngIf="row.comments && row.comments.length" style="margin-top: 10px;">

                              <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
                                <mat-panel-title> View {{ row.comments.length }} Comments </mat-panel-title>
                              </mat-expansion-panel-header>

                              <ul>
                                <li *ngFor="let comment of row.comments; index as i">
                                  <div style="padding:5px">
                                    <span>
                                      <img src="http://cdn.gastroguide.de/{{comment.memberDataInfo.photoFile}}" width="20" height="20" style="border-radius:25px">
                                    </span>&nbsp;
                                    {{ comment.comment }}
                                  </div>
                                  <div style="padding:10px">
                                    <span style="cursor: pointer;" (click)="setReply(row, comment, null)"><i class="fa fa-reply"></i>&nbsp;Reply</span>&nbsp;&nbsp;

                                    <span style="cursor: pointer;">
                                      <i class="far fa-thumbs-up" *ngIf="!comment['commentLikeData'] && !comment.likeLoader" (click)="likeUnlikeComment('like', row, comment)"></i> 
                                      <i class="fa fa-spinner fa-spin" 
                                        style="pointer-events: none;"
                                        *ngIf="comment.likeLoader"></i>
                                      <i class="fas fa-thumbs-up" *ngIf="comment['commentLikeData'] && !comment.likeLoader" (click)="likeUnlikeComment('unlike', row, comment)"></i>
                                      &nbsp;{{ comment.likes }}
                                    </span>&nbsp;&nbsp;

                                    <span style="cursor: pointer;" *ngIf="comment.memberDataInfo.id === memberInfo.id" (click)="editComment(comment, i, row)">
                                      <i class="fa fa-edit"></i>&nbsp;Edit
                                    </span>&nbsp;&nbsp;
                                    <span style="cursor: pointer;" *ngIf="comment.memberDataInfo.id === memberInfo.id" (click)="removeComment(comment, row)">
                                      <i class="fa fa-trash"></i>&nbsp;Remove
                                    </span>&nbsp;&nbsp;
                                  </div>
                                  
                                  <div>
                                    <mat-accordion class="integration-expandable">
                                      <mat-expansion-panel *ngIf="comment.comments && comment.comments.length" style="margin-top: 10px;">

                                        <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
                                          <mat-panel-title> View {{ comment.comments.length }} Comments </mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <ul>
                                          <li *ngFor="let childComment of comment.comments; index as p">
                                            <div style="padding:5px">
                                              <span>
                                                <img src="http://cdn.gastroguide.de/{{childComment.memberDataInfo.photoFile}}" width="20" height="20" style="border-radius:25px">
                                              </span>&nbsp;
                                              {{ childComment.comment }}
                                            </div>
                                            <div style="padding:10px">
                                              <span style="cursor: pointer;" (click)="setReply(row, childComment, null)"><i class="fa fa-reply"></i>&nbsp;Reply</span>&nbsp;&nbsp;
                                              <span style="cursor: pointer;">
                                                <i class="far fa-thumbs-up" *ngIf="!childComment['commentLikeData'] && !childComment.likeLoader" (click)="likeUnlikeComment('like', row, childComment)"></i> 
                                                <i class="fa fa-spinner fa-spin" 
                                                  style="pointer-events: none;"
                                                  *ngIf="childComment.likeLoader"></i>
                                                <i class="fas fa-thumbs-up" *ngIf="childComment['commentLikeData'] && !childComment.likeLoader" (click)="likeUnlikeComment('unlike', row, childComment)"></i>
                                                &nbsp;{{ childComment.likes }}
                                              </span>&nbsp;&nbsp;
                                              <span style="cursor: pointer;" *ngIf="childComment.memberDataInfo.id === memberInfo.id" (click)="editComment(childComment, p, comment)">
                                                <i class="fa fa-edit"></i>&nbsp;Edit
                                              </span>&nbsp;&nbsp;
                                              <span style="cursor: pointer;" *ngIf="childComment.memberDataInfo.id === memberInfo.id" (click)="removeComment(childComment, comment)">
                                                <i class="fa fa-trash"></i>&nbsp;Remove
                                              </span>&nbsp;&nbsp;
                                            </div>

                                            <div>
                                              <mat-accordion class="integration-expandable">
                                                <mat-expansion-panel *ngIf="childComment.comments && childComment.comments.length" style="margin-top: 10px;">
          
                                                  <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
                                                    <mat-panel-title> View {{ childComment.comments.length }} Comments </mat-panel-title>
                                                  </mat-expansion-panel-header>
          
                                                  <ul>
                                                    <li *ngFor="let grandChildComment of childComment.comments; index as p">
                                                      <div style="padding:5px">
                                                        <span>
                                                          <img src="http://cdn.gastroguide.de/{{grandChildComment.memberDataInfo.photoFile}}" width="20" height="20" style="border-radius:25px">
                                                        </span>&nbsp;
                                                        {{ grandChildComment.comment }}
                                                      </div>
                                                      <div style="padding:10px">
                                                        <span style="cursor: pointer;" (click)="setReply(row, grandChildComment, childComment)"><i class="fa fa-reply"></i>&nbsp;Reply</span>&nbsp;&nbsp;
                                                        <span style="cursor: pointer;">
                                                          <i class="far fa-thumbs-up" *ngIf="!grandChildComment['commentLikeData'] && !grandChildComment.likeLoader" (click)="likeUnlikeComment('like', row, grandChildComment)"></i> 
                                                          <i class="fa fa-spinner fa-spin" 
                                                            style="pointer-events: none;"
                                                            *ngIf="grandChildComment.likeLoader"></i>
                                                          <i class="fas fa-thumbs-up" *ngIf="grandChildComment['commentLikeData'] && !grandChildComment.likeLoader" (click)="likeUnlikeComment('unlike', row, grandChildComment)"></i>
                                                          &nbsp;{{ grandChildComment.likes }}
                                                        </span>&nbsp;&nbsp;
                                                        <span style="cursor: pointer;" *ngIf="grandChildComment.memberDataInfo.id === memberInfo.id" (click)="editComment(grandChildComment, p, childComment)">
                                                          <i class="fa fa-edit"></i>&nbsp;Edit
                                                        </span>&nbsp;&nbsp;
                                                        <span style="cursor: pointer;" *ngIf="grandChildComment.memberDataInfo.id === memberInfo.id" (click)="removeComment(grandChildComment, childComment)">
                                                          <i class="fa fa-trash"></i>&nbsp;Remove
                                                        </span>&nbsp;&nbsp;
                                                      </div>
                                                    </li>
                                                  </ul>
          
                                                </mat-expansion-panel>
                                              </mat-accordion>
                                            </div>


                                          </li>
                                        </ul>

                                      </mat-expansion-panel>
                                    </mat-accordion>
                                  </div>

                                </li>
                              </ul>

                            </mat-expansion-panel>
                          </mat-accordion> -->
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngx-datatable-row-detail>

            <ngx-datatable-column
              [width]="30"
              [resizeable]="false"
              [sortable]="false"
              [draggable]="false"
              [canAutoResize]="false"
            >
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                <a
                  href="javascript:void(0)"
                  [class.datatable-icon-right]="!expanded"
                  [class.datatable-icon-down]="expanded"
                  title="Expand/Collapse Row"
                  (click)="toggleExpandRow(row)"
                >
                  <i class="fas fa-chevron-right"></i>
                </a>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Reviews">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span
                  ><img
                    src="http://cdn.gastroguide.de/{{ row.memberInfo.photoFile }}"
                    width="20"
                    height="20"
                    style="border-radius:25px"
                /></span>
                <b style="font-weight: bold;"> &nbsp;{{ row.memberInfo.username }} </b>
                <span>&nbsp;( {{ row.readCntr }} <i class="fa fa-star"></i>)</span>
                <span *ngIf="row.postedDate">&nbsp;am {{ row.postedDate }}</span>
                <span style="float: right;">
                  <ngb-rating [max]="5" [(rate)]="row.rating" [readonly]="true"></ngb-rating> &nbsp; {{ row.rating }}
                </span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngStyle]="{ display: loader ? 'block' : 'none' }" style="height: 50%;">
  <div style="position: relative; left: 25%; top: 50%; width: 50%;">
    <span>Please Wait...</span>
    <mat-progress-bar mode="indeterminate" [value]="10"></mat-progress-bar>
  </div>
</div>
