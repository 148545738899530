<div class="modal-content text-center">
  <div class="modal-header">
    <h4 class="modal-title">Edit Comment</h4>
    <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
      <span class="fas fa-times"></span>
    </button>
  </div>
  <div class="onboarding-content" style="text-align: left;">
    Edit Comment
    <textarea class="col-sm-12" [(ngModel)]="comment" style="outline: none; border:1px solid #dedede"> </textarea>
    <button class="btn btn-primary" (click)="updateComment()">Update</button>
    <button class="btn btn-secondary" (click)="activeModal.dismiss()">Abort</button>
  </div>
</div>
