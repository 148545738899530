<breadcrumb></breadcrumb>
<div class="content-box" [ngStyle]="{ display: loader ? 'none' : 'block' }">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Gallery
        </h6>
        <div class="element-box">
          <div class="form-group">
            <button class="btn btn-primary" (click)="create()">Create Album</button>
          </div>
          <div class="desktop-only" *ngIf="albumData && albumData.length">
            <ngx-datatable
              class="material striped"
              [rows]="albumData"
              [columnMode]="'force'"
              headerHeight="50"
              footerHeight="50"
              rowHeight="145"
              [limit]="50"
              [cssClasses]="{
                pagerLeftArrow: 'fas fa-backward',
                pagerRightArrow: 'fas fa-forward',
                pagerPrevious: 'fas fa-step-backward',
                pagerNext: 'fas fa-step-forward'
              }"
            >
              <ngx-datatable-column [sortable]="true" name="Album Name">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="!row.isEdit" title="{{ row.name }}" class="date-label">
                    <b>{{ row.name }}</b> </span
                  >&nbsp;
                  <i class="fa fa-edit" *ngIf="!row.isEdit" style="cursor: pointer;" (click)="row['isEdit'] = true"></i>
                  <input *ngIf="row.isEdit" type="text" [(ngModel)]="albumNameData[row.id].name" />
                  <button *ngIf="row.isEdit" class="btn btn-primary" (click)="set(row)">Set</button>
                  <button *ngIf="row.isEdit" class="btn btn-secondary" (click)="cancel(row)">Abort</button>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="true" name="Total Photos">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.albums.length" title="{{ row.albums.length }}" class="date-label">
                    {{ row.albums.length }} Photos
                  </span>
                  <span *ngIf="!row.albums.length" title="{{ row.albums.length }}" class="date-label">
                    No Photos
                  </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="true" name="Options">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div>
                    <input
                      type="checkbox"
                      (change)="set(row)"
                      [(ngModel)]="checkboxData[row.id].showInGastroguide"
                    />&nbsp;Visible on Gastroguide.de
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      (change)="set(row)"
                      [(ngModel)]="checkboxData[row.id].showInWebsite"
                    />&nbsp;Visible on their own homepage
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      (change)="set(row)"
                      [(ngModel)]="checkboxData[row.id].showInApp"
                    />&nbsp;Visible in the app
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      (change)="set(row)"
                      [(ngModel)]="checkboxData[row.id].isSliderAlbum"
                    />&nbsp;Homepage slider album
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="true" name="Action">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button class="btn" (click)="delete(row)">
                    <i class="fa fa-trash" style="color:red;"></i>
                  </button>
                  <button class="btn" (click)="upload(row)">
                    <i class="fa fa-upload" style="color:green;"></i>
                  </button>
                  <button class="btn" (click)="viewAlbum(row)">
                    <i class="fas fa-images" style="color:palevioletred;"></i>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
          <!-- <div class="mobile-only">
            <ngx-datatable
              #myTable
              class="material striped expandable"
              [rows]="activeDates"
              [columnMode]="'force'"
              headerHeight="50"
              footerHeight="50"
              rowHeight="auto"
              [limit]="50"
              [cssClasses]="{
                pagerLeftArrow: 'fas fa-backward',
                pagerRightArrow: 'fas fa-forward',
                pagerPrevious: 'fas fa-step-backward',
                pagerNext: 'fas fa-step-forward'
              }"
            >
              <ngx-datatable-row-detail [rowHeight]="auto" #myDetailRow (toggle)="onDetailToggle($event)">
                <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                  <ul class="table-list">
                    <li>
                      <label>{{ 'Events&Tickets.AllDates.Beginning' | translate }}</label>
                      <strong>{{ row.startsAtDay }}</strong>
                    </li>
                    <li>
                      <label>{{ 'Events&Tickets.Common.Time' | translate }}</label>
                      <strong>{{ row.startsAtHour }}</strong>
                    </li>
                    <li>
                      <label>{{ 'Events&Tickets.AllDates.End' | translate }}</label>
                      <strong>{{ row.endsAtDay }}</strong>
                    </li>
                    <li>
                      <label>{{ 'Events&Tickets.Common.Time' | translate }}</label>
                      <strong>{{ row.endsAtHour }}</strong>
                    </li>
                  </ul>
                </ng-template>
              </ngx-datatable-row-detail>
              <ngx-datatable-column
                [width]="30"
                [resizeable]="false"
                [sortable]="false"
                [draggable]="false"
                [canAutoResize]="false"
              >
                <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                  <a
                    href="javascript:void(0)"
                    [class.datatable-icon-right]="!expanded"
                    [class.datatable-icon-down]="expanded"
                    title="Expand/Collapse Row"
                    (click)="toggleExpandRow(row)"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="true"
                name="{{ 'Events&Tickets.Common.EventName' | translate }}"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span
                    title="{{ row.event.name }}"
                    class="date-label"
                    style="cursor: pointer;"
                    (click)="editEvent(row.event, 'active')"
                  >
                    {{ row.event.name }}
                  </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="true" name="{{ 'Events&Tickets.Common.Actions' | translate }}">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button class="btn edit-btn" (click)="updateDate(row, row.event)" matTooltip="Bearbeiten">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    class="btn delete-btn"
                    (click)="removeEventDate(row.id, row.event.id, 'active')"
                    matTooltip="Löschen"
                  >
                    <i class="far fa-trash-alt"></i>
                  </button>
                  <button class="btn view-btn" (click)="preview(row.event.id, row.id)">
                    <i class="fas fa-eye"></i>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngStyle]="{ display: loader ? 'block' : 'none' }" style="height: 100%;">
  <div style="position: relative; left: 25%; top: 45%; width: 50%;">
    <span>Please Wait...</span>
    <mat-progress-bar mode="indeterminate" [value]="10"></mat-progress-bar>
  </div>
</div>
