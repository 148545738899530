<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      Move Tickets
    </h4>
    <form [formGroup]="moveTicketDateFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for=""> {{ eventData.name }} ({{ ticketInfo.eventDate }})</label>
          </div>
        </div>
        <div class="ticket-list">
          <ul *ngIf="ticketInfo.tickets && ticketInfo.tickets.length">
            <li *ngFor="let ticket of ticketInfo.tickets">
              <div class="ticket-name">
                <label>Ticket Name</label>
                <span class="value">{{ ticket.ticket.name }}</span>
                <span class="value" *ngIf="ticket.subCategory && ticket.subCategory.name">
                  ({{ ticket.subCategory.name }})</span
                >
              </div>
            </li>
          </ul>
        </div>

        <!-- <div class="col-sm-12">
          <div class="form-group">
            <label for="">All associated tickets</label>
            <br />
            <span *ngFor="let ticketDates of associatedTickets" class="checkbox">
              <label class="days-checkbox">
                <input
                  type="checkbox"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="ticketDates.isChecked"
                  (change)="ticketDates.isChecked = $event.target.checked"
                />&nbsp; {{ ticketDates.name }}&nbsp;
              </label>
            </span>
          </div>
        </div> -->

        <div class="col-sm-12" *ngIf="eventData && eventData.eventDates">
          <div class="form-group">
            <label for="">Move to</label>
            <select class="form-control" formControlName="dateId">
              <option *ngFor="let date of eventData.eventDates" [ngValue]="date.id">
                {{ date.startsAtDay | amDateFormat: 'DD.MM.YYYY' }} {{ date.startsAtHour ? date.startsAtHour : '' }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

      <div class="clearfix" style="text-align: center;margin-top: 15px;">
        <button class="btn btn-block btn-primary btn-lg" (click)="save()">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
