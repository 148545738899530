var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationService } from '../reservation.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
export class GuestComponent {
    constructor(activeModal, reservationService, formBuilder, snackBar) {
        this.activeModal = activeModal;
        this.reservationService = reservationService;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.passEntry = new EventEmitter();
        this.loadingReservationList = true;
        this.ngUnsubscribe = new Subject();
    }
    ngOnInit() {
        this.reservationService.getGuest(this.guestId).subscribe((guestData) => {
            this.guestData = guestData;
            this.guestFormGroup = this.formBuilder.group({
                name: [guestData.name, Validators.required],
                email: [guestData.email],
                phone: [guestData.phone],
                birthdate: [guestData.birthdate],
                notes: [guestData.notes]
            });
        });
        this.getGuestReservations();
    }
    getGuestReservations() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loadingReservationList = true;
            yield this.reservationService
                .getGuestReservations(this.guestId)
                .toPromise()
                .then((data) => {
                this.loadingReservationList = false;
                this.reservations = data;
            });
        });
    }
    save() {
        const passData = this.guestFormGroup.value;
        passData.id = this.guestData.id;
        passData.birthdate = passData.birthdate.format('YYYY-MM-DD');
        this.savingPromise = this.reservationService
            .saveGuest(passData)
            .toPromise()
            .then((res) => {
            if (res) {
                this.snackBar.open('Gast-Daten gespeichert.', '', {
                    duration: 2000,
                    panelClass: ['snackbar-success']
                });
            }
            else {
                this.snackBar.open('Gast-Daten konnten nicht gespeichert werden:' + res.msg, '', {
                    duration: 2000,
                    panelClass: ['snackbar-error']
                });
            }
        });
    }
}
