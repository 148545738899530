var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getTransformationsFromExifData, supportsAutomaticRotation } from '../utils/exif.utils';
import * as i0 from "@angular/core";
export class LoadImageService {
    constructor() {
        this.autoRotateSupported = supportsAutomaticRotation();
    }
    loadImageFile(file, cropperSettings) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                this.loadImage(event.target.result, file.type, cropperSettings)
                    .then(resolve)
                    .catch(reject);
            };
            fileReader.readAsDataURL(file);
        });
    }
    loadImage(imageBase64, imageType, cropperSettings) {
        if (!this.isValidImageType(imageType)) {
            return Promise.reject(new Error('Invalid image type'));
        }
        return this.loadBase64Image(imageBase64, cropperSettings);
    }
    isValidImageType(type) {
        return /image\/(png|jpg|jpeg|bmp|gif|tiff|webp)/.test(type);
    }
    loadImageFromURL(url, cropperSettings) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onerror = () => reject;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);
                this.loadBase64Image(canvas.toDataURL(), cropperSettings).then(resolve);
            };
            img.crossOrigin = 'anonymous';
            img.src = url;
        });
    }
    loadBase64Image(imageBase64, cropperSettings) {
        return new Promise((resolve, reject) => {
            const originalImage = new Image();
            originalImage.onload = () => resolve({
                originalImage,
                originalBase64: imageBase64
            });
            originalImage.onerror = reject;
            originalImage.src = imageBase64;
        }).then((res) => this.transformImageBase64(res, cropperSettings));
    }
    transformImageBase64(res, cropperSettings) {
        return __awaiter(this, void 0, void 0, function* () {
            const autoRotate = yield this.autoRotateSupported;
            const exifTransform = yield getTransformationsFromExifData(autoRotate ? -1 : res.originalBase64);
            if (!res.originalImage || !res.originalImage.complete) {
                return Promise.reject(new Error('No image loaded'));
            }
            const loadedImage = {
                original: {
                    base64: res.originalBase64,
                    image: res.originalImage,
                    size: {
                        width: res.originalImage.naturalWidth,
                        height: res.originalImage.naturalHeight
                    }
                },
                exifTransform
            };
            return this.transformLoadedImage(loadedImage, cropperSettings);
        });
    }
    transformLoadedImage(loadedImage, cropperSettings) {
        return __awaiter(this, void 0, void 0, function* () {
            const canvasRotation = cropperSettings.canvasRotation + loadedImage.exifTransform.rotate;
            const originalSize = {
                width: loadedImage.original.image.naturalWidth,
                height: loadedImage.original.image.naturalHeight
            };
            if (canvasRotation === 0 && !loadedImage.exifTransform.flip && !cropperSettings.containWithinAspectRatio) {
                return {
                    original: {
                        base64: loadedImage.original.base64,
                        image: loadedImage.original.image,
                        size: Object.assign({}, originalSize)
                    },
                    transformed: {
                        base64: loadedImage.original.base64,
                        image: loadedImage.original.image,
                        size: Object.assign({}, originalSize)
                    },
                    exifTransform: loadedImage.exifTransform
                };
            }
            const transformedSize = this.getTransformedSize(originalSize, loadedImage.exifTransform, cropperSettings);
            const canvas = document.createElement('canvas');
            canvas.width = transformedSize.width;
            canvas.height = transformedSize.height;
            const ctx = canvas.getContext('2d');
            ctx.setTransform(loadedImage.exifTransform.flip ? -1 : 1, 0, 0, 1, canvas.width / 2, canvas.height / 2);
            ctx.rotate(Math.PI * (canvasRotation / 2));
            ctx.drawImage(loadedImage.original.image, -originalSize.width / 2, -originalSize.height / 2);
            const transformedBase64 = canvas.toDataURL();
            const transformedImage = yield this.loadImageFromBase64(transformedBase64);
            return {
                original: {
                    base64: loadedImage.original.base64,
                    image: loadedImage.original.image,
                    size: Object.assign({}, originalSize)
                },
                transformed: {
                    base64: transformedBase64,
                    image: transformedImage,
                    size: {
                        width: transformedImage.width,
                        height: transformedImage.height
                    }
                },
                exifTransform: loadedImage.exifTransform
            };
        });
    }
    loadImageFromBase64(imageBase64) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(image);
            image.onerror = reject;
            image.src = imageBase64;
        });
    }
    getTransformedSize(originalSize, exifTransform, cropperSettings) {
        const canvasRotation = cropperSettings.canvasRotation + exifTransform.rotate;
        if (cropperSettings.containWithinAspectRatio) {
            if (canvasRotation % 2) {
                const minWidthToContain = originalSize.width * cropperSettings.aspectRatio;
                const minHeightToContain = originalSize.height / cropperSettings.aspectRatio;
                return {
                    width: Math.max(originalSize.height, minWidthToContain),
                    height: Math.max(originalSize.width, minHeightToContain)
                };
            }
            else {
                const minWidthToContain = originalSize.height * cropperSettings.aspectRatio;
                const minHeightToContain = originalSize.width / cropperSettings.aspectRatio;
                return {
                    width: Math.max(originalSize.width, minWidthToContain),
                    height: Math.max(originalSize.height, minHeightToContain)
                };
            }
        }
        if (canvasRotation % 2) {
            return {
                height: originalSize.width,
                width: originalSize.height
            };
        }
        return {
            width: originalSize.width,
            height: originalSize.height
        };
    }
}
LoadImageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadImageService_Factory() { return new LoadImageService(); }, token: LoadImageService, providedIn: "root" });
