<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Räume und Tische
          <span class="version-toggle">
            <a *ngIf="!combinationTables" (click)="combinationTables = true; saveVersionSettings()"
              >Neues Tischkombinationssystem</a
            >
            <a *ngIf="combinationTables" (click)="combinationTables = false; saveVersionSettings()"
              >Altes Tischkombinationssystem</a
            >
          </span>
        </h6>
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)" [selectedIndex]="tab">
          <mat-tab label="Räume und Tische">
            <div class="element-box">
              <h5 class="form-header">Räume und Tische</h5>
              <div class="form-desc">
                Legen Sie Ihre Räume und Tische an in denen reserviert werden soll. Sie können die Sortierung der Räume
                und Tische mittels Drag & Drop vornehmen.
                <button class="btn btn-primary" (click)="addRoom()" style="margin-top:15px">
                  <i class="fa fa-plus-circle fa-fw"></i> Neuen Raum anlegen
                </button>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="form-group">
                    <div>
                      <mat-slide-toggle name="isVisible" [(ngModel)]="isVisible">
                        Tische einblenden
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
              </div>
              <!-- cdkDropListOrientation="horizontal" -->
              <div
                cdkDropList
                [cdkDropListData]="rooms"
                (cdkDropListDropped)="dropGroup($event)"
                class="row"
                style="margin-top: 10px"
              >
                <div
                  class="drag-container col-sm-6 col-md-4"
                  *ngFor="let room of rooms; let i = index"
                  cdkDropListGroup
                  cdkDrag
                  [cdkDragData]="room"
                >
                  <h5>
                    <div>
                      <button
                        class="btn btn-white btn-sm"
                        matTooltip="Klicken und verschieben um Raum zu sortieren"
                        cdkDragHandle
                      >
                        <mat-icon svgIcon="move"></mat-icon>
                      </button>
                      {{ room.name }}
                    </div>
                    <div>
                      <button class="btn btn-white btn-sm" (click)="editRoom(room, i)" matTooltip="Raum bearbeiten">
                        <mat-icon svgIcon="edit"></mat-icon>
                      </button>
                      <div
                        ngbDropdown
                        style="display:inline-block"
                        class="btn btn-white btn-sm"
                        matTooltip="Tisch oder Kombination hinzufügen"
                      >
                        <mat-icon svgIcon="plus" ngbDropdownToggle style="width: 24px;height: 24px"></mat-icon>
                        <div ngbDropdownMenu aria-labelledby="shiftDropdown">
                          <button ngbDropdownItem (click)="addTable(room)">Tisch</button>
                          <button ngbDropdownItem (click)="addCombination(room)">Kombination</button>
                        </div>
                      </div>
                    </div>
                  </h5>

                  <div
                    cdkDropList
                    [cdkDropListData]="room.tables"
                    class="rooms-list"
                    [style.display]="isVisible ? 'block' : 'none'"
                    id="{{ room.id }}"
                    (cdkDropListDropped)="dropItem($event)"
                    [cdkDropListConnectedTo]="getConnectedList()"
                  >
                    <div class="pipeline-item" *ngFor="let item of room.tables" cdkDrag [cdkDragData]="item">
                      <div class="pi-controls">
                        <div class="pi-settings os-dropdown-trigger" ngbDropdown>
                          <mat-icon svgIcon="settings" ngbDropdownToggle></mat-icon>
                          <div ngbDropdownMenu>
                            <button ngbDropdownItem (click)="editTable(room, item)">Bearbeiten</button>
                            <button ngbDropdownItem (click)="deleteTable(item.id)">Löschen</button>
                          </div>
                        </div>
                        <div
                          class="status"
                          [class.status-green]="item.isActive"
                          [class.status-red]="!item.isActive"
                        ></div>
                      </div>
                      <div class="pi-body">
                        <div class="pi-info">
                          <div class="h6 pi-name">
                            <ng-template [ngIf]="!item.isCombined">Tisch</ng-template>
                            <ng-template [ngIf]="item.isCombined">Kombi</ng-template> #{{ item.name }}
                          </div>
                          <div class="pi-sub" *ngIf="!item.isCombined">
                            {{ item.seats }} <span *ngIf="item.seats === 1">Platz</span>
                            <span *ngIf="item.seats !== 1">Plätze</span>
                          </div>
                          <div class="pi-sub" *ngIf="item.isCombined">
                            <span class="badge badge-default" *ngFor="let cTable of item.combinedTables">{{
                              cTable.name
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Tischliste">
            <div class="element-box">
              <h5 class="form-header">Tischliste</h5>
              <div class="form-desc">
                Die Priorisierung der Tische wirkt sich nur auf die Online-Reservierung aus.
              </div>
              <ngb-alert type="warning" (close)="closeAlert(alert)" *ngIf="alert">
                {{ alert }}
              </ngb-alert>
              <div class="content-box" style="padding:0px;" #contentBoxEl>
                <div style="height:1000px;">
                  <ag-grid-angular
                    style="width: 100%; height: 100%;"
                    class="ag-theme-alpine"
                    [rowData]="items"
                    [gridOptions]="gridOptions"
                    [localeText]="gridLocale"
                    [frameworkComponents]="frameworkComponents"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [enableRangeSelection]="true"
                    [enableFillHandle]="true"
                    [modules]="modules"
                    [rowDragManaged]="true"
                    [animateRows]="true"
                    [allowContextMenuWithControlKey]="true"
                    [getContextMenuItems]="getContextMenuItems"
                    [sideBar]="sideBar"
                    (gridReady)="onGridReady($event)"
                    (cellValueChanged)="onCellValueChanged($event)"
                    (rangeSelectionChanged)="onRangeSelectionChanged($event)"
                    (rowDragEnd)="onRowDragEnd($event)"
                    (pasteEnd)="onPasteEnd($event)"
                  >
                  </ag-grid-angular>
                </div>
              </div>
              <div class="table-note">
                <span class="form-header">Active: Tisch ist für online Reservierung freigegeben</span>
                <span class="form-header">Instant Payment: Der Gast muss an diesem Tisch direkt online bezahlen</span>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="{{ 'tablePlan.tablePlan' | translate }}">
            <app-table-plan isEditing="true" [reservationBookSettings]="reservationBook"></app-table-plan>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
