<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="element-wrapper" style="padding-bottom: 0;">
    <h6 class="element-header">{{ 'ResStatistics.ReservationStatistics' | translate }}</h6>

    <div class="element-box">
      <div class="row">
        <div class="col-md-3">
          <section>
            <div class="form-group">
              <label for="singleSelect">{{ 'ResStatistics.Data' | translate }}:</label>
              <div class="input-group">
                <ng-select
                  [searchable]="false"
                  [items]="chartValueArray"
                  [clearable]="false"
                  [(ngModel)]="showChartValue"
                  bindLabel="name"
                  bindValue="value"
                  (change)="setDates(showChartType)"
                >
                </ng-select>
              </div>
            </div>
          </section>
        </div>

        <div class="col-md-4" *ngIf="showTagsSelector">
          <section>
            <div class="form-group">
              <label for="singleSelect">{{ 'ResStatistics.SelectTags' | translate }}: </label>
              <div class="input-group">
                <ng-select
                  [items]="tags"
                  bindLabel="label"
                  [multiple]="true"
                  placeholder="Select Tags"
                  [(ngModel)]="showTagsValue"
                  (change)="getSelectedTags(showTagsValue)"
                >
                </ng-select>
              </div>
            </div>
          </section>
        </div>

        <div [class.col-md-5]="showTagsSelector" [class.col-md-9]="!showTagsSelector">
          <section>
            <div class="form-group">
              <label for="">{{ 'ResStatistics.Period' | translate }}:</label><br />
              <button
                class="btn type-btn"
                [class.btn-light]="showChartType != 'next30days'"
                [class.btn-primary]="showChartType == 'next30days'"
                (click)="setDates('next30days')"
              >
                {{ 'ResStatistics.Next30Days' | translate }}
              </button>
              <button
                class="btn type-btn"
                [class.btn-light]="showChartType != 'last30days'"
                [class.btn-primary]="showChartType == 'last30days'"
                (click)="setDates('last30days')"
              >
                {{ 'ResStatistics.Last30days' | translate }}
              </button>
              <button
                class="btn type-btn"
                [class.btn-light]="showChartType != 'thisMonth'"
                [class.btn-primary]="showChartType == 'thisMonth'"
                (click)="setDates('thisMonth')"
              >
                {{ 'ResStatistics.ActiveMonth' | translate }}
              </button>
              <button
                class="btn type-btn"
                [class.btn-light]="showChartType != 'lastMonth'"
                [class.btn-primary]="showChartType == 'lastMonth'"
                (click)="setDates('lastMonth')"
              >
                {{ 'ResStatistics.LastMonth' | translate }}
              </button>
              <button
                class="btn type-btn"
                [class.btn-light]="showChartType != 'last24months'"
                [class.btn-primary]="showChartType == 'last24months'"
                (click)="setDates('last24months')"
              >
                {{ 'ResStatistics.YearByYear' | translate }}
              </button>
            </div>
          </section>
        </div>
      </div>
      <div class="row" *ngIf="showButtonDiv">
        <div class="col-md-3 col-sm-12">
          <div class="form-group">
            <label for="">{{ 'ResStatistics.SelectYear' | translate }}</label>
            <select
              class="form-control"
              placeholder="Select Year"
              [(ngModel)]="dateValue"
              (change)="filterYearBy($event.target.value)"
            >
              <option value="none" disabled>
                {{ 'ResStatistics.SelectYear' | translate }}
              </option>
              <option *ngFor="let country of optionYear" [value]="country">{{ country }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-9 col-sm-12" *ngIf="filterDiv.isDateFilter">
          <form [formGroup]="range" (ngSubmit)="getTransactionByYear()">
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="">{{ 'Events&Tickets.Common.StartDate' | translate }}</label>
                  <div class="input-group">
                    <div class="input-group-prepend" (click)="startDateFromPicker.open()">
                      <div class="input-group-text">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <mat-datepicker
                      #startDateFromPicker
                      (closed)="dateToInput.blur()"
                      [startAt]="minDate"
                    ></mat-datepicker>
                    <input
                      class="form-control date-picker"
                      #dateToInput
                      formControlName="startDate"
                      placeholder="Start Date"
                      type="text"
                      name="date"
                      [min]="minDate"
                      [max]="maxDate"
                      [matDatepicker]="startDateFromPicker"
                      (focus)="startDateFromPicker.open()"
                      autocomplete="false"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="">{{ 'Events&Tickets.Common.EndDate' | translate }}</label>
                  <div class="input-group">
                    <div class="input-group-prepend" (click)="endDateFromPicker.open()">
                      <div class="input-group-text">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <mat-datepicker
                      #endDateFromPicker
                      (closed)="dateFromInput.blur()"
                      [startAt]="minDate"
                    ></mat-datepicker>
                    <input
                      class="form-control date-picker"
                      #dateFromInput
                      formControlName="endDate"
                      placeholder="End Date"
                      type="text"
                      name="date"
                      [min]="minDate"
                      [max]="maxDate"
                      [matDatepicker]="endDateFromPicker"
                      (focus)="endDateFromPicker.open()"
                      autocomplete="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-sm-12">
                <div class="form-group">
                  <label for="">{{ 'RecurrenceTab.RepeatationType' | translate }}</label>
                  <select class="form-control" placeholder="Select type" (change)="typeOfGraph($event.target.value)">
                    <option value="daily">Daily</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 col-sm-12 submit-btn-div">
                <button class="btn btn-primary btn-lg" type="submit" [disabled]="!range.valid">
                  {{ 'ResStatistics.Submit' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 p-0" *ngIf="!(showBarCharts && !showTagsChart)">
    <a class="element-box el-tablo" href="javascript:return;">
      <div class="row">
        <div class="col-sm-3 text-center" *ngIf="showChartValue !== 'people' && showChartValue !== 'tags'">
          <div class="label">
            {{ 'ResStatistics.Reservation' | translate }}
          </div>
          <div class="value tlCount">
            <p>{{ _totalCount }}</p>
          </div>
        </div>
        <div class="col-sm-3 text-center" *ngIf="showChartValue === 'people'">
          <div class="label">
            {{ 'ResStatistics.TotalNumberOfGuest' | translate }}
          </div>
          <div class="value tlCount">
            <p>{{ totalCountOfPeople }}</p>
          </div>
        </div>

        <div class="col-sm-3 text-center" *ngIf="showChartValue === 'tags' && showTagsChart">
          <div class="label">
            {{ 'ResStatistics.TotalNumberOfTags' | translate }}
          </div>
          <div class="value tlCount">
            <p>{{ totalCountOfTags }}</p>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 text-center" *ngIf="showChartValue !== 'tags'">
          <div class="label">
            Online
          </div>
          <div class="value tlCount">
            <p style="margin-bottom: 5px;">
              {{ onlineAggr }}%<span class="cal-percentage">({{ _onlineCount }})</span>
            </p>
          </div>
          <div class="online-sub-cat-div">
            <span class="online-sub-cat"
              >( Web: {{ perOtherReferrerCount }}% / Google: {{ perMitGoogleCount }}% / Phone:
              {{ perAssistantAiCount }}% )</span
            >
          </div>
        </div>
        <div class="col-md-3 col-sm-3 text-center" *ngIf="showChartValue !== 'tags'">
          <div class="label">
            Manuell
          </div>
          <div class="value tlCount">
            <p>
              {{ manuellAggr }}%<span class="cal-percentage">({{ _manuellCount }})</span>
            </p>
          </div>
        </div>
        <div class="col-md-2 col-sm-2 text-center" *ngIf="showChartValue !== 'tags'">
          <div class="label" style="word-break: break-all;">
            Walkins
          </div>
          <div class="value tlCount">
            <p>
              {{ walkinsAggr }}%<span class="cal-percentage">({{ _walkinsCount }})</span>
            </p>
          </div>
        </div>

        <div class="col-md-9" *ngIf="showChartValue === 'tags'">
          <div class="row">
            <div *ngFor="let tags of listOfTagsWithCount">
              <div class="col-12 col-sm-12 text-center">
                <div class="label">
                  {{ tags.label }}
                </div>
                <div class="value tlCount">
                  <p>
                    {{ tags.percentage }}%<span class="cal-percentage">({{ tags.totalCount }})</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <div class="element-box mat-wrapper">
          <div *ngIf="!showBarCharts">
            <div class="chart-container" *ngIf="showChart && !showTagsChart && !showBarCharts">
              <canvas
                baseChart
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [legend]="barChartLegend"
                [chartType]="barChartType"
                [colors]="barChartColors"
              >
              </canvas>
            </div>
          </div>

          <div *ngIf="showTagsChart">
            <div class="chart-container" *ngIf="showChart && showTagsChart">
              <canvas
                baseChart
                [datasets]="barChartTagsData"
                [labels]="barChartLabelsTags"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [legend]="barChartLegend"
                [chartType]="barChartType"
                [colors]="barChartColorsTag"
              >
              </canvas>
            </div>
          </div>
          <div *ngIf="showBarCharts && !showTagsChart">
            <p>{{ 'ResStatistics.NoDataToDisplay' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
