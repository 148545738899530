var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EventsService } from '../events.service';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
export class MoveTicketDatesComponent {
    constructor(formBuilder, eventsService, snackBar, activeModal, translate) {
        this.formBuilder = formBuilder;
        this.eventsService = eventsService;
        this.snackBar = snackBar;
        this.activeModal = activeModal;
        this.translate = translate;
        this.passEntry = new EventEmitter();
        this.eventData = [];
        this.associatedTickets = [];
        this.ngUnsubscribe = new Subject();
        this.moveTicketDateFormGroup = this.formBuilder.group({
            oldDateId: [],
            dateId: ['', Validators.required],
            movingTickets: []
        });
        this.loadTranslation();
        this.translate.onLangChange.subscribe((event) => {
            this.loadTranslation();
        });
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const eventId = this.ticketInfo.tickets[0].eventId;
            let events1 = yield Promise.all([this.eventsService.getSingleEvent(eventId, 'active').toPromise()]);
            let events2 = yield Promise.all([this.eventsService.getAllDateTickets('active', eventId).toPromise()]);
            let commonEvents = [];
            // if (this.dateInfo && this.dateInfo.ticketDates) {
            //   this.dateInfo.ticketDates.map((item: any) => {
            //     this.associatedTickets.push({
            //       id: item.id,
            //       name: item.tickets.name,
            //       isChecked: false
            //     });
            //   });
            // }
            this.eventData = events1 && events1.length > 0 ? events1[0] : [];
            if (events1 && events1[0] && events1[0].eventDates && events2 && events2[0]) {
                events1 = events1[0].eventDates;
                events2 = events2[0];
                commonEvents = events1.filter((event1) => events2.some(event2 => event2.id === event1.id));
            }
            this.eventData.eventDates = commonEvents;
            let movingTickets = [];
            let oldDateId;
            if (this.ticketInfo && this.ticketInfo.tickets) {
                this.ticketInfo.tickets.map((item) => {
                    oldDateId = item.dateId;
                    movingTickets.push(item.id);
                });
            }
            this.moveTicketDateFormGroup.get('movingTickets').setValue(movingTickets);
            this.moveTicketDateFormGroup.get('oldDateId').setValue(oldDateId);
        });
    }
    loadTranslation() {
        this.formInvalid = this.translate.instant('Events&Tickets.Common.FormInvalid');
    }
    save() {
        // let selectedTickets: any = [];
        // selectedTickets = this.associatedTickets.filter((opt: any) => opt.isChecked).map((opt: any) => opt.id);
        // this.moveTicketDateFormGroup.get('selectedTickets').setValue(selectedTickets);
        // if (!this.moveTicketDateFormGroup.value.selectedTickets) {
        //   this.snackBar.open('Please select at least one ticket', '', {
        //     duration: 2000,
        //     panelClass: ['snackbar-error']
        //   });
        //   return;
        // }
        if (!this.moveTicketDateFormGroup.valid) {
            this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        console.log('data', this.moveTicketDateFormGroup.value);
        this.eventsService
            .moveTicketDate(this.moveTicketDateFormGroup.value)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response) => {
            this.passEntry.emit(response);
        }, err => {
            this.error = err.error.message;
        });
    }
}
