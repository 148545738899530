<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editChoiceData">Neues Auswahlfenster anlegen</ng-template>
      <ng-template [ngIf]="editChoiceData">Auswahlfenster ändern</ng-template>
    </h4>
    <form [formGroup]="choiceFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">
              Interner Name<br />
              <small>Nicht für den Gast sichtbar</small>
            </label>
            <input
              class="form-control"
              name="internalName"
              formControlName="internalName"
              type="text"
              value=""
              ngbAutofocus
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">
              Überschrift / Titel<br />
              <small>Für den Gast sichtbar</small>
            </label>
            <input
              class="form-control"
              placeholder="z.B Welche Extras möchtest du?"
              name="name"
              formControlName="name"
              type="text"
              value=""
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">
              Beschreibung<br />
              <small>Für den Gast sichtbar</small>
            </label>
            <textarea
              class="form-control"
              placeholder="(Optional)"
              type="text"
              name="description"
              formControlName="description"
              rows="2"
            ></textarea>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <label for="">
              Auswahl-Typ<br />
              <small>Wählen Sie die Art der Auswahl</small>
            </label>
            <ng-select
              appendTo="body"
              [searchable]="false"
              [clearable]="false"
              formControlName="type"
              placeholder="-- Typ auswählen --"
              [items]="choiceTypes"
              bindLabel="label"
              bindValue="value"
              (change)="changeType($event)"
            >
              <ng-template ng-label-tmp let-item="item">
                <span [class]="item.icon"></span>
                {{ item.label }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <span [class]="item.icon"></span>
                <b>{{ item.label }}</b>
                <small *ngIf="item.description" style="display:block; white-space: normal;">{{
                  item.description
                }}</small>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div
          class="col-sm-6"
          *ngIf="choiceFormGroup.get('type').value === 'checkbox' || choiceFormGroup.get('type').value === 'input'"
        >
          <div class="form-group">
            <label for="">
              Mindest-Anzahl<br />
              <small>Wieviele Produkte müssen mindestens ausgewählt werden?</small>
            </label>
            <number-spinner
              name="minQuantity"
              [min]="0"
              [max]="1000"
              [step]="1"
              [precision]="1"
              [defaultValue]="choiceFormGroup.get('minQuantity').value"
              (whenChange)="minQuantityChange($event)"
            ></number-spinner>
          </div>
        </div>

        <div
          class="col-sm-6"
          *ngIf="choiceFormGroup.get('type').value === 'checkbox' || choiceFormGroup.get('type').value === 'input'"
        >
          <div class="form-group">
            <label for="">
              Maximal-Anzahl<br />
              <small>Wieviele Produkte dürfen maximal ausgewählt werden?</small>
            </label>
            <number-spinner
              name="maxQuantity"
              [min]="1"
              [max]="1000"
              [step]="1"
              [precision]="1"
              [defaultValue]="choiceFormGroup.get('maxQuantity').value"
              (whenChange)="maxQuantityChange($event)"
            ></number-spinner>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12" style="padding-top: 10px;padding-bottom: 25px;">
          <mat-slide-toggle
            formControlName="hasIncludedProducts"
            (change)="toggleHasIncludedProducts($event)"
            class="toggle-field"
            [disabled]="!choiceFormGroup.get('type').value || choiceFormGroup.get('type').value === 'input'"
          >
            Enthält Inklusiv-Artikel
            <div class="info">
              Sollen Artikel in dieser Auswahl im Hauptproduktpreis einbegriffen sein?
            </div>
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row" *ngIf="choiceFormGroup.get('hasIncludedProducts').value">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">
              Anzahl inklusiver Artikel<br />
              <small>Wieviele Artikel sind inklusive? Weitere Auswahl kostet extra</small>
            </label>
            <number-spinner
              name="includedProducts"
              [min]="1"
              [max]="
                choiceFormGroup.get('type').value === 'checkbox' || choiceFormGroup.get('type').value === 'input'
                  ? 1000
                  : 1
              "
              [step]="1"
              [precision]="1"
              [readonly]="
                choiceFormGroup.get('type').value !== 'checkbox' && choiceFormGroup.get('type').value !== 'input'
              "
              [defaultValue]="choiceFormGroup.get('includedProducts').value"
              (whenChange)="includedProductsChange($event)"
            ></number-spinner>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12" style="padding-top: 10px;padding-bottom: 25px;">
          <mat-slide-toggle formControlName="isRequired" class="toggle-field">
            Auswahlfenster Pflicht
            <div class="info">
              Muss in diesem Auswahlfenster eine Option gewählt werden?
            </div>
          </mat-slide-toggle>
        </div>

        <div class="col-sm-12" style="text-align: center">
          <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

          <button
            class="btn btn-primary btn-block btn-lg"
            (click)="add()"
            *ngIf="!editChoiceData"
            [promiseBtn]="addObservable"
          >
            Auswahlfenster anlegen
          </button>
          <button
            class="btn btn-primary btn-block btn-lg"
            (click)="save()"
            *ngIf="editChoiceData"
            [promiseBtn]="editObservable"
          >
            Auswahlfenster speichern
          </button>
          <button
            class="btn btn-link btn-sm delete-button"
            (click)="delete()"
            *ngIf="editChoiceData"
            [promiseBtn]="deleteObservable"
          >
            Auswahlfenster löschen
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
