<breadcrumb></breadcrumb>
<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Auszahlungen - Wertgutscheine
        </h6>

        <div class="element-box">
          <div class="alert alert-light borderless">
            <div>
              <h4>Aktuelles Guthaben: {{ returnPrice(payoutData?.balance) | number: '1.2' }}€</h4>
              <span class="text-danger" *ngIf="payoutData?.hasPendingPayouts">
                Sie haben noch ausstehende Auszahlungen
              </span>
              <span class="text-success" *ngIf="!payoutData?.hasPendingPayouts && payoutData?.balance >= 200">
                Sie können sich Ihr Guthaben auszahlen lassen
              </span>
              <span class="text-danger" *ngIf="!payoutData?.hasPendingPayouts && payoutData?.balance < 200">
                Mindestguthaben von 200,00€ noch nicht erreicht
              </span>
            </div>
            <button
              class="btn btn-primary"
              (click)="requestPayout()"
              [disabled]="payoutData?.hasPendingPayouts || payoutData?.balance < 200"
              *ngIf="creds.id != 3 && creds.id != 24703 && billingCase.iban != ''"
            >
              Auszahlung beantragen
            </button>
            <button
              class="btn btn-danger"
              routerLink="../../../accounting/payment-settings/1"
              *ngIf="billingCase.iban == ''"
            >
              Bankdaten vervollständigen
            </button>
            <button
              class="btn btn-primary"
              (click)="requestPayout()"
              *ngIf="billingCase.iban != '' && (creds.id == 3 || creds.id == 24703)"
            >
              Auszahlung als Admin beantragen
            </button>
          </div>

          <div class="table-responsive shifts" *ngIf="payoutData">
            <table class="table table-padded">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Datum</th>
                  <th>Betrag</th>
                  <th>Status</th>
                  <th>Aktionen</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let payout of payoutData.payouts; let i = index">
                  <td class="nowrap">
                    <span>{{ payout.id }}</span>
                  </td>
                  <td class="nowrap">
                    <span>{{ payout.createdAt | amDateFormat: 'DD.MM.YYYY HH:mm' }}</span>
                  </td>
                  <td>
                    <span>{{ returnPrice(payout.value) | number: '1.2' }} €</span>
                  </td>
                  <td>
                    <span *ngIf="payout.status === 'pending'">
                      Ausstehend
                    </span>
                    <span *ngIf="payout.status === 'completed'" class="text-success">
                      Ausbezahlt ({{ payout.paidAt | amDateFormat: 'DD.MM.YYYY' }})
                    </span>
                  </td>
                  <td>
                    <input
                      type="button"
                      value="PDF herunterladen"
                      class="btn btn-light"
                      (click)="downloadPdf(payout)"
                    />
                    <!-- <input
                      type="button"
                      value="CSV generieren"
                      class="btn btn-light"
                      (click)="downloadCsv(payout)"
                      *ngIf="showPayoutId != payout.id"
                    /> -->

                    <!--[data]="downloadCsv(payout)" -->

                    <!-- <angular2csv
                      class="btn btn-light"
                      [data]="ordersCsv[payout.id]"
                      filename="auszahlung_{{ now | date: 'yyyy_MM_dd_H_mm' }}"
                      [options]="csvOptions"
                    ></angular2csv> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
