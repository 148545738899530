
    <span class="input-group">
      <div class="input-group-btn">
        <button type="button" class="btn btn-primary decrease" (click)="decreaseValue()">
          <i class="fas fa-minus fa-fw"></i>
        </button>
      </div>
      <input
        [formControl]="numberPicker"
        class="form-control"
        type="number"
        min="{{ min }}"
        max="{{ max }}"
        (blur)="changeValue()"
      />
      <div class="input-group-btn">
        <button type="button" class="btn btn-primary increase" (click)="increaseValue()">
          <i class="fas fa-plus fa-fw"></i>
        </button>
      </div>
    </span>
  