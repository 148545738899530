<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editStaffMember">Neuen Mitarbeiter anlegen</ng-template>
      <ng-template [ngIf]="editStaffMember">Mitarbeiter ändern</ng-template>
    </h4>
    <form [formGroup]="staffMemberFormGroup">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              Mitarbeiter ID
            </label>
            <input
              class="form-control"
              name="staffId"
              formControlName="staffId"
              [customErrors]="{ pattern: 'Nur Zahlen erlaubt' }"
              type="text"
              value=""
              ngbAutofocus
              autocomplete="nope"
              required
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              Kürzel
            </label>
            <input
              class="form-control uppercase"
              name="shorthand"
              formControlName="shorthand"
              type="text"
              value=""
              maxlength="4"
              required
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              Personal code
            </label>
            <input class="form-control" name="secret" formControlName="secret" type="text" value="" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              Name
            </label>
            <input class="form-control" name="name" formControlName="name" type="text" value="" required />
          </div>
        </div>
        <button class="btn btn-primary btn-block btn-lg" (click)="add()" *ngIf="!editStaffMember">
          Mitarbeiter anlegen
        </button>
        <button class="btn btn-primary btn-block btn-lg" (click)="save()" *ngIf="editStaffMember">
          Mitarbeiter speichern
        </button>
      </div>
    </form>
  </div>
</div>
