<div class="onboarding-content">
  <div class="element-box">
    <div class="section-header">
      <h4 class="onboarding-title">
        <ng-template [ngIf]="!isEditTicket">{{ 'Events&Tickets.Tickets.AddTicket' | translate }}</ng-template>
        <ng-template [ngIf]="isEditTicket">{{ 'Events&Tickets.Tickets.EditTicket' | translate }}</ng-template>
      </h4>
    </div>
    <form [formGroup]="ticketFormGroup">
      <div class="alert alert-danger borderless w-100" *ngIf="error">{{ error }}</div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.TicketName' | translate }}</label>
            <input class="form-control" name="name" formControlName="name" type="text" value="" ngbAutofocus />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group col-sm-4">
            <label for="">{{ 'Events&Tickets.Tickets.TicketCount' | translate }}</label>
            <input class="form-control" name="amount" formControlName="amount" type="number" ngbAutofocus />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.Description' | translate }}</label>
            <textarea
              class="form-control"
              name="description"
              formControlName="description"
              type="text"
              value=""
            ></textarea>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Tickets.AdditionalText' | translate }} </label>
            <textarea
              class="form-control"
              name="additionalText"
              formControlName="additionalText"
              placeholder="Dieser Text wird nur auf das Ticket gedruckt."
              type="text"
              value=""
            ></textarea>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group col-sm-4">
            <label for="">{{ 'Events&Tickets.Tickets.MinimumPeople' | translate }} </label>
            <input class="form-control" name="minimumPeople" formControlName="minimumPeople" type="number" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="w-100">{{ 'Events&Tickets.Tickets.Personalization' | translate }} </label>
            <mat-slide-toggle
              name="isTcktHldrNameCmplsr"
              formControlName="isTcktHldrNameCmplsr"
              [(ngModel)]="isTcktHldrNameCmplsr"
            >
              <div class="info">
                {{ 'Events&Tickets.Tickets.PerText' | translate }}
              </div>
            </mat-slide-toggle>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group">
            <label class="w-100">{{ 'Events&Tickets.Tickets.feeCalculation' | translate }} </label>
            <mat-slide-toggle name="feeTakeover" formControlName="feeTakeover" [(ngModel)]="feeTakeover">
              <div class="info">
                {{ 'Vouchers.Settings.GuestPayPayment' | translate }}
                <i
                  class="fa fa-info-circle details-icon"
                  title="{{ 'Vouchers.Settings.PaymentFeesInfo' | translate }}"
                ></i>
              </div>
            </mat-slide-toggle>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Tickets.ServiceCharge' | translate }} (EUR) </label>
            <div class="col-sm-4">
              <number-spinner
                name="serviceCharge"
                [min]="0.0"
                [max]="3.0"
                [step]="0.01"
                [precision]="0.01"
                [inputDisabled]="false"
                [defaultValue]="+ticketFormGroup.value.serviceCharge"
                (whenChange)="serviceChargeChanged($event)"
              ></number-spinner>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="element-box">
    <div class="section-header">
      <h4 class="onboarding-title">{{ 'Events&Tickets.Categories.Title' | translate }}</h4>
    </div>
    <div class="alert alert-outline-primary" style="background-color: #e1f0f7; border: 1px solid #5BC0DE;">
      <h5 style="color: #5BC0DE;">
        {{ 'Events&Tickets.Categories.WhatPriceCat' | translate }}
      </h5>
      <p>{{ 'Events&Tickets.Categories.PriceCatDes' | translate }}</p>
    </div>

    <div class="shifts price-category" *ngIf="allSubCategory && allSubCategory.length">
      <div style="color:red; padding-left: 10px;">{{ lastDeteleMessage }}</div>
      <ul class="form-list">
        <li class="row" *ngFor="let eachData of allSubCategory; index as i">
          <div class="col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="">{{ 'Common.Name' | translate }}</label>
              <input
                class="form-control"
                [ngStyle]="{ 'border-color': i === 0 && ifNoCategory ? 'red' : '' }"
                type="text"
                [(ngModel)]="eachData.name"
                name="name{{ i + 1 }}"
              />
            </div>
          </div>
          <div class="col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="">{{ 'Events&Tickets.Common.Description' | translate }}</label>
              <input
                class="form-control"
                type="text"
                name="description{{ i + 1 }}"
                [(ngModel)]="eachData.description"
              />
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="form-group">
              <label for="">{{ 'Events&Tickets.Common.Price' | translate }}</label>
              <input
                value="0"
                class="form-control"
                oninput="validity.valid||(value='')"
                name="price{{ i + 1 }}"
                [(ngModel)]="eachData.price"
                (focusout)="setToTwoDigits('subCategory', i)"
              />
            </div>
          </div>
          <div class="col-sm-1">
            <div class="form-group">
              <label for="">{{ 'Events&Tickets.Common.Actions' | translate }}</label>
              <button class="btn btn-danger delete-btn" (click)="removeCategory(eachData, i)" matTooltip="Löschen">
                <i class="far fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </li>
      </ul>
      <button class="btn btn-primary" (click)="addSubCategory(isEditTicket)">
        <i class="fa fa-plus-circle fa-fw"></i> {{ 'Events&Tickets.Categories.AddPriceCat' | translate }}
      </button>
    </div>
  </div>
  <div class="element-box">
    <div class="section-header">
      <h4 class="onboarding-title">{{ 'Events&Tickets.Option.Title' | translate }}</h4>
    </div>
    <div class="alert alert-outline-primary" style="background-color: #e1f0f7; border: 1px solid #5BC0DE;">
      <h5 style="color: #5BC0DE;">
        {{ 'Events&Tickets.Option.AdditionalOpshead' | translate }}
      </h5>
      <p>{{ 'Events&Tickets.Option.AdditionalOpscont' | translate }}</p>
    </div>
    <div class="table-responsive shifts" *ngIf="allOptions && allOptions.length">
      <ul class="form-list">
        <li class="row" *ngFor="let eachData of allOptions; index as i">
          <div class="col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="">{{ 'Common.Name' | translate }}</label>
              <input class="form-control" type="text" [(ngModel)]="eachData.name" name="name{{ i + 1 }}" />
            </div>
          </div>
          <div class="col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="">{{ 'Events&Tickets.Common.Description' | translate }}</label>
              <input
                class="form-control"
                type="text"
                name="description{{ i + 1 }}"
                [(ngModel)]="eachData.description"
              />
            </div>
          </div>
          <div class="col-sm-6 col-lg-3">
            <div class="form-group">
              <label for="">{{ 'Events&Tickets.Common.Price' | translate }}</label>
              <input
                value="0"
                class="form-control"
                oninput="validity.valid||(value='')"
                name="price{{ i + 1 }}"
                [(ngModel)]="eachData.price"
                (focusout)="setToTwoDigits('option', i)"
              />
            </div>
          </div>
          <div class="col-sm-1">
            <div class="form-group">
              <label for="">{{ 'Events&Tickets.Common.Actions' | translate }}</label>
              <button class="btn btn-danger delete-btn" (click)="removeOption(eachData, i)" matTooltip="Löschen">
                <i class="far fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </li>
      </ul>

      <!-- <table class="table table-padded">
        <thead>
          <tr>
            <th>name</th>
            <th>Beschreibung</th>
            <th style="text-align: right;">Preis</th>
            <th style="text-align: center;">Aktion</th>
          </tr>
        </thead>
        <tbody style="font-size: 14px;">
          <tr *ngFor="let eachData of allOptions; index as i">
            <th>
              <input class="form-control" type="text" [(ngModel)]="eachData.name" name="name{{ i + 1 }}" />
            </th>
            <th>
              <input
                class="form-control"
                type="text"
                name="description{{ i + 1 }}"
                [(ngModel)]="eachData.description"
              />
            </th>
            <th>
              <input
                type="number"
                style="text-align: right;"
                class="form-control"
                name="price{{ i + 1 }}"
                [(ngModel)]="eachData.price"
                (focusout)="setToTwoDigits('option', i)"
              />
            </th>
            <th style="text-align: center;">
              <-- <button
                class="btn btn-light"
                *ngIf="isEditTicket"
                (click)="updateOption(eachData)"
                matTooltip="Bearbeiten"
              >
                <i class="fas fa-edit"></i>
              </button> --
              <button class="btn btn-danger" (click)="removeOption(eachData, i)" matTooltip="Löschen">
                <i class="far fa-trash-alt"></i>
              </button>
            </th>
          </tr>
        </tbody>
      </table> -->
    </div>
    <button class="btn btn-primary" (click)="addOption(isEditTicket)">
      <i class="fa fa-plus-circle fa-fw"></i> {{ 'Events&Tickets.Option.AddOption' | translate }}
    </button>
  </div>
  <div class="clearfix">
    <button class="btn btn-primary" (click)="add()" *ngIf="!isEditTicket">
      {{ 'Events&Tickets.Tickets.AddTicket' | translate }}
    </button>
    <button class="btn btn-primary" (click)="save()" *ngIf="isEditTicket">
      {{ 'Events&Tickets.Tickets.UpdateTicket' | translate }}
    </button>
    <button class="btn btn-secondary" (click)="cancel()">
      {{ 'Common.Abort' | translate }}
    </button>
  </div>
</div>
