/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./recaptcha-v3.component";
import * as i2 from "./recaptcha-v3.service";
var styles_RecaptchaV3Component = [];
var RenderType_RecaptchaV3Component = i0.ɵcrt({ encapsulation: 2, styles: styles_RecaptchaV3Component, data: {} });
export { RenderType_RecaptchaV3Component as RenderType_RecaptchaV3Component };
export function View_RecaptchaV3Component_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_RecaptchaV3Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-recaptcha-v3", [], null, null, null, View_RecaptchaV3Component_0, RenderType_RecaptchaV3Component)), i0.ɵdid(1, 114688, null, 0, i1.RecaptchaV3Component, [i2.RecaptchaV3CustomService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecaptchaV3ComponentNgFactory = i0.ɵccf("app-recaptcha-v3", i1.RecaptchaV3Component, View_RecaptchaV3Component_Host_0, {}, { tokenGenerated: "tokenGenerated" }, []);
export { RecaptchaV3ComponentNgFactory as RecaptchaV3ComponentNgFactory };
