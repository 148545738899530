<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editStatusData">{{
        'ResSettings.ReservationSetting.AddStatusAndColor' | translate
      }}</ng-template>
      <ng-template [ngIf]="editStatusData">
        {{ 'ResSettings.ReservationSetting.EditStatusAndColor' | translate }}</ng-template
      >
    </h4>
    <form [formGroup]="resStatusFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'ResSettings.ReservationSetting.Status' | translate }}</label>
            <input class="form-control" placeholder="" type="text" formControlName="label" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>{{ 'ResSettings.ReservationSetting.selectIcon' | translate }}</label>
            <ng-select
              [searchable]="false"
              [clearable]="true"
              formControlName="icon"
              [items]="icons"
              bindLabel="name"
              bindValue="value"
            >
              <ng-template ng-label-tmp let-item="item">
                <span [class]="item.value"></span>
                {{ item.name }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <span [class]="item.value"></span>
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for=""> {{ 'ResSettings.ReservationSetting.statusColor' | translate }}</label>
            <color-picker-wrapper
              [(color)]="resStatusFormGroup.value.color"
              (colorChange)="changeMainColor($event)"
            ></color-picker-wrapper>
            <!-- <p>{{ resStatusFormGroup.value.color }}</p> -->
          </div>
        </div>
      </div>
      <button class="btn btn-primary btn-block btn-lg" (click)="add()" [disabled]="!this.resStatusFormGroup.valid">
        {{ 'ResSettings.ResAddTimeFilter.Save' | translate }}
      </button>
    </form>
  </div>
</div>
