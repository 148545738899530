<div class="modal-header">
  <h4 class="modal-title">Create New Album</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="">Album Name</label>
          <input type="text" class="form-control" [(ngModel)]="name" [ngModelOptions]="{ standalone: true }" />
        </div>
        <br />
        <div class="form-group">
          <input type="checkbox" [(ngModel)]="isChecked" [ngModelOptions]="{ standalone: true }" />&nbsp;Visible in the
          smartphone app?
        </div>
        <br />
        <div class="form-group">
          <button class="btn btn-primary" (click)="createAlbum()">Create</button>
        </div>
      </div>
    </div>
  </div>
</form>
