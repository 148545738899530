<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editMenuData">Neue Speisekarte anlegen</ng-template>
      <ng-template [ngIf]="editMenuData">Speisekarte ändern</ng-template>
    </h4>
    <form [formGroup]="menucardFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">Name der Karte</label>
            <input class="form-control" name="name" formControlName="name" type="text" value="" ngbAutofocus />
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">Beschreibung</label>
            <textarea
              class="form-control"
              name="description"
              formControlName="description"
              type="text"
              value=""
            ></textarea>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">Typ</label>
            <ng-select [searchable]="false" [clearable]="false" formControlName="type" placeholder="Aufenthaltsdauer">
              <ng-option value="main">Speisekarte</ng-option>
              <ng-option value="lunch">Mittagskarte</ng-option>
              <ng-option value="drinks">Getränkekarte</ng-option>
              <ng-option value="promotion">Aktionskarte</ng-option>
              <ng-option value="menu">Menükarte</ng-option>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="">Wofür soll die Karte im Bestellsystem genutzt werden?</label>
        <div class="row">
          <div class="col-sm-4">
            <mat-checkbox class="example-margin" formControlName="isForTakeaway">
              Abholung
            </mat-checkbox>
          </div>
          <div class="col-sm-4">
            <mat-checkbox class="example-margin" formControlName="isForDelivery">
              Lieferung
            </mat-checkbox>
          </div>
          <div class="col-sm-4">
            <mat-checkbox class="example-margin" formControlName="isForInhouse">
              Inhouse
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">Foto</label>
            <div>
              <input
                class="file-input form-control"
                style="display:none"
                type="file"
                name="photo"
                #fileInput
                (change)="onChange(fileInput.files[0])"
              />
              <div class="input-group">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-secondary" (click)="fileInput.click()">Foto auswählen</button>
                </div>
                <input
                  class="form-control"
                  placeholder="Kein Foto ausgewählt"
                  [value]="fileName ? fileName : ''"
                  readonly
                />
                <div class="input-group-append" *ngIf="fileName">
                  <button type="button" class="btn btn-danger" (click)="removeFile()" matTooltip="Foto entfernen">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12" *ngIf="imageUrl">
          <div class="preview-img">
            <img [src]="imageUrl" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12" style="padding-bottom: 25px;padding-top: 15px;">
          <mat-slide-toggle formControlName="isSpecial" class="toggle-field" (change)="changeSpecial($event)">
            Speisekarte ist eine Spezialkarte
            <div class="info">
              Spezialkarten haben eigene Liefer-/Abholzeiten
            </div>
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12" style="padding-bottom: 25px;padding-top: 15px;">
          <mat-slide-toggle formControlName="hasValidDate" class="toggle-field">
            Speisekarte hat eine Gültigkeit
            <div class="info">
              Soll diese Speisekarte nur in einem bestimmten Zeitraum gültig sein?
            </div>
          </mat-slide-toggle>
        </div>
      </div>

      <ng-template [ngIf]="menucardFormGroup.get('hasValidDate').value">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="">Gültig von</label>
              <div class="input-group">
                <div class="input-group-prepend" (click)="dateFromPicker.open()">
                  <div class="input-group-text">
                    <i class="fas fa-calendar-alt"></i>
                  </div>
                </div>
                <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                <input
                  class="form-control"
                  #dateFromInput
                  formControlName="validFrom"
                  placeholder="Datum auswählen"
                  type="text"
                  name="date"
                  [matDatepicker]="dateFromPicker"
                  (dateChange)="changeValidDates()"
                  (focus)="dateFromPicker.open()"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="">Gültig bis</label>
              <div class="input-group">
                <div class="input-group-prepend" (click)="dateToPicker.open()">
                  <div class="input-group-text">
                    <i class="fas fa-calendar-alt"></i>
                  </div>
                </div>
                <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
                <input
                  class="form-control"
                  #dateToInput
                  formControlName="validUntil"
                  placeholder="Datum auswählen"
                  type="text"
                  name="date"
                  [matDatepicker]="dateToPicker"
                  (dateChange)="changeValidDates()"
                  (focus)="dateToPicker.open()"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="menucardFormGroup.get('isSpecial').value">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="">Bestellbar von</label>
              <div class="input-group">
                <div class="input-group-prepend" (click)="orderableFromPicker.open()">
                  <div class="input-group-text">
                    <i class="fas fa-calendar-alt"></i>
                  </div>
                </div>
                <mat-datepicker #orderableFromPicker (closed)="orderableFromInput.blur()"></mat-datepicker>
                <input
                  class="form-control"
                  #orderableFromInput
                  formControlName="orderableFrom"
                  placeholder="Datum auswählen"
                  type="text"
                  name="date"
                  [matDatepicker]="orderableFromPicker"
                  (focus)="orderableFromPicker.open()"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="">Bestellbar bis</label>
              <div class="input-group">
                <div class="input-group-prepend" (click)="orderableToPicker.open()">
                  <div class="input-group-text">
                    <i class="fas fa-calendar-alt"></i>
                  </div>
                </div>
                <mat-datepicker #orderableToPicker (closed)="orderableToInput.blur()"></mat-datepicker>
                <input
                  class="form-control"
                  #orderableToInput
                  formControlName="orderableUntil"
                  placeholder="Datum auswählen"
                  type="text"
                  name="date"
                  [matDatepicker]="orderableToPicker"
                  (focus)="orderableToPicker.open()"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="menucardFormGroup.get('isSpecial').value">
          <div class="col-sm-12">
            <div class="form-group" formArrayName="deliveryTimes">
              <label for="">Liefer-/Abholzeiten</label>
              <div class="help-block form-text text-muted">
                Legen Sie die individuellen Liefer-Abholzeiten für diese Karte fest
              </div>
              <ng-template ngFor let-unit [ngForOf]="formData.controls" let-i="index">
                <div style="display: flex; margin-bottom: 5px" [formGroupName]="i">
                  <div style="flex: 2; margin-right: 10px;">
                    <select class="form-control" formControlName="day" required>
                      <option value="0" selected>Jeden Tag</option>
                      <option [value]="day" *ngFor="let day of deliveryTimesRange">{{
                        day | dfnsFormat: 'dddd, DD.MM.YYYY'
                      }}</option>
                    </select>
                  </div>
                  <div style="flex: 1;margin-right: 10px;">
                    <input type="text" formControlName="from" placeholder="Von" class="form-control" mask="00:00" />
                  </div>
                  <div style="flex: 1">
                    <input
                      type="text"
                      formControlName="to"
                      placeholder="Bis"
                      class="form-control"
                      style="margin-right: 5px"
                      mask="00:00"
                    />
                  </div>
                  <div>
                    <button class="btn btn-white" type="button" (click)="removeTime(i)">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </ng-template>
              <button class="btn btn-dark btn-circle" type="button" style="margin-top: 5px" (click)="addNewTime()">
                + Neue Zeit
              </button>
            </div>
          </div>
        </div>
        <div class="row form-group" *ngIf="menucardFormGroup.get('isSpecial').value">
          <div class="col-sm-12">
            <label class="col-form-label" for="">
              Intervall
              <div class="info">
                In welchem Intervall sollen die Liefer-/Abholzeiten angeboten werden?
              </div>
            </label>
            <div>
              <div class="leadtime-slider">
                <ng5-slider formControlName="timeInterval" [options]="intervalOptions"> </ng5-slider>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

      <div style="text-align: center;margin-top: 15px;">
        <mat-progress-bar
          *ngIf="false"
          mode="determinate"
          [value]="progress"
          style="margin-bottom: 5px"
        ></mat-progress-bar>
        <button class="btn btn-primary btn-block btn-lg" (click)="add()" *ngIf="!editMenuData">
          Speisekarte anlegen
        </button>
        <button class="btn btn-primary btn-block btn-lg" (click)="save()" *ngIf="editMenuData">
          Speisekarte speichern
        </button>
      </div>
    </form>
  </div>
</div>
