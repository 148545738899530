<!--
<div class="spinner-wrapper" *ngIf="loading">
  <span>... wird geladen ...</span>
</div>
-->

<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Öffnungszeiten - Administration
          <div class="element-actions"></div>
        </h6>

        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Öffnungszeiten">
            <div class="element-box">
              <div class="alert alert-info">
                Info: Klicken Sie auf <i class="far fa-copy"></i> (kopieren) um die Öffnungszeiten eines Tages über
                <i class="fas fa-file-import"></i> (einfügen) auf einen anderen Tag zu übertragen.
              </div>

              <div class="card-area">
                <div class="container_">
                  <div class="row">
                    <div class="col-xs-6 col-sm-4 col-md-3 col-card" *ngFor="let day of openingHours; let i = index">
                      <div class="card" style="min-height: 245px;">
                        <div class="card-body">
                          <button
                            class="btn btn-light float-right"
                            title="Kopieren"
                            (click)="copiedDay = i"
                            *ngIf="copiedDay == 99"
                          >
                            <i class="far fa-copy"></i>
                          </button>
                          <button
                            class="btn btn-danger float-right"
                            title="Kopieren"
                            (click)="copiedDay = 99"
                            *ngIf="copiedDay == i"
                          >
                            <i class="far fa-copy"></i>
                          </button>
                          <button
                            class="btn btn-primary float-right"
                            title="Einfügen"
                            *ngIf="copiedDay != 99 && copiedDay != i"
                            (click)="pasteData(i)"
                          >
                            <i class="fas fa-file-import"></i>
                          </button>
                          <h5 class="card-title">{{ dayTranslated[day.day] }}</h5>

                          <div class="card-text">
                            <div
                              class="cursor-pointer"
                              *ngIf="day.tFrom == '0:00' && day.tTo == '0:00'"
                              (click)="day.tFrom = '0:00'; day.tTo = '23:45'"
                            >
                              <i class="far fa-check-square"></i>
                              <span>Ruhetag</span>
                            </div>
                            <div
                              class="cursor-pointer"
                              *ngIf="day.tFrom != '0:00' || day.tTo != '0:00'"
                              (click)="
                                day.tFrom = '0:00';
                                day.tTo = '0:00';
                                openingHours2[i].tFrom = '0:00';
                                openingHours2[i].tTo = '0:00'
                              "
                            >
                              <i class="far fa-square"></i>
                              <span>Ruhetag</span>
                            </div>

                            <div class="row" style="margin-top: 5px;" *ngIf="day.tFrom != '0:00' || day.tTo != '0:00'">
                              <div class="col-5">
                                <div class="form-group">
                                  <select class="custom-select" [(ngModel)]="day.tFrom">
                                    <ng-container *ngFor="let hour of selectHours">
                                      <ng-container *ngFor="let minute of selectMinutes">
                                        <option value="{{ hour + ':' + minute }}">{{ hour + ':' + minute }}</option>
                                      </ng-container>
                                    </ng-container>

                                    <option value="24:00">24:00</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-2 text-center">
                                <span style="line-height: 36px;">-</span>
                              </div>
                              <div class="col-5">
                                <div class="form-group">
                                  <select class="custom-select" [(ngModel)]="day.tTo">
                                    <ng-container *ngFor="let hour of selectHours">
                                      <ng-container *ngFor="let minute of selectMinutes">
                                        <option value="{{ hour + ':' + minute }}">{{ hour + ':' + minute }}</option>
                                      </ng-container>
                                    </ng-container>

                                    <option value="24:00">24:00</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <ng-container *ngIf="day.tFrom != '0:00' || day.tTo != '0:00'">
                              <div
                                class="cursor-pointer"
                                *ngIf="openingHours2[i].tFrom == '0:00' && openingHours2[i].tTo == '0:00'"
                                (click)="openingHours2[i].tFrom = '15:00'; openingHours2[i].tTo = '17:00'"
                              >
                                <i class="far fa-square"></i>
                                <span>Pause</span>
                              </div>
                              <div
                                class="cursor-pointer"
                                *ngIf="openingHours2[i].tFrom != '0:00' || openingHours2[i].tTo != '0:00'"
                                (click)="openingHours2[i].tFrom = '0:00'; openingHours2[i].tTo = '0:00'"
                              >
                                <i class="far fa-check-square"></i>
                                <span>Pause</span>
                              </div>
                            </ng-container>

                            <div
                              class="row"
                              style="margin-top: 5px;"
                              [style.visibility]="
                                openingHours2[i].tFrom != '0:00' || openingHours2[i].tTo != '0:00'
                                  ? 'inherit'
                                  : 'hidden'
                              "
                            >
                              <div class="col-5">
                                <div class="form-group">
                                  <select class="custom-select" [(ngModel)]="openingHours2[i].tFrom">
                                    <ng-container *ngFor="let hour of selectHours">
                                      <ng-container *ngFor="let minute of selectMinutes">
                                        <option value="{{ hour + ':' + minute }}">{{ hour + ':' + minute }}</option>
                                      </ng-container>
                                    </ng-container>

                                    <option value="24:00">24:00</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-2 text-center">
                                <span style="line-height: 36px;">-</span>
                              </div>
                              <div class="col-5">
                                <div class="form-group">
                                  <select class="custom-select" [(ngModel)]="openingHours2[i].tTo">
                                    <ng-container *ngFor="let hour of selectHours">
                                      <ng-container *ngFor="let minute of selectMinutes">
                                        <option value="{{ hour + ':' + minute }}">{{ hour + ':' + minute }}</option>
                                      </ng-container>
                                    </ng-container>

                                    <option value="24:00">24:00</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="alert alert-light">
                    <div class="form-group">
                      <label for="textarea"
                        >Hier tragen Sie bitte Abweichungen von den regulären Öffnungszeiten ein:</label
                      >
                      <textarea
                        class="form-control"
                        id="textarea"
                        rows="3"
                        [(ngModel)]="openingHoursFreetext"
                      ></textarea>
                    </div>
                    <!--
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="checkbox" />
                      <label class="form-check-label" for="checkbox"
                        ><strong>Ruhetage auf der Homepage anzeigen</strong><br />(es werden nur die Zeiten angezeigt,
                        an denen geöffnet ist)</label
                      >
                    </div>
                    -->

                    <br />
                    <button class="btn btn-primary" (click)="saveOpeningHours()">
                      <i class="far fa-save"></i> Alle Speichern
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab
            label="Sonderöffnungszeiten ({{ specialOpeningHours.length }})"
            *ngIf="specialOpeningHours.length >= 0"
          >
            <div class="element-box" style="min-height: 720px;">
              <!--
              <div class="alert alert-info">
                Info: Klicken Sie auf <i class="far fa-copy"></i> (kopieren) um die Öffnungszeiten eines Tages über  <i class="fas fa-file-import"></i> (einfügen) auf einen anderen Tag zu übertragen. 
              </div>
              -->
              <div>
                <button class="btn btn-light" (click)="addSpecialOpeningHours()">
                  <i class="far fa-edit"></i> Neuen Eintrag erstellen
                </button>
                <button class="btn btn-primary" (click)="saveSpecialOpeningHours()">
                  <i class="far fa-save"></i> Alle Speichern
                </button>
              </div>
              <br />

              <div class="card-area">
                <div class="container_">
                  <div class="row">
                    <div
                      class="col-xs-6 col-sm-4 col-md-3 col-card"
                      *ngFor="let day of specialOpeningHours; let i = index"
                    >
                      <!-- style="min-width: 355px;" -->

                      <div class="card" style="min-height: 350px;">
                        <div class="card-body">
                          <!--
                          <button class="btn btn-light float-right" title="Kopieren" (click)="copiedDay = i;" *ngIf="copiedDay == 99">
                            <i class="far fa-copy"></i>
                          </button>
                          <button class="btn btn-danger float-right" title="Kopieren" (click)="copiedDay = 99;" *ngIf="copiedDay == i">
                            <i class="far fa-copy"></i>
                          </button>
                          <button class="btn btn-primary float-right" title="Einfügen" *ngIf="copiedDay != 99 && copiedDay != i" (click)="pasteData(i)">
                            <i class="fas fa-file-import"></i>
                          </button>
                          -->

                          <div class="form-group">
                            <!--  style="width: 235px;" -->
                            <div class="date-input">
                              <input
                                class="form-control single-daterange"
                                placeholder="Datum auswählen"
                                (click)="picker.open()"
                                [satDatepicker]="picker"
                                [(ngModel)]="day.date"
                                (ngModelChange)="onDateRangesChange($event, day)"
                              />
                            </div>
                            <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
                          </div>

                          <!-- <h5 class="card-title">{{ dayTranslated[day.date.begin] }}</h5> -->

                          <div class="card-text">
                            <div
                              class="cursor-pointer"
                              *ngIf="day.from1 == '0:00' && day.to1 == '0:00'"
                              (click)="day.closed = 0; day.from1 = '0:00'; day.to1 = '23:45'"
                            >
                              <i class="far fa-check-square"></i>
                              <span>Geschlossen</span>
                            </div>
                            <div
                              class="cursor-pointer"
                              *ngIf="day.from1 != '0:00' || day.to1 != '0:00'"
                              (click)="
                                day.closed = 1;
                                day.from1 = '0:00';
                                day.to1 = '0:00';
                                day.from2 = '0:00';
                                day.to2 = '0:00'
                              "
                            >
                              <i class="far fa-square"></i>
                              <span>Geschlossen</span>
                            </div>

                            <ng-container *ngIf="day.from1 == '0:00' && day.to1 == '0:00'">
                              <div class="form-group" style="margin-top: 5px;">
                                <select class="custom-select" [(ngModel)]="day.vocation">
                                  <option value="1">Betriebsferien: ja</option>
                                  <option value="0">Betriebsferien: nein</option>
                                </select>
                              </div>
                              <div class="form-group" style="margin-top: 5px;" *ngIf="day.vocation == 0">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="reason"
                                  placeholder="Anderen Grund eingeben"
                                  [(ngModel)]="day.reason"
                                />
                              </div>
                            </ng-container>

                            <ng-container *ngIf="day.from1 != '0:00' || day.to1 != '0:00'">
                              <div class="row" style="margin-top: 5px;">
                                <div class="col-5">
                                  <div class="form-group">
                                    <select class="custom-select" [(ngModel)]="day.from1">
                                      <ng-container *ngFor="let hour of selectHours">
                                        <ng-container *ngFor="let minute of selectMinutes">
                                          <option value="{{ hour + ':' + minute }}">{{ hour + ':' + minute }}</option>
                                        </ng-container>
                                      </ng-container>

                                      <option value="24:00">24:00</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-2 text-center">
                                  <span style="line-height: 36px;">-</span>
                                </div>
                                <div class="col-5">
                                  <div class="form-group">
                                    <select class="custom-select" [(ngModel)]="day.to1">
                                      <ng-container *ngFor="let hour of selectHours">
                                        <ng-container *ngFor="let minute of selectMinutes">
                                          <option value="{{ hour + ':' + minute }}">{{ hour + ':' + minute }}</option>
                                        </ng-container>
                                      </ng-container>

                                      <option value="24:00">24:00</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="cursor-pointer"
                                *ngIf="day.from2 == '0:00' && day.from2 == '0:00'"
                                (click)="day.from2 = '15:00'; day.to2 = '17:00'"
                              >
                                <i class="far fa-square"></i>
                                <span>Pause</span>
                              </div>
                              <div
                                class="cursor-pointer"
                                *ngIf="day.from2 != '0:00' || day.from2 != '0:00'"
                                (click)="day.from2 = '0:00'; day.to2 = '0:00'"
                              >
                                <i class="far fa-check-square"></i>
                                <span>Pause</span>
                              </div>

                              <div
                                class="row"
                                style="margin-top: 5px;"
                                *ngIf="day.from2 != '0:00' || day.to2 != '0:00'"
                              >
                                <div class="col-5">
                                  <div class="form-group">
                                    <select class="custom-select" [(ngModel)]="day.from2">
                                      <ng-container *ngFor="let hour of selectHours">
                                        <ng-container *ngFor="let minute of selectMinutes">
                                          <option value="{{ hour + ':' + minute }}">{{ hour + ':' + minute }}</option>
                                        </ng-container>
                                      </ng-container>

                                      <option value="24:00">24:00</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-2 text-center">
                                  <span style="line-height: 36px;">-</span>
                                </div>
                                <div class="col-5">
                                  <div class="form-group">
                                    <select class="custom-select" [(ngModel)]="day.to2">
                                      <ng-container *ngFor="let hour of selectHours">
                                        <ng-container *ngFor="let minute of selectMinutes">
                                          <option value="{{ hour + ':' + minute }}">{{ hour + ':' + minute }}</option>
                                        </ng-container>
                                      </ng-container>

                                      <option value="24:00">24:00</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <ng-multiselect-dropdown
                                [placeholder]="'An bestimmten Wochentagen'"
                                [data]="dropdownList"
                                [(ngModel)]="selectedItems[day.id]"
                                [settings]="dropdownSettings"
                                (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)"
                              >
                              </ng-multiselect-dropdown>
                              <br />
                            </ng-container>
                          </div>
                          <button
                            class="btn btn-light btn-sm"
                            style="position: absolute; bottom: 20px; left: 20px;"
                            (click)="day.delete = true"
                            *ngIf="!day.delete"
                          >
                            <i class="far fa-trash-alt"></i> Zum Löschen markieren
                          </button>
                          <button
                            class="btn btn-info btn-sm"
                            style="position: absolute; bottom: 20px; left: 20px;"
                            (click)="day.delete = false"
                            *ngIf="day.delete"
                          >
                            <i class="fas fa-undo"></i> Rückgängig machen
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
