
    <ng-select
      [items]="items"
      [multiple]="false"
      bindLabel="name"
      placeholder="Stadtteil auswählen..."
      [closeOnSelect]="true"
      [(ngModel)]="selectedItem"
      appendTo="body"
      [clearable]="true"
      [disabled]="isDisabled"
      #select
    >
    </ng-select>
  