<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Covid19 Datenerfassung
          <div class="element-actions">
            <button
              class="btn btn-primary hidden-xs-down"
              (click)="downloadCSV()"
              [disabled]="!checkins || !checkins.length"
            >
              <i class="fas fa-plus-circle fa-fw"></i>
              CSV Export
            </button>
          </div>
        </h6>

        <div class="alert alert-warning borderless">
          Hinweis: Aufgrund gesetzlicher Bestimmungen gegen die Ausbreitung des Corona-Virus sind Sie verpflichtet,
          Kontaktdaten Ihrer Gäste aufzunehmen und 30 Tage zu speichern. Dafür können Sie unser Check-In Tool nutzen.<br />
          Ihr persönlicher Link:<br />
          <a href="https://checkin.gastroguide.de/checkin/{{ activeClient?.id }}" target="blank">
            https://checkin.gastroguide.de/checkin/{{ activeClient?.id }}
          </a>
          [<a
            href="https://api.qrserver.com/v1/create-qr-code/?size=800x800&data=https://checkin.gastroguide.de/checkin/{{
              activeClient?.id
            }}"
            target="_blank"
            style="text-decoration: underline;"
          >
            QR Code </a
          >]
        </div>

        <div class="filter-box" fxLayout="row">
          <div class="form-group" fxFlex="50%" fxFlex.gt-sm="25%" style="padding-right: 10px;">
            <label for=""> Datum auswählen </label>
            <div class="date-input">
              <input
                class="form-control single-daterange"
                placeholder="Datum auswählen"
                (click)="picker.open()"
                [matDatepicker]="picker"
                [(ngModel)]="date"
                (dateChange)="updateCheckIns()"
              />
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>
          <div class="form-group" fxFlex="50%" fxFlex.gt-sm="25%"></div>
          <div class="form-group" fxFlex="50%" fxFlex.gt-sm="25%"></div>
          <div class="form-group" fxFlex="50%" fxFlex.gt-sm="25%"></div>
        </div>

        <div class="element-box-tp">
          <div class="loading" *ngIf="!checkins">
            <i class="fa fa-spinner fa-spin"></i>
            Check-Ins werden geladen
          </div>

          <div class="table-responsive" *ngIf="checkins">
            <ngx-datatable
              #dataTable
              class="bootstrap log-table expandable"
              [columnMode]="'flex'"
              [headerHeight]="35"
              [footerHeight]="50"
              [summaryRow]="false"
              [summaryHeight]="'auto'"
              [rowHeight]="'auto'"
              [scrollbarV]="false"
              [rows]="checkins"
              [limit]="25"
              [cssClasses]="{
                pagerLeftArrow: 'fas fa-backward',
                pagerRightArrow: 'fas fa-forward',
                pagerPrevious: 'fas fa-step-backward',
                pagerNext: 'fas fa-step-forward'
              }"
              [sorts]="[{ prop: 'createdAt', dir: 'desc' }]"
              [messages]="{ emptyMessage: 'Keine Check-Ins am gewählten Datum', total: ' insgesamt' }"
            >
              <ngx-datatable-column
                [sortable]="true"
                [resizeable]="false"
                [canAutoResize]="false"
                prop="createdAt"
                name="Datum"
                [width]="150"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div style="display: flex;align-items: center;">
                    <div *ngIf="!row.reservation">
                      {{ row.createdAt | dfnsFormat: 'DD.MM.YYYY HH:mm' }}
                    </div>
                    <div *ngIf="row.reservation">
                      {{ row.reservation.reservedFor | dfnsFormat: 'DD.MM.YYYY HH:mm' }}
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="true"
                [resizeable]="false"
                [canAutoResize]="false"
                prop="lastName"
                name="Name"
                [width]="200"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.firstName }} {{ row.lastName }}
                  <span class="badge badge-default" *ngIf="row.isReservation" matTooltip="Check-In aus Reservierung">
                    Res.
                  </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="true"
                [resizeable]="false"
                [canAutoResize]="false"
                prop="table"
                name="Tisch"
                [width]="50"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.table }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="true"
                [resizeable]="false"
                [canAutoResize]="false"
                prop="phone"
                name="Telefon"
                [width]="120"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.phone }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="true"
                [resizeable]="false"
                [canAutoResize]="false"
                prop="email"
                name="E-Mail"
                [width]="200"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.email }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="true"
                [resizeable]="false"
                [canAutoResize]="false"
                prop="street"
                name="Straße"
                [width]="400"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.street }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="true"
                [resizeable]="false"
                [canAutoResize]="false"
                prop="zipCode"
                name="PLZ"
                [width]="120"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.zipCode }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="true"
                [resizeable]="false"
                [canAutoResize]="false"
                prop="city"
                name="Ort"
                [width]="120"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.city }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count">
                    <span *ngIf="selectedMessage"> {{ selectedCount.toLocaleString() }} {{ selectedMessage }} / </span>
                    Zeige
                    <strong>{{ pageSize < rowCount.toLocaleString() ? pageSize : rowCount.toLocaleString() }}</strong>
                    von <strong>{{ rowCount.toLocaleString() }}</strong> Check-Ins
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-backward'"
                    [pagerRightArrowIcon]="'fas fa-forward'"
                    [pagerPreviousIcon]="'fas fa-step-backward'"
                    [pagerNextIcon]="'fas fa-step-forward'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="dataTable.onFooterPage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
