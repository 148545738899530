<div class="auth-box-w">
  <div class="logo-w">
    <a href="index.html"><img alt="" src="./assets/logo.png"/></a>
    <app-lang-selector-backoffice></app-lang-selector-backoffice>
  </div>
  <h4 class="auth-header">
    {{ 'ForgotPassword.ForgotPassword' | translate }}
  </h4>

  <mat-horizontal-stepper #stepper class="forgot-password-stepper">
    <mat-step [stepControl]="forgotPasswordForm">
      <form (ngSubmit)="requestReset()" [formGroup]="forgotPasswordForm" novalidate>
        <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
          {{ error }}
        </div>
        <div class="form-group">
          <label for="">{{ 'ForgotPassword.YourEmailAddress' | translate }}</label
          ><input
            class="form-control"
            formControlName="email"
            placeholder="{{ 'ForgotPassword.EmailLabel' | translate }}"
            type="email"
            autocomplete="email"
          />
          <div class="pre-icon os-icon os-icon-user-male-circle"></div>
          <small
            [hidden]="forgotPasswordForm.controls.email.valid || forgotPasswordForm.controls.email.untouched"
            class="text-danger"
            translate
          >
            {{ 'ForgotPassword.EmailInvalid' | translate }}
          </small>
        </div>
        <div class="buttons-w">
          <button class="btn btn-primary" type="submit" [disabled]="forgotPasswordForm.invalid || isLoading">
            {{ 'ForgotPassword.ResetPassword' | translate }}
          </button>
          <a routerLink="/login" class="back-to-login"> {{ 'ForgotPassword.BackToLogin' | translate }}</a>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="forgotPasswordForm">
      <div class="success-screen" fxLayout="row">
        <div fxFlex="66" fxFlexOffset="15" fxFlex.xs="100" fxFlexOffset.xs="0">
          <svg-icon
            src="https://s3.eu-central-1.amazonaws.com/gastro.digital/icons/check-1.svg"
            class="success-icon"
            [svgStyle]="{ 'height.px': 75 }"
          ></svg-icon>
          <h2 style="color: #028e00">{{ 'ForgotPassword.EmailSent' | translate }}</h2>
          <br />
          <p>
            {{ 'ForgotPassword.EmailMessage1' | translate }} <b>{{ forgotPasswordForm.value.email }}</b>
            {{ 'ForgotPassword.EmailMessage2' | translate }}
          </p>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
