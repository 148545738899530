<div class="register-box-w">
  <h4 class="auth-header" style="padding-top:20px">
    <small style="font-size: 60%;"><h4>Change Password</h4></small>
  </h4>
  <form (ngSubmit)="changePassword()" [formGroup]="changePasswordForm" novalidate>
    <div class="form-group">
      <!-- <i class="far fa-building"></i> -->
      <label for="OldPassword">Old Password</label>
      <div class="clearfix">
        <input
          class="form-control"
          formControlName="oldPassword"
          placeholder="Old Password"
          type="{{ oldPassword }}"
          style="width: 95%; float: left;"
        />
        <span *ngIf="oldPassword == 'password'" (click)="changeType('oldPassword', true)"
          ><i class="fa fa-eye"></i
        ></span>
        <span *ngIf="oldPassword == 'text'" (click)="changeType('oldPassword', false)"
          ><i class="fa fa-eye-slash"></i
        ></span>
      </div>
      <small
        [hidden]="changePasswordForm.controls.oldPassword.valid || changePasswordForm.controls.oldPassword.untouched"
        class="text-danger"
        translate
      >
        Old Password ist ungültig.
      </small>
    </div>
    <div class="form-group">
      <!-- <i class="far fa-user"></i> -->
      <label for="newPassword">New Password</label>
      <div class="clearfix">
        <input
          class="form-control"
          formControlName="newPassword"
          placeholder="New Password"
          type="{{ newPassword }}"
          style="width: 95%; float: left;"
        />
        <span *ngIf="newPassword == 'password'" (click)="changeType('newPassword', true)"
          ><i class="fa fa-eye"></i
        ></span>
        <span *ngIf="newPassword == 'text'" (click)="changeType('newPassword', false)"
          ><i class="fa fa-eye-slash"></i
        ></span>
      </div>
      <small
        [hidden]="changePasswordForm.controls.newPassword.valid || changePasswordForm.controls.newPassword.untouched"
        class="text-danger"
        translate
      >
        New Password ist ungültig.
      </small>
    </div>
    <div class="form-group">
      <!-- <i class="far fa-key"></i> -->
      <label for="confirmPassword">Confirm Password</label>
      <div class="clearfix">
        <input
          class="form-control"
          formControlName="confirmPassword"
          placeholder="Confirm Password"
          type="{{ confirmPassword }}"
          style="width: 95%; float: left;"
        />
        <span *ngIf="confirmPassword == 'password'" (click)="changeType('confirmPassword', true)"
          ><i class="fa fa-eye"></i
        ></span>
        <span *ngIf="confirmPassword == 'text'" (click)="changeType('confirmPassword', false)"
          ><i class="fa fa-eye-slash"></i
        ></span>
      </div>
      <small
        [hidden]="
          changePasswordForm.controls.confirmPassword.valid || changePasswordForm.controls.confirmPassword.untouched
        "
        class="text-danger"
        translate
      >
        Confirm Password ist leer.
      </small>
    </div>
    <div style="color: red;">
      {{ message }}
    </div>
    <div class="buttons-w">
      <button class="btn btn-primary" type="submit">Change</button>
    </div>
  </form>
</div>
