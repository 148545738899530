<div class="container">
  <div class="row" [formGroup]="searchReservationGroup">
    <div class="form-group col-md-4">
      <input
        class="form-control input-section"
        formControlName="reservationNumber"
        type="text"
        autocomplete="nope"
        placeholder="Reservierungs-Nr."
      />
    </div>
    <div class="form-group col-md-4">
      <input
        class="form-control input-section"
        formControlName="roomNumber"
        type="text"
        autocomplete="nope"
        placeholder="Zimmer-Nr."
      />
    </div>
    <div class="col-md-3">
      <button class="btn btn-primary btn-lg btn-block" (click)="findReservation()">Suchen</button>
    </div>
  </div>
  <div *ngIf="errorMessage" class="row">
    <div class="col-md-8">
      <div class="alert alert-warning" role="alert">
        {{ errorMessage }}
      </div>
    </div>
  </div>
  <div class="row" *ngIf="reservation">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Datum</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">1</th>
          <td>{{ reservation.guest.name }}</td>
          <td>{{ reservation.reservations[0]?.createdAt | date }}</td>
          <td><button class="btn btn-primary" (click)="createPDF()">Download PDF</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
