<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h3 class="element-header">
          {{ 'ReservationDemo.DemoText' | translate }}
        </h3>
        <div class="row desktop-img">
          <img class="demo-img" alt="" src="./assets/img/demo1.png" />
        </div>
        <div class="row mobile-img">
          <img class="demo-img" alt="" src="./assets/img/demo3.png" />
        </div>
        <div class="center-block element-actions">
          <a href="https://www.gastro.digital/demotermin/" target="_blank" class="btn btn-primary demo-btn demo-link">
            {{ 'ReservationDemo.RequestDemo' | translate }}
          </a>
          <button class="btn btn-primary demo-btn" (click)="defaultResSystem()" [disabled]="disableBtn">
            {{ 'ReservationDemo.TryNow' | translate }}
          </button>
        </div>
        <div class="row">
          <img class="center-block" alt="" src="./assets/img/demo2.png" />
        </div>
      </div>
    </div>
  </div>
</div>
