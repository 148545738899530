<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editPaymentTemplateData">
        {{ 'ResSettings.ResAddPaymentTemplate.CreateNewDownPayment' | translate }}</ng-template
      >
      <ng-template [ngIf]="editPaymentTemplateData">{{
        'ResSettings.ResAddPaymentTemplate.ChangeDepositeTemplate' | translate
      }}</ng-template>
    </h4>
    <form [formGroup]="paymentTemplateFormGroup">
      <div fxLayout="row" fxLayoutGap="15px">
        <div fxFlex>
          <div class="form-group">
            <label for="">
              {{ 'ResSettings.ResAddPaymentTemplate.Name' | translate }}<br />
              <small>{{ 'ResSettings.ResAddPaymentTemplate.ForInternalUseOnly' | translate }}</small>
            </label>
            <input
              class="form-control"
              placeholder="Geben Sie einen Namen ein..."
              name="name"
              formControlName="name"
              type="text"
              value=""
              ngbAutofocus
            />
          </div>
        </div>
        <div fxFlex>
          <div class="form-group">
            <label for=""
              >{{ 'ResSettings.ResAddPaymentTemplate.MinNumberOfPeople' | translate }}<br />
              <small>{{ 'ResSettings.ResAddPaymentTemplate.FromHowManyPeople' | translate }}</small></label
            >
            <number-spinner
              name="minGuests"
              [min]="1"
              [max]="1000"
              [step]="1"
              [precision]="1"
              [inputDisabled]="false"
              [defaultValue]="+paymentTemplateFormGroup.value.minGuests"
              (whenChange)="minGuestsChanged($event)"
            ></number-spinner>
          </div>
        </div>
      </div>
      <div>
        <div class="form-group">
          <label for="">{{ 'ResSettings.ResAddPaymentTemplate.FlatRateForEveryOne' | translate }}</label>
          <br />
          <mat-radio-group
            aria-label="Wähle eine Option"
            formControlName="priceOption"
            (change)="paymentInfoTemplate($event.value)"
          >
            <mat-radio-button value="guest">{{
              'ResSettings.ResAddPaymentTemplate.PerGuest' | translate
            }}</mat-radio-button>
            <mat-radio-button value="flatrate" style="margin-left: 20px;">{{
              'ResSettings.ResAddPaymentTemplate.LumSum' | translate
            }}</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="form-group">
          <label for="" *ngIf="paymentTemplateFormGroup.value.priceOption === 'guest'">{{
            'ResSettings.ResAddPaymentTemplate.AmountToBePaidForEachGuest' | translate
          }}</label>
          <label for="" *ngIf="paymentTemplateFormGroup.value.priceOption === 'flatrate'">{{
            'ResSettings.ResAddPaymentTemplate.AmountToBePaidForLumSum' | translate
          }}</label>
          <div class="input-group">
            <input
              class="form-control"
              placeholder="Betrag eingeben..."
              value="0"
              formControlName="price"
              style="max-width:165px"
              mask="0*.00"
              [dropSpecialCharacters]="false"
            />
            <div class="input-group-append">
              <div class="input-group-text">{{ 'ResSettings.ResAddPaymentTemplate.Eur' | translate }}</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>{{ 'ResSettings.ResAddPaymentTemplate.WaitTimeForReservationUntilCancel' | translate }}</label>
          <select class="form-control" formControlName="cancelTime" style="max-width: 220px">
            <option *ngFor="let time of paymentCancelTimes" [value]="time">
              {{ time }}
              <ng-template [ngIf]="time === 1">{{ 'ResSettings.ResAddPaymentTemplate.Hour' | translate }}</ng-template>
              <ng-template [ngIf]="time > 1">{{ 'ResSettings.ResAddPaymentTemplate.Hours' | translate }}</ng-template>
            </option>
          </select>
        </div>

        <div class="form-group">
          <label>{{ 'ResSettings.ResAddPaymentTemplate.InfoTextForReservation' | translate }}</label>
          <textarea class="form-control" formControlName="infoText" rows="5"></textarea>
          <div class="textarea-append">
            <span
              >{{ 'ResSettings.ResAddPaymentTemplate.MagicVariables' | translate }}
              <i class="fa fa-info-circle" matTooltip="Magische Variablen werden durch deren Werte ersetzt"></i
            ></span>
            <div class="variables">
              <button class="badge badge-default" (click)="addVariable('[betrag]')">
                [{{ 'ResSettings.ResAddPaymentTemplate.Amount' | translate }}]
              </button>
              <button class="badge badge-default" (click)="addVariable('[betragproPerson]')">
                [{{ 'ResSettings.ResAddPaymentTemplate.AmountPerPerson' | translate }}]
              </button>
            </div>
          </div>
        </div>

        <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="add()"
          *ngIf="!editPaymentTemplateData"
          [promiseBtn]="addObservable"
        >
          {{ 'ResSettings.ResAddPaymentTemplate.CreateDepositTemplate' | translate }}
        </button>
        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="save()"
          *ngIf="editPaymentTemplateData"
          [promiseBtn]="editObservable"
        >
          {{ 'ResSettings.ResAddPaymentTemplate.SaveDepositTemplate' | translate }}
        </button>
        <button
          class="btn btn-link btn-sm delete-button"
          (click)="delete()"
          *ngIf="editPaymentTemplateData"
          [promiseBtn]="deleteObservable"
        >
          {{ 'ResSettings.ResAddPaymentTemplate.DeleteDepositTemplate' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
