<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Vorschaubild einstellen
        </h6>
        <div class="element-box">
          <div class="row">
            <div class="col-md-9">
              <div class="cropper-wrapper">
                <image-cropper
                  [imageChangedEvent]="imageChangedEvent"
                  [imageURL]="imageURL"
                  [maintainAspectRatio]="true"
                  [resizeToWidth]="128"
                  [cropperMinWidth]="128"
                  [onlyScaleDown]="true"
                  [roundCropper]="false"
                  [canvasRotation]="canvasRotation"
                  [transform]="transform"
                  [alignImage]="'center'"
                  [style.display]="showCropper ? null : 'none'"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (cropperReady)="cropperReady($event)"
                  (loadImageFailed)="loadImageFailed()"
                ></image-cropper>
                <div *ngIf="loading" class="loader">Loading...</div>
              </div>
              <div *ngIf="!showCropper" class="dropFile">
                <div class="dropText">Drop File Here...</div>
                <input #imageFile type="file" class="innerFile" title="" (change)="fileChangeEvent($event)" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <button class="btn btn-primary col-md-12" (click)="imageFile.click()">Add File</button>
                <input #imageFile type="file" style="display: none;" (change)="fileChangeEvent($event)" />
              </div>
              <div class="form-group">
                <label>Rotate Image</label>
                <button class="btn btn-primary" (click)="rotateLeft()"><i class="fas fa-undo"></i></button>
                <button class="btn btn-primary" (click)="rotateRight()"><i class="fas fa-redo"></i></button>
              </div>
              <div class="form-group">
                <label>Flip Image</label>
                <button class="btn btn-primary" (click)="flipHorizontal()">Flip horizontal</button>
                <button class="btn btn-primary" (click)="flipVertical()">Flip vertical</button>
              </div>
              <div class="form-group">
                <label>Zoom Image</label>
                <button class="btn btn-primary" (click)="zoomOut()"><i class="fas fa-minus"></i></button>
                <button class="btn btn-primary" (click)="zoomIn()"><i class="fas fa-plus"></i></button>
              </div>
              <div class="form-group">
                <button class="col-md-6 btn btn-primary" (click)="zoomOut()">Upload Image</button>
                <button class="col-md-4 btn btn-secondary" (click)="resetImage()">Reset image</button>
              </div>
              <div class="form-group">
                <button class="col-md-12 btn btn-danger" (click)="removeImage()">Remove image</button>
              </div>
              <!-- <button class="btn btn-secondary" (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain Within Aspect Ratio'}}</button>
							<button class="btn btn-secondary" (click)="toggleAspectRatio()">Aspect ratio: {{aspectRatio === 4/3 ? '4/3' : '16/9'}}</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
