<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          API-Key
        </h6>

        <div class="element-box orders-scheduler" *ngIf="clientApiKey">
          Ihr API Key für {{ clientApiKey.api.name }}:<br /><br />

          <strong>{{ clientApiKey.apiKey }}</strong>
        </div>
        <div class="element-box orders-scheduler" *ngIf="!clientApiKey">
          Sie haben noch keinen API-Key<br /><br />

          <button class="btn btn-primary" (click)="generateNewKey()">Neuen API-Key generieren</button>
        </div>
      </div>
    </div>
  </div>
</div>
