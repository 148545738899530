<breadcrumb></breadcrumb>
<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          {{ 'Accounting.Payout.Order.PayoutsOrderSystem' | translate }}
        </h6>

        <div class="element-box">
          <div class="alert alert-light borderless">
            <div>
              <h4>
                {{ 'Accounting.Payout.Order.CurrentBalances' | translate }}
                {{ returnPrice(payoutData?.balance) | number: '1.2' }}€
              </h4>
              <span class="text-danger" *ngIf="payoutData?.hasPendingPayouts">
                {{ 'Accounting.Payout.Order.YourStillOutStandingWithdrawals' | translate }}
              </span>
              <span class="text-success" *ngIf="!payoutData?.hasPendingPayouts && payoutData?.balance >= 200">
                {{ 'Accounting.Payout.Order.YouHaveYourBalancePayOut' | translate }}
              </span>
              <span class="text-danger" *ngIf="!payoutData?.hasPendingPayouts && payoutData?.balance < 200">
                {{ 'Accounting.Payout.Order.MinBalance' | translate }}
              </span>
            </div>
            <button
              class="btn btn-primary"
              (click)="requestPayout()"
              [disabled]="
                payoutInProgress ||
                payoutData?.hasPendingPayouts ||
                payoutData?.balance < 200 ||
                settings.autoMonthlyPayout === 1 ||
                companyName === '' ||
                companyAddress === ''
              "
              *ngIf="creds.id != 3 && creds.id != 24703 && billingCase.iban != ''"
            >
              {{ 'Accounting.Payout.Order.RequestPayout' | translate }}
            </button>
            <button
              class="btn btn-danger"
              routerLink="../../../accounting/payment-settings/1"
              *ngIf="billingCase.iban == ''"
            >
              {{ 'Accounting.Payout.Order.CompleteBankDetails' | translate }}
            </button>
            <button
              class="btn btn-primary"
              (click)="requestPayout()"
              *ngIf="billingCase.iban != '' && (creds.id == 3 || creds.id == 24703)"
            >
              {{ 'Accounting.Payout.Order.ReqPayoutAsAdmin' | translate }}
            </button>
          </div>

          <div class="system-setting-div" *ngIf="addDetails">
            <span class="text-error">
              {{ 'Accounting.Payout.Order.WaringMessageToAddBillingAdd' | translate }}
              <a
                target="_blank"
                class="link-error"
                href=" https://kunden.gastro.digital/client/{{ clientId }}/accounting/payment-settings"
              >
                {{ 'Accounting.Payout.Order.AddBillingAdd' | translate }}</a
              ></span
            >
          </div>

          <div class="table-responsive shifts" *ngIf="payoutData">
            <table class="table table-padded">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{{ 'Accounting.Payout.Order.Date' | translate }}</th>
                  <th>{{ 'Accounting.Payout.Order.Amount' | translate }}</th>
                  <th>Status</th>
                  <th>{{ 'Accounting.Payout.Order.Actions' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let payout of payoutData.payouts; let i = index">
                  <td class="nowrap">
                    <span>{{ payout.id }}</span>
                    <!--
                    <button class="btn btn-primary" (click)="createInvoicePdf(payout)">{{ payout.id }}</button>
                    -->
                  </td>
                  <td class="nowrap">
                    <span>{{ payout.createdAt | amDateFormat: 'DD.MM.YYYY HH:mm' }}</span>
                  </td>
                  <td>
                    <span>{{ returnPrice(payout.value) | number: '1.2' }} €</span>
                  </td>
                  <td>
                    <span *ngIf="payout.status === 'pending'">
                      {{ 'Accounting.Payout.Order.Pending' | translate }}
                    </span>
                    <span *ngIf="payout.status === 'completed'" class="text-success">
                      {{ 'Accounting.Payout.Order.Pending' | translate }} ({{
                        payout.paidAt | amDateFormat: 'DD.MM.YYYY'
                      }})
                    </span>
                  </td>
                  <td>
                    <input
                      type="button"
                      value="PDF herunterladen"
                      class="btn btn-light"
                      (click)="downloadPdf(payout)"
                    />
                    <!-- <input
                      type="button"
                      value="CSV generieren"
                      class="btn btn-light"
                      (click)="downloadCsv(payout)"
                      *ngIf="showPayoutId != payout.id"
                    /> -->

                    <!--[data]="downloadCsv(payout)" -->

                    <angular2csv
                      class="btn btn-light"
                      [data]="ordersCsv[payout.id]"
                      filename="auszahlung_{{ now | date: 'yyyy_MM_dd_H_mm' }}"
                      [options]="csvOptions"
                    ></angular2csv>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
