<div class="auth-box-w">
  <div class="logo-w">
    <a href="index.html"><img alt="" class="img-fluid" src="./assets/logo.png"/></a>
    <app-lang-selector-backoffice></app-lang-selector-backoffice>
  </div>
  <div class="auth-header">
    <h4>{{ 'Login.Title' | translate }}</h4>
    <p>{{ 'Login.Note' | translate }}</p>
  </div>
  <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
    <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
      {{ error }}
    </div>
    <div class="form-group">
      <label for="">{{ 'Common.eMail' | translate }}</label
      ><input
        class="form-control"
        formControlName="email"
        placeholder="{{ 'Common.eMail' | translate }}"
        type="text"
        autocomplete="email"
      />
      <div class="pre-icon os-icon os-icon-user-male-circle"></div>
      <small
        [hidden]="loginForm.controls.email.valid || loginForm.controls.email.untouched"
        class="text-danger"
        translate
      >
        {{ 'Login.EmailError' | translate }}
      </small>
    </div>
    <div class="form-group">
      <label for="">{{ 'Common.Password' | translate }}</label
      ><input
        class="form-control"
        formControlName="password"
        placeholder="{{ 'Common.Password' | translate }}"
        type="password"
        autocomplete="current-password"
      />
      <div class="pre-icon os-icon os-icon-fingerprint"></div>
      <small
        [hidden]="loginForm.controls.password.valid || loginForm.controls.password.untouched"
        class="text-danger"
        translate
      >
        {{ 'Login.PasswordError' | translate }}
      </small>
    </div>
    <div class="form-check-inline">
      <label class="form-check-label">
        <input class="form-check-input" type="checkbox" formControlName="remember" />{{ 'Login.Remain' | translate }}
      </label>
    </div>
    <div class="buttons-w">
      <button class="btn btn-primary" type="submit" [disabled]="loginForm.invalid || isLoading">
        {{ 'Common.login' | translate }}
      </button>
    </div>

    <p style="margin-top: 20px">
      {{ 'Login.Fotgot' | translate }} <a routerLink="/login/forgot-password">{{ 'Login.Fotgot2' | translate }}</a>
    </p>
    <p style="margin-top: 20px">
      {{ 'Login.NotMem' | translate }} <a routerLink="/register">{{ 'Login.Rgister' | translate }}</a>
    </p>
  </form>
</div>
