<breadcrumb></breadcrumb>
<div class="content-box" [ngStyle]="{ display: loader ? 'none' : 'block' }">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">Upload Photos -: {{ albumInfo.name }}</h6>
        <div class="element-box">
          <ngx-dropzone (change)="onFileChange($event)" [expandable]="true">
            <ngx-dropzone-label>Drop Files Here</ngx-dropzone-label>
            <ngx-dropzone-image-preview
              ngProjectAs="ngx-dropzone-preview"
              *ngFor="let f of files"
              [file]="f"
              [removable]="true"
              (removed)="onRemove(f)"
            >
              <ngx-dropzone-label style="background-color: #bee1f8; color: green;"
                >{{ f.name }} {{ f.sizeInKB }} KB</ngx-dropzone-label
              >
            </ngx-dropzone-image-preview>
          </ngx-dropzone>
          <div class="text-danger">{{ photoFormMessage }}</div>
          <div class="form-group" style="margin-top:10px">
            <button *ngIf="files && files.length" class="btn btn-primary" (click)="uploadPhotos()">
              Upload Photos
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngStyle]="{ display: loader ? 'block' : 'none' }" style="height: 100%;">
  <div style="position: relative; left: 25%; top: 48%; width: 50%;">
    <span>Please Wait while Uploading Photos</span>
    <mat-progress-bar mode="indeterminate" [value]="10"></mat-progress-bar>
  </div>
</div>
