/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-spinner";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/common";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./photo-renderer.component";
import * as i7 from "@angular/material/snack-bar";
import * as i8 from "../../../menucards/menucards.service";
var styles_PhotoRendererComponent = [];
var RenderType_PhotoRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PhotoRendererComponent, data: {} });
export { RenderType_PhotoRendererComponent as RenderType_PhotoRendererComponent };
function View_PhotoRendererComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-light btn-sm"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v.parent, 1).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      Upload\n    "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.params.data.id; _ck(_v, 0, 0, currVal_0); }); }
function View_PhotoRendererComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "20"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i1.View_MatSpinner_0, i1.RenderType_MatSpinner)), i0.ɵdid(1, 49152, null, 0, i2.MatSpinner, [i0.ElementRef, i3.Platform, [2, i4.DOCUMENT], [2, i5.ANIMATION_MODULE_TYPE], i2.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = "20"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i0.ɵnov(_v, 1).diameter; var currVal_2 = i0.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PhotoRendererComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["style", "width:30px; height: 30px; border-radius:30px;object-fit: contain;"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v.parent, 1).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.data.photo; _ck(_v, 0, 0, currVal_0); }); }
export function View_PhotoRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, [["file", 1]], null, 0, "input", [["accept", "image/*"], ["style", "display:none"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.uploadPhoto(i0.ɵnov(_v, 1).files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PhotoRendererComponent_1)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PhotoRendererComponent_2)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PhotoRendererComponent_3)), i0.ɵdid(10, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.params && !_co.params.data.photo) && !_co.isUploading); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.isUploading; _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.params && _co.params.data.photo) && !_co.isUploading); _ck(_v, 10, 0, currVal_2); }, null); }
export function View_PhotoRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "photo-renderer", [], null, null, null, View_PhotoRendererComponent_0, RenderType_PhotoRendererComponent)), i0.ɵdid(1, 180224, null, 0, i6.PhotoRendererComponent, [i7.MatSnackBar, i8.MenucardsService], null, null)], null, null); }
var PhotoRendererComponentNgFactory = i0.ɵccf("photo-renderer", i6.PhotoRendererComponent, View_PhotoRendererComponent_Host_0, {}, {}, []);
export { PhotoRendererComponentNgFactory as PhotoRendererComponentNgFactory };
