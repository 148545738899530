<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Change log
          <small *ngIf="filterBy"><br />Filter: <a class="link" (click)="filterOrdersBy()">aufheben</a></small>
        </h6>

        <div class="element-box mat-wrapper">
          <div class="row">
            <!-- <div class="col-md-3">
              <section>
                <div class="form-group">
                  <label for="">Datum von</label>
                  <div class="input-group">
                    <div class="input-group-prepend" (click)="dateFromPicker.open()">
                      <div class="input-group-text">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                    <input
                      class="form-control"
                      #dateFromInput
                      placeholder=""
                      [(ngModel)]="dates.from"
                      type="text"
                      name="date"
                      [matDatepicker]="dateFromPicker"
                      (focus)="dateFromPicker.open()"
                      (dateChange)="getLogs(false)"
                    />
                  </div>
                </div>
              </section>
            </div>

            <div class="col-md-3">
              <section>
                <div class="form-group">
                  <label for="">Datum bis</label>
                  <div class="input-group">
                    <div class="input-group-prepend" (click)="dateFromPicker.open()">
                      <div class="input-group-text">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
                    <input
                      class="form-control"
                      #dateToInput
                      placeholder=""
                      [(ngModel)]="dates.to"
                      type="text"
                      name="date"
                      [matDatepicker]="dateToPicker"
                      (focus)="dateToPicker.open()"
                      (dateChange)="getLogs(false)"
                    />
                  </div>
                </div>
              </section>
            </div> -->

            <!-- <div class="col-md-3" *ngIf="tablePages.length > 1">
              <section>
                <div class="form-group">
                  <label for="">Zeige Seite</label>
                  <div class="input-group">
                    <mat-select [(ngModel)]="tableOffset" (selectionChange)="changeTableOffset(tableOffset - 1)">
                      <mat-option *ngFor="let option of tablePages" [value]="option">Seite {{ option }}</mat-option>
                    </mat-select>
                  </div>
                </div>
              </section>
            </div> -->

            <div class="col-md-3">
              <div class="form-group row_">
                <form class="example-form">
                  <label style="margin-bottom: -4px; display: block;">Module:</label>
                  <mat-form-field class="example-full-width" style="width: 100%;">
                    <input
                      type="text"
                      matInput
                      [formControl]="myControl"
                      [(ngModel)]="filterBy"
                      style="width: auto;"
                      (keyup)="filterOrdersBy('module', $event.target.value)"
                    />
                  </mat-form-field>
                </form>
              </div>
            </div>

            <!-- <div class="col-md-12">
              <section>
                <div class="form-group actions">
                  <label for="">Aktionen</label><br />
                  <button class="btn btn-warning" *ngIf="filterBy" (click)="filterOrdersBy()">
                    <i class="fas fa-times"></i>&nbsp;Module:&nbsp;{{ filterBy }}
                  </button>
                  <button class="btn btn-light" (click)="setDates('today')">heute</button>
                  <button class="btn btn-light" (click)="setDates('yesterday')">gestern</button>
                  <button class="btn btn-light" (click)="setDates('lastSeven')">letzten 7 Tage</button>
                  <button class="btn btn-light" (click)="setDates('thisWeek')">akt. Woche</button>
                  <button class="btn btn-light" (click)="setDates('lastWeek')">letzte Woche</button>
                  <button class="btn btn-light" (click)="setDates('thisMonth')">akt. Monat</button>
                  <button class="btn btn-light" (click)="setDates('lastMonth')">letzter Monat</button>
                </div>
              </section>
            </div> -->
          </div>
        </div>

        <div class="element-box datatable-res-logs">
          <ngx-datatable
            class="material"
            [rows]="logs"
            [columnMode]="'force'"
            headerHeight="50"
            footerHeight="50"
            rowHeight="auto"
            [limit]="tableLimit"
            [sorts]="[{ prop: 'id', dir: 'desc' }]"
            [cssClasses]="{
              pagerLeftArrow: 'fas fa-backward',
              pagerRightArrow: 'fas fa-forward',
              pagerPrevious: 'fas fa-step-backward',
              pagerNext: 'fas fa-step-forward'
            }"
          >
            <ngx-datatable-column [sortable]="true" name="Datum" prop="date">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="date-label" style="white-space: nowrap;"
                  >{{ row.createdAt | amDateFormat: 'DD.MM.YYYY' }} -
                  {{ row.createdAt | amDateFormat: 'HH:mm' }}
                </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="true" name="User" prop="row?.memberLogs?.firstName">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{ row?.memberLogs?.username }} </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="true" name="Module" prop="module">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span>{{ row.module }} - {{ row.subModule }} </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="true" name="Action" prop="action">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span [ngClass]="row.action" class="badge badge-dark">{{ row.action }} </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-row-detail [rowHeight]="'100%'" #myDetailRow>
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                <div class="logs-list">
                  <div class="ta-activity" [innerHTML]="row.details"></div>
                </div>
              </ng-template>
            </ngx-datatable-row-detail>

            <ngx-datatable-column
              [width]="400"
              [resizeable]="false"
              [sortable]="false"
              [draggable]="false"
              [canAutoResize]="false"
            >
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                <a
                  *ngIf="row.details.length > 200"
                  href="javascript:void(0)"
                  title="Expand/Collapse Row"
                  (click)="toggleExpandRow(row)"
                >
                  Show details...</a
                >
                <div *ngIf="row.details.length <= 200" [innerHTML]="row.details"></div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

          <div class="grid">
            <mat-paginator
              [length]="logsMobile.length"
              pageSize="{{ pageSize }}"
              (page)="getPaginatorData($event)"
              [pageSizeOptions]="[10, 20, 50]"
              showFirstLastButtons="false"
            >
            </mat-paginator>
            <div class="row">
              <ng-container *ngFor="let row of logsMobile; let i = index">
                <div
                  class="col-sm-6 col-md-6"
                  *ngIf="i < table.offset * tableLimit + tableLimit && i >= table.offset * tableLimit"
                >
                  <div class="card" id="card-{{ row.id }}">
                    <div class="card-body">
                      <h5 class="card-title">
                        <span class="badge" [ngClass]="row.action" class="badge badge-dark">{{ row.action }} </span>
                        <span class="badge" style="font-weight: normal;"> {{ row.module }} - {{ row.subModule }} </span>
                        <br />
                        <small
                          >{{ row.createdAt | amDateFormat: 'DD.MM.YYYY' }} -
                          {{ row.createdAt | amDateFormat: 'HH:mm' }}</small
                        >
                        <br />
                        <small>
                          {{ row.memberLogs?.username }}
                        </small>
                      </h5>
                      <p class="card-text" style="font-size: 13px;">
                        <span *ngIf="!row.showMore" [innerHtml]="row.lessDetails"></span>
                        <span *ngIf="row.showMore" [innerHTML]="row.details"> </span>
                      </p>
                      <a class="show-detail" *ngIf="row.details.length > 200" (click)="row.showMore = !row.showMore"
                        >Show
                        <span>{{ row.showMore ? 'less' : 'More' }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
