<div class="modal-content voucher-modal text-center">
  <button aria-label="Close" class="close" style="outline:none;" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      Post Voucher Codes
    </h4>
    <div class="table-responsive shifts" *ngIf="order.templates">
      <table class="table table-voucher table-padded">
        <tbody>
          <ng-container *ngFor="let template of order.templates; index as i">
            <tr>
              <td>
                <span>{{ i + 1 }}. </span>
              </td>
              <td>
                <span>{{ template.value | currency: 'EUR' }}</span>
              </td>
              <td>
                <span> {{ template.code }}</span>
              </td>
              <td>
                <div class="form-group">
                  <label for="">Gutscheincode auswählen</label>
                  <ng-select
                    [searchable]="true"
                    [clearable]="true"
                    (change)="filterVoucher($event, template.id)"
                    (search)="searchVoucher($event)"
                    (clear)="searchVoucher()"
                    placeholder="-- Gutscheincode --"
                    [class.filtered]="voucherValue"
                    [items]="vouchers"
                    bindLabel="code"
                    notFoundText="Kein Gutschein gefunden"
                  >
                  </ng-select>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="row">
      <button class="btn btn-primary btn-block btn-lg" (click)="save()">
        speichern
      </button>
    </div>
  </div>
</div>
