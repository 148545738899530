import { Component, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ClientService } from '@app/core/client.service';
import { MatSnackBar } from '@angular/material';
import { CredentialsService } from '@app/core';

import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-feedback-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  public showFeedbacks: number = 20;
  public showMoreFeedbacks: number = 10;
  public feedbacks: any[] = [];
  public selectedFeedback: number = 0;
  public selectedFeedbackAnswer: number = -1;
  private ngUnsubscribe: Subject<any> = new Subject();
  public clientName: string = '';
  canDeleteFeedback = false;

  constructor(
    private clientService: ClientService,
    private snackBar: MatSnackBar,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    this.clientService
      .getPermissionsOfUser(this.credentialsService.getCredentials().id)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((permisions: string[]) => {
        if (this.credentialsService.isAdmin()) {
          this.canDeleteFeedback = true;
        }
      });
    this.getFeedbacks();
  }

  getFeedbacks() {
    console.log('getFeedbacks', this.feedbacks);
    this.clientService
      .getFeedbacksOverview()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        this.feedbacks = res || [];

        this.feedbacks.forEach(feedback => {
          feedback.postedAt = new Date(feedback.postedAt.replace(/\s/, 'T') + 'Z');
          if (feedback.reservation) {
            feedback.reservation.reservedFor = new Date(feedback.reservation.reservedFor.replace(/\s/, 'T') + 'Z');
          }
        });

        console.log('res', this.feedbacks);
        if (this.clientName == '' && this.feedbacks.length) {
          this.clientService
            .getClient(this.feedbacks[0].betriebId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(res => {
              if (res) {
                this.clientName = res.name;
              }
            });
        }
      });
  }
  saveFeedback(feedback: any) {
    feedback.clientName = this.clientName;
    console.log('save', feedback);
    this.clientService.saveFeedback(feedback).subscribe((res: any) => {
      this.snackBar.open('Das Feedback wurden erfolgreich aktualisiert', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }
  deleteFeedback(feedback: any, i: number) {
    console.log('delete', feedback);
    if (confirm('Möchten Sie das Feedback wirklich löschen?')) {
      this.clientService.deleteFeedback(feedback).subscribe((res: any) => {
        this.selectedFeedback = -1;
        this.feedbacks.splice(i, 1);
        this.snackBar.open('Das Feedback wurde gelöscht', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
    }
  }
}
