
    <ng-select
      [items]="tags"
      [multiple]="true"
      bindLabel="name"
      placeholder="Nach Tags filtern..."
      [closeOnSelect]="false"
      [(ngModel)]="selectedTags"
      appendTo="body"
      [clearable]="false"
      #select
    >
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value custom-select-tag" *ngFor="let item of items" [style.background-color]="item.color">
          <span class="ng-value-label">{{ item.name }}</span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
        <div class="ng-value custom-select-tag" style="background-color: #b1b1b1" *ngIf="items.length > 1">
          <span class="ng-value-label">+ {{ items.length - 1 }}</span>
        </div>
      </ng-template>
    </ng-select>
  