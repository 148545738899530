/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./tags-renderer.component";
var styles_TagsRendererComponent = [];
var RenderType_TagsRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TagsRendererComponent, data: {} });
export { RenderType_TagsRendererComponent as RenderType_TagsRendererComponent };
function View_TagsRendererComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-default"], ["style", "color: white"]], [[4, "background-color", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.color; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_1); }); }
export function View_TagsRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TagsRendererComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.data.tags; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TagsRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tags-renderer", [], null, null, null, View_TagsRendererComponent_0, RenderType_TagsRendererComponent)), i0.ɵdid(1, 180224, null, 0, i2.TagsRendererComponent, [], null, null)], null, null); }
var TagsRendererComponentNgFactory = i0.ɵccf("tags-renderer", i2.TagsRendererComponent, View_TagsRendererComponent_Host_0, {}, {}, []);
export { TagsRendererComponentNgFactory as TagsRendererComponentNgFactory };
