<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Gutschein - Transaktionen
        </h6>

        <div class="row">
          <div class="col-md-8">
            <div class="element-box">
              <div class="row">
                <div class="col-md-8">
                  <legend><span>Filtern nach</span></legend>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <section>
                    <div class="form-group">
                      <label for="">Datum von</label>
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateFromPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                        <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #dateFromInput
                          placeholder=""
                          [(ngModel)]="dates.from"
                          type="text"
                          name="date"
                          [matDatepicker]="dateFromPicker"
                          (focus)="dateFromPicker.open()"
                          (dateChange)="filterDates('from', $event)"
                        />
                      </div>
                    </div>
                  </section>
                </div>
                <div class="col-md-4">
                  <section>
                    <div class="form-group">
                      <label for="">Datum bis</label>
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateFromPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                        <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #dateToInput
                          placeholder=""
                          [(ngModel)]="dates.to"
                          type="text"
                          name="date"
                          [matDatepicker]="dateToPicker"
                          (focus)="dateToPicker.open()"
                          (dateChange)="filterDates('to', $event)"
                        />
                      </div>
                    </div>
                  </section>
                </div>

                <div class="col-md-4 hidden">
                  <section>
                    <div class="form-group">
                      <label for="">Tabelle exportieren</label><br />
                      <angular2csv
                        class="btn btn-primary"
                        [data]="transactions"
                        filename="export_{{ now | date: 'yyyy_MM_dd_H_mm' }}"
                        [options]="csvOptions"
                      ></angular2csv>
                    </div>
                  </section>
                </div>

                <div class="col-md-12">
                  <section>
                    <div class="form-group actions">
                      <label for="">Aktionen</label><br />
                      <button class="btn btn-light" (click)="setDates('today')">heute</button>
                      <button class="btn btn-light" (click)="setDates('yesterday')">gestern</button>
                      <button class="btn btn-light" (click)="setDates('lastSeven')">letzten 7 Tage</button>
                      <button class="btn btn-light" (click)="setDates('thisWeek')">akt. Woche</button>
                      <button class="btn btn-light" (click)="setDates('lastWeek')">letzte Woche</button>
                      <button class="btn btn-light" (click)="setDates('thisMonth')">akt. Monat</button>
                      <button class="btn btn-light" (click)="setDates('lastMonth')">letzter Monat</button>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4" *ngIf="transactions.length">
            <a class="element-box el-tablo" style="padding: 1.65rem 1rem 1.65rem;" href="javascript:return;">
              <div class="row">
                <div class="col-sm-8">
                  <div class="label">
                    Umsatz (brutto)
                  </div>
                  <div class="value">
                    {{ filterStats?.orders.total | currency: 'EUR' }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="label">
                    Anz.
                  </div>
                  <div class="value text-right">
                    {{ filterStats.orders.count }}
                  </div>
                </div>
              </div>

              <div class="value">
                <div class="label" style="padding-top: 19px;">Zeitraum</div>
                <small>{{ dates.from | date: 'd.M.yy' }} - {{ dates.to | date: 'd.M.yy' }}</small>
              </div>

              <div class="row" style="margin-top: 15px;">
                <div class="el-tablo smaller col-sm-4">
                  <div class="label">Bar</div>
                  <div class="value">{{ filterStats?.orders.cash | currency: 'EUR' }}</div>
                </div>
                <div class="el-tablo smaller col-sm-4">
                  <div class="label">Online</div>
                  <div class="value">{{ filterStats?.orders.online | currency: 'EUR' }}</div>
                </div>
                <div class="el-tablo smaller col-sm-4">
                  <div class="label">Gebühren</div>
                  <div class="value">{{ filterStats?.orders.commission | currency: 'EUR' }}</div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <!-- filter box -->

        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Alle Einträge ({{ transactions ? transactions.length : 0 }})">
            <div class="element-box">
              <div class="loading" *ngIf="!transactions">
                <i class="fa fa-spinner fa-spin"></i>
                Transaktionen werden geladen
              </div>
              <ng-template [ngIf]="transactions">
                <div class="alert alert-info" *ngIf="!transactions.length">
                  Sie haben keine Transaktionen im gewählten Zeitraum
                </div>

                <div class="datatable-orders" *ngIf="transactions.length">
                  <ngx-datatable
                    class="material"
                    [rows]="transactions"
                    [columnMode]="'force'"
                    headerHeight="50"
                    footerHeight="50"
                    rowHeight="auto"
                    [limit]="tableLimit"
                    [sorts]="[{ prop: 'createdAt', dir: 'desc' }]"
                    [cssClasses]="{
                      pagerLeftArrow: 'fas fa-backward',
                      pagerRightArrow: 'fas fa-forward',
                      pagerPrevious: 'fas fa-step-backward',
                      pagerNext: 'fas fa-step-forward'
                    }"
                  >
                    <ngx-datatable-column [sortable]="true" name="ID" prop="id">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span>{{ row.id }}</span>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [sortable]="true" name="Datum" prop="createdAt">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="date-label">{{ row.createdAt | amDateFormat: 'DD.MM.YYYY' }}</span>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [sortable]="true" name="Uhrzeit" prop="createdAt">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="date-label">{{ row.createdAt | amDateFormat: 'HH:mm' }}</span>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [sortable]="true" name="Besteller" prop="lastName">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <ng-container *ngIf="row.lastName != 'Auszahlung'">
                          {{ row.lastName + ', ' + row.firstName }}
                        </ng-container>
                        <ng-container *ngIf="row.lastName == 'Auszahlung'">
                          <span class="text-danger">{{ row.lastName }}</span>
                        </ng-container>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [sortable]="true" name="Betrag" prop="subtotal">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="price-label"
                          >{{ returnPrice(row.subtotal) + returnPrice(row.deliveryFee) | number: '1.2' }} €</span
                        >
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [sortable]="true" name="Gebühr" prop="commissionFee">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="price-label">{{ returnPrice(row.commissionFee) | number: '1.2' }} €</span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column [sortable]="true" name="Zahlart" prop="paymentMethod">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.paymentMethod === 'cash'">Bar</span>
                        <span *ngIf="row.paymentMethod === 'gastropay'">Gastropay</span>
                        <span *ngIf="row.paymentMethod === 'online'">{{
                          row.paymentTransaction?.paymentMethod
                            .replace('paypal', 'PayPal')
                            .replace('creditcard', 'Kreditkarte')
                            .replace('klarna', 'Klarna')
                            .replace('applepay', 'Apple Pay')
                        }}</span>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [sortable]="true" name="Typ" prop="type">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.type === 'delivery'">Lieferung</span>
                        <span *ngIf="row.type === 'takeaway'">Abholung</span>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [sortable]="true" name="Status" prop="status">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="text-success" *ngIf="row.status === 'confirmed'">
                          Fertig
                        </span>
                        <span class="text-light" *ngIf="row.status === 'pending'">
                          Ausstehend
                        </span>
                        <span class="text-danger" *ngIf="row.status === 'canceled'">
                          Storniert
                        </span>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [sortable]="false" name="Kontostand" prop="balance">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="price-label" *ngIf="row.balance"
                          >{{ returnPrice(row.balance) | number: '1.2' }} €</span
                        >
                      </ng-template>
                    </ngx-datatable-column>
                  </ngx-datatable>
                </div>
              </ng-template>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
