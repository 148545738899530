<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="col-sm-12" *ngIf="client">
    <div class="element-wrapper">
      <h6 class="element-header">
        {{ 'ShellMenu.GuestMarketing.OwnApp.RestaurantInfo' | translate }}
      </h6>
      <div class="fullscreen-element">
        <div *ngIf="client.client">
          <div class="card">
            <div class="row profile-wrapper">
              <div class="col-md-2 col-sm-12">
                <div class="d-flex">
                  <div class="profile-pic-container">
                    <img
                      [src]="
                        client && client.logo
                          ? client.logo
                          : 'https://s3.eu-central-1.amazonaws.com/gastro.digital/imgs/default_logo.png'
                      "
                      alt="Profile Picture"
                      class="profile-pic"
                    />

                    <button class="btn btn-primary add-button" (click)="logoOptions()">
                      <i class="fas fa-plus plus-icon"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-10 col-sm-12">
                <div class="row">
                  <div class="col-md-4 col-sm-4 title-restaurant">
                    <label class="title-restaurant-info" for="">{{
                      'RestaurantProfile.RestaurantName' | translate
                    }}</label
                    ><br />
                    <span class="restaurant-info">{{ client?.client?.name }}</span>
                  </div>
                  <div class="col-md-4 col-sm-4 title-restaurant">
                    <label class="title-restaurant-info" for="">{{ 'RestaurantProfile.Street' | translate }}</label
                    ><br />
                    <span class="restaurant-info">{{ client?.client?.street }}, {{ client?.client?.zipCode }}</span>
                  </div>
                  <div class="col-md-4 col-sm-4 title-restaurant">
                    <label class="title-restaurant-info" for="">{{ 'RestaurantProfile.Telephone' | translate }}</label
                    ><br />
                    <span class="restaurant-info">{{ client?.client?.telefon }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!client.client">
          Keine Daten gefunden
        </div>
      </div>
    </div>
  </div>
</div>
