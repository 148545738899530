<div class="modal-content text-center">
  <button aria-label="Close" class="close" style="outline:none;" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      {{ 'Vouchers.Transactions.CancelReasonText' | translate }}
    </h4>
    <form [formGroup]="msgAddressFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <textarea
              class="form-control"
              placeholder=""
              type="text"
              name="msg"
              formControlName="msg"
              rows="4"
            ></textarea>
          </div>
        </div>
        <button class="btn btn-primary btn-block btn-lg" (click)="saveCancelText()">
          {{ 'Common.Cancel' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
