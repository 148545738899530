<breadcrumb></breadcrumb>

<ng-template
  [ngIf]="
    settings && (!settings.poolSettings || (settings.poolSettings.type === 'city' && !settings.poolSettings.isMother))
  "
>
  <div padding>
    <div class="alert alert-danger borderless">
      <b>{{ 'Vouchers.VoucherPool.NoAccess' | translate }}</b>
      <p>{{ 'Vouchers.VoucherPool.NoAccessMessage' | translate }}</p>
    </div>
  </div>
</ng-template>

<ng-template
  [ngIf]="
    settings && settings.poolSettings && !(settings.poolSettings.type === 'city' && !settings.poolSettings.isMother)
  "
>
  <div
    class="content-box"
    style="padding-bottom:0"
    *ngIf="
      settings.poolSettings.type === 'community' ||
      settings.poolSettings.type === 'city' ||
      (settings.poolSettings.type === 'branch' && !settings.poolSettings.isMother)
    "
  >
    <div class="row">
      <div class="col-sm-12">
        <div class="element-wrapper">
          <h6 class="element-header">
            {{ 'Vouchers.VoucherPool.PoolSettlement' | translate }}
          </h6>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for=""> {{ 'Common.DateRange' | translate }}</label>
                <div class="date-input">
                  <input
                    class="form-control single-daterange"
                    placeholder="{{ 'Common.SelectDate' | translate }}"
                    (click)="picker.open()"
                    [satDatepicker]="picker"
                    [(ngModel)]="date"
                    (ngModelChange)="getPoolClients()"
                  />
                </div>
                <sat-datepicker #picker [rangeMode]="true" [calendarFooterComponent]="rangesFooter"></sat-datepicker>
              </div>
            </div>
            <div class="col-md-3">
              <button
                class="btn btn-primary"
                (click)="downloadExcelList(date.begin, date.end)"
                style="margin-top: 30px"
              >
                {{ 'Vouchers.VoucherPool.DownloadExcelFile' | translate }}
              </button>
            </div>
            <div class="col-md-3" *ngIf="showVoucherResBtn">
              <button
                class="btn btn-primary"
                (click)="downloadVoucherResidualValues(liabilityDate.end)"
                style="margin-top: 30px"
              >
                {{ 'Vouchers.VoucherPool.DownloadVoucherResidualValues' | translate }}
              </button>
            </div>
            <div class="col-md-3"></div>
            <div class="col-md-3"></div>
          </div>

          <div class="loading" *ngIf="!poolClients || !poolClients.length">
            <i class="fa fa-spinner fa-spin"></i>
            {{ 'Vouchers.VoucherPool.CompaniesLoadMessage' | translate }}
          </div>

          <div class="element-box-tp" *ngIf="poolClients && poolClients.length">
            <div class="table-responsive">
              <table class="table table-padded">
                <tbody>
                  <tr *ngFor="let client of poolClients; let i = index">
                    <td>
                      <h6>
                        {{ client.name }}
                        <i
                          *ngIf="!client.allowRecharge"
                          class="text-danger fas fa-times-circle fa-fw"
                          matTooltip="{{ 'Vouchers.VoucherPool.NoTopUpVouchers' | translate }}"
                        ></i>
                      </h6>
                      <small>{{ client.street }}, {{ client.zipCode }} {{ client.location }}</small>
                    </td>
                    <td class="balance">
                      <span [class.text-success]="client.balance >= 0" [class.text-danger]="client.balance < 0">
                        {{ client.balance | currency: 'EUR' }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-box" *ngIf="settings.poolSettings.type === 'branch' && settings.poolSettings.isMother">
    <div class="row">
      <div class="col-sm-12">
        <div class="element-wrapper">
          <h6 class="element-header">
            {{ 'Vouchers.VoucherPool.PoolParticipantsBilling' | translate }}
          </h6>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="">{{ 'Common.DateRange' | translate }}</label>
                <div class="date-input">
                  <input
                    class="form-control single-daterange"
                    placeholder="{{ 'Common.SelectDate' | translate }}"
                    (click)="liabilityPicker.open()"
                    [satDatepicker]="liabilityPicker"
                    [(ngModel)]="liabilityDate"
                    (ngModelChange)="getPoolLiability()"
                  />
                </div>
                <sat-datepicker #liabilityPicker [rangeMode]="true" [calendarFooterComponent]="rangesFooter">
                </sat-datepicker>
              </div>
            </div>
            <div class="col-md-3">
              <button
                class="btn btn-primary"
                (click)="downloadExcelList(liabilityDate.begin, liabilityDate.end)"
                style="margin-top: 30px"
              >
                {{ 'Vouchers.VoucherPool.DownloadExcelFile' | translate }}
              </button>
            </div>
            <div class="col-md-3" *ngIf="showVoucherResBtn">
              <button
                class="btn btn-primary"
                (click)="downloadVoucherResidualValues(liabilityDate.end)"
                style="margin-top: 30px"
              >
                {{ 'Vouchers.VoucherPool.DownloadVoucherResidualValues' | translate }}
              </button>
            </div>

            <div class="col-md-3"></div>
            <div class="col-md-3"></div>
          </div>

          <div class="loading" *ngIf="!poolLiability || !poolLiability.length">
            <i class="fa fa-spinner fa-spin"></i>
            Abrechnung wird geladen
            {{ 'Vouchers.VoucherPool.BillingLoading' | translate }}
          </div>

          <div class="element-box" *ngIf="poolLiability && poolLiability.length">
            <div class="table-responsive">
              <ng-scrollbar [trackX]="false" [shown]="'hover'" [compact]="true">
                <table class="table table-lightborder">
                  <thead>
                    <tr>
                      <th>{{ 'Vouchers.VoucherPool.Holder' | translate }}</th>
                      <th *ngFor="let client of poolLiability">
                        <span [innerHtml]="client.name | truncatetext: 20" [matTooltip]="client.name"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let client of poolLiability">
                      <td><span [innerHtml]="client.name | truncatetext: 20" [matTooltip]="client.name"></span></td>
                      <td
                        *ngFor="let data of client.accounting"
                        [class.text-success]="data.value > 0"
                        [class.text-danger]="data.value < 0"
                      >
                        <span [class.muted]="data.value === 0">{{ data.value | currency: 'EUR' }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
