<div class="modal-header">
  <h4 class="modal-title">Offene Reservierungsanfragen</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="padding: 0">
  <div class="list-view">
    <ngx-datatable
      #pendingReservations
      class="bootstrap reservations-table expandable"
      [columnMode]="'flex'"
      [headerHeight]="35"
      [footerHeight]="50"
      [summaryRow]="true"
      [loadingIndicator]="loadingReservationList"
      [summaryHeight]="'auto'"
      [rowHeight]="'auto'"
      [scrollbarV]="false"
      [scrollbarH]="false"
      [groupRowsBy]="'date'"
      [groupExpansionDefault]="true"
      [rows]="reservations"
      [limit]="10000"
      (activate)="onRowActivate($event)"
      [sorts]="[{ prop: 'reservedFor', dir: 'asc' }]"
      (sort)="onSort($event)"
      [messages]="{ emptyMessage: 'Keine ausstehenden Reservierungen vorhanden', total: ' insgesamt' }"
    >
      <ngx-datatable-group-header [rowHeight]="50" #myGroupHeader>
        <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
          <div style="padding-left:2rem;">
            <b>{{ group.value[0].date | dfnsFormat: 'dddd, DD. MMMM YYYY' }}</b>
          </div>
        </ng-template>
      </ngx-datatable-group-header>

      <ngx-datatable-column
        [sortable]="true"
        [canAutoResize]="false"
        [resizeable]="false"
        prop="reservedFor"
        name="Zeit"
        [width]="80"
        [summaryTemplate]="nameSummaryCell"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div style="display: flex;align-items: center;">
            <div>
              <b>{{ row.startDate | dfnsFormat: 'HH:mm' }}</b>
              <br />
              {{ row.endDate | dfnsFormat: 'HH:mm' }}
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [sortable]="false" [resizeable]="false" prop="guestData.name" name="Name" [flexGrow]="2">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <ng-template [ngIf]="row.guestData">
            <div style="display:flex; align-items:center">
              <span
                *ngIf="row && row.shift"
                class="status-pill smaller"
                [matTooltip]="row.shift.name"
                [ngStyle]="{ 'background-color': row.shift ? row.shift.color : '#fff' }"
              ></span>
              <span *ngIf="layout > LAYOUT.XXS">{{ row.guestData.name ? row.guestData.name : 'Kein Name' }}</span>
              <b *ngIf="layout < LAYOUT.XS">{{ row.guestData.name ? row.guestData.name : 'Kein Name' }}</b>
              <span
                class="badge badge-pill badge-visits"
                *ngIf="row.guestData && row.guestData.visits > 0"
                matTooltip="{{ row.guestData.visits }} Besuche insgesamt"
              >
                <ng-template [ngIf]="row.guestData.visits >= 50"><i class="fas fa-crown fa-fw"></i></ng-template>
                {{ row.guestData.visits }}
              </span>
            </div>
          </ng-template>
          <ng-template [ngIf]="!row.guestData">
            <div>
              <span *ngIf="layout > LAYOUT.XXS">Walk-In</span>
              <b *ngIf="layout < LAYOUT.XS">Walk-In</b>
            </div>
          </ng-template>
          <span class="date-label">
            {{ row.createdAt | amTimeAgo }}
            <mat-chip-list *ngIf="row.msg || row.notes">
              <mat-chip class="guest-note" *ngIf="row.msg" [matTooltip]="row.msg">Hinweis</mat-chip>
              <mat-chip class="admin-note" *ngIf="row.notes" [matTooltip]="row.notes">Notiz</mat-chip>
            </mat-chip-list>
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [sortable]="true" [resizeable]="false" prop="peopleCount" name="Gäste" [flexGrow]="0.5">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div [class.text-right]="layout < LAYOUT.XS">
            {{ row.peopleCount }} <span class="d-none d-xl-inline">Personen</span>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [sortable]="false"
        [resizeable]="false"
        prop="tables"
        name="Tisch"
        [flexGrow]="1"
        [comparator]="sortTablesComparator"
        *ngIf="layout > LAYOUT.XXS"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="tables">
            <ng-template ngFor let-table [ngForOf]="row.tables">
              <span *ngIf="table" class="badge badge-default" placement="bottom" [ngbTooltip]="table?.room?.name">
                {{ table.name }}
              </span>
            </ng-template>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [sortable]="false"
        [resizeable]="false"
        prop="tags"
        name="Tags"
        [flexGrow]="1"
        *ngIf="layout > LAYOUT.XXS"
      >
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          <div class="tags">
            <ng-template ngFor let-tag [ngForOf]="row.tags">
              <span class="badge" [style.background-color]="tag.color">
                {{ tag.label }}
              </span>
            </ng-template>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [sortable]="true"
        [resizeable]="false"
        prop="status"
        name="Status"
        [flexGrow]="1"
        cellClass="text-right"
        headerClass="text-right"
        *ngIf="layout > LAYOUT.XXS"
      >
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          <div class="status-buttons">
            <button
              class="btn btn-success btn-sm"
              matTooltip="Bestätigt"
              (click)="callUpdateStatus('confirmed', 'status', rowIndex, row)"
            >
              <i class="fas fa-check-circle fa-fw"></i>
            </button>
            <button
              class="btn btn-danger btn-sm"
              matTooltip="Storniert"
              (click)="callUpdateStatus('canceled', 'status', rowIndex, row)"
            >
              <i class="fas fa-ban fa-fw"></i>
            </button>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [sortable]="false"
        [cellClass]="'cell-align-right'"
        [resizeable]="false"
        [canAutoResize]="false"
        *ngIf="false"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <button class="btn btn-light" (click)="editReservation(row)">
            <i class="fas fa-edit"></i>
          </button>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-footer>
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-curPage="curPage"
          let-offset="offset"
        >
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
    <ng-template #nameSummaryCell let-row="row" let-value="value">
      <div class="table-footer" *ngIf="table && table.rows && table.rows.length ? true : false">
        Sie haben
        <strong>{{ table?.rows?.length }} ausstehende Reservierung<span *ngIf="table.rows.length > 1">en</span></strong>
        mit
        <strong *ngIf="row && row.peopleCount">{{
          row.peopleCount === 1 ? 'einem Gast' : row.peopleCount + ' Gästen'
        }}</strong>
      </div>
    </ng-template>
  </div>
</div>
