/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-draft-company.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./select-draft-company.component";
import * as i5 from "../new-company/new-company.service";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
var styles_SelectDraftCompanyComponent = [i0.styles];
var RenderType_SelectDraftCompanyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectDraftCompanyComponent, data: {} });
export { RenderType_SelectDraftCompanyComponent as RenderType_SelectDraftCompanyComponent };
function View_SelectDraftCompanyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["style", "padding-bottom:10px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      No Drafts Available\n    "]))], null, null); }
function View_SelectDraftCompanyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "logged-user-name"], ["style", "cursor:pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loadForm(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "address"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ", ", " ", "\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "auth-type"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fa fa-trash"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.masterData.name; _ck(_v, 5, 0, currVal_0); var currVal_1 = _v.context.$implicit.masterData.street; var currVal_2 = _v.context.$implicit.masterData.zipCode; var currVal_3 = _v.context.$implicit.masterData.location; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_SelectDraftCompanyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "auth-box-w wider centered"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 6, "h4", [["class", "auth-header"], ["style", "padding-top:20px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select Draft New Client from list"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "client-list"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDraftCompanyComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDraftCompanyComponent_2)), i1.ɵdid(16, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(21, 1), (_l()(), i1.ɵted(-1, null, ["Go to Select Client"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(24, 0, null, null, 3, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(26, 1), (_l()(), i1.ɵted(-1, null, ["Neuen Betrieb hinzuf\u00FCgen"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dataList && !_co.dataList.length); _ck(_v, 13, 0, currVal_0); var currVal_1 = _co.dataList; _ck(_v, 16, 0, currVal_1); var currVal_2 = _ck(_v, 21, 0, "/select"); _ck(_v, 20, 0, currVal_2); var currVal_3 = _ck(_v, 26, 0, "/new-client"); _ck(_v, 25, 0, currVal_3); }, null); }
export function View_SelectDraftCompanyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-select-draft-company", [], null, null, null, View_SelectDraftCompanyComponent_0, RenderType_SelectDraftCompanyComponent)), i1.ɵdid(1, 114688, null, 0, i4.SelectDraftCompanyComponent, [i5.NewCompanyService, i6.NgbModal, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectDraftCompanyComponentNgFactory = i1.ɵccf("app-select-draft-company", i4.SelectDraftCompanyComponent, View_SelectDraftCompanyComponent_Host_0, {}, {}, []);
export { SelectDraftCompanyComponentNgFactory as SelectDraftCompanyComponentNgFactory };
