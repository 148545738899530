/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./change-email-address.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/control-error/form-submit.directive";
import * as i3 from "@angular/forms";
import * as i4 from "../../../shared/control-error/control-errors.directive";
import * as i5 from "../../../shared/control-error/control-error-container.directive";
import * as i6 from "../../../shared/control-error/form-errors";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../core/i18n.service";
import * as i9 from "./change-email-address.component";
import * as i10 from "@angular/material/snack-bar";
import * as i11 from "@ng-bootstrap/ng-bootstrap";
var styles_ChangeEmailAddressComponent = [i0.styles];
var RenderType_ChangeEmailAddressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangeEmailAddressComponent, data: {} });
export { RenderType_ChangeEmailAddressComponent as RenderType_ChangeEmailAddressComponent };
export function View_ChangeEmailAddressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 46, "div", [["class", "modal-content text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["aria-label", "Close"], ["class", "close"], ["style", "outline:none;"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, null, 38, "div", [["class", "onboarding-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h4", [["class", "onboarding-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      Best\u00E4tige die E-Mail-Adresse\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(12, 0, null, null, 32, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i2.FormSubmitDirective, [i1.ElementRef], null, null), i1.ɵdid(14, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(15, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(17, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(19, 0, null, null, 24, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(21, 0, null, null, 18, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(23, 0, null, null, 15, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "label", [["for", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n              Email\n            "])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(28, 16777216, null, null, 9, "input", [["autocomplete", "email"], ["class", "form-control"], ["formControlName", "email"], ["placeholder", ""], ["required", ""], ["type", "email"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 31)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 31).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 31)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 31)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(29, 16384, null, 0, i3.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.RequiredValidator]), i1.ɵdid(31, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(33, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i1.ɵdid(35, 212992, null, 0, i4.ControlErrorsDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver, [2, i5.ControlErrorContainerDirective], i6.FORM_ERRORS, [2, i2.FormSubmitDirective], i3.NgControl, i7.TranslateService, i8.I18nService], { customErrors: [0, "customErrors"] }, null), i1.ɵpod(36, { email: 0 }), i1.ɵdid(37, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(41, 0, null, null, 1, "button", [["class", "btn btn-primary btn-block btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendTicket() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          Ticket versenden\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.emailAddressFormGroup; _ck(_v, 15, 0, currVal_7); var currVal_16 = ""; _ck(_v, 29, 0, currVal_16); var currVal_17 = "email"; _ck(_v, 33, 0, currVal_17); var currVal_18 = _ck(_v, 36, 0, "invalidEmail"); _ck(_v, 35, 0, currVal_18); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 17).ngClassValid; var currVal_5 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 12, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (i1.ɵnov(_v, 29).required ? "" : null); var currVal_9 = i1.ɵnov(_v, 37).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 37).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 37).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 37).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 37).ngClassValid; var currVal_14 = i1.ɵnov(_v, 37).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 37).ngClassPending; _ck(_v, 28, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }); }
export function View_ChangeEmailAddressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-change-email-address", [], null, null, null, View_ChangeEmailAddressComponent_0, RenderType_ChangeEmailAddressComponent)), i1.ɵdid(1, 114688, null, 0, i9.ChangeEmailAddressComponent, [i3.FormBuilder, i10.MatSnackBar, i11.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangeEmailAddressComponentNgFactory = i1.ɵccf("app-change-email-address", i9.ChangeEmailAddressComponent, View_ChangeEmailAddressComponent_Host_0, { email: "email" }, { passEntry: "passEntry" }, []);
export { ChangeEmailAddressComponentNgFactory as ChangeEmailAddressComponentNgFactory };
