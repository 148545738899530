<div class="title">
  Belegung
  <span class="toggle-table-button" (click)="hideTable()"
    ><i class="fas " [ngClass]="isHidden ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'"></i
  ></span>
</div>

<div [ngClass]="isHidden ? 'hidden' : ''" class="table-wrapper">
  <ng-scrollbar [trackX]="true" [trackY]="false" [shown]="'hover'" style="height: 180px;">
    <table *ngIf="hasAllNecessarySettings; else showNeedSettingsMesssage" id="table-graph">
      <tbody *ngIf="isAutoPlaceOn; else autoPlaceIsOff">
        <tr
          class="qtr"
          *ngFor="let res of reservations; index as i"
          id="q{{ i }}"
          [ngStyle]="{ 'left.px': 50 * i }"
          matTooltip="Tische: {{ res.tablesTaken }} / {{ numberOfTables }}
                        Plätze: {{ res.seatsTaken }} / {{ numberOfSeats }}
                        Neuankömmlinge: {{ res.newArriving }}"
          matTooltipPosition="above"
        >
          <th scope="row">
            {{ res.time }}
            <br />
            <i class="fas fa-user"></i> {{ res.seatsTaken }}
          </th>
          <td class="empty bar" style="height: 100px;"></td>
          <td
            class="new-arriving bar"
            [ngStyle]="{ 'height.px': (100 / numberOfSeats) * res.seatsTaken, 'max-height.px': 100 }"
          ></td>
          <td
            class="fill bar"
            [ngStyle]="{
              'height.px': (100 / numberOfSeats) * (res.seatsTaken - res.newArriving),
              'max-height.px': 100
            }"
          ></td>
        </tr>
      </tbody>
      <ng-template #autoPlaceIsOff>
        <tbody>
          <tr
            class="qtr"
            *ngFor="let res of reservations; index as i"
            id="q{{ i }}"
            [ngStyle]="{ 'left.px': 50 * i }"
            matTooltip="Tische: {{ res.tablesTaken }} / {{ numberOfTables }}
            Plätze: {{ res.seatsTaken }} / {{ numberOfSeats }}
            Neuankömmlinge: {{ res.newArriving }}"
            matTooltipPosition="above"
          >
            <th scope="row">
              {{ res.time }}
              <br />
              <i class="fas fa-user"></i> {{ res.seatsTaken }}
            </th>
            <td class="empty bar" style="height: 100px;"></td>
            <!-- <td class="fill bar" *ngIf="res.seatsTaken" style="height: 100px;"></td> -->
            <td
              class="new-arriving bar"
              [ngStyle]="{ 'height.px': (100 / numberOfSeats) * res.seatsTaken, 'max-height.px': 100 }"
            ></td>
            <td
              class="fill bar"
              [ngStyle]="{
                'height.px': (100 / numberOfSeats) * (res.seatsTaken - res.newArriving),
                'max-height.px': 100
              }"
            ></td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </ng-scrollbar>
  <ng-template #showNeedSettingsMesssage>
    <div class="container">
      <div class="row">
        <div class="col-12 pt-3 pl-3 settings-info-message">
          <h5>
            Um das Belegungsdiagramm anzuzeigen, stellen Sie die folgenden Werte in den Reservierungsbucheinstellungen
            ein:
          </h5>
          <h5>1. Startzeit in der Zeitleiste</h5>
          <h5>2. Endzeitpunkt in der Zeitleiste</h5>
          <h5>3. Zeitintervall in der Zeitleiste</h5>
        </div>
      </div>
    </div>
  </ng-template>
</div>
