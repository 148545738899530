<div class="order">
  <div class="row header-info">
    <div class="d-flex flex-column col-md-6 col-12">
      <div class="d-flex flex-row">
        <div>
          <h4>
            <div>
              <ng-template [ngIf]="data.desiredTime || data.deliveryTimestamp">
                {{
                  roundTimeToFiveMinutes(currentDeliveryTimestamp)
                    | amCalendar
                      : {
                          sameDay: 'HH:mm [Uhr]',
                          sameElse: 'DD.MM.YYYY [um] HH:mm [Uhr]'
                        }
                }}
              </ng-template>
            </div>
          </h4>
          <h4
            *ngIf="
              data.deliveryTimestamp &&
              (currentDeliveryTimestamp | dfnsDifferenceInMinutes: currentDateTime) <= 60 &&
              (data.status === 'pending' || data.status === 'paying')
            "
            [ngStyle]="{
              color: (currentDeliveryTimestamp | dfnsDifferenceInMinutes: currentDateTime) < 0 ? 'red' : 'green'
            }"
          >
            ({{ currentDeliveryTimestamp | dfnsDifferenceInMinutes: currentDateTime }} min)
          </h4>
        </div>
        <div class="ml-2">
          <img
            *ngIf="data.type === 'delivery'"
            src="../../../assets/order_icons/Delivery.png"
            style="width: 50px;"
            alt=""
          />
          <img
            *ngIf="data.type === 'takeaway'"
            src="../../../assets/order_icons/PickUp.png"
            style="width: 50px;"
            alt=""
          />
          <img
            *ngIf="data.type === 'inhouse'"
            src="../../../assets/order_icons/Inhouse.png"
            style="width: 50px;"
            alt=""
          />
        </div>
      </div>
      <!-- Button to increment and decrement delivery time -->
      <div
        *ngIf="(data.deliveryTime || data.deliveryTime === 0) && data.deliveryTimestamp && data.status === 'pending'"
        class="d-flex align-items-center"
      >
        <button class="btn btn-light mr-1" (click)="subtractFromDeliveryTime()">-</button>
        10 min
        <button class="btn btn-light ml-1" (click)="addToDeliveryTime()">+</button>
        <button
          class="btn btn-primary ml-1"
          (click)="setNewDeliveryTime()"
          [disabled]="(currentDeliveryTimestamp | dfnsDifferenceInMinutes: currentDateTime) < 0"
        >
          <ng-template [ngIf]="data.type === 'delivery'">Lieferzeit ändern</ng-template>
          <ng-template [ngIf]="data.type === 'takeaway' || data.type === 'inhouse'">Abholzeit ändern</ng-template>
        </button>
      </div>
    </div>
    <div class="text-md-center text-left col-md-3 col-6">
      <h4 *ngIf="data.type !== 'inhouse'">{{ data.firstName }} {{ data.lastName }}</h4>
      <h4 *ngIf="data.type === 'inhouse' && data.tableNr && (data.firstName || data.lastName); else inhouseData">
        Tisch {{ data.tableNr }} ({{ data.firstName }} {{ data.lastName }})
      </h4>
      <ng-template #inhouseData>
        <h4 *ngIf="data.type === 'inhouse'">
          <span *ngIf="data.tableNr">Tisch {{ data.tableNr }}</span>
          <span *ngIf="data.firstName || data.lastName">{{ data.firstName }} {{ data.lastName }}</span>
        </h4>
      </ng-template>

      <small class="text-muted">
        <span class="badge badge-light">Order #{{ data.id }}</span>
      </small>
    </div>
    <div class="d-flex justify-content-end col-md-3 col-6">
      <img *ngIf="data.paymentId" src="../../../assets/order_icons/PayOnline.png" style="width: 50px;" alt="" />
      <img *ngIf="!data.paymentId" src="../../../assets/order_icons/payCash.png" style="width: 50px;" alt="" />
      <div class="d-flex align-items-center ml-2">
        <h4
          *ngIf="data.status !== 'canceled'"
          [class.text-success]="data.paymentTransaction && data.paymentTransaction.status === 'finished'"
          [class.text-danger]="
            !data.paymentId || !data.paymentTransaction || data.paymentTransaction.status === 'pending'
          "
        >
          {{
            data.paymentTransaction && data.paymentTransaction.status === 'finished'
              ? 'bezahlt'
              : (+data.subtotal + +data.tip + +data.deliveryFee - +data.voucher | number: '1.2') + ' €'
          }}
        </h4>
        <h4 *ngIf="data.status === 'canceled'" class="text-danger">
          storniert
        </h4>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <h6>Gewünschte Uhrzeit</h6>
      <div class="text-muted" *ngIf="data.createdAt">
        {{ (data.desiredTime ? data.desiredTime : data.createdAt) | dfnsFormat: 'DD.MM.YYYY' }} um
        {{
          (data.desiredTime ? roundTimeToFiveMinutes(data.desiredTime) : roundTimeToFiveMinutes(data.createdAt))
            | dfnsFormat: 'HH:mm'
        }}
        Uhr
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <h6>Bestelldatum</h6>
          <div class="text-muted" *ngIf="data.createdAt">
            {{ data.createdAt | dfnsFormat: 'DD.MM.YYYY' }} um {{ data.createdAt | dfnsFormat: 'HH:mm' }} Uhr
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div [class.emphasize-guest-note]="data.notes">
            <label class="mt-0">
              Gast-Hinweise
            </label>

            <div *ngIf="data.resDetails">
              #ResId: {{ data.resDetails.id }}
              <span *ngIf="data.resDetails.isTablePlan">
                &nbsp; #Table:
                <ng-template ngFor let-tables [ngForOf]="data.resDetails.isTablePlan" class="tables">
                  <span *ngFor="let table of tables | keyvalue" class="tables">
                    <span *ngIf="table.key == 'name'" class="badge badge-default" placement="bottom">
                      {{ table.value }}
                    </span>
                  </span>
                </ng-template>
              </span>
            </div>

            <div [innerHtml]="data.notes ? (data.notes | nl2br) : '-'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div fxFlexLayout="row">
    <div fxFlex>
      <label>
        Bestellte Artikel
      </label>
      <ul>
        <li *ngFor="let item of data.orderItems" class="order-item">
          {{ item.quantity }}x <span *ngIf="item.productId" class="badge badge-default">{{ item.productId }}</span>
          {{ item.name }}
          <br />
          <ng-template [ngIf]="item.size">
            <small style="font-style:italic"> <b>Größe:</b> {{ item.size }} </small>
            <br />
          </ng-template>
          <small *ngFor="let choice of item.choices" style="font-style:italic; display:block">
            <b>{{ choice.name }}:</b>
            <span *ngFor="let option of choice.options; let i = index">
              <ng-template [ngIf]="option.quantity > 1">{{ option.quantity }}x</ng-template>
              {{ option.name }}<ng-template [ngIf]="i + 1 < choice.options.length">,</ng-template>
            </span>
          </small>
        </li>
      </ul>
    </div>
  </div>
  <div fxFlexLayout="row">
    <div fxFlex *ngIf="data.type === 'delivery'">
      <label>
        Lieferadresse
      </label>
      <div>
        {{ data.firstName }} {{ data.lastName }}<br />
        {{ data.street }}<br />
        Etage: {{ data.floor ? data.floor : '' }} <br />
        {{ data.zipCode }} {{ data.city }}<br />
        Tel.: {{ data.phone }}<br />
        E-Mail: {{ data.email }}
        <a
          href="http://maps.google.de/maps?daddr={{ data.street }},{{ data.zipCode }}%20%{{ data.city }}"
          style="text-decoration: underline"
          target="_blank"
          >Google Maps Routenplaner</a
        >
      </div>
    </div>
    <div fxFlex *ngIf="data.type === 'takeaway'">
      <label *ngIf="!data.inhousePickup">
        Gast Daten (Abholung)
      </label>
      <label *ngIf="data.inhousePickup">
        Gast Daten (Abholung - Vorbestellen)
      </label>
      <div>
        Tel.: {{ data.phone }}<br />
        E-Mail: {{ data.email }}
      </div>
    </div>
    <div fxFlex>
      <label>
        Bezahlung
      </label>
      <div>
        <ul>
          <li><b>Gesamtbetrag:</b> {{ +data.subtotal + +data.deliveryFee | number: '1.2' }} €</li>
          <li><b>Trinkgeld:</b> {{ +data.tip | number: '1.2' }} €</li>
          <li><b>Zahlungsart:</b> {{ data.paymentId ? data.paymentTransaction?.paymentMethod : 'Bar' }}</li>
          <li *ngIf="data.status === 'pending'">
            <b>Status:</b>
            <span
              [class.text-success]="data.paymentTransaction && data.paymentTransaction.status === 'finished'"
              [class.text-danger]="
                !data.paymentId || !data.paymentTransaction || data.paymentTransaction.status === 'pending'
              "
            >
              {{
                data.paymentTransaction && data.paymentTransaction.status === 'finished'
                  ? 'Vollständig bezahlt'
                  : data.voucher > 0
                  ? 'Teilweise bezahlt (Gutschein)'
                  : 'Noch nicht bezahlt'
              }}
            </span>
          </li>
          <li *ngIf="!data.paymentTransaction || data.paymentTransaction.status !== 'finished'">
            <b>Offener Betrag:</b>
            {{ +data.subtotal + +data.tip + +data.deliveryFee - +data.voucher | number: '1.2' }} €
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="button-row" *ngIf="data.status === 'pending'">
    <button class="btn btn-dark" (click)="setDeliveryTime()" *ngIf="!data.deliveryTime && data.deliveryTime !== 0">
      <i class="fas fa-check fa-fw"></i>
      Bestellung in Arbeit
    </button>
    <button
      class="btn btn-dark"
      (click)="setDeliveryTime()"
      *ngIf="data.deliveryTime && !data.deliveryTimestamp"
      matTooltip="Lieferzeit ändern"
    >
      <i class="fas fa-check fa-fw"></i>
      <ng-template [ngIf]="data.deliveryTimestamp">
        <ng-template [ngIf]="data.type === 'delivery'">Lieferung</ng-template>
        <ng-template [ngIf]="data.type === 'takeaway'">Abholung</ng-template>
        in {{ data.deliveryTimestamp | dfnsDifferenceInMinutes: currentDateTime }} Minuten
      </ng-template>
      <ng-template [ngIf]="!data.deliveryTimestamp">
        <ng-template [ngIf]="data.type === 'delivery'">Lieferzeit</ng-template>
        <ng-template [ngIf]="data.type === 'takeaway'">Abholzeit</ng-template>
        ändern
      </ng-template>
    </button>
    <button class="btn btn-success" (click)="changeStatus('confirmed')">
      <i class="fas fa-check-double fa-fw"></i>
      Bestellung abschließen
    </button>
    <button class="btn btn-danger" (click)="changeStatus('canceled')">
      <i class="fas fa-times fa-fw"></i>
      Bestellung stornieren
    </button>
    <button class="btn btn-light" (click)="printBon(data.id)">
      <i class="fas fa-receipt fa-fw"></i>
      Bon erneut drucken
    </button>
  </div>
  <div class="button-row" *ngIf="data.status !== 'pending'">
    <button class="btn btn-light" (click)="printBon(data.id)" *ngIf="finished">
      <i class="fas fa-receipt fa-fw"></i>
      Bon erneut drucken
    </button>

    <button class="btn btn-success" (click)="changeStatus('pending')">
      <i class="fas fa-check-double fa-fw"></i>
      Bestellung wieder auf offen
    </button>
  </div>
</div>
