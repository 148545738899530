<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          {{ 'FeedBack.Integration.Title' | translate }}
        </h6>

        <div class="element-box">
          <h5 class="form-header">{{ 'FeedBack.Integration.Subtitle' | translate }}</h5>
          <div class="form-desc">
            {{ 'FeedBack.Integration.Description' | translate }}
          </div>

          <mat-accordion class="integration-expandable">
            <mat-expansion-panel (afterExpand)="expandPanel('widget')">
              <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
                <i class="icon icon-code"></i>
                <div>
                  <mat-panel-title>
                    {{ 'FeedBack.Integration.FeedbackWidget.Title' | translate }}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{ 'FeedBack.Integration.FeedbackWidget.Description' | translate }}
                  </mat-panel-description>
                </div>
              </mat-expansion-panel-header>
              <div>
                {{ 'FeedBack.Integration.CodeInfo' | translate }}
                <code>
                  &lt;div id="gd-feedback-container"&gt;&lt;/div&gt;<br />
                  &lt;script src="https://widgets.gastro.digital/assets/js/feedback.js"&gt;&lt;/script&gt;<br />
                  &lt;script&gt;<br />
                  initFeedbackWidget('gd-feedback-container', {{ activeClient?.id }});<br />
                  &lt;/script&gt;<br />
                </code>
              </div>
              <div class="alert alert-info borderless">
                <strong>{{ 'FeedBack.Integration.FeedbackWidget.InfoAlert.Title' | translate }}</strong>
                <div
                  [innerHTML]="
                    'FeedBack.Integration.FeedbackWidget.InfoAlert.Description'
                      | translate: { clientId: activeClient?.id }
                  "
                ></div>
              </div>
              <legend><span>Vorschau</span></legend>
              <div class="widget-preview">
                <div id="gd-feedback-container"></div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
