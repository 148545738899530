import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { DocumentsService } from './documents.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { AddShortURLComponent } from './add-short-url/add-short-url.component';
import { AuthenticationService } from '@app/core';
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  uploadSuccessMessage: string;
  deleteDocumentModelButtonText: string;
  deleteDocumentModalTitle: string;
  deleteDocumentModalMessage: string;
  documentDeleteSuccessMessage: string;
  documentNameUpdateSuccessMessage: string;
  successMessage: string;
  docList: any;
  loader: boolean;
  tableLimit: number = 50;
  modelObject: any = {};
  shortURLs: any = [];
  activeClient: any;
  loaderShortURL: boolean;

  constructor(
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private documentsService: DocumentsService,
    private translateService: TranslateService,
    private authService: AuthenticationService
  ) {
    this.docList = [];
    this.loader = true;
    this.loaderShortURL = true;
    this.loadData();
    this.loadTranslation();
    this.translateService.onLangChange.subscribe((event: any) => {
      this.loadTranslation();
    });
  }

  ngOnInit() {
    this.authService
      .getActiveClient()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((clientData: any) => {
        this.activeClient = clientData;
      });
  }

  private loadTranslation() {
    this.uploadSuccessMessage = this.translateService.instant('Documents.UploadDocumentSuccessMessage');
    this.deleteDocumentModelButtonText = this.translateService.instant('Common.Delete');
    this.deleteDocumentModalTitle = this.translateService.instant('Documents.DeleteDocumentModalTitle');
    this.deleteDocumentModalMessage = this.translateService.instant('Documents.DeleteDocumentMessage');
    this.documentDeleteSuccessMessage = this.translateService.instant('Documents.DocumentDeleteSuccessMessage');
    this.documentNameUpdateSuccessMessage = this.translateService.instant('Documents.DocumentNameUpdateSuccessMessage');
    this.successMessage = this.translateService.instant('Common.Successful');
  }

  loadData() {
    this.documentsService.getDocuments().subscribe((response: any) => {
      this.docList = response;
      if (response && response.length) {
        for (let i = 0; i < response.length; i++) {
          this.modelObject[response[i].id] = response[i].name;
        }
      }
      this.loader = false;
    });
    this.documentsService.getShortURLs().subscribe((response: any) => {
      this.shortURLs = response;
      this.loaderShortURL = false;
    });
  }

  openModel() {
    const modalRef = this.modalService.open(UploadDocumentComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.addDocumentResponse.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      // const response = { ...receivedEntry };
      // const newDoc = {
      //   name: response.name,
      //   id: response.id,
      //   file: response.file,
      //   addedAt: response.addedAt,
      //   betriebId: response.betriebId
      // }
      // this.docList.push(newDoc)
      // console.log('DocList => ', this.docList)
      this.loader = true;
      this.loadData();
      const snackBarRef = this.snackBar.open(this.uploadSuccessMessage, 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  viewDoc(document: any) {
    const url = `https://cdn.gastroguide.de/${document.file}`;
    window.open(`${url}`, '_blank');
  }

  removeDocument(document: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = this.deleteDocumentModalTitle;
    modalRef.componentInstance.message = this.deleteDocumentModalMessage;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = this.deleteDocumentModelButtonText;
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.documentsService.removeDocument(document.id).subscribe(
            (event: any) => {
              this.docList = this.docList.filter((doc: any) => doc.id !== document.id);
              this.snackBar.open(this.documentDeleteSuccessMessage, '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            },
            (err: any) => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  cancel(document: any) {
    delete document['isEdit'];
    this.modelObject[document.id] = document.name;
  }

  setDocName(document: any) {
    const docId = document.id;
    const name = this.modelObject[document.id];

    this.documentsService.updateDocName(docId, name).subscribe((response: any) => {
      document.name = name;
      delete document['isEdit'];
      this.snackBar.open(this.documentNameUpdateSuccessMessage, '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  addShortURL() {
    const modalRef = this.modalService.open(AddShortURLComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.clientData = { ...this.activeClient };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.shortURLs.push(receivedEntry);
      this.shortURLs = [...this.shortURLs];
      const snackBarRef = this.snackBar.open(this.successMessage, 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  editShortURL(data: any, index: number) {
    const modalRef = this.modalService.open(AddShortURLComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.clientData = { ...this.activeClient };
    modalRef.componentInstance.editShortURL = { ...data };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.shortURLs[index] = receivedEntry;
        this.shortURLs = [...this.shortURLs];
        const snackBarRef = this.snackBar.open(this.successMessage, 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        modalRef.close();
      }
    });
  }

  deleteShortURL(id: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = this.translateService.instant('ShortURLs.DeleteShortURL');
    modalRef.componentInstance.message = this.translateService.instant('ShortURLs.ConfirmDeleteShortURL');
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.documentsService
            .deleteShortURL(id)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response: any) => {
              this.shortURLs = this.shortURLs.filter((obj: any) => {
                return obj.id !== id;
              });
              const snackBarRef = this.snackBar.open(this.successMessage, 'Ok', {
                duration: 3000,
                panelClass: ['snackbar-success']
              });
            });
        }
      },
      () => {}
    );
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
