<div class="modal-content text-center">
  <button aria-label="Close" class="close" style="outline:none;" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      Nutzerdetails
    </h4>
    <form [formGroup]="buyerAddressFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">
              Email
            </label>
            <input class="form-control" placeholder="Email" type="text" formControlName="email" />
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">
              Firma
            </label>
            <input class="form-control" placeholder="Firma" type="text" formControlName="company" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              Name
            </label>
            <input class="form-control" placeholder="Name" type="text" formControlName="name" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              Adresse
            </label>
            <input class="form-control" placeholder="Adresse" type="text" formControlName="street" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              PLZ
            </label>
            <input class="form-control" placeholder="PLZ" type="text" formControlName="zipCode" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              Stadt
            </label>
            <input class="form-control" placeholder="Stadt" type="text" formControlName="city" />
          </div>
        </div>
        <button class="btn btn-primary btn-block btn-lg" (click)="sendVoucher()">
          Quittung senden
        </button>
      </div>
    </form>
  </div>
</div>
