<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editAlertData">{{ 'ResSettings.AddAlert.CreateNewNote' | translate }}</ng-template>
      <ng-template [ngIf]="editAlertData">{{ 'ResSettings.AddAlert.EditNote' | translate }}</ng-template>
    </h4>
    <form [formGroup]="alertFormGroup">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'ResSettings.AddAlert.WhoShouldSeeNotice' | translate }}</label>
            <ng-select [searchable]="false" [clearable]="false" formControlName="type" required>
              <ng-option value="staff">{{ 'ResSettings.AddAlert.Employees' | translate }}</ng-option>
              <ng-option value="guest">{{ 'ResSettings.AddAlert.Guest' | translate }}</ng-option>
              <ng-option value="all">{{ 'ResSettings.AddAlert.All' | translate }}</ng-option>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'ResSettings.AddAlert.StartDate' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="dateFromPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <input
                class="form-control"
                #dateFromInput
                placeholder=""
                type="text"
                name="alert"
                formControlName="dateFrom"
                [matDatepicker]="dateFromPicker"
                (focus)="dateFromPicker.open()"
                required
              />
              <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'ResSettings.AddAlert.EndDate' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="dateToPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <input
                class="form-control"
                #dateToInput
                placeholder="Optional"
                type="text"
                name="alert"
                formControlName="dateTo"
                [matDatepicker]="dateToPicker"
                (focus)="dateToPicker.open()"
              />
              <span (click)="clearInput()" *ngIf="alertFormGroup.value.dateTo" class="clear-input"
                ><i class="fas fa-times"></i
              ></span>
              <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="alert alert-danger" *ngIf="alertFormGroup.errors && alertFormGroup.errors.dates">
        {{ alertFormGroup.errors?.dates }}
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <input
              class="file-input form-control"
              style="display:none"
              type="file"
              name="fileUrl"
              #fileInput
              (change)="onFilesSelected(fileInput.files)"
            />
            <div class="input-group">
              <div class="input-group-prepend">
                <button type="button" class="btn btn-secondary" (click)="fileInput.click()">
                  {{ 'ResSettings.AddAlert.ChooseFile' | translate }}
                </button>
              </div>
              <input class="form-control" placeholder="Keine ausgewählten Dateien" [value]="fileName" readonly />
              <div class="input-group-append" *ngIf="fileName">
                <button type="button" class="btn btn-danger" (click)="removeFile()" matTooltip="Dateien entfernen">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <small> {{ 'ResSettings.AddAlert.AllowFileFormat' | translate }}</small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'ResSettings.AddAlert.Notice' | translate }}</label>
            <textarea rows="4" class="form-control" name="info" formControlName="info"></textarea>
          </div>
        </div>
      </div>
      <button class="btn btn-primary btn-block btn-lg" (click)="add()" *ngIf="!editAlertData">
        {{ 'ResSettings.AddAlert.CreateNote' | translate }}
      </button>
      <button class="btn btn-primary btn-block btn-lg" (click)="add()" *ngIf="editAlertData">
        {{ 'ResSettings.AddAlert.SaveNote' | translate }}
      </button>
    </form>
  </div>
</div>
