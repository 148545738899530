/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./delete-button-renderer.component";
import * as i2 from "../../gastro-pay.service";
var styles_DeleteButtonRendererComponent = [];
var RenderType_DeleteButtonRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DeleteButtonRendererComponent, data: {} });
export { RenderType_DeleteButtonRendererComponent as RenderType_DeleteButtonRendererComponent };
export function View_DeleteButtonRendererComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { select: 0 }), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "button", [["class", "btn btn-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["TEST"])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, null); }
export function View_DeleteButtonRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "delete-button-renderer", [], null, null, null, View_DeleteButtonRendererComponent_0, RenderType_DeleteButtonRendererComponent)), i0.ɵdid(1, 180224, null, 0, i1.DeleteButtonRendererComponent, [i2.GastroPayService], null, null)], null, null); }
var DeleteButtonRendererComponentNgFactory = i0.ɵccf("delete-button-renderer", i1.DeleteButtonRendererComponent, View_DeleteButtonRendererComponent_Host_0, {}, {}, []);
export { DeleteButtonRendererComponentNgFactory as DeleteButtonRendererComponentNgFactory };
