<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">Zahlungseinstellungen - Buchhaltung</h6>
        <mat-tab-group animationDuration="0ms" [(selectedIndex)]="showTab">
          <mat-tab label="Rechnungsempfänger">
            <div class="element-box" [formGroup]="invoiceRecipientForm">
              <div class="form-group">
                <label for="">E-Mail Adresse für Rechnungen</label>
                <input class="form-control" placeholder="" formControlName="email" type="email" />
              </div>
              <div class="form-group">
                <label for="">(Firmen)name</label>
                <input class="form-control" placeholder="" formControlName="company" type="text" />
              </div>
              <!-- <div class="form-group" [class.d-none]="invoiceRecipientForm?.value?.country == 'DE'">
                <label for="">Umsatzsteuer Identifikationsnummer (USt-ID)</label>
                <input class="form-control" placeholder="" formControlName="vatId" type="text" />
              </div> -->
              <div class="form-group">
                <label for="">Zusatz</label>
                <input class="form-control" placeholder="" formControlName="notation" type="text" />
              </div>
              <div class="form-group">
                <label for="">Straße und Hausnummer</label>
                <input class="form-control" placeholder="" formControlName="street" type="text" />
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">PLZ</label>
                    <input class="form-control" placeholder="" formControlName="zipCode" type="number" />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="">Ort</label>
                    <input class="form-control" placeholder="" formControlName="city" type="text" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="">Land</label>
                <ng-select formControlName="country">
                  <ng-option value="DE">Deutschland</ng-option>
                  <ng-option value="AT">Österreich</ng-option>
                  <ng-option value="CH">Schweiz</ng-option>
                  <ng-option value="NL">Niederlande</ng-option>
                </ng-select>
              </div>

              <div class="form-group" [class.d-none]="invoiceRecipientForm?.value?.country == 'DE'">
                <label for="">Umsatzsteuer Identifikationsnummer (USt-ID)</label>
                <input class="form-control" placeholder="" formControlName="vatId" type="text" />
              </div>

              <div class="form-group">
                <button (click)="saveInvoiceRecipient()" class="btn btn-primary">
                  <i class="far fa-save"></i> Speichern
                </button>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Bankverbindung">
            <div class="element-box" [formGroup]="bankdetailsForm">
              <div class="form-group">
                <label for="">Kontoinhaber</label>
                <input class="form-control" placeholder="" formControlName="bankAccountOwner" type="text" />
              </div>
              <div class="form-group">
                <label for="">IBAN</label>
                <input class="form-control" placeholder="" formControlName="iban" type="text" />
              </div>
              <div class="form-group">
                <label for="">SWIFT</label>
                <input class="form-control" placeholder="" formControlName="swift" type="text" />
              </div>
              <div class="form-group">
                <label for="">Bankname</label>
                <input class="form-control" placeholder="" formControlName="bank" type="text" />
              </div>

              <div class="form-group">
                <mat-checkbox formControlName="elv">
                  Hiermit ermächtigen wir die GastroDigital GmbH bis auf Widerruf alle offene Rechnungen vom o.g. Konto
                  einzuziehen.<br />Im Falle einer Rücklastschrift berechnet Gastro.Digital eine Gebühr von 15,- €.
                </mat-checkbox>
              </div>

              <div class="form-group">
                <button (click)="saveBankData()" class="btn btn-primary"><i class="far fa-save"></i> Speichern</button>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
