<div class="modal-content text-center">
  <div class="modal-header">
    <h4 class="modal-title">Report Review -: {{ review.title }}</h4>
    <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
      <span class="fas fa-times"></span>
    </button>
  </div>
  <div class="onboarding-content" style="text-align: left;">
    <div class="comment" [innerHtml]="review.comment | nl2br"></div>
    <div *ngIf="review.comment" style="margin-top:5px">
      <ngb-rating [max]="5" [(rate)]="review.rating" [readonly]="true"></ngb-rating> by
      <b style="font-weight: bold;">{{ review.memberInfo.username }}</b>
    </div>
  </div>
  <div class="onboarding-content" style="text-align: left;">
    Why do you want to report the review?
    <textarea class="col-sm-12" [(ngModel)]="report" style="outline: none; border:1px solid #dedede"></textarea>
    <button class="btn btn-danger" (click)="submitReport()">Report Review</button>
    <button class="btn btn-secondary" (click)="activeModal.dismiss()">Abort</button>
  </div>
</div>
