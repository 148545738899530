<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          {{ 'Vouchers.Coupons.Vouchers' | translate }}
        </h6>

        <div class="element-box">
          <div class="element-content">
            <div class="filter-box_">
              <div class="form-group" fxFlex="50%" fxFlex.gt-sm="45%" style="padding-right: 25px;">
                <label for="">{{ 'Vouchers.Coupons.EnterVoucherCode' | translate }}</label>
                <!-- <ng-select
                  [searchable]="true"
                  [clearable]="true"
                  [(ngModel)]="filter.voucher"
                  (change)="filterVoucher($event)"
                  placeholder="{{ 'Vouchers.Common.CouponCode' | translate }}"
                  [class.filtered]="filter.voucher"
                  [items]="vouchers"
                  bindLabel="code"
                  notFoundText="{{ 'Vouchers.Common.NoVoucherFound' | translate }}"
                >
                </ng-select> -->

                <div class="row">
                  <div class="col-md-8">
                    <input
                      class="form-control"
                      placeholder="{{ 'Vouchers.Common.CouponCode' | translate }}"
                      type="text"
                      #voucherData
                    />
                  </div>
                  <div class="col-md-4">
                    <div class="input-group" style="height:100%">
                      <div
                        class="input-group-append"
                        style="cursor: pointer;"
                        (click)="searchTransactions(voucherData.value)"
                      >
                        <div class="input-group-text primary bg-primary">
                          <i class="fas fa-search text-light"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" *ngIf="errorMsg" class="error-msg">*{{ errorMsg }}</div>
                </div>
              </div>

              <!-- <div class="form-group" fxFlex="50%" fxFlex.gt-sm="25%" style="padding-right: 25px;">
                <label for="">{{ 'Vouchers.Coupons.Export' | translate }}</label>
                <angular2csv
                  style="width: 100%;"
                  class="btn btn-primary"
                  [data]="getArrayCopy(vouchersForExport)"
                  filename="vouchers_{{ now | date: 'yyyy_MM_dd_H_mm' }}"
                  [options]="csvOptions"
                ></angular2csv>
              </div> -->
              <div class="form-group" fxFlex="50%" fxFlex.gt-sm="15%" style="padding-right: 10px;"></div>
              <div class="form-group" fxFlex="50%" fxFlex.gt-sm="15%" style="padding-right: 10px;"></div>
            </div>
          </div>
        </div>

        <div *ngIf="voucherDetails && voucherDetails.id">
          <div [ngStyle]="{ background: voucherDetails.status == 2 ? '#ff948a' : null }" class="element-box">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">{{ 'Vouchers.Coupons.Client' | translate }}</label
                  ><br />
                  {{ voucherDetails.client.name }}
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">{{ 'Vouchers.Coupons.ResidualValue' | translate }} </label><br />
                  <span>{{ voucherDetails.residualValue | currency: 'EUR' }}</span>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">{{ 'Vouchers.Coupons.ValidUntil' | translate }} </label><br />
                  <span>{{ voucherDetails.validUntil | date: 'dd.MM.yyy' }}</span>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="">{{ 'Vouchers.Coupons.Status' | translate }} </label><br />
                  <span>{{ getStatusMessage(voucherDetails.status) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="element-box" *ngIf="filter.voucher">
          <div class="table-responsive">
            <ngx-datatable
              #table
              class="bootstrap list-table expandable datatable-vouchers-list"
              [columnMode]="'flex'"
              [headerHeight]="35"
              [footerHeight]="50"
              [summaryRow]="false"
              [summaryHeight]="'auto'"
              [rowHeight]="'auto'"
              [scrollbarV]="false"
              [rows]="transactions"
              [limit]="pageLimit"
              [cssClasses]="{
                pagerLeftArrow: 'fas fa-backward',
                pagerRightArrow: 'fas fa-forward',
                pagerPrevious: 'fas fa-step-backward',
                pagerNext: 'fas fa-step-forward'
              }"
              [sorts]="[{ prop: 'createdAt', dir: 'desc' }]"
              [messages]="message"
            >
              <ngx-datatable-column [sortable]="true" [canAutoResize]="false" prop="createdAt" name="Datum">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{ 'Vouchers.Common.Date' | translate }}</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div *ngIf="row.createdAt">
                    {{ row.createdAt | dfnsFormat: 'DD.MM.YYYY HH:mm' }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [sortable]="true" [canAutoResize]="false" prop="id" name="ID">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{ 'Vouchers.Common.ID' | translate }}</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div *ngIf="row.id">
                    {{ row.id }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [sortable]="true"
                [resizeable]="true"
                [canAutoResize]="false"
                prop="transType"
                name="Art"
              >
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>Aktion</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div style="white-space: nowrap;">
                    <span *ngIf="row.transType === 'withdraw'">{{
                      'Vouchers.Transactions.VoucherRedeemed' | translate
                    }}</span>
                    <span *ngIf="row.transType === 'recharge'">{{
                      'Vouchers.Transactions.VoucherCharged' | translate
                    }}</span>
                    <span *ngIf="row.transType === 'create'">{{
                      'Vouchers.Transactions.VoucherCreated' | translate
                    }}</span>
                    <span *ngIf="row.transType === 'payout'">{{ 'Vouchers.Transactions.Payout' | translate }}</span>
                    <span *ngIf="row.transType === 'debit'"> {{ 'Vouchers.Transactions.Indent' | translate }}</span>

                    <!-- <span *ngIf="row.actionClient" title="row.actionClient.name">({{ row.actionClient.name }})</span> -->
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [sortable]="false" prop="client.name" [canAutoResize]="false" name="Betrieb">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{ 'Vouchers.Transactions.Business' | translate }}</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <ng-template [ngIf]="row.client">
                    <a href="#" (click)="$event.preventDefault()">{{ row.client.name }}</a>
                  </ng-template>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [sortable]="false" prop="transReferrer" name="Quelle" [canAutoResize]="false"
                ><!-- [flexGrow]="1" -->
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{ 'Vouchers.Transactions.Source' | translate }}</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <ng-template [ngIf]="row.transReferrer">
                    <ng-template [ngIf]="row.transReferrer === 'gg'">
                      Online
                    </ng-template>
                    <ng-template [ngIf]="row.transReferrer === 'business'">
                      BackOffice
                    </ng-template>
                    <ng-template [ngIf]="row.transReferrer === 'app'">
                      ChefApp
                    </ng-template>
                    <ng-template [ngIf]="row.transReferrer === 'admin'">
                      System
                    </ng-template>
                    <ng-template
                      [ngIf]="
                        row.transReferrer !== 'gg' &&
                        row.transReferrer !== 'business' &&
                        row.transReferrer !== 'app' &&
                        row.transReferrer !== 'admin'
                      "
                    >
                      POS
                    </ng-template>
                  </ng-template>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [sortable]="false" prop="status" name="Status" [canAutoResize]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{ 'Vouchers.Coupons.Status' | translate }}</span>
                </ng-template>

                <ng-template let-row="row" ngx-datatable-cell-template>
                  <ng-container *ngIf="row.status === 0; else second">Aktiv</ng-container>
                  <ng-template #second>
                    <ng-container *ngIf="row.status === 1; else third">Wartend</ng-container>
                  </ng-template>
                  <ng-template #third>Gesperrt</ng-template>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [sortable]="true" prop="ip" name="IP" [canAutoResize]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>IP</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.ip }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [sortable]="true"
                [resizeable]="true"
                [canAutoResize]="false"
                prop="transValue"
                name="Wert"
                [flexGrow]="1"
              >
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>{{ 'Vouchers.Coupons.Value' | translate }}</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div style="width: 100%; text-align: right;">
                    <div *ngIf="row.transValue">
                      <ng-container *ngIf="row.transType == 'withdraw'">-</ng-container
                      >{{ row.transValue | currency: 'EUR' }}
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [sortable]="true"
                [resizeable]="true"
                [canAutoResize]="false"
                prop="transValue"
                name="Wert"
                [flexGrow]="1"
              >
                <ng-template let-column="column" ngx-datatable-header-template>
                  <span>Restwert</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div style="width: 100%; text-align: right;">
                    <div *ngIf="row.transValue">
                      {{ row.voucher.residualValue | currency: 'EUR' }}
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
