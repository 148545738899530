import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ReservationService } from '@app/reservation/reservation.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-guest',
  templateUrl: './add-guest.component.html',
  styleUrls: ['./add-guest.component.scss']
})
export class AddGuestComponent implements OnInit, OnDestroy {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() public editGuest: any;
  guestFormGroup: FormGroup;
  incompatibilities: any[] = [];
  arrayOfPrefixes: string[] = ['Herr', 'Frau', 'Divers', 'Firma'];
  subscriptions: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal,
    private reservationService: ReservationService,
    private modalService: NgbModal,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.reservationService.getIncompatibilities().subscribe(incompatibilities => {
        this.incompatibilities = incompatibilities.data;
      })
    );

    this.guestFormGroup = this.formBuilder.group({
      form: [''],
      name: ['', Validators.required],
      firstName: [''],
      address: [''],
      birthdate: [null],
      weddingDay: [null],
      email: ['', Validators.email],
      intolerance: [''],
      notes: [''],
      phone: [''],
      place: [''],
      zip: ['', Validators.maxLength(5)],
      company: ['']
    });

    if (this.editGuest) {
      this.guestFormGroup.addControl('id', new FormControl(this.editGuest.id, Validators.required));
      this.guestFormGroup.get('name').setValue(this.editGuest.name);
      this.guestFormGroup.get('form').setValue(this.editGuest.form);
      this.guestFormGroup.get('firstName').setValue(this.editGuest.firstName);
      this.guestFormGroup.get('address').setValue(this.editGuest.address);
      this.guestFormGroup.get('birthdate').setValue(this.editGuest.birthdate);
      this.guestFormGroup.get('weddingDay').setValue(this.editGuest.weddingDay);
      this.guestFormGroup.get('email').setValue(this.editGuest.email);
      this.guestFormGroup.get('intolerance').setValue(JSON.parse(this.editGuest.intolerance));
      this.guestFormGroup.get('notes').setValue(this.editGuest.notes);
      this.guestFormGroup.get('phone').setValue(this.editGuest.phone);
      this.guestFormGroup.get('place').setValue(this.editGuest.place);
      this.guestFormGroup.get('zip').setValue(this.editGuest.zip);
      this.guestFormGroup.get('company').setValue(this.editGuest.company);
    }
  }

  createGuest() {
    if (!this.guestFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.subscriptions.push(
      this.reservationService.createGuest(this.guestFormGroup.value).subscribe(response => {
        this.passEntry.emit(response);
        this.activeModal.dismiss('');
      })
    );
  }

  updateGuest() {
    if (!this.guestFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.subscriptions.push(
      this.reservationService.saveGuest(this.guestFormGroup.value).subscribe(response => {
        this.passEntry.emit(response);
        this.activeModal.dismiss('');
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  deleteGuest(guest: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Gast löschen';
    modalRef.componentInstance.message = `Sind Sie sicher, dass Sie den Gast ${guest.name} löschen wollen?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.reservationService.deleteGuest(guest.id).subscribe((response: any) => {
            this.passEntry.emit(response);
            this.activeModal.dismiss('');
          });
        }
      },
      () => {}
    );
  }
}
