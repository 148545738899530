<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title" *ngIf="order.type === 'delivery'">
      Lieferzeit festlegen
    </h4>
    <h4 class="onboarding-title" *ngIf="order.type === 'takeaway'">
      Abholzeit festlegen
    </h4>
    <h5 *ngIf="order.type === 'delivery'">Wann wird die Bestellung ungefähr geliefert?</h5>
    <h5 *ngIf="order.type === 'takeaway'">Wann ist die Bestellung ungefähr abholbereit?</h5>

    <div class="button-wrapper">
      <button class="btn btn-light btn-lg" (click)="setDeliveryTime(15)" [promiseBtn]="setDeliveryTimeObservable">
        15 <small>min</small>
      </button>
      <button class="btn btn-light btn-lg" (click)="setDeliveryTime(30)" [promiseBtn]="setDeliveryTimeObservable">
        30 <small>min</small>
      </button>
      <button class="btn btn-light btn-lg" (click)="setDeliveryTime(45)" [promiseBtn]="setDeliveryTimeObservable">
        45 <small>min</small>
      </button>
      <button class="btn btn-light btn-lg" (click)="setDeliveryTime(60)" [promiseBtn]="setDeliveryTimeObservable">
        60 <small>min</small></button
      ><br />
      <button
        style="margin-top: 10px;"
        class="btn btn-light btn-lg"
        (click)="setDeliveryTime(0)"
        [promiseBtn]="setDeliveryTimeObservable"
      >
        zur gewünschten Zeit
      </button>
    </div>

    <div class="or-delimiter">----- oder eigenen Wert eintragen: -----</div>

    <div class="input-group custom-value" [formGroup]="customValueForm">
      <input class="form-control" type="tel" formControlName="deliveryTime" />
      <div class="input-group-append">
        <select formControlName="type">
          <option value="minutes">Minuten</option>
          <option value="hours">Stunden</option>
        </select>
      </div>
      <button
        class="btn btn-primary"
        (click)="setCustomDeliveryTime()"
        [disabled]="!customValueForm.valid"
        [promiseBtn]="setDeliveryTimeObservable"
      >
        Abschicken
      </button>
    </div>
  </div>
</div>
