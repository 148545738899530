var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VouchersService } from '../../vouchers.service';
export class ChangeVoucherPostCodes {
    constructor(snackBar, activeModal, voucherService) {
        this.snackBar = snackBar;
        this.activeModal = activeModal;
        this.voucherService = voucherService;
        this.passEntry = new EventEmitter();
        this.vouchers = [];
        this.codeArray = [];
    }
    ngOnInit() {
        this.getTransactions();
        this.order.templates.map((item) => {
            this.codeArray[item.id] = '';
        });
    }
    save() {
        let isError = false;
        this.codeArray.map((item, key) => {
            if (key && (!item || !item.code)) {
                isError = true;
                this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
                    duration: 2000,
                    panelClass: ['snackbar-error']
                });
                return;
            }
        });
        if (!isError) {
            this.passEntry.emit(this.codeArray);
            this.activeModal.dismiss('');
        }
    }
    getTransactions(voucherCode = '') {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.voucherService
                .getVoucherTransactions(this.order.betriebId, voucherCode)
                .toPromise()
                .then((data) => {
                this.transactions = data;
                if (this.transactions.voucher) {
                    // Reset voucher list
                    this.vouchers = [];
                    let voucherKeys = [];
                    // Sum transaction values
                    this.transactions.voucher.forEach((row) => {
                        if (row) {
                            if (!voucherKeys.includes(row.code)) {
                                voucherKeys.push(row.code);
                                this.vouchers.push({ id: row.id, code: row.code });
                            }
                        }
                    });
                    this.vouchers.sort(this.dynamicSort('code'));
                    if (voucherCode && voucherCode != '') {
                        this.transactions = this.transactions.voucher.filter(function (el) {
                            if (el.code) {
                                return el.code.includes(voucherCode);
                            }
                            else {
                                return false;
                            }
                        });
                    }
                }
            });
        });
    }
    filterVoucher(voucher, id) {
        return __awaiter(this, void 0, void 0, function* () {
            // this.transactions = [];
            if (voucher === undefined || !voucher) {
                voucher = { code: '' };
            }
            if (voucher.code) {
                this.voucherValue = voucher.code;
                // this.getTransactions(this.voucherValue);
            }
            this.codeArray[id] = voucher;
        });
    }
    searchVoucher(voucher) {
        return __awaiter(this, void 0, void 0, function* () {
            if (voucher && voucher.term.length >= 3) {
                this.voucherValue = voucher.term;
                this.getTransactions(voucher.term);
            }
            else if (!voucher) {
                this.getTransactions();
            }
        });
    }
    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
            return result * sortOrder;
        };
    }
}
