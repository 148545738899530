<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Einstellungen - Online Bestellung
          <a
            class="btn btn-default btn-sm"
            ngbTooltip="Hilfe zum Bestellsystem"
            href="https://blog.gastro.digital/article-categories/bestellsystem/"
            target="_blank"
          >
            <mat-icon svgIcon="help"></mat-icon>
          </a>
        </h6>
        <div [formGroup]="generalSettings">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <div
                  class="system-setting-div"
                  [ngClass]="{
                    'toggle-success': generalSettings.value.isActive,
                    'toggle-danger': !generalSettings.value.isActive
                  }"
                >
                  <mat-slide-toggle name="delivery" (change)="save()" formControlName="isActive">
                    Bestellsystem aktivieren
                    <div class="info">
                      Möchten Sie das Bestellsystem aktivieren?
                      <span
                        ><b>Zahlungsgebühren : {{ transactionFeeCash }}&nbsp;&euro;</b></span
                      >
                    </div>
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </div>

        <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)">
          <mat-tab label="Allgemein">
            <div class="element-box" [formGroup]="generalSettings">
              <div class="alert alert-warning borderless" style="margin-bottom: 30px;">
                <strong>Link zu Ihrer Bestellseite:</strong>
                <br />
                Lieferung/Abholung:
                <a [href]="ordershopLink" target="_blank" style="text-decoration: underline;">{{ ordershopLink }}</a>
                [
                <a
                  href="https://api.qrserver.com/v1/create-qr-code/?size=800x800&data={{ ordershopLink }}"
                  target="_blank"
                  style="text-decoration: underline;"
                >
                  QR Code
                </a>
                ]
              </div>

              <!-- <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <mat-slide-toggle name="delivery" (change)="save()" formControlName="isActive">
                        Bestellsystem aktivieren
                        <div class="info">
                          Möchten Sie das Bestellsystem aktivieren?
                        </div>
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div> -->

              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="delivery"
                      (change)="save()"
                      formControlName="cashEnabled"
                      [disabled]="!generalSettings.value.isActive"
                    >
                      Barzahlung erlauben
                      <div class="info">
                        Soll der Gast per Bargeld bezahlen dürfen?
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle name="enableTip" (change)="save()" formControlName="enableTip">
                      Trinkgeld erlauben
                      <div class="info">
                        Soll der Gast Trinkgeld direkt bei der Bestellung geben dürfen?
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="vouchersEnabled"
                      (change)="save()"
                      formControlName="vouchersEnabled"
                      [disabled]="!generalSettings.value.isActive"
                    >
                      Gutscheine erlauben
                      <div class="info">
                        Soll der Gast Gutscheine einlösen dürfen?
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="notifyByEmailOption"
                      (change)="changeNotifyEmailOption($event.checked)"
                      formControlName="notifyByEmailOption"
                      [disabled]="!generalSettings.value.isActive"
                    >
                      E-Mailbestätigung
                      <div class="info">
                        Wollen Sie die Bestellungen per E-Mail erhalten?
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="generalSettings.value.notifyByEmailOption">
                <div class="col-sm-12">
                  <div class="form-group" style="margin: 0;">
                    <input
                      class="form-control"
                      placeholder="E-Mail Adresse"
                      name="notifyByEmail"
                      (change)="save()"
                      [customErrors]="{ email: 'invalidEmail' }"
                      formControlName="notifyByEmail"
                    />
                  </div>
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group" style="margin: 0;">
                    <label for="">Absenderadresse der Bestell-Emails an den Gast</label>
                    <input
                      class="form-control"
                      placeholder="E-Mail Adresse"
                      name="email"
                      (change)="save()"
                      [customErrors]="{ email: 'invalidEmail' }"
                      formControlName="email"
                    />
                  </div>
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for=""> Ton bei neuer Bestellung</label>
                    <div>
                      <mat-radio-group (change)="save()" formControlName="notificationSound" class="select-sound">
                        <mat-radio-button value="none">Kein Ton</mat-radio-button>
                        <mat-radio-button value="bell">
                          <button class="btn btm-sm btn-link preview-button" (click)="previewSound('bell')">
                            <i class="far fa-play-circle"></i>
                          </button>
                          Klingel
                        </mat-radio-button>
                        <mat-radio-button value="cashregister">
                          <button class="btn btm-sm btn-link preview-button" (click)="previewSound('cashregister')">
                            <i class="far fa-play-circle"></i>
                          </button>
                          Kasse
                        </mat-radio-button>
                        <mat-radio-button value="chime">
                          <button class="btn btm-sm btn-link preview-button" (click)="previewSound('chime')">
                            <i class="far fa-play-circle"></i>
                          </button>
                          Glocken
                        </mat-radio-button>
                        <mat-radio-button value="vibrant">
                          <button class="btn btm-sm btn-link preview-button" (click)="previewSound('vibrant')">
                            <i class="far fa-play-circle"></i>
                          </button>
                          Vibrant
                        </mat-radio-button>
                        <mat-radio-button value="alert">
                          <button class="btn btm-sm btn-link preview-button" (click)="previewSound('alert')">
                            <i class="far fa-play-circle"></i>
                          </button>
                          Hinweis
                        </mat-radio-button>
                        <mat-radio-button value="firealarm">
                          <button class="btn btm-sm btn-link preview-button" (click)="previewSound('firealarm')">
                            <i class="far fa-play-circle"></i>
                          </button>
                          Feueralarm
                        </mat-radio-button>
                        <mat-radio-button value="battlestation">
                          <button class="btn btm-sm btn-link preview-button" (click)="previewSound('battlestation')">
                            <i class="far fa-play-circle"></i>
                          </button>
                          Alarm
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>

                  <div class="form-group">
                    <mat-slide-toggle
                      name="loopNotificationSound"
                      (change)="save()"
                      formControlName="loopNotificationSound"
                    >
                      Ton wiederholen
                      <div class="info">
                        Soll der Ton so lange wiederholt werden bis Sie ihn manuell ausschalten?
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12"></div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Lieferung">
            <div class="element-box" [formGroup]="generalSettings">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="delivery"
                      (change)="save()"
                      formControlName="delivery"
                      [disabled]="!generalSettings.value.isActive"
                    >
                      Lieferung aktivieren
                      <div class="info">
                        Wird ein Lieferdienst angeboten?
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="row ml-5">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <mat-radio-group
                          formControlName="deliveryType"
                          aria-labelledby="example-radio-group-label"
                          class="delivery-radio-group"
                          [disabled]="!generalSettings.value.delivery"
                          (change)="save()"
                        >
                          <mat-radio-button class="deliveryType-radio-button pb-2" name="deliveryType" value="personal">
                            Lieferung
                          </mat-radio-button>
                          <mat-radio-button class="deliveryType-radio-button" name="deliveryType" value="mail">
                            Versand per Post
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="">Welcher Text soll dem Gast bei Öffnen der Karte angezeigt werden?</label>
                    <textarea
                      class="form-control"
                      placeholder="Geben Sie einen Text ein"
                      name="alert"
                      (change)="save()"
                      [disabled]="!generalSettings.value.isActive"
                      formControlName="alert"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div
                *ngIf="generalSettings.value.deliveryType === 'personal' && generalSettings.value.delivery"
                style="margin-top:50px"
              >
                <h5 class="form-header">Liefergebiete</h5>
                <div class="form-desc">
                  Stellen Sie die Postleitzahlen, die Sie beliefern, mit den Liefergebühren sowie dem Mindestbestellwert
                  ein.<br />
                  Einen Eintrag können Sie wieder löschen, in dem Sie einen rechten Mausklick auf die jeweilige Zeile
                  durchführen und im Kontextmenü "Zeile löschen" klicken.

                  <button class="btn btn-primary" (click)="addEmptyRow()">Zeile hinzufügen</button>
                </div>
                <ag-grid-angular
                  style="width: 100%; height: 400px;"
                  class="ag-theme-alpine"
                  [rowData]="deliveryAreas"
                  [gridOptions]="gridOptions"
                  [localeText]="gridLocale"
                  [frameworkComponents]="frameworkComponents"
                  [columnDefs]="columnDefs"
                  [defaultColDef]="defaultColDef"
                  [enableRangeSelection]="true"
                  [enableFillHandle]="true"
                  [modules]="modules"
                  [rowDragManaged]="true"
                  [animateRows]="true"
                  [allowContextMenuWithControlKey]="true"
                  [getContextMenuItems]="getContextMenuItems"
                  [sideBar]="sideBar"
                  (gridReady)="onGridReady($event)"
                  (cellValueChanged)="onCellValueChanged($event)"
                  (rangeSelectionChanged)="onRangeSelectionChanged($event)"
                  (pasteEnd)="onPasteEnd($event)"
                >
                </ag-grid-angular>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Abholung">
            <div class="element-box" [formGroup]="generalSettings">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="delivery"
                      (change)="save()"
                      formControlName="takeaway"
                      [disabled]="!generalSettings.value.isActive"
                    >
                      Abholung aktivieren
                      <div class="info">
                        Soll der Gast die Möglichkeit haben das Essen abzuholen?
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="delivery"
                      (change)="changeDiscountOption($event)"
                      formControlName="giveTakeawayDiscount"
                      [disabled]="!generalSettings.value.isActive"
                    >
                      Rabatt bei Abholung
                      <div class="info">
                        Möchten Sie dem Gast einen Rabatt auf seinen Warenkorbwert geben?
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="delivery"
                      (change)="save()"
                      formControlName="inhousePickupActive"
                      [disabled]="!generalSettings.value.isActive"
                    >
                      Vorbestellung Inhouse
                      <div class="info">
                        Sollen Gäste für Inhouse vorbestellen können?
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <ng-template [ngIf]="generalSettings.value.giveTakeawayDiscount">
                <hr />
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group" style="margin: 0;">
                      <label for="">Wieviel Rabatt möchten Sie dem Gast gewähren?</label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          placeholder=""
                          name="takeawayDiscount"
                          (change)="save()"
                          formControlName="takeawayDiscount"
                        />
                        <div class="input-group-append">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="">Welcher Text soll dem Gast bei Öffnen der Karte angezeigt werden?</label>
                    <textarea
                      class="form-control"
                      placeholder="Geben Sie einen Text ein"
                      name="alert"
                      (change)="save()"
                      [disabled]="!generalSettings.value.isActive"
                      formControlName="alert"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Inhouse">
            <div class="element-box" [formGroup]="generalSettings">
              <div class="alert alert-warning borderless" style="margin-bottom: 30px;" *ngIf="inhouseshopLink">
                <strong>Link zu Ihrer Bestellseite:</strong>
                <br />
                Inhouse:
                <a [href]="inhouseshopLink" target="_blank" style="text-decoration: underline;">{{
                  inhouseshopLink
                }}</a>
                [
                <a
                  href="https://api.qrserver.com/v1/create-qr-code/?size=800x800&data={{ inhouseshopLink }}"
                  target="_blank"
                  style="text-decoration: underline;"
                >
                  QR Code
                </a>
                ]
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle name="inhouse" (change)="save()" formControlName="inhouse">
                      Inhouse aktivieren
                      <div class="info">
                        Möchten Sie Inhouse Bestellungen aktivieren?
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle name="inhouseTableNo" (change)="save()" formControlName="inhouseTableNo">
                      Tisch-Nummer
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle name="inhouseName" (change)="save()" formControlName="inhouseName">
                      Name
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="">Welcher Text soll dem Gast bei Öffnen der Karte angezeigt werden?</label>
                    <textarea
                      class="form-control"
                      placeholder="Geben Sie einen Text ein"
                      name="inhouseAlert"
                      (change)="save()"
                      [disabled]="!generalSettings.value.inhouse"
                      formControlName="inhouseAlert"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Liefer-/Abholzeiten">
            <div class="element-box">
              <h5 class="form-header">Liefer- und Abholzeiten</h5>
              <div class="form-desc">
                Stellen Sie pro Tag die Uhrzeiten ein, an denen Sie die Lieferung und/oder die Abholung anbieten.
              </div>

              <div [formGroup]="timeOptionsForm">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <mat-slide-toggle name="delivery" (change)="saveTimeOptions()" formControlName="enablePreorder">
                        Vorbestellung aktivieren?
                        <div class="info">
                          Soll eine Vorbestellung möglich sein?
                        </div>
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>

                <ng-template [ngIf]="timeOptionsForm.value.enablePreorder">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <mat-slide-toggle name="usePeriods" (change)="saveTimeOptions()" formControlName="usePeriods">
                          Zeitblöcke verwenden
                          <div class="info">
                            Sollen die Zeiten in Blöcken (z.B 10:00 - 12:00, 12:00 - 14:00, ...) ausgewählt werden? Die
                            Blöcke richten sich nach dem eingestellten Intervall.
                          </div>
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </div>

                  <legend style="margin-bottom: 20px;margin-top:30px;">
                    <span>Zeiteinstellungen für Vorbestellungen</span>
                  </legend>

                  <div class="form-group row">
                    <label class="col-form-label col-sm-6" for="">
                      Vorlaufzeit in Tagen
                      <div class="info">
                        Wieviele Tage im Vorraus darf vorbestellt werden?
                      </div>
                    </label>
                    <div class="col-sm-6">
                      <div class="leadtime-slider">
                        <ng5-slider
                          formControlName="leadTime"
                          [options]="leadTimeOptions"
                          (userChange)="saveTimeOptions()"
                        >
                        </ng5-slider>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-form-label col-sm-6" for="">
                      Intervall
                      <div class="info">
                        In welchem Intervall sollen die Liefer-/Abholzeiten angeboten werden?
                      </div>
                    </label>
                    <div class="col-sm-6">
                      <div class="leadtime-slider">
                        <ng5-slider
                          formControlName="timeInterval"
                          [options]="intervalOptions"
                          (userChange)="saveTimeOptions()"
                        >
                        </ng5-slider>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-form-label col-sm-6" for="">
                      Pufferzeit
                      <div class="info">
                        Mindestzeit zwischen Bestellung und Abholung/Lieferung.
                      </div>
                    </label>
                    <div class="col-sm-6">
                      <div class="input-group">
                        <input class="form-control" formControlName="bufferTime" (change)="saveTimeOptions()" />
                        <div class="input-group-append">
                          <div class="input-group-text">Minuten</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-form-label col-sm-6" for="">
                      Lieferzeit
                      <div class="info">
                        (Mindestl)ieferzeit, die dem Gast bei Bestellung angezeigt wird
                      </div>
                    </label>
                    <div class="col-sm-6">
                      <div class="input-group">
                        <input class="form-control" formControlName="minDeliveryTime" (change)="saveTimeOptions()" />
                        <div class="input-group-append">
                          <div class="input-group-text">Minuten</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <legend style="margin-bottom: 20px;margin-top:30px;">
                  <span>Liefer- und Abholzeiten pro Wochentag</span>
                </legend>

                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <mat-slide-toggle
                        name="showTimeSection"
                        (change)="saveTimeOptions()"
                        formControlName="showTimeSection"
                      >
                        Liefer- und Abholzeiten = Öffnungszeiten
                        <div class="info">
                          Die Liefer- und Abholzeiten entsprechen den Öffnungszeiten
                        </div>
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
                <a
                  *ngIf="timeOptionsForm.value.showTimeSection"
                  class="btn btn-light btn btn-primary"
                  style="padding: 7px;color: white;"
                  routerLink="/client/{{ activeClient?.id }}/opening-hours"
                  target="_blank"
                >
                  Öffnungszeiten
                </a>

                <ng-template [ngIf]="!timeOptionsForm.value.showTimeSection">
                  Einen Eintrag können Sie löschen, in dem Sie einen rechten Mausklick auf die jeweilige Zeile
                  durchführen und im Kontextmenü "Zeit löschen" klicken.

                  <app-delivery-times-settings></app-delivery-times-settings>

                  <legend style="margin-bottom: 20px;margin-top:30px;">
                    <span>Sonderzeiten für Lieferung und Abholung</span>
                  </legend>

                  <button class="btn btn-primary" (click)="addSpecialGastroTimes()">
                    <i class="fa fa-plus-circle fa-fw reservation-time-beta"></i>Neue Zeit
                  </button>

                  <div class="element-box datatable-gastro-special-time">
                    <ngx-datatable
                      class="material"
                      [rows]="specialGastroPayTimes"
                      [columnMode]="'force'"
                      headerHeight="50"
                      footerHeight="50"
                      rowHeight="auto"
                      [limit]="tableLimit"
                      [sorts]="[{ prop: 'id', dir: 'asc' }]"
                      [cssClasses]="{
                        pagerLeftArrow: 'fas fa-backward',
                        pagerRightArrow: 'fas fa-forward',
                        pagerPrevious: 'fas fa-step-backward',
                        pagerNext: 'fas fa-step-forward'
                      }"
                    >
                      <ngx-datatable-column [sortable]="true" name="Tag" prop="day">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span>{{ row.day | dfnsFormat: 'DD.MM.YYYY' }}</span>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column [sortable]="true" name="Von" prop="from">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span>{{ row.from }} Uhr</span>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column [sortable]="true" name="Bis" prop="to">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span>{{ row.to }} Uhr</span>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column [sortable]="true" name="Abholung?" prop="takeaway">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <input class="chk-gt" type="checkbox" [checked]="row.takeaway" onclick="return false;" />
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column [sortable]="true" name="Lieferung?" prop="delivery">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <input class="chk-gt" type="checkbox" [checked]="row.delivery" onclick="return false;" />
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column [sortable]="true" name="Bestell-Limits wie" prop="limitDayTemplate">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span>{{ LimitDayTemplateValue[row.limitDayTemplate] }}</span>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column [sortable]="false" name="Aktionen">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <div class="action-btn">
                            <button (click)="editSpecialGastroTimes(row)" class="btn btn-small btn-light">
                              <i class="fas fa-edit fa-fw"></i>
                            </button>
                            <button
                              class="btn btn-light"
                              (click)="deleteSpecialGastroTimes(row.id)"
                              class="btn btn-danger"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </ng-template>
              </div>

              <legend style="margin-bottom: 20px;margin-top:30px;">
                <span>Bestell-Limits</span>
              </legend>
              <form [formGroup]="limitFormGroup">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group" formArrayName="maxOrderLimit">
                      <label for="">Anzahl maximale Bestellungen pro halbe Stunde</label>
                      <ng-template ngFor let-unit [ngForOf]="formData.controls" let-i="index">
                        <div style="display: flex; margin-bottom: 5px;" [formGroupName]="i">
                          <div style="flex: 1.5; padding-right: 1%;">
                            <select class="form-control" formControlName="day" required style="margin-right: 5px;">
                              <option [value]="day.value" *ngFor="let day of dayLimitTemplate">{{ day.label }}</option>
                            </select>
                          </div>
                          <div style="flex: 1;padding-right: 1%;">
                            <ng-select
                              [searchable]="false"
                              placeholder="Uhrzeit"
                              [clearable]="false"
                              formControlName="time"
                            >
                              <ng-option [value]="time.value" *ngFor="let time of times">{{ time.label }}</ng-option>
                            </ng-select>
                          </div>

                          <div style="flex: 1;padding-right: 1%;">
                            <input type="text" formControlName="limit" placeholder="Limit" class="form-control" />
                          </div>
                          <div>
                            <button class="btn btn-white" type="button" (click)="removeUnit(i)">
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </ng-template>
                      <button
                        class="btn btn-dark btn-circle"
                        type="button"
                        style="margin-top: 5px"
                        (click)="addNewUnit()"
                      >
                        Hinzufügen
                      </button>
                    </div>
                  </div>
                </div>
                <button class="btn btn-primary btn-block btn-lg" (click)="saveLimit()">
                  Speichern
                </button>
              </form>
            </div>
          </mat-tab>

          <mat-tab label="Drucksoftware">
            <div class="element-box" [formGroup]="generalSettings">
              <h5 class="form-header">Drucksoftware Einstellungen</h5>
              <div class="form-desc">
                Mit unserer Drucksoftware können Sie einfach und bequem Ihre Bestellungen per Bondruck erhalten.
                <br />
                Weitere Informationen und den Download der Software finden Sie in unserem
                <a href="https://blog.gastro.digital/knowledge-base/druckdienst-installieren/" target="_blank"
                  >Blogeintrag</a
                >.
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="printServiceActive"
                      (change)="save()"
                      formControlName="printServiceActive"
                      [disabled]="!generalSettings.value.isActive"
                    >
                      Druckdienst aktiv
                      <div class="info">
                        Sollen die Bestellungen an den Druckdienst übergeben werden?
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="generalSettings.value.printServiceActive">
                <div class="col-sm-12">
                  <div class="form-group">
                    <mat-slide-toggle name="printItemPrices" (change)="save()" formControlName="printItemPrices">
                      Preise anzeigen
                      <div class="info">
                        Sollen die Preise auf den Bon gedruckt werden?
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <ng-template [ngIf]="generalSettings.value.printServiceActive">
                <legend style="margin-bottom: 20px;margin-top:30px;" class="ng-star-inserted">
                  <span>Konfiguration</span>
                </legend>
                <div class="form-group row">
                  <label class="col-form-label col-sm-6" for="">
                    Bon-Druck
                    <div class="info">
                      Wie oft soll ein Beleg gedruckt werden?
                    </div>
                  </label>
                  <div class="col-sm-6">
                    <div class="leadtime-slider">
                      <ng5-slider
                        formControlName="printServiceTimes"
                        [options]="printServiceTimesOptions"
                        (userChange)="save()"
                      >
                      </ng5-slider>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </mat-tab>

          <mat-tab label="Tracking">
            <div class="element-box" [formGroup]="analyticsForm">
              <h5 class="form-header">Tracking</h5>
              <div class="form-desc">
                Analysiere die Besucher deines Online-Shops.
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-6" for="">
                  Google Analytics
                </label>
                <div class="col-sm-6">
                  <div class="input-group">
                    <input class="form-control" formControlName="googleAnalyticsId" />
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-sm-6" for="">
                  Facebook Pixel
                </label>
                <div class="col-sm-6">
                  <div class="input-group">
                    <input class="form-control" formControlName="facebookAnalyticsId" />
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" (click)="saveAnalytics()">Speichern</button>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
