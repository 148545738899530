<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-box">
        <div class="col-sm-12">
          <div class="onboarding-content">
            <div class="section-header">
              <h4 class="onboarding-title">
                {{ 'Documents.Title' | translate }}
              </h4>
              <button class="btn btn-primary" (click)="openModel()">
                {{ 'Documents.UploadNewDocument' | translate }}
              </button>
            </div>
          </div>
          <div [ngStyle]="{ display: loader ? 'none' : 'block' }" class="onboarding-content">
            <ngx-datatable
              #myTable
              class="material striped"
              [rows]="docList"
              [columnMode]="'force'"
              [limit]="tableLimit"
              [sorts]="[{ prop: 'lastVisit', dir: 'desc' }]"
              [scrollbarH]="false"
              [rowHeight]="'100%'"
              [cssClasses]="{
                pagerLeftArrow: 'fas fa-backward',
                pagerRightArrow: 'fas fa-forward',
                pagerPrevious: 'fas fa-step-backward',
                pagerNext: 'fas fa-step-forward'
              }"
            >
              <ngx-datatable-column name="ID">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.id }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{ 'Documents.DocumentName' | translate }}">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="!row.isEdit">{{ row.name }}</span>
                  <input type="text" *ngIf="row.isEdit" [(ngModel)]="modelObject[row.id]" />
                  <button *ngIf="row.isEdit" class="btn btn-primary" (click)="setDocName(row)">
                    {{ 'Common.Save' | translate }}
                  </button>
                  <button *ngIf="row.isEdit" class="btn btn-secondary" (click)="cancel(row)">
                    {{ 'Common.Abort' | translate }}
                  </button>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{ 'Documents.UploadDate' | translate }}">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.addedAt }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{ 'Documents.DownloadCounter' | translate }}">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.downloadsCntr }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="{{ 'Common.Actions' | translate }}">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button class="btn edit-btn" (click)="row.isEdit = true" matTooltip="Bearbeiten">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button class="btn edit-btn" (click)="viewDoc(row)" matTooltip="Bearbeiten">
                    <i class="fas fa-search"></i>
                  </button>
                  <button class="btn delete-btn" (click)="removeDocument(row)" matTooltip="Löschen">
                    <i class="far fa-trash-alt"></i>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
          <div [ngStyle]="{ display: loader ? 'block' : 'none' }" style="height: 100%;">
            <div style="position: relative; left: 25%; top: 48%; width: 50%;">
              Please Wait...
              <mat-progress-bar mode="indeterminate" [value]="10"></mat-progress-bar>
            </div>
          </div>
        </div>

        <div class="col-sm-12" style="margin-top: 50px">
          <div class="short-url">
            <div class="section-header">
              <h4 class="onboarding-title">
                {{ 'ShortURLs.Title' | translate }}
              </h4>
              <button class="btn btn-primary" (click)="addShortURL()">
                {{ 'ShortURLs.AddShortURL' | translate }}
              </button>
            </div>
          </div>
          <div [ngStyle]="{ display: loaderShortURL ? 'none' : 'block' }" class="onboarding-content">
            <div class="table-responsive shifts" *ngIf="shortURLs && shortURLs.length">
              <ngx-datatable
                #shortURLTable
                class="material striped"
                [rows]="shortURLs"
                [columnMode]="'force'"
                [limit]="tableLimit"
                [sorts]="[{ prop: 'id', dir: 'asc' }]"
                [scrollbarH]="false"
                [rowHeight]="'100%'"
                [cssClasses]="{
                  pagerLeftArrow: 'fas fa-backward',
                  pagerRightArrow: 'fas fa-forward',
                  pagerPrevious: 'fas fa-step-backward',
                  pagerNext: 'fas fa-step-forward'
                }"
              >
                <ngx-datatable-column name="{{ 'ShortURLs.ID' | translate }}">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span>{{ row.id }}</span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{ 'ShortURLs.DocumentLink' | translate }}">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.url }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{ 'ShortURLs.Description' | translate }}">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.description }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{ 'ShortURLs.Clicks' | translate }}">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.clicks }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{ 'Common.Actions' | translate }}">
                  <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <button
                      class="btn edit-btn"
                      (click)="editShortURL(row, rowIndex)"
                      matTooltip="{{ 'Common.Edit' | translate }}"
                    >
                      <i class="fas fa-edit"></i>
                    </button>

                    <button
                      class="btn edit-btn"
                      (click)="copyMessage('https://ggurl.de/' + row.id)"
                      matTooltip="{{ 'ShortURLs.CopyShortURL' | translate }}"
                    >
                      <i class="fas fa-copy"></i>
                    </button>

                    <button
                      class="btn delete-btn"
                      (click)="deleteShortURL(row.id)"
                      matTooltip="{{ 'Common.Delete' | translate }}"
                    >
                      <i class="far fa-trash-alt"></i>
                    </button>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
          <div [ngStyle]="{ display: loaderShortURL ? 'block' : 'none' }" style="height: 100%;">
            <div style="position: relative; left: 25%; top: 48%; width: 50%;">
              Please Wait...
              <mat-progress-bar mode="indeterminate" [value]="10"></mat-progress-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
