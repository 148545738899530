<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editRoomData">Neuen Raum anlegen</ng-template>
      <ng-template [ngIf]="editRoomData">Raum ändern</ng-template>
    </h4>
    <form [formGroup]="roomFormGroup">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">
              Raumname<br />
              <small>Auch für den Gast sichtbar</small>
            </label>
            <input
              class="form-control"
              placeholder="Geben Sie den Raumnamen ein..."
              name="name"
              formControlName="name"
              type="text"
              value=""
              ngbAutofocus
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for=""
              >Anzahl Plätze<br />
              <small>(falls keine Tische hinterlegt sind)</small></label
            >
            <number-spinner
              name="seats"
              [min]="1"
              [max]="1000"
              [step]="1"
              [precision]="1"
              [inputDisabled]="false"
              [defaultValue]="+roomFormGroup.value.seats"
              (whenChange)="seatsAmountChanged($event)"
            ></number-spinner>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="">
                  Raumbreite in Metern
                </label>
                <input
                  class="form-control"
                  placeholder="7"
                  name="width"
                  formControlName="width"
                  type="number"
                  min="1"
                  max="50"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="">
                  Raumlänge in Metern
                </label>
                <input
                  class="form-control"
                  placeholder="5"
                  name="length"
                  formControlName="length"
                  type="number"
                  min="1"
                  max="50"
                />
              </div>
            </div>
            <div class="dol-sm-12">
              <small></small>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">Hinweis für den Gast</label>
            <textarea
              class="form-control"
              placeholder="Optional"
              type="text"
              name="alert"
              formControlName="alert"
              rows="2"
            ></textarea>
          </div>
        </div>
        <div class="col-sm-12" style="margin-bottom: 20px">
          <mat-slide-toggle name="isActive" formControlName="isActive">
            Raum ist reservierbar
          </mat-slide-toggle>
        </div>

        <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>

        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="add()"
          *ngIf="!editRoomData"
          [promiseBtn]="addObservable"
        >
          Raum anlegen
        </button>
        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="save()"
          *ngIf="editRoomData"
          [promiseBtn]="editObservable"
        >
          Raum speichern
        </button>
        <button
          class="btn btn-link btn-sm delete-button"
          (click)="delete()"
          *ngIf="editRoomData"
          [promiseBtn]="deleteObservable"
        >
          Raum löschen
        </button>
      </div>
    </form>
  </div>
</div>
