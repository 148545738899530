<breadcrumb></breadcrumb>
<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          {{ 'Administration.NewsLetter' | translate }}
          <div class="element-actions"></div>
        </h6>

        <div class="csv-main-conatiner">
          <section>
            <div class="form-group">
              <angular2csv
                class="btn btn-primary"
                [data]="getAllNewsletter"
                filename="export_{{ now | date: 'yyyy_MM_dd_H_mm' }}"
                [options]="csvOptions"
              ></angular2csv>
            </div>
          </section>
        </div>

        <div class="element-box" *ngIf="newLetters?.users">
          <ngx-datatable
            class="material my-datatable striped"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="80"
            [rows]="
              newLetters?.users
                | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalElements }
            "
            [rowHeight]="'auto'"
            [scrollbarV]="false"
            [scrollbarH]="true"
            [sorts]="[{ prop: 'subscribedAt', dir: 'desc' }]"
            [cssClasses]="{
              pagerLeftArrow: 'fas fa-backward',
              pagerRightArrow: 'fas fa-forward',
              pagerPrevious: 'fas fa-step-backward',
              pagerNext: 'fas fa-step-forward'
            }"
          >
            <ngx-datatable-column name="Email" prop="email" [sortable]="true">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                <span *ngIf="row.email" style="overflow: hidden;word-wrap: break-word;margin-left: 6px;">{{
                  row.email
                }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Date" prop="subscribedAt" [sortable]="true">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                <span *ngIf="row.email">{{ row.subscribedAt | amDateFormat: 'DD-MM-YYYY' }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Action" prop="action" [sortable]="true">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                <button class="btn btn-danger" (click)="deleteMenu(row)" matTooltip="Löschen">
                  <i class="far fa-trash-alt"></i>
                </button>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <div class="pagination-div">
                  <div class="pagesize-div" *ngIf="newLetters?.users.length >= 10">
                    <label for="pageSize">Page Size:</label>
                    <select id="pageSize" (change)="onPageSizeChange($event)">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="40">40</option>
                      <option value="60">60</option>
                      <option value="80">80</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-backward'"
                    [pagerRightArrowIcon]="'fas fa-forward'"
                    [pagerPreviousIcon]="'fas fa-step-backward'"
                    [pagerNextIcon]="'fas fa-step-forward'"
                    [page]="currentPage"
                    [size]="pageSize"
                    [count]="totalElements"
                    [hidden]="!(totalElements / pageSize > 1)"
                    (change)="onPage($event)"
                  >
                  </datatable-pager>
                </div>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
