/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reservation-overview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/breadcrumb/breadcrumb.component.ngfactory";
import * as i3 from "../../shared/breadcrumb/breadcrumb.component";
import * as i4 from "@angular/router";
import * as i5 from "../../core/authentication/authentication.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./reservation-overview.component";
var styles_ReservationOverviewComponent = [i0.styles];
var RenderType_ReservationOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReservationOverviewComponent, data: {} });
export { RenderType_ReservationOverviewComponent as RenderType_ReservationOverviewComponent };
export function View_ReservationOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "breadcrumb", [], null, null, null, i2.View_BreadcrumbComponent_0, i2.RenderType_BreadcrumbComponent)), i1.ɵdid(1, 114688, null, 0, i3.BreadcrumbComponent, [i4.Router, i4.ActivatedRoute, i5.AuthenticationService, i6.TranslateService], null, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ReservationOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reservation-overview", [], null, null, null, View_ReservationOverviewComponent_0, RenderType_ReservationOverviewComponent)), i1.ɵdid(1, 114688, null, 0, i7.ReservationOverviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReservationOverviewComponentNgFactory = i1.ɵccf("app-reservation-overview", i7.ReservationOverviewComponent, View_ReservationOverviewComponent_Host_0, {}, {}, []);
export { ReservationOverviewComponentNgFactory as ReservationOverviewComponentNgFactory };
