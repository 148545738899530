import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaV3CustomService {
  constructor(private recaptchaV3Service: ReCaptchaV3Service) {}

  execute(action: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.recaptchaV3Service.execute(action).subscribe(
        (token: string) => {
          resolve(token); // Return the token
        },
        error => {
          reject(error); // Handle error
        }
      );
    });
  }
}
