<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      {{ 'ResSettings.ResAddNotification.AddNotification' | translate }}
    </h4>
    <form>
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="">{{ 'ResSettings.ResAddNotification.Channel' | translate }}</label>
            <select class="form-control" name="buffer" [(ngModel)]="channel.type">
              <option value="email">{{ 'ResSettings.ResAddNotification.Email' | translate }}</option>
              <option value="sms">{{ 'ResSettings.ResAddNotification.SMS' | translate }}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-9" *ngIf="channel.type === 'email'">
          <div class="form-group">
            <label for="">{{ 'ResSettings.ResAddNotification.MailAddress' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fas fa-at"></i>
                </div>
              </div>
              <input
                class="form-control"
                placeholder=""
                type="email"
                name="alert"
                [(ngModel)]="channel.value"
                ngbAutofocus
              />
            </div>
          </div>
        </div>
        <div class="col-sm-9" *ngIf="channel.type === 'sms'">
          <div class="form-group">
            <label for="">{{ 'ResSettings.ResAddNotification.MobileNumber' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fas fa-mobile-alt"></i>
                </div>
              </div>
              <input
                class="form-control"
                placeholder=""
                type="phone"
                name="alert"
                [(ngModel)]="channel.value"
                ngbAutofocus
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-desc" style="margin:0px">
        {{ 'ResSettings.ResAddNotification.SMSNotification' | translate }}
      </div>
      <button class="btn btn-primary btn-block btn-lg" (click)="add()" *ngIf="!editChannelData">
        {{ 'ResSettings.ResAddNotification.CreateChannel' | translate }}
      </button>
      <button class="btn btn-primary btn-block btn-lg" (click)="add()" *ngIf="editChannelData">
        {{ 'ResSettings.ResAddNotification.CreateChannel' | translate }}
      </button>
    </form>
  </div>
</div>
