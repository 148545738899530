<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Einstellungen - Speisekarten
          <a
            class="btn btn-default btn-sm"
            ngbTooltip="Hilfe zu Speisekarten"
            href="https://blog.gastro.digital/article-categories/speisekarte/"
            target="_blank"
          >
            <mat-icon svgIcon="help"></mat-icon>
          </a>
        </h6>
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Allgemein" *ngIf="false">
            <div class="element-box">
              <h5 class="form-header">Allgemein</h5>
              <div class="form-desc">Hier finden Sie wichtige Einstellungen zu Ihren Speisekarten.</div>
            </div>
          </mat-tab>
          <mat-tab label="Preiskategorien">
            <div class="element-box">
              <h5 class="form-header">Preiskategorien</h5>
              <div class="form-desc">
                Legen Sie Preiskategorien an welche Sie mit Artikel-Größen verknüpfen können.
                <button class="btn btn-primary" (click)="addPricecategory()" style="margin-top:15px">
                  <i class="fa fa-plus-circle fa-fw"></i> Neue Preiskategorie anlegen
                </button>
              </div>

              <div *ngIf="priceCategories && !priceCategories.length" class="alert alert-info">
                Sie haben noch keine Preiskategorien angelegt
              </div>
              <div class="table-responsive shifts" *ngIf="priceCategories && priceCategories.length">
                <table class="table table-padded">
                  <thead>
                    <tr>
                      <th>Name der Preiskategorie</th>
                      <th>Erstellt am</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let cat of priceCategories; let i = index">
                      <td class="nowrap">
                        <span>
                          {{ cat.name }}
                          <div class="badge badge-default" *ngIf="cat.isMainCategory">Standard</div>
                        </span>
                      </td>
                      <td>
                        <span *ngIf="!cat.isMainCategory">{{ cat.createdAt | amDateFormat: 'DD.MM.YYYY HH:mm' }}</span>
                        <span *ngIf="cat.isMainCategory">-</span>
                      </td>
                      <td style="width: 130px;">
                        <button
                          class="btn btn-light"
                          *ngIf="!cat.isMainCategory"
                          (click)="editPricecategory(cat)"
                          matTooltip="Preiskategorie bearbeiten"
                        >
                          <i class="far fa-edit"></i>
                        </button>
                        <button
                          class="btn btn-danger"
                          *ngIf="!cat.isMainCategory"
                          (click)="deletePricecategory(cat.id)"
                          matTooltip="Preiskategorie löschen"
                        >
                          <i class="far fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Größen">
            <div class="element-box">
              <h5 class="form-header">Größen</h5>
              <div class="form-desc">
                Legen Sie Größen an welche Sie mit Preiskategorien verknüpfen.
                <button class="btn btn-primary" (click)="addSize()" style="margin-top:15px">
                  <i class="fa fa-plus-circle fa-fw"></i> Neue Größe anlegen
                </button>
              </div>

              <div class="alert alert-info" *ngIf="!sizes || !sizes.length" style="margin:0">
                Keine Größen gefunden
              </div>

              <div class="table-responsive shifts" *ngIf="sizes && sizes.length">
                <table class="table table-padded">
                  <thead>
                    <tr>
                      <th>Interner Name</th>
                      <th>Name der Größe</th>
                      <th>Preiskategorie</th>
                      <th>Erstellt am</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let size of sizes; let i = index">
                      <td class="nowrap">
                        <span>{{ size.internalName }}</span>
                      </td>
                      <td class="nowrap">
                        <span>{{ size.name }}</span>
                      </td>
                      <td class="nowrap">
                        <span>
                          {{
                            size.priceCategoryData?.internalName
                              ? size.priceCategoryData?.internalName
                              : size.priceCategoryData?.name
                          }}
                        </span>
                      </td>
                      <td>
                        <span>{{ size.createdAt | amDateFormat: 'DD.MM.YYYY HH:mm' }}</span>
                      </td>
                      <td style="width: 155px;">
                        <button class="btn btn-light" (click)="editSize(size)" matTooltip="Bearbeiten">
                          <i class="far fa-edit"></i>
                        </button>
                        <button class="btn btn-danger" (click)="deleteSize(size.id)" matTooltip="Löschen">
                          <i class="far fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Tags">
            <div class="element-box">
              <h5 class="form-header">Tags</h5>
              <div class="form-desc">Richten Sie Tags für Artikel ein um diese besser filtern zu können.</div>

              <div class="table-responsive shifts">
                <table class="table table-padded">
                  <thead>
                    <tr>
                      <th style="width: 85px">Farbe</th>
                      <th>Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr [formGroup]="tagsForm">
                      <td>
                        <app-color-picker color="#f44336" (event)="changeTagColor($event)"></app-color-picker>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          formControlName="name"
                          placeholder="Name des Tags..."
                          required
                        />
                      </td>
                      <td style="padding-right: 0;">
                        <button
                          class="btn btn-primary"
                          (click)="addTag()"
                          style="display: flex;"
                          [disabled]="!tagsForm.valid"
                        >
                          <i class="far fa-save fa-fw"></i>
                          Speichern
                        </button>
                      </td>
                    </tr>

                    <tr *ngFor="let tag of tags; let i = index">
                      <td class="nowrap">
                        <div [style.background-color]="tag.color" class="tag-circle"></div>
                      </td>
                      <td>
                        <span>{{ tag.name }}</span>
                      </td>
                      <td style="width: 55px;">
                        <div class="shift-options">
                          <button class="btn btn-white" (click)="deleteTag(tag.id)">
                            <mat-icon svgIcon="delete"></mat-icon>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
