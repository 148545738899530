/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./control-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./control-error.component";
var styles_ControlErrorComponent = [i0.styles];
var RenderType_ControlErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ControlErrorComponent, data: {} });
export { RenderType_ControlErrorComponent as RenderType_ControlErrorComponent };
export function View_ControlErrorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "help is-danger"]], [[2, "hide", null]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._hide; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co._text; _ck(_v, 2, 0, currVal_1); }); }
export function View_ControlErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ControlErrorComponent_0, RenderType_ControlErrorComponent)), i1.ɵdid(1, 114688, null, 0, i2.ControlErrorComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ControlErrorComponentNgFactory = i1.ɵccf("ng-component", i2.ControlErrorComponent, View_ControlErrorComponent_Host_0, { text: "text" }, {}, []);
export { ControlErrorComponentNgFactory as ControlErrorComponentNgFactory };
