import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'specialCharacters' })
export class SpecialCharactersPipe implements PipeTransform {
  transform(value: string, removeSlashAndDot: boolean = false): string {
    const div = document.createElement('div');
    div.innerHTML = value;
    let result = div.textContent || div.innerText || '';

    // If removeSlashAndDot is true, remove '/' and '.' characters from the result
    if (removeSlashAndDot) {
      result = result.replace(/[\/\.]/g, ''); // Removing slashes and periods
    }

    return result;
  }
}
