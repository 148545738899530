<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editResMenuOrders">{{
        'ResSettings.ResMenuOrder.CreateResMenuOrder' | translate
      }}</ng-template>
      <ng-template [ngIf]="editResMenuOrders">{{
        'ResSettings.ResMenuOrder.CreateResMenuOrder' | translate
      }}</ng-template>
    </h4>
    <form [formGroup]="resMenuOrdersFormGroup">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">Name<br /> </label>
            <input
              class="form-control"
              placeholder=""
              name="name"
              formControlName="name"
              type="text"
              value=""
              required
              ngbAutofocus
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'ResSettings.ResMenuOrder.TotalAmount' | translate }}<br /> </label>
            <mat-form-field>
              <input
                style="margin-top: -9%;"
                type="number"
                class="form-control"
                matInput
                min="1"
                max="1000"
                name="value"
                formControlName="totalAmount"
                required
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group" formArrayName="specialResMenuOrders">
            <label for="">{{ 'ResSettings.ResMenuOrder.SpecialResMenuOrder' | translate }}</label>
            <ng-template ngFor let-unit [ngForOf]="formData.controls" let-i="index">
              <div style="display: flex; margin-bottom: 5px" [formGroupName]="i">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateFromPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                        <input
                          class="form-control"
                          #dateFromInput
                          placeholder=""
                          type="text"
                          name="date"
                          formControlName="date"
                          [matDatepicker]="dateFromPicker"
                          (focus)="dateFromPicker.open()"
                        />
                        <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <mat-form-field>
                        <input
                          style="margin-top: -9%;"
                          type="number"
                          class="form-control"
                          matInput
                          min="1"
                          max="1000"
                          name="value"
                          formControlName="totalAmount"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div>
                  <button class="btn btn-white" type="button" (click)="removeUnit(i)">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </ng-template>
            <button class="btn btn-dark btn-circle" type="button" style="margin-top: 5px" (click)="addNewUnit()">
              + {{ 'ResSettings.ResMenuOrder.NewUnit' | translate }}
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="">Parent Menu</label>
            <ng-select formControlName="parentId" [(ngModel)]="resMenuOrdersFormGroup.value.parentId"
              placeholder="-- Bitte wählen --" [searchable]="true" [clearable]="true" [items]="resMenuOrders"
              bindLabel="name" bindValue="id" notFoundText="Kein Eintrag gefunden">
            </ng-select>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">Datum</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="dateFromPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <input class="form-control" #dateFromInput placeholder="" type="text" name="date" formControlName="date"
                [matDatepicker]="dateFromPicker" (focus)="dateFromPicker.open()" />
              <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
            </div>
          </div>
        </div>
      </div> -->

      <div>
        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="add()"
          *ngIf="!editResMenuOrders"
          [promiseBtn]="addObservable"
        >
          {{ 'ResSettings.ResMenuOrder.CreateResMenuOrderBtn' | translate }}
        </button>
        <button
          class="btn btn-primary btn-block btn-lg"
          (click)="save()"
          *ngIf="editResMenuOrders"
          [promiseBtn]="editObservable"
        >
          {{ 'ResSettings.ResMenuOrder.CreateResMenuOrderBtn' | translate }}
        </button>
      </div>
      <div></div>
    </form>
  </div>
</div>
