<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          {{ 'Vouchers.Settings.SettingsVoucher' | translate }}
        </h6>

        <div class="row" *ngIf="settings">
          <div class="col-sm-12 col-md-6">
            <div class="form-group">
              <div
                class="system-setting-div"
                [ngClass]="{ 'toggle-success': settings?.isActive, 'toggle-danger': !settings?.isActive }"
              >
                <mat-slide-toggle
                  name="isActive"
                  (change)="changedSettings(settings?.isActive)"
                  [(ngModel)]="settings.isActive"
                >
                  Aktives Gutscheinsystem
                  <div class="info">
                    Möchten Sie das Gutscheinsystem aktivieren?
                  </div>
                </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>

        <mat-tab-group animationDuration="0ms">
          <mat-tab label="{{ 'Vouchers.Settings.GeneralSettings' | translate }}">
            <div class="element-box">
              <div class="alert alert-warning borderless" style="margin-bottom: 30px;">
                <strong>Link zu Ihrer Bestellseite:</strong>
                <br />
                <a [href]="voucherShopLink" target="_blank" style="text-decoration: underline;">{{
                  voucherShopLink
                }}</a>
                [
                <a
                  href="https://api.qrserver.com/v1/create-qr-code/?size=800x800&data={{ voucherShopLink }}"
                  target="_blank"
                  style="text-decoration: underline;"
                >
                  QR Code
                </a>
                ]
              </div>
              <h5 class="form-header" style="margin-bottom: 0;">{{ 'Vouchers.Settings.VoucherValues' | translate }}</h5>
              <div class="form-desc" style="border-bottom: 0; padding-bottom: 0;">
                {{ 'Vouchers.Settings.MaximumDeposit' | translate }}
              </div>
              <div class="row">
                <div class="col-md-2" *ngFor="let voucherValue of voucherValuesRange; let i = index">
                  <div class="form-group">
                    <select class="custom-select" [(ngModel)]="voucherValues[i]">
                      <option value="0">{{ 'Common.PleaseChoose' | translate }}</option>
                      <option [value]="val" *ngFor="let val of voucherValueRange">{{ val }},00 €</option>
                    </select>
                  </div>
                </div>
              </div>

              <h5 class="form-header">{{ 'Vouchers.Settings.MoreSettings' | translate }}</h5>

              <br />

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <mat-slide-toggle name="delivery" [(ngModel)]="comissionTakeover">
                      {{ 'Vouchers.Settings.GuestPayPayment' | translate }}
                      <i
                        class="fa fa-info-circle details-icon"
                        title="{{ 'Vouchers.Settings.PaymentFeesInfo' | translate }}"
                      ></i>
                      <div class="info">{{ 'Vouchers.Settings.WorksWithNewShopOnly' | translate }}</div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <br />
              <!-- row-->

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <mat-slide-toggle name="delivery" [(ngModel)]="postDelivery">
                      {{ 'Vouchers.Settings.OfferVoucherPost' | translate }}
                      <div class="info">( {{ 'Common.PleaseChoose' | translate }})</div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <!-- row-->
              <br />
              <ng-container *ngIf="postDelivery == 1">
                <div class="row">
                  <div class="col-md-6 col-sm-12 postal-email-div">
                    <span>
                      {{ 'Vouchers.Settings.HappyToOvertakeThePostal' | translate }} <br />
                      <a [href]="'mailto:kunden@gastro.digital'">kunden@gastro.digital</a>
                    </span>
                    <!-- <mat-slide-toggle name="sender" [(ngModel)]="sender" (change)="setShippingData()">
                        {{ 'Vouchers.Settings.Sender' | translate }}
                        <div class="info">( {{ 'Common.PleaseChoose' | translate }})</div>
                      </mat-slide-toggle> -->
                  </div>
                </div>
              </ng-container>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <mat-slide-toggle name="sender" [disabled]="true" [(ngModel)]="sender" (change)="setShippingData()">
                      {{ 'Vouchers.Settings.Sender' | translate }}
                      <div class="info">( {{ 'Common.PleaseChoose' | translate }})</div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="sender == 0">
                <div class="row">
                  <div class="col-md-4">
                    <h6 class="form-header">{{ 'Vouchers.Settings.ShippingFee' | translate }}</h6>
                    <div class="form-group">
                      <input
                        class="form-control"
                        placeholder=""
                        type="text"
                        name="shippingFee"
                        [(ngModel)]="shippingFee"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <h6 class="form-header">{{ 'Vouchers.Settings.DeliveryPeriod' | translate }}</h6>
                    <div class="form-group">
                      <select class="custom-select" [(ngModel)]="deliveryPeriod">
                        <option value="0">{{ 'Common.PleaseChoose' | translate }}</option>
                        <option value="1"> {{ 'Vouchers.Settings.OneWorkday' | translate }}</option>
                        <option [value]="val" *ngFor="let val of deliveryPeriods"
                          >{{ val }} {{ 'Vouchers.Settings.WorkingDays' | translate }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
                <!-- row-->
              </ng-container>

              <div class="row">
                <div class="col-md-12">
                  <h6 class="form-header">{{ 'Vouchers.Settings.ShopDescription' | translate }}</h6>
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      placeholder=""
                      type="text"
                      name="shopDescription"
                      [(ngModel)]="shopDescription"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
              <br />
              <button class="btn btn-primary" (click)="saveSettings()">
                <i class="far fa-save"></i> &nbsp; {{ 'Common.Save' | translate }}
              </button>
            </div>
          </mat-tab>

          <mat-tab label="Benachrichtigungen">
            <div class="element-box">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="notifyByEmailOption"
                      (change)="changeNotifyEmailOption($event.checked)"
                      [(ngModel)]="notifyByEmailOption"
                    >
                      {{ 'Vouchers.Settings.EmailConfirmation' | translate }}
                      <div class="info">
                        {{ 'Vouchers.Settings.EmailConfirmationText' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="this.notifyByEmailOption">
                <div class="col-md-12">
                  <div class="form-group" style="margin: 0;">
                    <input
                      class="form-control"
                      type="email"
                      placeholder="E-Mail Adresse"
                      name="notifyByEmail"
                      [(ngModel)]="notifyByEmail"
                    />
                  </div>
                  <div class="voucher-email-error" *ngIf="emailTextError">
                    {{ emailTextError }}
                  </div>
                </div>
                <!-- <div lass="col-md-12" style="padding-left: 0px;">
                  <div class="form-group toggle-div">
                    {{ 'Vouchers.Settings.EmailForAllVouchers' | translate }} &nbsp;
                    <mat-slide-toggle
                      style="vertical-align:bottom"
                      name="notifyByEmailType"
                      [(ngModel)]="notifyByEmailType"
                    >
                    </mat-slide-toggle>
                    &nbsp; {{ 'Vouchers.Settings.OnlyPostEmailVouchers' | translate }}
                  </div>
                </div> -->
              </div>
              <br />
              <button class="btn btn-primary" (click)="saveSettings()">
                <i class="far fa-save"></i> &nbsp; {{ 'Common.Save' | translate }}
              </button>
            </div>
          </mat-tab>

          <mat-tab label="Shopdesign">
            <div class="element-box">
              <div class="form-group">
                <label for="">{{ 'Vouchers.Settings.HeaderBackgroundImage' | translate }}</label>
                <div class="input-group">
                  <input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput />
                  <input
                    readonly
                    type="text"
                    class="form-control"
                    [(ngModel)]="headerBackground"
                    [ngModelOptions]="{ standalone: true }"
                    (click)="$event.preventDefault(); fileInput.click()"
                    (keypress)="returnVal(false)"
                  />
                  <div
                    class="input-group-append"
                    (click)="$event.preventDefault(); fileInput.click()"
                    style="cursor: pointer;"
                  >
                    <button class="btn btn-outline-primary" type="button">
                      <i class="fas fa-file-upload"></i>
                    </button>
                  </div>
                </div>

                <div class="preview-img" *ngIf="imageURL">
                  <div class="uploaded-list">
                    <div>
                      <img class="img-fluid" src="{{ imageURL }}" />
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <button class="btn btn-primary" (click)="saveSettings()">
                <i class="far fa-save"></i> &nbsp; {{ 'Common.Save' | translate }}
              </button>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
