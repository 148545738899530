<div class="auth-box-w wider centered">
  <div class="logo-w">
    <a href="index.html"><img alt="" src="./assets/logo.png"/></a>
  </div>

  <ng-template [ngIf]="!invalidHashError">
    <h5 class="auth-header">Tragen Sie ihr gewünschtes Passwort ein um die Einladung abzuschließen</h5>

    <div style="padding: 20px" [formGroup]="inviteForm">
      <div class="form-group">
        <label for="">Passwort</label
        ><input class="form-control" formControlName="password" placeholder="Ihr neues Passwort" type="password" />
        <small
          [hidden]="inviteForm.controls.password.valid || inviteForm.controls.password.untouched"
          class="text-danger"
          translate
        >
          Passwort ist leer.
        </small>
      </div>
      <div class="form-group">
        <label for="">Passwort erneut eingeben</label
        ><input
          class="form-control"
          formControlName="passwordRepeat"
          placeholder="Ihr neues Passwort wiederholen"
          type="password"
        />
        <small
          [hidden]="inviteForm.controls.passwordRepeat.valid || inviteForm.controls.passwordRepeat.untouched"
          class="text-danger"
          translate
        >
          Passwörter stimmen nicht überein.
        </small>
      </div>

      <div class="buttons-w">
        <button class="btn btn-primary" (click)="send()" [disabled]="inviteForm.invalid || isLoading">
          Passwort speichern & Einladung abschließen
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template [ngIf]="invalidHashError">
    <h4 class="text-danger">Der Einladungscode ist ungültig oder abgelaufen.</h4>
  </ng-template>
</div>
