<div class="modal-content text-center">
  <div class="modal-header">
    <h4 class="modal-title">Reply</h4>
    <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
      <span class="fas fa-times"></span>
    </button>
  </div>
  <div class="onboarding-content" style="text-align: left;">
    {{ commentData.comment }}
    <div *ngIf="commentData.comment" style="margin-top:5px">
      by <b style="font-weight: bold;">{{ commentData.memberDataInfo.username }}</b>
    </div>
  </div>
  <div class="onboarding-content" style="text-align: left;">
    Write a Reply
    <textarea class="col-sm-12" [(ngModel)]="reply" style="outline: none; border:1px solid #dedede"></textarea>
    <button class="btn btn-primary" (click)="submitReply()">Reply</button>
    <button class="btn btn-secondary" (click)="activeModal.dismiss()">Abort</button>
  </div>
</div>
