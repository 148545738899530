/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-cropper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./image-cropper.component";
import * as i4 from "../services/crop.service";
import * as i5 from "../services/cropper-position.service";
import * as i6 from "../services/load-image.service";
import * as i7 from "@angular/platform-browser";
var styles_ImageCropperComponent = [i0.styles];
var RenderType_ImageCropperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageCropperComponent, data: {} });
export { RenderType_ImageCropperComponent as RenderType_ImageCropperComponent };
function View_ImageCropperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["sourceImage", 1]], null, 0, "img", [["class", "source-image"]], [[8, "src", 4], [4, "visibility", null], [4, "transform", null]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.imageLoadedInView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.safeImgDataUrl; var currVal_1 = (_co.imageVisible ? "visible" : "hidden"); var currVal_2 = _co.safeTransformStyle; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ImageCropperComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 53, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "resize topleft"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, _co.moveTypes.Resize, "topleft") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, _co.moveTypes.Resize, "topleft") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "span", [["class", "resize top"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "span", [["class", "resize topright"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, _co.moveTypes.Resize, "topright") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, _co.moveTypes.Resize, "topright") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(17, 0, null, null, 3, "span", [["class", "resize right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(19, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(22, 0, null, null, 3, "span", [["class", "resize bottomright"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, _co.moveTypes.Resize, "bottomright") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, _co.moveTypes.Resize, "bottomright") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(24, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(27, 0, null, null, 3, "span", [["class", "resize bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(29, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(32, 0, null, null, 3, "span", [["class", "resize bottomleft"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, _co.moveTypes.Resize, "bottomleft") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, _co.moveTypes.Resize, "bottomleft") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(34, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(37, 0, null, null, 3, "span", [["class", "resize left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(39, 0, null, null, 0, "span", [["class", "square"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(42, 0, null, null, 1, "span", [["class", "resize-bar top"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, _co.moveTypes.Resize, "top") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, _co.moveTypes.Resize, "top") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(45, 0, null, null, 1, "span", [["class", "resize-bar right"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, _co.moveTypes.Resize, "right") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, _co.moveTypes.Resize, "right") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(48, 0, null, null, 1, "span", [["class", "resize-bar bottom"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, _co.moveTypes.Resize, "bottom") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, _co.moveTypes.Resize, "bottom") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(51, 0, null, null, 1, "span", [["class", "resize-bar left"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, _co.moveTypes.Resize, "left") !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, _co.moveTypes.Resize, "left") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, null); }
function View_ImageCropperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "cropper"], ["tabindex", "0"]], [[2, "rounded", null], [4, "top", "px"], [4, "left", "px"], [4, "width", "px"], [4, "height", "px"], [4, "margin-left", null], [4, "visibility", null]], [[null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.keyboardAccess($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "move"]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startMove($event, _co.moveTypes.Move) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.startMove($event, _co.moveTypes.Move) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageCropperComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = !_co.hideResizeSquares; _ck(_v, 5, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.roundCropper; var currVal_1 = _co.cropper.y1; var currVal_2 = _co.cropper.x1; var currVal_3 = (_co.cropper.x2 - _co.cropper.x1); var currVal_4 = (_co.cropper.y2 - _co.cropper.y1); var currVal_5 = ((_co.alignImage === "center") ? _co.marginLeft : null); var currVal_6 = (_co.imageVisible ? "visible" : "hidden"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ImageCropperComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { wrapper: 0 }), i1.ɵqud(671088640, 2, { sourceImage: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["wrapper", 1]], null, 9, "div", [], [[4, "background", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageCropperComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "overlay"]], [[4, "width", "px"], [4, "height", "px"], [4, "margin-left", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageCropperComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.safeImgDataUrl; _ck(_v, 5, 0, currVal_1); var currVal_5 = _co.imageVisible; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.imageVisible && _co.backgroundColor); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.maxSize.width; var currVal_3 = _co.maxSize.height; var currVal_4 = ((_co.alignImage === "center") ? _co.marginLeft : null); _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_ImageCropperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "image-cropper", [], [[4, "text-align", null], [2, "disabled", null]], [["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_4 && ad);
    } return ad; }, View_ImageCropperComponent_0, RenderType_ImageCropperComponent)), i1.ɵdid(1, 638976, null, 0, i3.ImageCropperComponent, [i4.CropService, i5.CropperPositionService, i6.LoadImageService, i7.DomSanitizer, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).alignImage; var currVal_1 = i1.ɵnov(_v, 1).disabled; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var ImageCropperComponentNgFactory = i1.ɵccf("image-cropper", i3.ImageCropperComponent, View_ImageCropperComponent_Host_0, { imageChangedEvent: "imageChangedEvent", imageURL: "imageURL", imageBase64: "imageBase64", imageFile: "imageFile", format: "format", transform: "transform", maintainAspectRatio: "maintainAspectRatio", aspectRatio: "aspectRatio", resizeToWidth: "resizeToWidth", resizeToHeight: "resizeToHeight", cropperMinWidth: "cropperMinWidth", cropperMinHeight: "cropperMinHeight", cropperMaxHeight: "cropperMaxHeight", cropperMaxWidth: "cropperMaxWidth", cropperStaticWidth: "cropperStaticWidth", cropperStaticHeight: "cropperStaticHeight", canvasRotation: "canvasRotation", initialStepSize: "initialStepSize", roundCropper: "roundCropper", onlyScaleDown: "onlyScaleDown", imageQuality: "imageQuality", autoCrop: "autoCrop", backgroundColor: "backgroundColor", containWithinAspectRatio: "containWithinAspectRatio", hideResizeSquares: "hideResizeSquares", cropper: "cropper", alignImage: "alignImage", disabled: "disabled" }, { imageCropped: "imageCropped", startCropImage: "startCropImage", imageLoaded: "imageLoaded", cropperReady: "cropperReady", loadImageFailed: "loadImageFailed" }, []);
export { ImageCropperComponentNgFactory as ImageCropperComponentNgFactory };
