import { Socket } from 'ngx-socket-io';
import { AuthenticationService } from './core';
import * as i0 from "@angular/core";
import * as i1 from "ngx-socket-io";
import * as i2 from "./core/authentication/authentication.service";
export class SocketService {
    constructor(socket, authService) {
        this.socket = socket;
        this.authService = authService;
        this.newReservation = this.socket.fromEvent('newReservation');
        this.updateReservation = this.socket.fromEvent('updateReservation');
        this.uploadFiles = this.socket.fromEvent('filesUploaded');
        this.newOrder = this.socket.fromEvent('newOrder');
        socket.on('connect', () => {
            this.authService.getActiveClient().subscribe((clientData) => {
                this.joinClient(clientData.id);
            });
            console.log('connected to server');
        });
    }
    joinClient(clientId) {
        this.socket.emit('register', clientId);
    }
}
SocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SocketService_Factory() { return new SocketService(i0.ɵɵinject(i1.Socket), i0.ɵɵinject(i2.AuthenticationService)); }, token: SocketService, providedIn: "root" });
