/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delivery-times.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ag-grid-angular/ag-grid-angular.ngfactory";
import * as i3 from "ag-grid-angular";
import * as i4 from "./delivery-times.component";
import * as i5 from "../../gastro-pay.service";
import * as i6 from "@angular/material/snack-bar";
var styles_DeliveryTimesComponent = [i0.styles];
var RenderType_DeliveryTimesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeliveryTimesComponent, data: {} });
export { RenderType_DeliveryTimesComponent as RenderType_DeliveryTimesComponent };
export function View_DeliveryTimesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "ag-grid-angular", [["class", "ag-theme-alpine"], ["style", "width: 100%; height: 400px;"]], null, [[null, "gridReady"], [null, "cellValueChanged"], [null, "rangeSelectionChanged"], [null, "pasteEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } if (("cellValueChanged" === en)) {
        var pd_1 = (_co.onCellValueChanged($event) !== false);
        ad = (pd_1 && ad);
    } if (("rangeSelectionChanged" === en)) {
        var pd_2 = (_co.onRangeSelectionChanged($event) !== false);
        ad = (pd_2 && ad);
    } if (("pasteEnd" === en)) {
        var pd_3 = (_co.onPasteEnd($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_AgGridAngular_0, i2.RenderType_AgGridAngular)), i1.ɵprd(512, null, i3.AngularFrameworkOverrides, i3.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i3.AngularFrameworkComponentWrapper, i3.AngularFrameworkComponentWrapper, []), i1.ɵdid(3, 4898816, null, 1, i3.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i3.AngularFrameworkOverrides, i3.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { gridOptions: [0, "gridOptions"], rowData: [1, "rowData"], columnDefs: [2, "columnDefs"], frameworkComponents: [3, "frameworkComponents"], localeText: [4, "localeText"], defaultColDef: [5, "defaultColDef"], sideBar: [6, "sideBar"], getContextMenuItems: [7, "getContextMenuItems"], enableRangeSelection: [8, "enableRangeSelection"], enableFillHandle: [9, "enableFillHandle"], allowContextMenuWithControlKey: [10, "allowContextMenuWithControlKey"], animateRows: [11, "animateRows"], rowDragManaged: [12, "rowDragManaged"] }, { rangeSelectionChanged: "rangeSelectionChanged", pasteEnd: "pasteEnd", cellValueChanged: "cellValueChanged", gridReady: "gridReady" }), i1.ɵqud(603979776, 1, { columns: 1 }), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridOptions; var currVal_1 = _co.deliveryTimes; var currVal_2 = _co.columnDefs; var currVal_3 = _co.frameworkComponents; var currVal_4 = _co.gridLocale; var currVal_5 = _co.defaultColDef; var currVal_6 = _co.sideBar; var currVal_7 = _co.getContextMenuItems; var currVal_8 = true; var currVal_9 = true; var currVal_10 = true; var currVal_11 = true; var currVal_12 = true; _ck(_v, 3, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
export function View_DeliveryTimesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delivery-times-settings", [], null, null, null, View_DeliveryTimesComponent_0, RenderType_DeliveryTimesComponent)), i1.ɵdid(1, 114688, null, 0, i4.DeliveryTimesComponent, [i5.GastroPayService, i6.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeliveryTimesComponentNgFactory = i1.ɵccf("app-delivery-times-settings", i4.DeliveryTimesComponent, View_DeliveryTimesComponent_Host_0, {}, {}, []);
export { DeliveryTimesComponentNgFactory as DeliveryTimesComponentNgFactory };
