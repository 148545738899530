<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('')"
  >
    <span class="fas fa-times"></span>
  </button>
</div>
<div class="modal-body">
  <div class="onboarding-content">
    <p>
      <strong [innerHtml]="message"></strong>
    </p>
    <p *ngIf="showInfo">
      {{ 'Common.DeleteText' | translate }}
      <span class="text-danger">{{ 'Common.CanTrevert' | translate }}</span>
    </p>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Abbrechen</button>
  <button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close('ok')">{{ buttonText }}</button>
</div>
