<div [ngStyle]="{ display: loader ? 'block' : 'none' }" style="height: 100%;">
  <div style="position: relative; left: 25%; top: 48%; width: 50%; padding:40px">
    Please Wait...
    <mat-progress-bar mode="indeterminate" [value]="10"></mat-progress-bar>
  </div>
</div>
<div [ngStyle]="{ display: loader ? 'none' : 'block' }" class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">
      <ng-template [ngIf]="!editEventData">{{ 'Events&Tickets.NewEvent.Title' | translate }}</ng-template>
    </h4>
    <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
      <span class="fas fa-times"></span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="eventFormGroup">
      <div class="alert alert-danger borderless" *ngIf="error" style="width: 100%">{{ error }}</div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.EventName' | translate }}</label>
            <input class="form-control" name="name" formControlName="name" type="text" value="" ngbAutofocus />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.Address' | translate }}</label>
            <div class="form-group">
              <input
                matInput
                class="form-control"
                name="name"
                (keypress)="checkForTyping()"
                (keyup)="fetchLocation()"
                formControlName="venue"
                type="text"
                aria-label="address"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let location of locationArray" (click)="selectLocation(location)" [value]="option">
                  {{ location.address }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
        </div>
        <!-- map-wrapper - start -->
        <div class="col-sm-12">
          <div class="form-group">
            <div class="map-wrapper mb-30">
              <!-- section-title - start -->
              <div class="section-title mb-30">
                <label for=""><strong>Ort</strong></label>
              </div>
              <!-- section-title - end -->
              <div id="google-map">
                <div id="googleMaps" class="google-map-container">
                  <agm-map
                    style="height: 300px;"
                    [zoom]="currZoom"
                    [latitude]="latitude"
                    [longitude]="longitude"
                    (mapClick)="mapClicked($event)"
                  >
                    <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                  </agm-map>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- map-wrapper - end -->
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.Description' | translate }}</label>
            <ckeditor formControlName="description" [config]="{ versionCheck: false }"></ckeditor>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!editEventData">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.Tags' | translate }}</label>
            <div class="tagInput-btn">
              <input
                class="form-control tag-input"
                style="float: left;"
                placeholder="{{ 'Events&Tickets.Common.AddTag' | translate }}"
                type="text"
                formControlName="tag"
              />
              <button class="btn btn-primary" (click)="addTag()" type="button">
                <i class="fa fa-plus-circle fa-fw"></i> {{ 'Events&Tickets.Common.AddTag' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div
            class="tags-list"
            [ngStyle]="{ 'margin-bottom': tags && tags.length ? '8px' : 0 }"
            *ngIf="!editEventData"
          >
            <div class="tag" *ngFor="let tag of tags; index as i">
              {{ tag }}&nbsp;
              <button class="btn btn-link" (click)="deleteTag(i)">
                <i class="far fa-trash-alt cancel-icon"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.Picture' | translate }}</label>
            <div>
              <input
                class="file-input form-control"
                style="display:none"
                type="file"
                name="file"
                #fileInput
                (change)="onChange(fileInput.files[0])"
              />
              <div class="input-group">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-secondary browsebtn" (click)="fileInput.click()">
                    {{ 'Events&Tickets.Common.SelectPhoto' | translate }}
                  </button>
                </div>
                <input
                  class="form-control"
                  placeholder="{{ 'Events&Tickets.Common.NoPhoto' | translate }}"
                  [value]="fileName ? fileName : ''"
                  readonly
                />
                <div class="input-group-append" *ngIf="fileName">
                  <button type="button" class="btn btn-danger" (click)="removeFile()" matTooltip="Foto entfernen">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="preview-img" *ngIf="imageUrl">
            <div class="uploaded-list">
              <div class="uploaded-item">
                <img class="img-fluid" [src]="imageUrl" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-sm-12">
        <div class="form-group">
          <label for="">Veranstaltungszeitraum</label>
        </div>
      </div>
      <div class="row" *ngIf="!editEventData">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.StartDate' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="startDateFromPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <mat-datepicker #startDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
              <input
                class="form-control"
                #dateFromInput
                formControlName="startsAtDay"
                placeholder=""
                type="text"
                name="date"
                [min]="startDate"
                [matDatepicker]="startDateFromPicker"
                (focus)="startDateFromPicker.open()"
                required
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6" *ngIf="!editEventData">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.Time' | translate }}</label>
            <ng-select
              [searchable]="false"
              [items]="times"
              [clearable]="false"
              formControlName="startsAtHour"
              bindLabel="time"
              bindValue="time"
            >
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!editEventData">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.EndDate' | translate }} (optional)</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="endDateFromPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <mat-datepicker #endDateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
              <input
                class="form-control"
                #dateFromInput
                formControlName="endsAtDay"
                placeholder=""
                type="text"
                name="date"
                [matDatepicker]="endDateFromPicker"
                (focus)="endDateFromPicker.open()"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6" *ngIf="!editEventData">
          <div class="form-group">
            <label for="">{{ 'Events&Tickets.Common.Time' | translate }} (optional)</label>
            <ng-select
              [searchable]="false"
              [items]="times"
              [clearable]="false"
              formControlName="endsAtHour"
              bindLabel="time"
              bindValue="time"
            >
            </ng-select>
          </div>
        </div>
      </div>

      <div class="alert alert-danger" *ngIf="eventFormGroup.errors && eventFormGroup.errors.dates">
        {{ eventFormGroup.errors?.dates }}
      </div> -->

      <div class="footer-btns">
        <mat-progress-bar *ngIf="false" mode="determinate" [value]="progress"></mat-progress-bar>
        <button class="btn btn-primary" (click)="add()" *ngIf="!editEventData">
          {{ 'Events&Tickets.NewEvent.AddEvent' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
