import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class GoogleCloudTranslateService {
    constructor(http) {
        this.http = http;
        this.API_KEY = 'AIzaSyCCtqoiEBklEYVOCIawFD-R3HkzC30fqAg';
        this.API_URL = 'https://translation.googleapis.com/language/translate/v2?key=';
    }
    translateData(data, targetLanguage, properties) {
        console.log('data ==>', data);
        const requestData = {
            q: [],
            target: targetLanguage
        };
        // Extract texts from specified properties
        properties.forEach(prop => {
            console.log('prop ===>', prop);
            console.log('data.length ===>', data.length);
            if (data.length > 0) {
                data.forEach(item => {
                    console.log('item ===>', item);
                    if (item[prop]) {
                        requestData.q.push(item[prop]);
                    }
                });
            }
            else {
                if (data[prop]) {
                    requestData.q.push(data[prop]);
                }
            }
        });
        return this.http.post(this.API_URL + this.API_KEY, requestData);
    }
    translateDataForNestedArray(data, targetLanguage) {
        const requestData = {
            q: data,
            target: targetLanguage
        };
        return this.http.post(this.API_URL + this.API_KEY, requestData);
    }
}
GoogleCloudTranslateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleCloudTranslateService_Factory() { return new GoogleCloudTranslateService(i0.ɵɵinject(i1.HttpClient)); }, token: GoogleCloudTranslateService, providedIn: "root" });
