<div class="alert alert-info" *ngIf="!eventDates || !eventDates.length" style="margin:0">
  {{ 'Events&Tickets.EventDates.NoAppointmentsFound' | translate }}
</div>
<div class="shifts ngx-datatable-template" *ngIf="eventDates && eventDates.length">
  <div class="desktop-only">
    <ngx-datatable
      #myTable
      class="material striped expandable"
      [rows]="eventDates"
      [columnMode]="'force'"
      [limit]="tableLimit"
      [sorts]="[{ prop: 'lastVisit', dir: 'desc' }]"
      [scrollbarH]="false"
      [rowHeight]="'100%'"
      [cssClasses]="{
        pagerLeftArrow: 'fas fa-backward',
        pagerRightArrow: 'fas fa-forward',
        pagerPrevious: 'fas fa-step-backward',
        pagerNext: 'fas fa-step-forward'
      }"
    >
      <ngx-datatable-row-detail [rowHeight]="auto" #myDetailRow (toggle)="onDetailToggle($event)">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div class="ticket-list">
            <ul *ngIf="row.ticketDates && row.ticketDates.length">
              <li *ngFor="let ticket of row.ticketDates">
                <div class="ticket-name">
                  <label>{{ 'Events&Tickets.Common.TicketName' | translate }}</label>
                  <span class="value">{{ ticket.tickets.name }}</span>
                </div>
                <div class="remaining-tickets">
                  <label>{{ 'Events&Tickets.Tickets.RemainingTickets' | translate }}</label>
                  <span class="value">{{ ticket.remainingTickets }}</span>
                </div>
                <div class="actions">
                  <button class="btn btn-light pdf-btn" *ngIf="!ticket.loader" (click)="getPreview(ticket, row)">
                    <i class="fa fa-file-pdf"></i>
                  </button>
                  <button class="btn btn-light pdf-btn" style="pointer-events: none;" *ngIf="ticket.loader">
                    <i class="fa fa-spinner fa-spin"></i>
                  </button>
                  <div class="shift-options" ngbDropdown>
                    <mat-icon svgIcon="settings" class="setting-btn" ngbDropdownToggle id="shiftDropdown"></mat-icon>
                    <div ngbDropdownMenu aria-labelledby="shiftDropdown">
                      <button ngbDropdownItem (click)="editDateTicket(ticket, row)">
                        {{ 'Events&Tickets.EventDates.UpdateRemainingSeats' | translate }}
                      </button>
                      <button ngbDropdownItem (click)="removeDateTicket(ticket, row)">
                        {{ 'Events&Tickets.EventDates.DeleteTicket' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul *ngIf="row.ticketDates && !row.ticketDates.length">
              <li>{{ 'Events&Tickets.Tickets.NoTicketsFound' | translate }}</li>
            </ul>
            <ul *ngIf="!row.ticketDates">
              <li>{{ 'Events&Tickets.Tickets.NoTicketsFound' | translate }}</li>
            </ul>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>
      <ngx-datatable-column
        [width]="30"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
      >
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <a
            href="javascript:void(0)"
            [class.datatable-icon-down]="expanded"
            title="Expand/Collapse Row"
            (click)="toggleExpandRow(row)"
          >
            <i class="fas fa-chevron-right"></i>
          </a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ 'Events&Tickets.Common.StartDate' | translate }}" [width]="200">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.startsAtDay | date: 'dd.MM.yyyy' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ 'Events&Tickets.Common.Time' | translate }}" [width]="150">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          {{ row.startsAtHour }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ 'Events&Tickets.Common.EndDate' | translate }}" [width]="200">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.endsAtDay | date: 'dd.MM.yyyy' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ 'Events&Tickets.Common.Time' | translate }}" [width]="150">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.endsAtHour }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="" [width]="auto">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="shift-options" ngbDropdown>
            <button class="btn btn-light view-btn" (click)="preview(row.eventId, row.id)">
              <i class="fas fa-eye"></i></button
            >&nbsp;
            <mat-icon svgIcon="settings" class="setting-btn" ngbDropdownToggle id="shiftDropdown"></mat-icon>
            <div ngbDropdownMenu aria-labelledby="shiftDropdown">
              <button ngbDropdownItem (click)="assignTicket(row)">
                {{ 'Events&Tickets.EventDates.LinkTicket' | translate }}
              </button>
              <button ngbDropdownItem (click)="updateDate(row, event)">
                {{ 'Events&Tickets.EventDates.EditAppointment' | translate }}
              </button>
              <button ngbDropdownItem (click)="removeEventDate(row)">
                {{ 'Events&Tickets.EventDates.DeleteAppointment' | translate }}
              </button>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

  <div class="mobile-only">
    <ngx-datatable
      #myTableMobile
      class="material striped expandable"
      [rows]="eventDates"
      [columnMode]="'force'"
      [limit]="tableLimit"
      [sorts]="[{ prop: 'lastVisit', dir: 'desc' }]"
      [scrollbarH]="false"
      [rowHeight]="'100%'"
      [cssClasses]="{
        pagerLeftArrow: 'fas fa-backward',
        pagerRightArrow: 'fas fa-forward',
        pagerPrevious: 'fas fa-step-backward',
        pagerNext: 'fas fa-step-forward'
      }"
    >
      <ngx-datatable-row-detail [rowHeight]="auto" #myDetailRow (toggle)="onDetailToggle($event)">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <ul class="Moreinfo">
            <li>
              <label>{{ 'Events&Tickets.Common.Time' | translate }}</label>
              <strong>{{ row.startsAtHour }}</strong>
            </li>
            <li>
              <label>{{ 'Events&Tickets.Common.EndDate' | translate }}</label>
              <strong class="value"> {{ row.endsAtDay | date: 'dd.MM.yyyy' }}</strong>
            </li>
            <li>
              <label>{{ 'Events&Tickets.Common.Time' | translate }}</label>
              <strong class="value"> {{ row.endsAtHour }}</strong>
            </li>
          </ul>
          <div class="ticket-list">
            <ul *ngIf="row.ticketDates && row.ticketDates.length">
              <li *ngFor="let ticket of row.ticketDates">
                <div class="ticket-name">
                  <label>{{ 'Events&Tickets.Common.TicketName' | translate }}</label>
                  <span class="value">{{ ticket.tickets.name }}</span>
                </div>
                <div class="remaining-tickets">
                  <label>{{ 'Events&Tickets.Tickets.RemainingTickets' | translate }}</label>
                  <span class="value">{{ ticket.remainingTickets }}</span>
                </div>
                <div class="actions">
                  <button class="btn btn-light pdf-btn" (click)="getPreview(ticket, row)">
                    <i class="fa fa-file-pdf"></i>
                  </button>
                  <div class="shift-options" ngbDropdown>
                    <mat-icon svgIcon="settings" class="setting-btn" ngbDropdownToggle id="shiftDropdown"></mat-icon>
                    <div ngbDropdownMenu aria-labelledby="shiftDropdown">
                      <button ngbDropdownItem (click)="editDateTicket(ticket, row)">
                        {{ 'EventList.Edit' | translate }}
                      </button>
                      <button ngbDropdownItem (click)="removeDateTicket(ticket, row)">
                        {{ 'EventList.TicketName' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul *ngIf="row.ticketDates && !row.ticketDates.length">
              <li>{{ 'Events&Tickets.Tickets.NoTicketsFound' | translate }}</li>
            </ul>
            <ul *ngIf="!row.ticketDates">
              <li>{{ 'Events&Tickets.Tickets.NoTicketsFound' | translate }}</li>
            </ul>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>
      <ngx-datatable-column
        [width]="30"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
      >
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <a
            href="javascript:void(0)"
            [class.datatable-icon-down]="expanded"
            title="Expand/Collapse Row"
            (click)="toggleExpandRow2(row)"
          >
            <i class="fas fa-chevron-right"></i>
          </a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ 'EventList.StartDate' | translate }}" [width]="120">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.startsAtDay | date: 'dd.MM.yyyy' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="" [width]="auto">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="shift-options" ngbDropdown>
            <button class="btn btn-light view-btn" (click)="preview(row.eventId, row.id)">
              <i class="fas fa-eye"></i>
            </button>
            <mat-icon svgIcon="settings" class="setting-btn" ngbDropdownToggle id="shiftDropdown"></mat-icon>
            <div ngbDropdownMenu aria-labelledby="shiftDropdown">
              <button ngbDropdownItem (click)="assignTicket(row)">
                {{ 'Events&Tickets.EventDates.LinkTicket' | translate }}
              </button>
              <button ngbDropdownItem (click)="updateDate(row, event)">
                {{ 'Events&Tickets.EventDates.EditAppointment' | translate }}
              </button>
              <button ngbDropdownItem (click)="removeEventDate(row)">
                {{ 'Events&Tickets.EventDates.DeleteAppointment' | translate }}
              </button>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
