<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="element-box">
    <div class="col-sm-9" style="margin: 0 auto">
      <form [formGroup]="masterDataForm">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="name">Name der Gastro*</label>
              <input class="form-control" formControlName="name" placeholder="Name der Gastro" type="text" />
              <small
                [hidden]="masterDataForm.controls.name.valid || masterDataForm.controls.name.untouched"
                class="text-danger"
                translate
                >Name der Gastro ist ungültig.</small
              >
            </div>
            <div class="form-group">
              <label for="name">Interner Betriebsname/Arbeitsname</label>
              <input class="form-control" formControlName="internalName" placeholder="Name der Gastro" type="text" />
              <small
                [hidden]="masterDataForm.controls.internalName.valid || masterDataForm.controls.internalName.untouched"
                class="text-danger"
                translate
                >Interner Betriebsname/Arbeitsname ist ungültig.</small
              >
            </div>
            <div class="form-group">
              <label for="Straße">Straße*</label>
              <input
                class="form-control"
                formControlName="street"
                placeholder="Straße"
                type="text"
                (keypress)="checkForTyping()"
                (keyup)="fetchLatLng()"
              />
              <small
                [hidden]="masterDataForm.controls.street.valid || masterDataForm.controls.street.untouched"
                class="text-danger"
                translate
              >
                Straße ist ungültig.
              </small>
            </div>
            <div class="form-group">
              <label for="PLZ">PLZ*</label>
              <input
                class="form-control"
                formControlName="zipCode"
                placeholder="PLZ"
                (keypress)="checkForTyping()"
                (keyup)="fetchLocation()"
                type="text"
                aria-label="PLZ*"
                [matAutocomplete]="auto"
              />
              <small
                [hidden]="masterDataForm.controls.zipCode.valid || masterDataForm.controls.zipCode.untouched"
                class="text-danger"
                translate
              >
                PLZ ist ungültig.
              </small>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                  *ngFor="let location of locationArray"
                  (click)="selectZipCode(location)"
                  [value]="location.name"
                >
                  {{ location.zips }}&nbsp;{{ location.name }}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="form-group">
              <label for="Ort">Ort</label>
              <input class="form-control" formControlName="place" placeholder="Ort" type="text" />
              <small
                [hidden]="masterDataForm.controls.place.valid || masterDataForm.controls.place.untouched"
                class="text-danger"
                translate
              >
                Ort ist ungültig.
              </small>
            </div>
            <div class="form-group">
              <label for="Stadtteil">Stadtteil</label>
              <select
                class="form-control"
                formControlName="district"
                (change)="changeDistrict($event.target.value)"
                *ngIf="districtArray && districtArray.length"
              >
                <option value="">Select District</option>
                <option *ngFor="let district of districtArray" value="{{ district.name }}">{{ district.name }}</option>
              </select>
              <input
                *ngIf="!districtArray.length"
                class="form-control"
                formControlName="district"
                placeholder="Stadtteil"
                type="text"
                disabled
              />
              <small
                [hidden]="masterDataForm.controls.district.valid || masterDataForm.controls.district.untouched"
                class="text-danger"
                translate
              >
                Stadtteil ist ungültig.
              </small>
            </div>
          </div>
          <div class="col-sm-6">
            <div id="map" class="map" style="width: 100%; height: 100%;"></div>
            <div
              *ngIf="newLocationPopup"
              style="position: absolute; border-radius: 2px; padding: 8px 8px 8px 10px; right:3%; top: 2%; background-color: #fff;"
            >
              Do you want to set new location ?
              <button class="btn btn-primary" (click)="setNewLocation()">
                Yes
              </button>
              <button class="btn btn-secondary" (click)="cancel()">
                No
              </button>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="Telefon">Telefon*</label>
          <input class="form-control" formControlName="telefon" placeholder="Telefon" type="text" />
          <small
            [hidden]="masterDataForm.controls.telefon.valid || masterDataForm.controls.telefon.untouched"
            class="text-danger"
            translate
            >Telefon ist ungültig.</small
          >
        </div>
        <div class="form-group">
          <label for="E-Mail Adresse">E-Mail Adresse</label>
          <input
            class="form-control"
            formControlName="email"
            placeholder="Geben Sie Ihre E-Mail Adresse ein"
            type="text"
          />
          <small
            [hidden]="masterDataForm.controls.email.valid || masterDataForm.controls.email.untouched"
            class="text-danger"
            translate
          >
            E-Mail Adresse ist ungültig.
          </small>
        </div>
        <div class="form-group">
          <label for="Webseite">Webseite</label>
          <input class="form-control" formControlName="homepage" placeholder="https://sometext.com" type="text" />
          <small
            [hidden]="masterDataForm.controls.homepage.valid || masterDataForm.controls.homepage.untouched"
            class="text-danger"
            translate
          >
            Webseite ist ungültig.
          </small>
        </div>
        <div class="form-group">
          <label for="Stadtteil">Eröffnungsdatum</label>
          <div class="input-group">
            <div class="input-group-prepend" (click)="establishedAtPicker.open()">
              <div class="input-group-text">
                <i class="fas fa-calendar-alt"></i>
              </div>
            </div>
            <mat-datepicker #establishedAtPicker (closed)="dateFromInput.blur()"></mat-datepicker>
            <input
              class="form-control"
              #dateFromInput
              formControlName="establishedAt"
              placeholder=""
              type="text"
              [matDatepicker]="establishedAtPicker"
              (focus)="establishedAtPicker.open()"
            />
          </div>
          <small
            [hidden]="masterDataForm.controls.establishedAt.valid || masterDataForm.controls.establishedAt.untouched"
            class="text-danger"
            translate
          >
            Eröffnungsdatum ist ungültig.
          </small>
        </div>
        <div class="form-group">
          <label for="Telefon">Kurzbeschreibung</label>
          <textarea class="form-control" formControlName="shortDescription" placeholder="Kurzbeschreibung"></textarea>
          <small
            [hidden]="
              masterDataForm.controls.shortDescription.valid || masterDataForm.controls.shortDescription.untouched
            "
            class="text-danger"
            translate
            >Kurzbeschreibung ist ungültig.</small
          >
        </div>
        <!-- <div class="text-danger" translate>{{ masterDataMessage }}</div> -->
        <div class="buttons-w">
          <button class="btn btn-primary" (click)="submitContactData()">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>
