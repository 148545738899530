<!-- <div class="container" style="border: 0px solid green; padding: 15px;">
  
</div>
<div class="container">
  <h3></h3>
</div> -->
<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-md-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Hotelreservierungen
        </h6>
        <div class="element-box">
          <div class="row">
            <div class="col-md-12">
              <!-- This PDF component is placed here for testing purposes -->
              <app-generate-pdf-reservation></app-generate-pdf-reservation>
            </div>
          </div>
          <div class="mt-5" [formGroup]="reservationFormGroup">
            <div class="container">
              <div class="row">
                <div class="form-group col-md-4">
                  <label for="">Name</label>
                  <input
                    class="form-control"
                    formControlName="name"
                    type="text"
                    [matAutocomplete]="nameAuto"
                    autocomplete="nope"
                    placeholder="Name eingeben"
                  />
                  <mat-autocomplete #nameAuto="matAutocomplete" [displayWith]="displayFnName.bind(this)">
                    <mat-option *ngFor="let guest of filteredGuests" [value]="guest">
                      <span class="title">{{ guest.name }}</span>
                      <br />
                      <small><i class="fas fa-envelope fa-fw"></i> {{ guest.email ? guest.email : '-' }}</small>
                      <br />
                      <small><i class="fas fa-phone fa-fw"></i> {{ guest.phone ? guest.phone : '-' }}</small>
                    </mat-option>
                  </mat-autocomplete>
                </div>

                <div class="form-group col-md-2">
                  <label for="">Zimmernummer</label>
                  <input
                    class="form-control"
                    formControlName="roomNumber"
                    type="number"
                    autocomplete="nope"
                    placeholder="Zimmernummer"
                  />
                </div>

                <div class="form-group col-md-2">
                  <label for="">Reservierungsnummer</label>
                  <input
                    class="form-control"
                    formControlName="reservationNumber"
                    type="text"
                    autocomplete="nope"
                    placeholder="Reservierungsnummer"
                  />
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Anreise</label>
                    <div class="input-group">
                      <div class="input-group-prepend" (click)="arrivalDatePicker.open()">
                        <div class="input-group-text">
                          <i class="fas fa-calendar-alt"></i>
                        </div>
                      </div>
                      <mat-datepicker #arrivalDatePicker (closed)="dateFromInput1.blur()"></mat-datepicker>
                      <input
                        class="form-control"
                        #dateFromInput1
                        formControlName="arrivalDate"
                        placeholder="Datum auswählen"
                        type="text"
                        name="arrivalDate"
                        [min]="minArrivalDate"
                        [matDatepicker]="arrivalDatePicker"
                        (focus)="arrivalDatePicker.open()"
                        (dateChange)="checkIsSelectedTimeIntervalValid()"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Abreise</label>
                    <div class="input-group">
                      <div class="input-group-prepend" (click)="departureDatePicker.open()">
                        <div class="input-group-text">
                          <i class="fas fa-calendar-alt"></i>
                        </div>
                      </div>
                      <mat-datepicker #departureDatePicker (closed)="dateFromInput2.blur()"></mat-datepicker>
                      <input
                        class="form-control"
                        #dateFromInput2
                        formControlName="departureDate"
                        placeholder="Datum auswählen"
                        type="text"
                        [min]="minDepartureDate"
                        name="departureDate"
                        [matDatepicker]="departureDatePicker"
                        (focus)="departureDatePicker.open()"
                        (dateChange)="checkIsSelectedTimeIntervalValid()"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Aufenthaltsdauer</label>
                    <ng-select
                      [searchable]="false"
                      [clearable]="false"
                      formControlName="stayTime"
                      placeholder="Aufenthaltsdauer"
                    >
                      <ng-option *ngFor="let st of stayTimes" [value]="st">{{ st?.name }}</ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <div class="form-group">
                      <label for="">Tags</label>
                      <ng-select
                        [items]="tags"
                        [addTag]="false"
                        [hideSelected]="true"
                        multiple="true"
                        bindLabel="label"
                        formControlName="selectedTags"
                        placeholder="Tags auswählen"
                      >
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label">
                            <i class="fas fa-circle" [style.color]="item.color"></i> {{ item.label }}
                          </span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>

                        <ng-template ng-option-tmp let-item="item">
                          <i class="fas fa-circle" [style.color]="item.color"></i> {{ item.label }}
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Anzahl Personen</label>
                    <ng-select
                      [searchable]="false"
                      (change)="onNumberOfPersonsChange()"
                      [clearable]="false"
                      formControlName="numberOfPersons"
                      required
                    >
                      <ng-option *ngFor="let person of createNumValues(20); let i = index" value="{{ i + 1 }}">
                        {{ i + 1 }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Davon Kinder</label>
                    <ng-select [searchable]="false" [clearable]="false" formControlName="numberOfKids" required>
                      <ng-option *ngFor="let person of createNumValues(21); let i = index" value="{{ i }}">{{
                        i
                      }}</ng-option>
                    </ng-select>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Anzahl Hochstühle</label>
                    <ng-select [searchable]="false" [clearable]="false" formControlName="numberOfHighChairs" required>
                      <ng-option *ngFor="let person of createNumValues(21); let i = index" value="{{ i }}">
                        {{ i }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Unverträglichkeiten</label>
                    <ng-select
                      multiple="true"
                      [searchable]="false"
                      [clearable]="false"
                      formControlName="incompatibilities"
                      placeholder="Wählen Sie Intoleranz"
                    >
                      <ng-option *ngFor="let intolerance of incompatibilities" value="{{ intolerance.id }}">
                        {{ intolerance.longName }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label for="">Freifeld für weitere Hinweise</label>
                  <textarea
                    class="form-control text-area-section"
                    rows="4"
                    formControlName="notes"
                    placeholder="Hinterlegen Sie Notizen zu dieser Reservierung."
                  ></textarea>
                </div>
              </div>

              <div class="row" *ngIf="reservationForm.controls.length > 0">
                <div class="col-md-12 table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Datum</th>
                        <th scope="col">Essen</th>
                        <th scope="col">Schicht</th>
                        <th scope="col">Restaurant</th>
                        <th scope="col">Zeit</th>
                        <th scope="col">Tisch</th>
                      </tr>
                    </thead>
                    <tbody
                      class="form-group"
                      formArrayName="reservations"
                      *ngFor="let reservation of reservationForm.controls; let i = index"
                    >
                      <tr formGroupName="{{ i }}">
                        <td style="width: 5%;">{{ i + 1 }}</td>
                        <td style="width: 20%;">
                          <input
                            class="form-control"
                            disabled
                            value="{{ reservation.value.date | date }}"
                            type="text"
                            autocomplete="nope"
                            style="width: 120px;"
                          />
                        </td>
                        <td style="width: 20%;">{{ i % 2 == '0' ? 'Abendessen' : 'Frühstück' }}</td>
                        <td class="form-group" style="width: 20%;">
                          <!-- <ng-select [searchable]="false" [clearable]="false" formControlName="time" placeholder="Select time">
                            <ng-option *ngFor="let time of times" value="{{ time }}">{{ time }}:00 Uhr</ng-option>
                          </ng-select> -->
                          <ng-select
                            [searchable]="false"
                            [clearable]="false"
                            formControlName="shift"
                            placeholder="Uhrzeit auswählen"
                            (change)="onTimeShiftSelect($event, i)"
                          >
                            <ng-option *ngFor="let shift of reservation.value.shifts" value="{{ shift.id }}">
                              {{ shift.name }}
                            </ng-option>
                          </ng-select>
                        </td>
                        <td class="form-group" style="width: 15%;">
                          <ng-select
                            [searchable]="false"
                            [clearable]="false"
                            (change)="onRestaurantSelect($event, i)"
                            formControlName="restaurant"
                            placeholder="Restaurant auswählen"
                          >
                            <ng-option
                              *ngFor="let restaurant of reservation.value.listOfRestaurants"
                              value="{{ restaurant.id }}"
                            >
                              {{ restaurant.name }}
                            </ng-option>
                          </ng-select>
                        </td>
                        <td class="form-group" style="width: 10%;">
                          <ng-select
                            [searchable]="false"
                            [clearable]="false"
                            formControlName="time"
                            placeholder="Zeit auswählen"
                            (change)="onTimeSelect($event, i)"
                          >
                            <ng-option *ngFor="let time of reservation.value.listOfTimes" value="{{ time }}">
                              {{ time }} Uhr
                            </ng-option>
                          </ng-select>
                        </td>
                        <td class="form-group" style="width: 10%;">
                          <ng-select
                            [items]="reservation.value.listOfTables"
                            [searchable]="false"
                            [clearable]="false"
                            formControlName="table"
                            placeholder="Tisch auswählen"
                          >
                            {{ item | json }}
                            <ng-template ng-label-tmp let-item="item">
                              {{ item.table.name }}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="i">
                              {{ item.table.name }}<br />
                              <span
                                class="badge badge-default"
                                [ngStyle]="{ color: item.disabled ? 'red' : 'green', opacity: 0.5 }"
                              >
                                {{ item.msg }}
                              </span>
                            </ng-template>
                          </ng-select>
                        </td>
                      </tr>
                      <tr class="table-is-not-free-error" *ngIf="reservation.value.errorMessage">
                        <td class="error-tr-td"></td>
                        <td class="error-tr-td"></td>
                        <td class="error-tr-td"></td>
                        <td colspan="3" class="error-tr-td">{{ reservation.value.errorMessage }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button class="btn btn-primary btn-lg btn-block save-button" (click)="createReservation()">
                  Reservierung erstellen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
