<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Ab dem 1.11.2022 können wir den Versand von SMS leider nicht mehr gratis anbieten. Mehr Informationen in
          unserem Blog.
        </h6>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <mat-slide-toggle
                name="smsConfirmationSettings"
                (change)="saveSMSSettings()"
                [(ngModel)]="smsConfirmationSettings.sendSMS"
              >
                Ja, ich möchte den sms-service ab dem 1.11.2022 weiterhin nutzen. Mir ist bekannt, dass dieser dann
                kostenpflichtig ist.
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
