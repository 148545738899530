<div class="color-picker">
  <div (click)="toggleColors()" matTooltip="Farbe auswählen">
    <div class="circle" [ngStyle]="{ background: color }"></div>
  </div>

  <div *ngIf="show" class="opened">
    <div class="colors">
      <div
        (click)="changeColor(paint)"
        *ngFor="let paint of defaultColors"
        class="circle"
        [ngStyle]="{ background: paint }"
      ></div>
    </div>
  </div>
</div>
