import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { RecaptchaV3CustomService } from './recaptcha-v3.service';

@Component({
  selector: 'app-recaptcha-v3',
  template: ''
})
export class RecaptchaV3Component implements OnInit {
  @Output() tokenGenerated = new EventEmitter<string>();

  constructor(private recaptchaService: RecaptchaV3CustomService) {}

  ngOnInit() {
    // Automatically generate the token on component initialization
    this.generateToken('yourAction');
  }

  generateToken(action: string) {
    this.recaptchaService.execute(action).then(
      (token: string) => {
        this.tokenGenerated.emit(token); // Emit the token automatically
      },
      error => {
        console.error('reCAPTCHA error:', error); // Handle error
      }
    );
  }
}
