<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="element-box">
    <div class="col-sm-9" style="margin: 0 auto">
      <h5 class="form-header">Gastronomietyp</h5>
      <form [formGroup]="restaurantForm">
        <!-- <div class="alert alert-outline-primary" style="background-color: #e1f0f7; border: 1px solid #5BC0DE;">
					<p style="color: #525459;">
						<font style="vertical-align: inherit;">
							<font style="vertical-align: inherit;"
								><b>Info:</b> Sie haben {{ cateringTypeArray.length }} von 3 möglichen Gastronomietypen ausgewählt</font>
						</font>
					</p>
				</div> -->

        <div class="form-group">
          <label for="cateringType">Bitte Gastronomie-Typen wählen: {{ cateringTypeArray.length }}/3</label>
          <div class="row">
            <ng-select
              class="col-sm-12"
              [items]="restaurantTypeArray"
              [multiple]="true"
              [closeOnSelect]="false"
              [hideSelected]="true"
              bindLabel="name"
              placeholder="Restaurant"
              [maxSelectedItems]="3"
              (change)="selectedCatType()"
              appendTo="body"
              formControlName="cateringType"
            >
            </ng-select>
          </div>
        </div>

        <h5 class="form-header">Angebot</h5>

        <!-- <div class="alert alert-outline-primary" style="background-color: #e1f0f7; border: 1px solid #5BC0DE;">
					<p style="color: #525459;">
						<font style="vertical-align: inherit;">
							<font style="vertical-align: inherit;">
								<b>Info:</b> Sie haben {{ offerArrayList.length }} von 5 möglichen Angebotsitems ausgewählt</font>
						</font>
					</p>
				</div> -->

        <div class="form-group">
          <label for="offerType">Bitte Angebots-Typen wählen: {{ offerArrayList.length }}/5</label>
          <div class="row">
            <ng-select
              class="col-sm-12"
              [items]="offerTypeArray"
              [multiple]="true"
              [closeOnSelect]="false"
              [hideSelected]="true"
              bindLabel="name"
              placeholder="Brunch"
              [maxSelectedItems]="5"
              (change)="selectedOfferType()"
              appendTo="body"
              formControlName="offerType"
            >
            </ng-select>
          </div>
        </div>
        <div class="text-danger" translate>{{ restaurantFormMessage }}</div>
        <div class="buttons-w">
          <button class="btn btn-primary" (click)="save()">Save</button>
          <!-- <button class="btn btn-secondary" (click)="cancel()">Cancel</button> -->
        </div>
      </form>
    </div>
  </div>
</div>
