<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editData">Sonderzeit hinzufügen</ng-template>
      <ng-template [ngIf]="editData">Sonderzeit bearbeiten</ng-template>
    </h4>
    <form [formGroup]="specialGastroTimesGroup">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">Datum</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="dateFromPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <input
                class="form-control"
                #dateFromInput
                placeholder=""
                type="text"
                name="alert"
                formControlName="day"
                [matDatepicker]="dateFromPicker"
                (focus)="dateFromPicker.open()"
                required
              />
              <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
            </div>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <label for="">Von</label>
            <ng-select
              [searchable]="false"
              [items]="times"
              placeholder="Uhrzeit"
              [clearable]="false"
              formControlName="from"
            >
            </ng-select>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <label for="">Bis</label>
            <ng-select
              [searchable]="false"
              [items]="times"
              placeholder="Uhrzeit"
              [clearable]="false"
              formControlName="to"
            >
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <mat-slide-toggle name="takeaway" formControlName="takeaway">
              Abholung
            </mat-slide-toggle>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group">
            <mat-slide-toggle name="delivery" formControlName="delivery">
              Lieferung
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">Bestell-Limits wie</label>
            <ng-select [searchable]="false" [clearable]="false" formControlName="limitDayTemplate">
              <ng-option [value]="day.value" *ngFor="let day of finalDayLimit">{{ day.label }}</ng-option>
            </ng-select>
          </div>
        </div>
      </div>

      <button class="btn btn-primary btn-block btn-lg" (click)="add()">Speichern</button>
    </form>
  </div>
</div>
