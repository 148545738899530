<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Allgemeine Einstellungen
          <div class="element-actions"></div>
        </h6>
        <div class="element-box">
          <div class="row">
            <div class="col-md-8 col-sm-12">Möchten Sie Ihre Gäste mit "Du" oder "Sie" ansprechen?</div>
            <div class="col-md-3 col-sm-12">
              <mat-select
                [(ngModel)]="addressGuest"
                [placeholder]="addressGuest"
                (selectionChange)="prepareChartData($event)"
              >
                <mat-option *ngFor="let option of selectOptions" [value]="option.value">{{ option.label }}</mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
