<div
  class="row incoming-reservations"
  [ngStyle]="{ 'fontSize.px': sizeFactor > 1 ? 12 * sizeFactor : 12, 'height.%': 100 * sizeFactor }"
>
  <div *ngIf="listOfTableReservations.length > 0" class="col-md-12">
    <div
      *ngFor="let data of listOfTableReservations; let i = index"
      [popoverTitle]="popTitle"
      [ngbPopover]="popContent"
      placement="right"
      container="body"
      [disablePopover]="isDraging"
      id="res{{ i }}{{ data.tableId }}"
    >
      <dx-context-menu
        [items]="statusArray"
        [width]="200"
        target="#res{{ i }}{{ data.tableId }}"
        (onItemClick)="statusItemClick($event, data)"
        (onShowing)="refreshStatusOfMenuItems(data, i)"
        showEvent="contextmenu"
      >
        <div
          *dxTemplate="let data of 'item'; let i = index"
          [ngStyle]="data.isActive && { color: 'white', background: '#009688' }"
        >
          <div *ngIf="data.color">
            <div class="item-badge" [ngStyle]="{ backgroundColor: data.color }"></div>
          </div>
          <div style="margin-left:5px">{{ data.text }}</div>
        </div>
      </dx-context-menu>
      <ng-template #popContent>
        <div class="row">
          <div class="col-md-6">{{ 'tablePlan.status' | translate }}</div>
          <div class="col-md-6">
            <div class="item-badge" [ngStyle]="{ backgroundColor: data.popoverData.status.color }"></div>

            <b>{{ data.popoverData.status.name }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">{{ 'tablePlan.guests' | translate }}</div>
          <div class="col-md-6">
            <b>{{ data.popoverData.numberOfPersons }} {{ 'tablePlan.persons' | translate }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">{{ 'tablePlan.stayTime' | translate }}</div>
          <div class="col-md-6">
            <b>{{ data.popoverData.stayTime }} {{ 'tablePlan.minutes' | translate }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">{{ 'tablePlan.start' | translate }}</div>
          <div class="col-md-6">
            <b>{{ data.popoverData.start | date: 'HH:mm' }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">{{ 'tablePlan.end' | translate }}</div>
          <div class="col-md-6">
            <b>{{ data.popoverData.end | date: 'HH:mm' }}</b>
          </div>
        </div>
      </ng-template>
      <ng-template #popTitle>
        <b>{{ data.guestName }}</b>
      </ng-template>
      <div
        class="row"
        [ngClass]="{ 'selected-row': data.isReservedAtSelectedTime, 'single-row': !data.isReservedAtSelectedTime }"
        (dragstart)="onDragStart(data)"
        (dragend)="onDragEnd()"
        (click)="handleReservationClick(data)"
        draggable="true"
      >
        <div
          class="time"
          [ngClass]="{
            'col-4': zoomSizeFactor * table.dimensions.width > 100,
            'col-12 text-center': table.dimensions.width <= 100
          }"
        >
          {{ data.time | date: 'HH:mm' }}
        </div>
        <div *ngIf="zoomSizeFactor * table.dimensions.width > 100" class="col-8 name text-nowrap pl-1">
          {{ data.guestName }}
        </div>
      </div>
    </div>
  </div>
</div>
