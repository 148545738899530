var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ReservationService } from '../../reservation.service';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
export class updateGuestDataComponent {
    constructor(formBuilder, reservationService, snackBar, activeModal, translate) {
        this.formBuilder = formBuilder;
        this.reservationService = reservationService;
        this.snackBar = snackBar;
        this.activeModal = activeModal;
        this.translate = translate;
        this.passEntry = new EventEmitter();
        this.ngUnsubscribe = new Subject();
        this.guestDataForm = this.formBuilder.group({
            name: [],
            firstName: ['']
        });
        this.loadTranslation();
        this.translate.onLangChange.subscribe((event) => {
            this.loadTranslation();
        });
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.guestDataForm.addControl('id', new FormControl(this.guestData.id, Validators.required));
            this.guestDataForm.get('name').setValue(this.guestData.name);
            this.guestDataForm.get('firstName').setValue(this.guestData.firstName);
        });
    }
    loadTranslation() { }
    save() {
        if (!this.guestDataForm.valid) {
            this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        console.log('data', this.guestDataForm.value);
        this.reservationService
            .updateGuestData(this.guestDataForm.value)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response) => {
            this.passEntry.emit(response);
        }, err => {
            this.error = err.error.message;
        });
    }
}
