<div class="modal-content text-center">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'Documents.AddNewDocument' | translate }}</h4>
    <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
      <span class="fas fa-times"></span>
    </button>
  </div>
  <div class="onboarding-content">
    <form>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'Documents.DocumentName' | translate }}</label>
            <input
              [ngStyle]="{ border: !documentName && isClicked ? '1px solid red' : '1px solid #dedede' }"
              class="form-control"
              [(ngModel)]="documentName"
              [ngModelOptions]="{ standalone: true }"
              type="text"
            />
            <small *ngIf="!documentName && isClicked" class="text-danger">{{
              'Documents.IsRequired' | translate
            }}</small>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'Documents.ChooseFile' | translate }}</label>
            <div>
              <input
                class="file-input form-control"
                style="display:none"
                type="file"
                name="file"
                #fileInput
                (change)="onChange(fileInput.files[0])"
              />
              <div class="input-group">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-secondary browsebtn" (click)="fileInput.click()">
                    {{ 'Documents.ChooseFile' | translate }}
                  </button>
                </div>
                <input
                  class="form-control"
                  placeholder="{{ 'Documents.NoFile' | translate }}"
                  [value]="fileName ? fileName : ''"
                  readonly
                  [ngStyle]="{ border: !fileInput.files.length && isClicked ? '1px solid red' : '1px solid #dedede' }"
                />
                <div class="input-group-append" *ngIf="fileName">
                  <button type="button" class="btn btn-danger" (click)="removeFile()" matTooltip="Foto entfernen">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div>{{ 'Documents.ExtMessage' | translate }}</div>
          </div>
        </div>
        <!-- <div class="col-sm-12" *ngIf="fileData">
					<table class="table">
						<thead>
							<td>File Name</td>
							<td>Size</td>
							<td style="text-align: center;">Action</td>
						</thead>
						<tbody>
							<tr>
								<td>{{ fileData.name }}</td>
								<td>{{ fileData.sizeInKB }} KB</td>
								<td style="text-align: center;"><i class="fa fa-trash" style="cursor: pointer;" (click)="removeFile()"></i></td>
							</tr>
						</tbody>
					</table>
				</div> -->
        <div class="col-sm-12">
          <button class="btn btn-primary" (click)="uploadDoc()">
            {{ 'Common.Upload' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
