<div class="row">
  <div class="content-wrap content-box col-xs-12 col-sm-7 col-sm-offset-3">
    <h1>{{ welcomeMsg }},<br />danke für deine Reservierung</h1>
    <br />
    <div class="row">
      <div class="col-md-8">
        <div class="element-wrapper">
          <div class="element-box">
            <h6 class="element-header">Übersicht deiner Reservierung</h6>
            <div>
              <div class="info-block" style="flex: 1 1 1e-09px; box-sizing: border-box;">
                <div>
                  <div class="row">
                    <div class="property">
                      <span>Reserviert für:</span>
                    </div>
                    <div class="definition" *ngIf="reservation">
                      {{ reservation.reservedFor | dfnsFormat: 'dddd, DD. MMM YYYY' }}
                    </div>
                  </div>

                  <div class="row">
                    <div class="property">
                      <span>Uhrzeit:</span>
                    </div>
                    <div class="definition" *ngIf="reservation">
                      {{ reservation.reservedFor | dfnsFormat: 'HH:mm' }} Uhr
                    </div>
                  </div>

                  <div class="row">
                    <div class="property">
                      <span>Anzahl Personen:</span>
                    </div>
                    <div class="definition">{{ reservation?.peopleCount }} Personen</div>
                  </div>

                  <div class="row">
                    <div class="property">
                      <span>Deine Nachricht:</span>
                    </div>
                    <div class="definition">
                      {{ reservation?.msg ? reservation.msg : '-' }}
                    </div>
                  </div>

                  <div class="row">
                    <div class="property">
                      <span>Status</span>
                    </div>
                    <div class="definition" *ngIf="reservation">
                      <span *ngIf="reservation.status === 'confirmed'" class="text-success">
                        <i class="fas fa-check-circle"></i>
                        Bestätigt
                      </span>
                      <span *ngIf="reservation.status === 'arrived'" class="text-secondary">
                        <i class="fas fa-store-alt fa-fw"></i>
                        Angekommen
                      </span>
                      <span *ngIf="reservation.status === 'placed'" class="text-info">
                        <i class="fas fa-chair fa-fw"></i>
                        Platziert
                      </span>
                      <span *ngIf="reservation.status === 'finished'" class="text-success">
                        <i class="fas fa-check-double fa-fw"></i>
                        Fertig
                      </span>
                      <span *ngIf="reservation.status === 'pending'" class="text-warning">
                        <i class="fas fa-minus-circle fa-fw"></i>
                        Ausstehend
                      </span>
                      <span *ngIf="reservation.status === 'canceled'" class="text-danger">
                        <i class="fas fa-ban fa-fw"></i>
                        Storniert
                      </span>
                      <span *ngIf="reservation.status === 'noShow'" class="text-danger">
                        <i class="fas fa-eye-slash fa-fw"></i>
                        No-Show
                      </span>
                      <span *ngIf="reservation.status === 'waiting'" class="text-muted">
                        <i class="fas fa-hourglass-half fa-fw"></i>
                        Warteliste
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row"
                style="color:red; margin-top:20px;"
                *ngIf="
                  reservation &&
                    ((reservation.payments && reservation.payments.status == 'finished') || reservation.ticketOrderId);
                  else other_content
                "
              >
                Dies ist eine Reservierung mit Anzahlung. Für eine Stornierung bitte direkt an den Veranstalter oder das
                Restaurant wenden. Vielen Dank.
              </div>

              <ng-template #other_content>
                <div class="row" style="margin-top:20px;">
                  <button
                    class="btn btn-danger btn-block"
                    (click)="cancelReservation()"
                    *ngIf="reservation && reservation.status !== 'canceled'"
                  >
                    Reservierung stornieren
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="element-wrapper">
          <div class="element-box">
            <h6 class="element-header">Kontakt</h6>
            <div>
              <img
                src="//cdn.gastroguide.de/{{ reservation?.client.logo }}"
                *ngIf="reservation && reservation.client.logo"
                class="client-logo"
              />
              <br />
              <strong>{{ reservation?.client.name }}</strong
              ><br />
              <span>{{ reservation?.client.street }}</span
              ><br />
              <span>{{ reservation?.client.zipCode }} {{ reservation?.client.location }}</span>
              <br /><br />
              <span><i class="fas fa-phone fa-fw"></i> {{ reservation?.client.telefon }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="footer-info">
      Das {{ reservation?.client.name }} nutzt das Online-Reservierungssystem von gastro.digital
    </p>
  </div>
</div>
