
    <input type="file" style="display:none" accept="image/*" (change)="uploadPhoto(file.files)" #file />
    <button
      class="btn btn-light btn-sm"
      (click)="file.click()"
      [disabled]="!params.data.id"
      *ngIf="params && !params.data.photo && !isUploading"
    >
      Upload
    </button>
    <mat-spinner *ngIf="isUploading" diameter="20"></mat-spinner>
    <img
      [src]="params.data.photo"
      *ngIf="params && params.data.photo && !isUploading"
      style="width:30px; height: 30px; border-radius:30px;object-fit: contain;"
      (click)="file.click()"
    />
  