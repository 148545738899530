<div class="order">
  <div class="header-info">
    <div>
      <h4 *ngIf="data.type !== 'inhouse'">{{ data.firstName }} {{ data.lastName }}</h4>
      <h4 *ngIf="data.type === 'inhouse'">Tisch {{ data.tableNr }}</h4>
      <small class="text-muted">
        <span class="badge badge-light">Order #{{ data.id }}</span>
        <span class="badge badge-primary" *ngIf="data.type === 'takeaway'">Abholung</span>
        <span class="badge badge-primary" *ngIf="data.type === 'delivery'">Lieferung</span>
        <span class="badge badge-primary" *ngIf="data.type === 'inhouse'">Inhouse</span>
        <span class="badge badge-danger" *ngIf="data.status === 'canceled'">Storniert</span>
        <span class="badge badge-warning" *ngIf="!data.paymentId">Unbezahlt</span>
        <span class="badge badge-dark" *ngIf="data.deliveryTime"> Lieferzeit: {{ data.deliveryTime }} min. </span>

        <button
          class="btn btn-light"
          (click)="printBon(data.id)"
          *ngIf="finished"
          style="padding: 2px 6px; vertical-align: baseline;"
        >
          <i class="fas fa-receipt fa-fw"></i>
          Bon erneut drucken
        </button>
      </small>
    </div>
    <div class="text-muted" *ngIf="data.createdAt">
      {{ data.createdAt | dfnsFormat: 'DD.MM.YYYY' }} um {{ data.createdAt | dfnsFormat: 'HH:mm' }} Uhr
    </div>
  </div>

  <div fxFlexLayout="row">
    <div fxFlex>
      <label>
        Bestellte Artikel
      </label>
      <ul>
        <li *ngFor="let item of data.orderItems">
          {{ item.quantity }}x {{ item.name }}
          <small *ngIf="item.option" style="font-style:italic">{{ item.option }}</small>
        </li>
      </ul>
    </div>
    <div fxFlex>
      <label>
        Bezahlung
      </label>
      <div>
        <ul>
          <li><b>Gesamtbetrag:</b> {{ +data.subtotal + +data.deliveryFee | number: '1.2' }} €</li>
          <li><b>Zahlungsart:</b> {{ data.paymentId ? data.paymentTransaction?.paymentMethod : 'Bar' }}</li>
          <li>
            <b>Status:</b>
            <span
              [class.text-success]="data.paymentTransaction && data.paymentTransaction.status === 'finished'"
              [class.text-danger]="
                !data.paymentId || !data.paymentTransaction || data.paymentTransaction.status === 'pending'
              "
            >
              {{
                data.paymentTransaction && data.paymentTransaction.status === 'finished'
                  ? 'Vollständig bezahlt'
                  : 'Noch nicht bezahlt'
              }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div fxFlex *ngIf="data.type !== 'inhouse'">
      <label>
        Gewünschte <ng-template [ngIf]="data.type === 'delivery'">Lieferzeit</ng-template>
        <ng-template [ngIf]="data.type === 'takeaway'">Abholung</ng-template>
      </label>
      <div>
        <ng-template [ngIf]="data.desiredTime">
          {{
            data.desiredTime
              | amCalendar
                : {
                    sameDay: '[Heute um] HH:mm [Uhr]',
                    nextDay: '[Morgen um] HH:mm [Uhr]',
                    sameElse: 'DD.MM.YYYY [um] HH:mm [Uhr]'
                  }
          }}
        </ng-template>
        <ng-template [ngIf]="!data.desiredTime">Sofort</ng-template>
      </div>
    </div>
  </div>
  <div fxFlexLayout="row">
    <div fxFlex *ngIf="data.type === 'delivery'">
      <label>
        Lieferadresse
      </label>
      <div>
        {{ data.firstName }} {{ data.lastName }}<br />
        {{ data.street }}<br />
        Etage: {{ data.floor ? data.floor : '' }} <br />
        {{ data.zipCode }} {{ data.city }}<br />
        Tel.: {{ data.phone }}<br />
        E-Mail: {{ data.email }}
        <a
          href="http://maps.google.de/maps?daddr={{ data.street }},{{ data.zipCode }}%20%{{ data.city }}"
          style="text-decoration: underline"
          target="_blank"
          >Google Maps Routenplaner</a
        >
      </div>
    </div>

    <div fxFlex *ngIf="data.type === 'takeaway'">
      <label>
        Gast Daten (Abholung)
      </label>
      <div>
        Tel.: {{ data.phone }}<br />
        E-Mail: {{ data.email }}
      </div>
    </div>

    <div fxFlex>
      <label>
        Gast-Hinweise
      </label>
      <div>
        {{ data.notes ? data.notes : '-' }}
      </div>
    </div>
  </div>

  <div class="button-row" *ngIf="data.status === 'pending'">
    <button class="btn btn-dark" (click)="setDeliveryTime()" *ngIf="!data.deliveryTime">
      <i class="fas fa-check fa-fw"></i>
      Bestellung in Arbeit
    </button>
    <button class="btn btn-success" (click)="changeStatus('confirmed')">
      <i class="fas fa-check-double fa-fw"></i>
      Bestellung abschließen
    </button>
    <button class="btn btn-danger" (click)="changeStatus('canceled')">
      <i class="fas fa-times fa-fw"></i>
      Bestellung stornieren
    </button>
    <button class="btn btn-light" (click)="printBon(data.id)">
      <i class="fas fa-receipt fa-fw"></i>
      Bon erneut drucken
    </button>
  </div>
</div>
