var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy } from '@angular/core';
import { ReservationService } from '../reservation.service';
import { MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/takeUntil';
export class ReservationDemoComponent {
    constructor(reservationService, snackBar, translate, router, activatedRoute) {
        this.reservationService = reservationService;
        this.snackBar = snackBar;
        this.translate = translate;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.ngUnsubscribe = new Subject();
    }
    ngOnInit() { }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    defaultResSystem() {
        this.disableBtn = true;
        this.reservationService
            .defaultResSystem()
            .takeUntil(this.ngUnsubscribe)
            .subscribe(() => {
            this.disableBtn = false;
            this.snackBar.open('Data added successfully.', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
            this.activatedRoute.params.subscribe((parameter) => __awaiter(this, void 0, void 0, function* () {
                const { clientId } = parameter;
                this.router.navigate([`/client/${clientId}/reservation/book`]).then(() => {
                    window.location.reload();
                });
            }));
        }, err => {
            // this.disableBtn = false;
            this.snackBar.open(`Something went wrong! Please contact administrator.`, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
        });
    }
}
