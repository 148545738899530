<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          {{ 'Events&Tickets.Common.SettingsTickets' | translate }}
        </h6>

        <div class="row" *ngIf="settings">
          <div class="col-sm-12 col-md-6">
            <div class="form-group">
              <div
                class="system-setting-div"
                [ngClass]="{ 'toggle-success': settings?.isActive, 'toggle-danger': !settings?.isActive }"
              >
                <mat-slide-toggle
                  name="isActive"
                  (change)="changedSettings(settings?.isActive)"
                  [(ngModel)]="settings.isActive"
                >
                  {{ 'Events&Tickets.Common.ActivateTicketSystem' | translate }}
                  <div class="info">
                    {{ 'Events&Tickets.Common.ActivateTicketSystemInfo' | translate }}
                  </div>
                </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>

        <mat-tab-group animationDuration="0ms">
          <mat-tab label="{{ 'Events&Tickets.Common.Notifications' | translate }}">
            <div class="element-box">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <mat-slide-toggle
                      name="notifyByEmailOption"
                      (change)="changeNotifyEmailOption($event.checked)"
                      [(ngModel)]="notifyByEmailOption"
                    >
                      {{ 'Events&Tickets.Common.EmailConfirmation' | translate }}
                      <div class="info">
                        {{ 'Events&Tickets.Common.EmailConfirmationText' | translate }}
                      </div>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="this.notifyByEmailOption">
                <div class="col-md-12">
                  <div class="form-group" style="margin: 0;">
                    <input
                      class="form-control"
                      type="email"
                      placeholder="E-Mail Adresse"
                      name="notifyByEmail"
                      [(ngModel)]="notifyByEmail"
                    />
                  </div>
                  <div class="ticket-email-error" *ngIf="emailTextError">
                    {{ emailTextError }}
                  </div>
                </div>
              </div>
              <br />
              <button class="btn btn-primary" (click)="saveSettings()">
                <i class="far fa-save"></i> &nbsp; {{ 'Common.Save' | translate }}
              </button>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
