<breadcrumb></breadcrumb>
<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)" [selectedIndex]="tab">
          <mat-tab label="Statistiken">
            <div class="element-box">
              <h6 class="form-header">Gutschein Statistiken</h6>
              <div class="row">
                <div class="col-md-4">
                  <section>
                    <div class="form-group">
                      <label for="">Datum von</label>
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateFromPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                        <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #dateFromInput
                          placeholder=""
                          [(ngModel)]="dates.from"
                          type="text"
                          name="date"
                          [matDatepicker]="dateFromPicker"
                          (focus)="dateFromPicker.open()"
                        />
                      </div>
                    </div>
                  </section>
                </div>

                <div class="col-md-4">
                  <section>
                    <div class="form-group">
                      <label for="">Datum bis</label>
                      <div class="input-group">
                        <div class="input-group-prepend" (click)="dateFromPicker.open()">
                          <div class="input-group-text">
                            <i class="fas fa-calendar-alt"></i>
                          </div>
                        </div>
                        <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
                        <input
                          class="form-control"
                          #dateToInput
                          placeholder=""
                          [(ngModel)]="dates.to"
                          type="text"
                          name="date"
                          [matDatepicker]="dateToPicker"
                          (focus)="dateToPicker.open()"
                        />
                        <div class="input-group-append" style="cursor: pointer;" (click)="getVoucherOnclick()">
                          <div class="input-group-text primary bg-primary">
                            <i class="fas fa-search text-light"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <section>
                    <div class="form-group">
                      <label for="">Zeitraum:</label><br />
                      <!-- <button
                        class="btn"
                        [class.btn-light]="showChartType != 'next30days'"
                        [class.btn-primary]="showChartType == 'next30days'"
                        (click)="setDates('next30days')"
                      >
                        kommende 30 Tage
                      </button> -->
                      <button
                        class="btn"
                        [class.btn-light]="showChartType != 'last30days'"
                        [class.btn-primary]="showChartType == 'last30days'"
                        (click)="setDates('last30days')"
                      >
                        letzte 30 Tage
                      </button>
                      <button
                        class="btn"
                        [class.btn-light]="showChartType != 'last13months'"
                        [class.btn-primary]="showChartType == 'last13months'"
                        (click)="setDates('last13months')"
                      >
                        letzten 13 Monate
                      </button>
                      <button
                        class="btn"
                        [class.btn-light]="showChartType != 'last25months'"
                        [class.btn-primary]="showChartType == 'last25months'"
                        (click)="setDates('last25months')"
                      >
                        letzten 25 Monate
                      </button>
                    </div>
                  </section>
                </div>
              </div>
            </div>

            <div class="col-md-12" style="padding: 0px;" *ngIf="finalOrders.length">
              <a class="element-box el-tablo" style="padding: 1.65rem 0.3rem" href="javascript:return;">
                <div class="row">
                  <div class="col-sm-3 text-center">
                    <div class="label">
                      Umsatz (brutto)
                    </div>
                    <div class="value">
                      {{ filterStats?.orders.total | currency: 'EUR' }}
                    </div>
                  </div>
                  <div class="col-sm-3 text-center">
                    <div class="label">
                      Anz.
                    </div>
                    <div class="value">
                      {{ filterStats?.orders.count }}
                    </div>
                  </div>
                  <div class="col-sm-3 text-center">
                    <div class="label">
                      Stornos (brutto)
                    </div>
                    <div class="value">
                      {{ filterStats?.cancelations.total | currency: 'EUR' }}
                    </div>
                  </div>
                  <div class="col-sm-3 text-center">
                    <div class="label" style="word-break: break-all;">
                      Stornos
                    </div>
                    <div class="value">
                      {{ filterStats?.cancelations.count }}
                    </div>
                  </div>
                </div>

                <!-- <div class="value">
                  <div class="label" style="padding-top: 19px;">Zeitraum</div>
                  <small>{{ dates.from | date: 'd.M.yy' }} - {{ dates.to | date: 'd.M.yy' }}</small>
                </div>
        
                <div class="row" style="margin-top: 15px;">
                  <div class="el-tablo smaller col-sm-6">
                    <div class="label">Bar</div>
                    <div class="value">{{ filterStats?.orders.cash | currency: 'EUR' }}</div>
                  </div>
                  <div class="el-tablo smaller col-sm-6">
                    <div class="label">Online</div>
                    <div class="value">
                      {{ filterStats?.orders.online + filterStats?.orders.gastropay | currency: 'EUR' }}
                    </div>
                  </div>
                </div>
        
                <div class="row" style="margin-top: 15px;">
                  <div class="el-tablo smaller col-sm-6">
                    <div class="label">Trinkg.</div>
                    <div class="value">{{ filterStats?.orders.tip | currency: 'EUR' }}</div>
                  </div>
                  <div class="el-tablo smaller col-sm-6">
                    <div class="label">Gebühren</div>
                    <div class="value">{{ filterStats?.orders.commission | currency: 'EUR' }}</div>
                  </div>
                </div> -->
              </a>
            </div>

            <!-- <div class="col-md-12 p-0">
              <a class="element-box el-tablo" href="javascript:return;">
                <div class="row">
                  <div class="col-sm-3 text-center">
                    <div class="label">
                      Umsatz (brutto)
                    </div>
                    <div class="value">
                    </div>
                  </div>
                  <div class="col-sm-3 text-center">
                    <div class="label">
                      Anz.
                    </div>
                    <div class="value">
                    </div>
                  </div>
                  <div class="col-sm-3 text-center">
                    <div class="label">
                      Stornos (brutto)
                    </div>
                    <div class="value">
                    </div>
                  </div>
                  <div class="col-sm-3 text-center">
                    <div class="label" style="word-break: break-all;">
                      Stornos
                    </div>
                    <div class="value">
                    </div>
                  </div>
                </div>
              </a>
            </div> -->

            <div class="row">
              <div class="col-sm-12">
                <div class="element-wrapper">
                  <div class="element-box mat-wrapper">
                    <div class="chart-wrapper" *ngIf="showChart">
                      <canvas
                        baseChart
                        [datasets]="barChartData"
                        [labels]="barChartLabels"
                        [options]="barChartOptions"
                        [plugins]="barChartPlugins"
                        [legend]="barChartLegend"
                        [chartType]="barChartType"
                        [colors]="barChartColors"
                      >
                      </canvas>
                    </div>
                    <hr *ngIf="showDoughnutChart && showChart" />
                    <h5 *ngIf="showDoughnutChart">Verteilung Online Gutscheine nach Wert</h5>
                    <div class="chart-wrapper" *ngIf="showDoughnutChart">
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <canvas
                            baseChart
                            [datasets]="barChartData2"
                            [labels]="barChartLabels2"
                            [options]="barChartOptions2"
                            [plugins]="barChartPlugins"
                            [legend]="barChartLegend2"
                            [chartType]="barChartType2"
                            [colors]="barChartColors2"
                          >
                          </canvas>
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <canvas
                            baseChart
                            [datasets]="doughnutChartData"
                            [labels]="doughnutChartLabels"
                            [options]="doughnutChartOptions"
                            [plugins]="doughnutChartPlugins"
                            [legend]="barChartLegend"
                            [chartType]="doughnutChartType"
                            [colors]="doughnutChartColors"
                          >
                          </canvas>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!showDoughnutChart && !showChart">
                      <p class="text-center">No Data to Display</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Gültigkeit">
            <div class="element-box">
              <h6 class="form-header">Ende der Gültigkeit von Gutscheinen</h6>
              <div class="row">
                <div class="col-sm-12">
                  <div *ngIf="vouchersValidity && !vouchersValidity.length" class="alert alert-info">
                    No vouchers available.
                  </div>
                  <div class="table-responsive" *ngIf="vouchersValidity && vouchersValidity.length">
                    <table class="table table-padded">
                      <thead>
                        <tr>
                          <th>Datum</th>
                          <th>Summe Jahr</th>
                          <th>Summe gesamt</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let validity of vouchersValidity; let i = index">
                          <td>
                            <span>31.12.{{ validity.validUntil }}</span>
                          </td>
                          <td>
                            <span>{{ validity.residualValue | currency: 'EUR' }}</span>
                          </td>
                          <td>
                            <span>{{ validity.residualValueSum | currency: 'EUR' }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
