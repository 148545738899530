<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">
          Mitarbeiter - Administration
          <div class="element-actions">
            <button class="btn btn-primary hidden-xs-down" (click)="addStaffMember()">
              <i class="fas fa-plus-circle fa-fw"></i>
              Neuen Mitarbeiter anlegen
            </button>
          </div>
        </h6>

        <div class="element-box-tp">
          <div class="table-responsive">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <mat-slide-toggle
                    name="requestStaffCode"
                    (change)="saveClientRequestCodeSettings()"
                    [(ngModel)]="requestStaffCode"
                  >
                    Nach Geheimcode fragen.
                    <span class="info">(Fordert einen Geheimcode bei Aktionen wie die Einlösung von tickets)</span>
                  </mat-slide-toggle>
                </div>
              </div>
              <br />
              <br />
            </div>
            <ngx-datatable
              #table
              class="material striped"
              [rows]="staffEntries"
              [columnMode]="'force'"
              headerHeight="50"
              footerHeight="50"
              [limit]="pageLimit"
              [sorts]="[{ prop: 'staffId', dir: 'asc' }]"
              [rowHeight]="55"
              [cssClasses]="{
                pagerLeftArrow: 'fas fa-backward',
                pagerRightArrow: 'fas fa-forward',
                pagerPrevious: 'fas fa-step-backward',
                pagerNext: 'fas fa-step-forward'
              }"
              [messages]="{ emptyMessage: 'Keine Mitarbeiter gefunden', total: '' }"
            >
              <ngx-datatable-column
                [sortable]="true"
                prop="shorthand"
                name="Kürzel"
                [canAutoResize]="false"
                [width]="90"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span class="badge badge-dark">{{ row.shorthand | uppercase }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="true" prop="name" name="Name" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.name }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="true" prop="staffId" name="Mitarbeiter ID" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span>{{ row.staffId }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [sortable]="true" prop="staffId" name="Personal Code" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span>{{ row.secret }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="false"
                [cellClass]="'cell-align-right'"
                [resizeable]="false"
                [flexGrow]="2"
              >
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                  <button class="btn btn-light" (click)="editStaffMember(row, rowIndex)">
                    <i class="fas fa-edit fa-fw"></i>
                    <span class="">Bearbeiten</span>
                  </button>
                  <button class="btn btn-danger" (click)="deleteStaffMember(row, rowIndex)">
                    <i class="fas fa-trash-alt fa-fw"></i>
                  </button>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count">
                    <span *ngIf="selectedMessage"> {{ selectedCount.toLocaleString() }} {{ selectedMessage }} / </span>
                    Zeige
                    <strong>{{ pageSize < rowCount.toLocaleString() ? pageSize : rowCount.toLocaleString() }}</strong>
                    von <strong>{{ rowCount.toLocaleString() }}</strong> Transaktionen
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-backward'"
                    [pagerRightArrowIcon]="'fas fa-forward'"
                    [pagerPreviousIcon]="'fas fa-step-backward'"
                    [pagerNextIcon]="'fas fa-step-forward'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="table.onFooterPage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
