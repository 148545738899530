<div class="spinner-wrapper" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div class="content-box">
  <div class="row">
    <div class="col-sm-12">
      <div class="element-wrapper">
        <h6 class="element-header">Statistiken</h6>

        <div class="element-box mat-wrapper">
          <div class="row">
            <div class="col-md-3">
              <section>
                <div class="form-group">
                  <label for="">Datum von</label>
                  <div class="input-group">
                    <div class="input-group-prepend" (click)="dateFromPicker.open()">
                      <div class="input-group-text">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
                    <input
                      class="form-control"
                      #dateFromInput
                      placeholder=""
                      [(ngModel)]="dates.from"
                      type="text"
                      name="date"
                      [matDatepicker]="dateFromPicker"
                      (focus)="dateFromPicker.open()"
                      (dateChange)="filterDates('from', $event)"
                    /><!-- 
                      [min]="minDate" -->
                  </div>
                </div>
              </section>
            </div>
            <div class="col-md-3">
              <section>
                <div class="form-group">
                  <label for="">Datum bis</label>
                  <div class="input-group">
                    <div class="input-group-prepend" (click)="dateFromPicker.open()">
                      <div class="input-group-text">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
                    <input
                      class="form-control"
                      #dateToInput
                      placeholder=""
                      [(ngModel)]="dates.to"
                      type="text"
                      name="date"
                      [matDatepicker]="dateToPicker"
                      (focus)="dateToPicker.open()"
                      (dateChange)="filterDates('to', $event)"
                    />
                  </div>
                </div>
              </section>
            </div>

            <div class="col-md-3">
              <section>
                <div class="form-group">
                  <label for="singleSelect">Statistik:</label>
                  <div class="input-group">
                    <mat-select [(ngModel)]="showChartType" (selectionChange)="loading = true; prepareChartData()">
                      <mat-option *ngFor="let option of selectOptions" [value]="option.value">{{
                        option.label
                      }}</mat-option>
                    </mat-select>
                  </div>
                </div>
              </section>
            </div>

            <div class="col-md-3" *ngIf="filterClient">
              <section>
                <div class="form-group">
                  <label for="singleSelect">Filter:</label>
                  <div class="input-group">
                    <button
                      class="btn btn-light"
                      style="padding-top: 9px; padding-bottom: 8px;"
                      (click)="newFilterBy('clientId', '')"
                    >
                      {{ filterClient }}
                      <i class="fa fa-times" style="margin-left: 10px;"></i>
                    </button>
                  </div>
                </div>
              </section>
            </div>

            <div class="col-md-12">
              <section>
                <div class="form-group">
                  <label for="">Aktionen</label><br />
                  <button class="btn btn-light" (click)="setDates('today')">heute</button>&nbsp;
                  <button class="btn btn-light" (click)="setDates('yesterday')">gestern</button>&nbsp;
                  <button class="btn btn-light d-none hidden" (click)="setDates('lastSeven')">letzten 7 Tage</button>
                  <button class="btn btn-light d-none hidden" (click)="setDates('thisWeek')">akt. Woche</button>
                  <button class="btn btn-light d-none hidden" (click)="setDates('lastWeek')">letzte Woche</button>
                  <button class="btn btn-light" (click)="setDates('thisMonth')">akt. Monat</button>&nbsp;
                  <button class="btn btn-light" (click)="setDates('lastMonth')">letzter Monat</button>&nbsp;
                  <button class="btn btn-light" (click)="setDates('thisYear')">dieses Jahr</button>&nbsp;
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="element-box mat-wrapper">
          <div class="chart-wrapper" *ngIf="showChart">
            <canvas
              baseChart
              [datasets]="barChartData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [chartType]="barChartType"
              [colors]="barChartColors"
            >
            </canvas>
          </div>
        </div>
        <!--
        <div class="element-box mat-wrapper" *ngIf="canceledListArray.length && !filterClient">
          <div class="element-wrapper__">
            <h6 class="element-header">Stornierungen (scrollbar)</h6>
            <div style="max-height: 260px; overflow: scroll; display: inline-block;">
              <table class="table" style="width: auto; max-width: 100%;">
                <thead>
                  <tr>
                    <td><strong>Kundennummer</strong></td>
                    <td><strong>Bestellungen</strong></td>
                    <td><strong>Storniert</strong></td>
                    <td><strong>Storno-Quote</strong></td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of canceledListArray">
                    <td *ngIf="clientList[row] >= 20">
                      <i style="margin-right: 10px;" class="fa fa-filter" (click)="filterOrdersBy('clientId', row)"></i>
                      <a href="https://kunden.gastro.digital/client/{{ row }}/orders/overview" target="_blank">{{
                        row
                      }}</a>
                    </td>
                    <td *ngIf="clientList[row] >= 20">
                      {{ clientList[row] }}
                    </td>
                    <td *ngIf="clientList[row] >= 20">
                      {{ canceledList[row] }}
                    </td>
                    <td *ngIf="clientList[row] >= 20">
                      {{ ((100 / clientList[row]) * canceledList[row]).toFixed(2) }}&nbsp;%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
  </div>
</div>
